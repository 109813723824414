define("propertycloud/adapters/application", ["exports", "@ember-data/adapter/json-api", "propertycloud/config/environment", "ember-simple-auth/mixins/data-adapter-mixin", "ember-ajax/mixins/ajax-support"], function (_exports, _jsonApi, _environment, _dataAdapterMixin, _ajaxSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // the authorizer adds the authorisation header to all requests
  var _default = _jsonApi.default.extend(_dataAdapterMixin.default, _ajaxSupport.default, {
    host: _environment.default.APP.apiConfig.host,
    namespace: _environment.default.APP.apiConfig.namespace,
    authorize: function authorize(xhr) {
      var _this$get = this.get('session.data.authenticated'),
          access_token = _this$get.access_token;

      if (Ember.isPresent(access_token)) {
        xhr.setRequestHeader('Authorization', "Bearer ".concat(access_token));
      }
    }
  });

  _exports.default = _default;
});