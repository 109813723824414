define("propertycloud/templates/components/select-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zbUs8RR2",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"if\",[[24,[\"prompt\"]]],null,{\"statements\":[[0,\"  \"],[7,\"option\",true],[10,\"value\",\"\"],[10,\"disabled\",\"\"],[11,\"selected\",[28,\"is-not\",[[24,[\"selection\"]]],null]],[8],[0,\"\\n    \"],[1,[22,\"prompt\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[24,[\"content\"]]],[[\"key\"],[\"@identity\"]],{\"statements\":[[0,\"  \"],[7,\"option\",true],[11,\"value\",[29,[[28,\"read-path\",[[23,1,[]],[24,[\"optionValuePath\"]]],null]]]],[11,\"selected\",[28,\"is-equal-by-path\",[[23,1,[]],[24,[\"value\"]],[24,[\"optionValuePath\"]]],null]],[8],[0,\"\\n    \"],[1,[28,\"read-path\",[[23,1,[]],[24,[\"optionLabelPath\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/templates/components/select-list.hbs"
    }
  });

  _exports.default = _default;
});