define("propertycloud/initializers/agent-context", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('controller', 'agentContext', 'service:agentContext');
    application.inject('route', 'agentContext', 'service:agentContext');
  }

  var _default = {
    name: 'agentContext',
    initialize: initialize
  };
  _exports.default = _default;
});