define("propertycloud/pods/components/validation-mobile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "niB7+L1V",
    "block": "{\"symbols\":[],\"statements\":[[4,\"validation-input\",null,[[\"propertyName\",\"label\",\"fieldId\",\"changeset\"],[[24,[\"propertyName\"]],[24,[\"label\"]],[24,[\"fieldId\"]],[24,[\"changeset\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"form__control form__control--phone-number\"],[8],[0,\"\\n    \"],[1,[28,\"intl-tel-input\",null,[[\"id\",\"value\",\"initialCountry\",\"nationalMode\",\"preferredCountries\",\"excludeCountries\",\"number\",\"validationError\",\"isValidNumber\"],[[24,[\"fieldId\"]],[28,\"get\",[[24,[\"changeset\"]],[24,[\"propertyName\"]]],null],\"gb\",true,[24,[\"phoneCountries\",\"preferred\"]],[24,[\"phoneCountries\",\"exclude\"]],[28,\"get\",[[24,[\"changeset\"]],[24,[\"propertyName\"]]],null],[24,[\"validationError\"]],[24,[\"isValidNumber\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/validation-mobile/template.hbs"
    }
  });

  _exports.default = _default;
});