define("propertycloud/utilities/contract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contractCost = contractCost;

  function contractCost(contractLengthDays, monthlyCost) {
    if (contractLengthDays && monthlyCost) {
      return (contractLengthDays / 365 * (monthlyCost * 12)).toFixed(2);
    }

    return 0;
  }
});