define("propertycloud/serializers/inspection", ["exports", "propertycloud/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize: function serialize(snapshot, options) {
      var _this = this;

      var json = {};

      if (options && options.includeId) {
        var id = snapshot.id;

        if (id) {
          json[this.primaryKey] = id;
        }
      }

      snapshot.eachAttribute(function (key, attribute) {
        _this.serializeAttribute(snapshot, json, key, attribute);
      });
      snapshot.eachRelationship(function (key, relationship) {
        if (relationship.kind === 'belongsTo') {
          _this.serializeBelongsTo(snapshot, json, relationship);
        } else if (relationship.kind === 'hasMany') {
          _this.serializeHasMany(snapshot, json, relationship);
        }
      });
      var payloadJson = {
        payload: {
          inspectionId: json.id,
          propertyId: json.relationships.property.data.id,
          inspectedBy: json.relationships['inspected-by'].data.id,
          inspectionDate: json.attributes['inspection-date'],
          description: json.attributes.description,
          evidence: []
        }
      };

      if (json.relationships.evidence) {
        payloadJson.payload.evidence = json.relationships.evidence.data.map(function (evidence) {
          return evidence.id.replace("".concat(json.id, "-"), '');
        });
      }

      return payloadJson;
    }
  });

  _exports.default = _default;
});