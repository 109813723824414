define("propertycloud/validations/register-guarantor", ["exports", "ember-changeset-validations/validators", "propertycloud/validators/mobile-number", "propertycloud/validators/checked", "propertycloud/validators/filesize"], function (_exports, _validators, _mobileNumber, _checked, _filesize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    fullName: (0, _validators.validatePresence)(true),
    phoneNumber: [(0, _validators.validatePresence)(true), (0, _mobileNumber.default)()],
    dateOfBirth: (0, _validators.validatePresence)(true),
    dateCurrentAddress: (0, _validators.validatePresence)(true),
    relation: (0, _validators.validatePresence)({
      presence: true,
      message: 'Please give a valid relation'
    }),
    building: (0, _validators.validatePresence)(true),
    street: (0, _validators.validatePresence)(true),
    postcode: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 5,
      message: 'Must be a valid postcode'
    })],
    homeowner: (0, _checked.default)(true),
    proofIdImage: [(0, _validators.validatePresence)(true), (0, _filesize.default)()],
    proofAddressImage: [(0, _validators.validatePresence)(true), (0, _filesize.default)()]
  };
  _exports.default = _default;
});