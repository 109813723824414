define("propertycloud/pods/logged-in/maintenance/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8f8hZ+Hb",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Maintenance\"],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"dashboard-columns issues\"],[8],[0,\"\\n\\n\"],[0,\"  \"],[7,\"section\",true],[10,\"class\",\"dashboard-column dashboard-column--sidebar issues__sidebar\"],[8],[0,\"\\n\\n\"],[0,\"    \"],[1,[28,\"maintenance/issue-list\",null,[[\"model\",\"totalResults\",\"totalPages\",\"page\",\"property\",\"supplierAgreements\",\"selectedProperty\",\"categories\",\"supplier\",\"category\",\"loadMore\",\"reverseSort\",\"sort\",\"loadingIssues\",\"resolvedQuery\",\"scheduledQuery\",\"assignedQuery\"],[[24,[\"issues\"]],[24,[\"totalResults\"]],[24,[\"totalPages\"]],[24,[\"page\"]],[24,[\"property\"]],[24,[\"model\",\"supplierAgreements\"]],[24,[\"model\",\"selectedProperty\"]],[24,[\"model\",\"categories\"]],[24,[\"supplier\"]],[24,[\"category\"]],[28,\"action\",[[23,0,[]],\"loadMore\"],null],[28,\"action\",[[23,0,[]],\"reverseSort\"],null],[24,[\"sort\"]],[24,[\"loadingIssues\"]],[24,[\"resolved\"]],[24,[\"scheduled\"]],[24,[\"assigned\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[0,\"  \"],[7,\"section\",true],[10,\"class\",\"dashboard-column issues__main-column\"],[8],[0,\"\\n    \"],[1,[22,\"liquid-outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/maintenance/template.hbs"
    }
  });

  _exports.default = _default;
});