define("propertycloud/components/export-selector", ["exports", "ember-cli-data-export/components/export-selector"], function (_exports, _exportSelector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _exportSelector.default;
    }
  });
});