define("propertycloud/pods/components/overdue-rent-table/input-method/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qDuBezNe",
    "block": "{\"symbols\":[\"sb\",\"method\"],\"statements\":[[4,\"select-box/native\",null,[[\"class\",\"value\",\"on-select\"],[\"form__control\",[24,[\"method\"]],[28,\"action\",[[23,0,[]],\"setCheque\"],null]]],{\"statements\":[[4,\"each\",[[24,[\"methods\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"option\"]],\"expected `sb.option` to be a contextual component but found a string. Did you mean `(component sb.option)`? ('propertycloud/pods/components/overdue-rent-table/input-method/template.hbs' @ L3:C6) \"],null]],[[\"value\",\"label\"],[[23,2,[\"option\"]],[23,2,[\"name\"]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/overdue-rent-table/input-method/template.hbs"
    }
  });

  _exports.default = _default;
});