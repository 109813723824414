define("propertycloud/pods/components/uploader/drag-drop/component", ["exports", "@uppy/status-bar", "drag-drop"], function (_exports, _statusBar, _dragDrop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function toArray(list) {
    return Array.prototype.slice.call(list || [], 0);
  }

  function testDragSupport() {
    var div = document.createElement('div');

    if (!('draggable' in div) || !('ondragstart' in div && 'ondrop' in div)) {
      return false;
    }

    if (!('FormData' in window)) {
      return false;
    }

    if (!('FileReader' in window)) {
      return false;
    }

    return true;
  }

  var _default = Ember.Component.extend({
    classNameBindings: ['isDragOver', 'isDragDropSupported'],
    classNames: ['drag-drop-uploader'],
    init: function init() {
      this._super.apply(this, arguments);

      this.restrictions = this.uppy.opts.restrictions;
      var opts = {};
      var defaultLocale = {
        strings: {
          dropHereOr: 'Drop files here or %{browse}',
          browse: 'browse'
        }
      }; // Default options

      var defaultOpts = {
        target: null,
        inputName: 'files[]',
        width: '100%',
        height: '100%',
        note: null,
        locale: defaultLocale
      }; // Merge default options with the ones set by user

      this.opts = Object.assign({}, defaultOpts, opts);
      this.id = this.opts.id || 'DragDrop'; // placeholder for event removal function from dragDrop

      this.removeDragDropListener = null;
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.removeDragDropListener = (0, _dragDrop.default)(this.element, {
        onDrop: function onDrop(files) {
          _this.handleDrop(files);

          _this.uppy.log(files);
        },
        onDragEnter: function onDragEnter() {},
        onDragOver: function onDragOver() {
          Ember.set(_this, 'isDragOver', true);
        },
        onDragLeave: function onDragLeave() {
          Ember.set(_this, 'isDragOver', false);
        }
      });
      this.uppy.use(_statusBar.default, {
        target: '.drag-drop-uploader__progress',
        hideUploadButton: true,
        hideAfterFinish: true
      });
      this.uppy.on('complete', function (result) {
        var successful = result.successful;

        if (_this.restrictions.maxNumberOfFiles === 1) {
          Ember.set(_this, 'success', "".concat(successful[0].name, " was uploaded sucessfully!"));
        } else {
          Ember.set(_this, 'success', "".concat(successful.length, " file").concat(successful.length > 1 ? 's were' : ' was', " uploaded sucessfully!"));
        }
      });
      Ember.set(this, 'isDragDropSupported', testDragSupport());
    },
    willDestroyElement: function willDestroyElement() {
      this.removeDragDropListener();
    },
    handleDrop: function handleDrop(files) {
      var _this2 = this;

      Ember.set(this, 'error', null);
      Ember.set(this, 'success', null);
      files.forEach(function (file) {
        try {
          _this2.uppy.addFile({
            source: _this2.id,
            name: file.name,
            type: file.type,
            data: file
          });
        } catch (err) {
          Ember.set(_this2, 'error', err.toString().replace('Error: ', ''));
        }
      });
    },
    actions: {
      handleInputChange: function handleInputChange(ev) {
        var files = toArray(ev.target.files);
        this.handleDrop(files);
      }
    }
  });

  _exports.default = _default;
});