define("propertycloud/pods/logged-in/tenant/maintenance/detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var issueController = this.controllerFor('logged-in.tenant.maintenance');
      var currentTenancy = Ember.get(issueController, 'tenancy');
      Ember.set(model, 'currentTenancy', currentTenancy);
    },
    activate: function activate() {
      Ember.run.schedule('afterRender', function () {
        document.querySelector('.issues__main-column').classList.add('is-open');
      });
    },
    deactivate: function deactivate() {
      document.querySelector('.issues__main-column').classList.remove('is-open');
    }
  });

  _exports.default = _default;
});