define("propertycloud/adapters/application-in-progress", ["exports", "propertycloud/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    agentContext: Ember.inject.service(),
    findAll: function findAll() {
      var url = "".concat(this.host, "/").concat(this.namespace, "/estate/agent.applications-in-progress?agentId=").concat(this.get('agentContext.id'));
      return this.ajax(url, 'GET');
    }
  });

  _exports.default = _default;
});