define("propertycloud/pods/components/overdue-rent-table/toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T43vkhvz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[10,\"class\",\"switch-light switch-material\"],[10,\"onclick\",\"\"],[8],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[24,[\"selected\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[7,\"strong\",true],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"span\",true],[8],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"Off\"],[9],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"On\"],[9],[0,\"\\n    \"],[7,\"a\",true],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/overdue-rent-table/toggle/template.hbs"
    }
  });

  _exports.default = _default;
});