define("propertycloud/controllers/freestyle", ["exports", "ember-freestyle/controllers/freestyle"], function (_exports, _freestyle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _freestyle.default.extend({
    emberFreestyle: Ember.inject.service(),

    /* BEGIN-FREESTYLE-USAGE fp--notes
    ### A few notes regarding freestyle-palette
    - Accepts a colorPalette POJO like the one found in the freestyle.js blueprint controller
    - Looks very nice
    And another thing...
    ###### Markdown note demonstrating prettified code
    ```
    import Ember from 'ember';
    export default Ember.Component.extend({
    // ...
    colorPalette: {
      'primary': {
        'name': 'cyan',
        'base': '#00bcd4'
      },
      'accent': {
        'name': 'amber',
        'base': '#ffc107'
      }
    }
    // ...
    });
    ```
    END-FREESTYLE-USAGE */
    colorPalette: Ember.computed(function () {
      return {
        'primary': {
          'name': 'cyan',
          'base': '#00bcd4'
        },
        'accent': {
          'name': 'amber',
          'base': '#ffc107'
        },
        'secondary': {
          'name': 'greyish',
          'base': '#b6b6b6'
        },
        'foreground': {
          'name': 'blackish',
          'base': '#212121'
        },
        'background': {
          'name': 'white',
          'base': '#ffffff'
        }
      };
    })
  });

  _exports.default = _default;
});