define("propertycloud/pods/components/add-workspace-tenancy/tenant-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['tenant-card'],
    classNameBindings: ['incomplete:tenant-card--empty'],
    editable: true,
    incomplete: Ember.computed('agreement.completed', function () {
      return !this.agreement.completed;
    }),
    total: Ember.computed('agreement.paymentSchedule.payments.[]', function () {
      var total = 0;
      this.agreement.paymentSchedule.payments.forEach(function (payment) {
        total += Number(payment.amountDue.amount);
      });
      return total;
    }),
    actions: {
      editAgreement: function editAgreement() {
        Ember.set(this, 'selectedAgreement', this.agreement);
        Ember.set(this, 'editingTenant', true);
        Ember.set(this, 'step', 4);
      }
    }
  });

  _exports.default = _default;
});