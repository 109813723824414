define("propertycloud/pods/logged-in/tenant/overview/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    lat: null,
    lng: null,
    zoom: 17,
    markers: null,
    mapError: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.roommates = this.roommates || [];
    },
    tenancyContractLink: Ember.computed('model.tenancy', function () {
      var contracts = Ember.get(this, 'model.tenancy.contracts');

      if (typeof contracts !== 'undefined' && typeof contracts[0] !== 'undefined') {
        return contracts[0].contract;
      }

      return null;
    }),
    updateRooommates: function updateRooommates(tenancy) {
      var _this = this;

      var agreements = Ember.get(tenancy, 'agreements');
      Ember.set(this, 'roommates', []);
      agreements.then(function (agreements) {
        var tenants = Ember.get(agreements, 'meta.tenants');
        var currentUserId = Ember.get(_this, 'user.userId');
        tenants.forEach(function (tenant) {
          if (Ember.get(tenant, 'id') !== currentUserId && Ember.get(tenant, 'voided') === false && Ember.get(tenant, 'agreement-type') !== 'past') {
            Ember.get(_this, 'roommates').pushObject(tenant.name);
          }
        });
      });
    },
    updatePropertyMap: function updatePropertyMap(postcode) {
      var _this2 = this;

      var postcodeLookup = this.ajax.request("https://api.postcodes.io/postcodes/".concat(postcode));
      postcodeLookup.then(function (data) {
        if (data.status === 200) {
          // set lat/lng
          var lat = data.result.latitude;
          var lng = data.result.longitude;
          Ember.set(_this2, 'lat', lat);
          Ember.set(_this2, 'lng', lng);
          Ember.set(_this2, 'markers', Ember.A([{
            id: 'place-marker',
            lat: lat,
            lng: lng,
            icon: '/map-marker.svg'
          }]));
        }
      }).catch(function () {
        Ember.set(_this2, 'mapError', true);
      });
    }
  });

  _exports.default = _default;
});