define("propertycloud/helpers/format-currency", ["exports", "accounting/format-money"], function (_exports, _formatMoney) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCurrency = formatCurrency;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function formatCurrency(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        _ref2$ = _ref2[0],
        value = _ref2$ === void 0 ? 0 : _ref2$,
        _ref2$2 = _ref2[1],
        hash = _ref2$2 === void 0 ? {} : _ref2$2;

    var result = Number(value) / 100;

    if (hash.hideSign) {
      if (hash.roundToPound) {
        result = Math.round(result);
      }

      result = result.toFixed(2);
      return result;
    } else {
      var currency = (0, _formatMoney.default)(result, '£', 2);
      var symbolRegex = /[$\xA2-\xA5\u058F\u060B\u09F2\u09F3\u09FB\u0AF1\u0BF9\u0E3F\u17DB\u20A0-\u20BD\uA838\uFDFC\uFE69\uFF04\uFFE0\uFFE1\uFFE5\uFFE6]/;

      var _currency$match = currency.match(symbolRegex),
          _currency$match2 = _slicedToArray(_currency$match, 1),
          symbol = _currency$match2[0];

      var price = currency.split('.');
      var pounds = price[0].replace(symbolRegex, '');
      var pennies = price[1] ? ".".concat(price[1]) : '';
      var html = "<span class=\"u-price\"><span class=\"u-price__symbol\">".concat(symbol, "</span><span class=\"u-price__pounds\">").concat(pounds, "</span><span class=\"u-price__pennies\">").concat(pennies, "</span></span>");

      if (hash.noResize) {
        html = "".concat(symbol).concat(pounds).concat(pennies);
      }

      return Ember.String.htmlSafe(html);
    }
  }

  var _default = Ember.Helper.helper(formatCurrency);

  _exports.default = _default;
});