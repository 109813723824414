define("propertycloud/validators/filesize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateFilesize;

  // validators/filesize.js
  function filesizeFormat(value) {
    var i, filesize;
    var units = ['B', 'KB', 'MB', 'GB', 'TB'];

    for (i = 0; i < units.length; i++) {
      if (value < 1024) {
        filesize = Math.floor(value) + units[i];
        break;
      }

      value = value / 1024;
    }

    return filesize;
  }

  function validateFilesize() {
    var max = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1024 * 1024 * 6;
    return function (key, newValue) {
      if (typeof newValue !== 'undefined') {
        var b64Size = 3 * Math.ceil(newValue.length / 4);

        if (b64Size > max) {
          return "The image you have selected is ".concat(filesizeFormat(b64Size), ". Please resize your image to reduce the filesize lower than ").concat(filesizeFormat(max), ".");
        }
      }

      return true;
    };
  }
});