define("propertycloud/pods/logged-in/manage/property/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sms: Ember.inject.service(),
    shortAddress: Ember.computed('model.address.{flat,building,street}', function () {
      var flat = Ember.get(this, 'model.address.flat') || null;
      var building = Ember.get(this, 'model.address.building');
      var street = Ember.get(this, 'model.address.street');
      return {
        flat: flat,
        building: building,
        street: street
      };
    }),
    actions: {
      send: function send(id) {
        var sms = this.sms;
        sms.sendMessage('upcomingTenants', id, 'hello there');
      }
    }
  });

  _exports.default = _default;
});