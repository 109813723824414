define("propertycloud/mixins/setup-tenant-communication-history", ["exports", "propertycloud/helpers/format-currency", "moment"], function (_exports, _formatCurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var reminders = {
    '-2': {
      message: 'First rent',
      emailPreview: 'https://s3.eu-west-1.amazonaws.com/propertycloud-static-assets/rent-reminders_advance.png'
    },
    '0': {
      message: 'Second rent',
      emailPreview: 'https://s3-eu-west-1.amazonaws.com/propertycloud-static-assets/rent-reminders_today.png'
    },
    '2': {
      message: 'Third rent',
      emailPreview: 'https://s3-eu-west-1.amazonaws.com/propertycloud-static-assets/rent-reminders_2-to-9-days.png'
    },
    '10': {
      message: 'Final rent',
      emailPreview: 'https://s3-eu-west-1.amazonaws.com/propertycloud-static-assets/rent-reminders_10-days.png'
    }
  };

  function closest(num, arr) {
    var _arr2 = _slicedToArray(arr, 1),
        curr = _arr2[0];

    var diff = Math.abs(num - curr);

    for (var val = 0; val < arr.length; val++) {
      var newdiff = Math.abs(num - arr[val]);

      if (newdiff < diff) {
        diff = newdiff;
        curr = arr[val];
      }
    }

    return curr;
  }

  var _default = Ember.Mixin.create({
    user: Ember.inject.service(),
    setupController: function setupController(controller) {
      var _this = this;

      this._super.apply(this, arguments);

      var ajax = this.ajax;
      var messages = [{
        message: "Welcome to your new PropertyCloud dashboard.\n\n      You can make payments directly, view details about your property, report maintenance issues and keep your details up to date with more features in development.\n\n      You can always contact ".concat(Ember.get(this, 'agentContext.tradingName'), " on ").concat(Ember.get(this, 'agentContext.contactNumber'), " or email at ").concat(Ember.get(this, 'agentContext.contactEmail')),
        time: new Date('2017-05-08T17:12:46+01:00'),
        outgoing: true
      }];
      Ember.set(controller, 'messages', []);
      ajax.request('/estate/tenant.communication-history', {
        data: {
          agentId: Ember.get(this, 'agentContext.id'),
          tenantId: this.tenantId
        }
      }).then(function (response) {
        response.data.forEach(function (obj) {
          var messageObj = {
            message: obj.attributes.message,
            time: new Date(obj.attributes.timeSent),
            outgoing: true,
            channel: obj.attributes.channel,
            sender: obj.attributes.sender
          };

          if (obj.attributes.channel === 'email') {
            messageObj.overdueAmount = obj.attributes.overdueAmount;
            messageObj.overdueSince = obj.attributes.overdueSince;
            var daysOverdue = 1;
            var amount = (0, _formatCurrency.formatCurrency)([messageObj.overdueAmount.amount, {
              noResize: true
            }]);
            var timeSent = (0, _moment.default)(messageObj.time).startOf('day');
            var overdueSince = (0, _moment.default)(messageObj.overdueSince).startOf('day');
            daysOverdue = timeSent.diff(overdueSince, 'days');
            var currentReminder = reminders["".concat(closest(daysOverdue, [-2, 0, 2, 10]))];
            var currentReminderMessage = currentReminder && currentReminder.message || 'Rent';

            if (daysOverdue < 0) {
              messageObj.message = "".concat(currentReminderMessage, " reminder email sent. ").concat(amount, " will be due in ").concat(Math.abs(daysOverdue), " day").concat(daysOverdue !== 1 ? 's' : '', ".");
            } else if (daysOverdue === 0) {
              messageObj.message = "".concat(currentReminderMessage, " reminder email sent. ").concat(amount, " is due today.");
            } else {
              messageObj.message = "".concat(currentReminderMessage, " reminder email sent. ").concat(amount, " has been overdue for ").concat(Math.abs(daysOverdue), " day").concat(daysOverdue !== 1 ? 's' : '', ".");
            }

            if (Ember.get(_this, 'user.isAgent')) {
              messageObj.message += " <a href=\"".concat(currentReminder.emailPreview, "\" target=\"_blank\">View Example Email</a>");
            }
          }

          messages.push(messageObj);
        });
        Ember.set(controller, 'messages', messages);
      });
    }
  });

  _exports.default = _default;
});