define("propertycloud/pods/logged-in/manage/property/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/H6Uc6EJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"error-page\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[8],[0,\"Error :(\"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"Something has gone wrong. Try refreshing your browser.\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/manage/property/error/template.hbs"
    }
  });

  _exports.default = _default;
});