define("propertycloud/pods/components/landlord-statements-approval/preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EMAwUw0M",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",\"btn btn--tiny btn--icon\"],[12,\"disabled\",[22,\"isGenerating\"]],[3,\"action\",[[23,0,[]],\"downloadPdf\"]],[8],[7,\"span\",true],[8],[0,\"Preview\"],[9],[0,\" \"],[4,\"if\",[[24,[\"isGenerating\"]]],null,{\"statements\":[[1,[28,\"svg-jar\",[\"spinner\"],null],false]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/landlord-statements-approval/preview/template.hbs"
    }
  });

  _exports.default = _default;
});