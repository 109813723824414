define("propertycloud/validations/record-income", ["exports", "ember-changeset-validations/validators", "ember-changeset-conditional-validations/validators/sometimes"], function (_exports, _validators, _sometimes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    type: (0, _validators.validatePresence)(true),
    tenant: (0, _sometimes.default)((0, _validators.validatePresence)(true), function () {
      if (this.get('type.option') !== 'credit') return true;
      return false;
    }),
    company: (0, _sometimes.default)((0, _validators.validatePresence)(true), function () {
      if (this.get('type.option') === 'credit') return true;
      return false;
    }),
    property: (0, _validators.validatePresence)(true),
    amount: (0, _validators.validatePresence)(true),
    paymentDate: (0, _validators.validatePresence)(true),
    method: (0, _validators.validatePresence)(true),
    chequeNumber: (0, _sometimes.default)((0, _validators.validatePresence)(true), function () {
      if (this.get('method.option') === 'cheque') return true;
      return false;
    })
  };
  _exports.default = _default;
});