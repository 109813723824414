define("propertycloud/pods/logged-in/manage/property/inspections/edit/route", ["exports", "propertycloud/utilities/scrollTo"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var parentModel = this.modelFor('logged-in/manage/property');
      var inspection = null;

      if (this.store.peekRecord('inspection', params.inspection_id)) {
        inspection = this.store.findRecord('inspection', params.inspection_id);
      } else {
        inspection = this.store.createRecord('inspection', {
          id: params.inspection_id,
          inspectionDate: new Date(),
          property: parentModel
        });
      }

      return Ember.RSVP.hash({
        inspection: inspection,
        staff: this.store.query('staff-member', {
          agentId: Ember.get(this, 'agentContext.id')
        })
      });
    },
    setupController: function setupController(controller, model) {
      Ember.set(controller, 'inspection', model.inspection);
      Ember.set(controller, 'staff', model.staff);
    },
    activate: function activate() {
      (0, _scrollTo.default)(document.querySelector('.app-internal-nav__outlet'), 0, 300);
    }
  });

  _exports.default = _default;
});