define("propertycloud/pods/components/add-workspace/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wSpqxjbG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"section\",true],[10,\"class\",\"off-workspace add-workspace\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isShown\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"class\",\"off-workspace__overlay\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"openAlert\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"off-workspace__panel\"],[8],[0,\"\\n    \"],[14,1,[[28,\"hash\",null,[[\"body\"],[[28,\"component\",[[24,[\"workspaceContext\"]]],[[\"property\",\"landlord\",\"hideWorkspace\"],[[24,[\"property\"]],[24,[\"landlord\"]],[28,\"action\",[[23,0,[]],\"hideWorkspace\"],null]]]]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"isAlertShown\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"off-workspace__overlay off-workspace__overlay--centered\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"off-workspace__alert\"],[8],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"off-workspace__alert-message\"],[8],[0,\"Are you sure you want to close the form?\"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"form__group form__group--centered\"],[8],[0,\"\\n\"],[4,\"hold-button\",null,[[\"type\",\"action\",\"delay\"],[\"btn btn--negative\",\"hideWorkspace\",1000]],{\"statements\":[[0,\"            Hold to Confirm\\n\"]],\"parameters\":[]},null],[0,\"          \"],[7,\"button\",false],[12,\"class\",\"btn btn--neutral\"],[3,\"action\",[[23,0,[]],\"closeAlert\"],[[\"bubbles\"],[false]]],[8],[0,\"Cancel\"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/add-workspace/template.hbs"
    }
  });

  _exports.default = _default;
});