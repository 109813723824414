define("propertycloud/pods/components/money-up/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D12wMuAM",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"count-up\",null,[[\"startVal\",\"endVal\",\"duration\",\"decimals\",\"useEasing\",\"useGrouping\",\"separator\",\"decimal\",\"prefix\",\"formattingFn\"],[[24,[\"startVal\"]],[24,[\"endVal\"]],[24,[\"duration\"]],2,true,true,\",\",\".\",\"£\",[24,[\"format\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/money-up/template.hbs"
    }
  });

  _exports.default = _default;
});