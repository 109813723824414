define("propertycloud/pods/tenancy/guarantor/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    user: Ember.inject.service(),
    model: function model(params) {
      var user = this.user;
      user.tenantInfo();
      var propertyId = params.property_id;
      Ember.set(this, 'propertyId', propertyId);
      Ember.set(this, 'params', params);
      var tenancyNumber = Ember.get(user, 'upcomingTenancyNumber');
      var tenancyQuery = {
        propertyId: propertyId,
        tenancyNumber: tenancyNumber,
        tenantId: Ember.get(user, 'userId')
      };

      if (Ember.get(this, 'user.isAgent')) {
        tenancyQuery.include = 'agreements';
      }

      return this.store.queryRecord('tenancy', tenancyQuery);
    },
    setupController: function setupController(controller) {
      Ember.set(controller, 'params', this.params);

      this._super.apply(this, arguments);
    },
    afterModel: function afterModel(model) {
      var unapproved = Ember.get(model, 'totalUnapprovedTenants');
      Ember.set(model, 'hasRoommates', unapproved > 1);
    }
  });

  _exports.default = _default;
});