define("propertycloud/pods/components/fullwidth-tabs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fomcM+hg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"pane\",\"activeId\",\"select\",\"navItems\"],[[28,\"component\",[\"fullwidth-tabs/tab-pane\"],[[\"parent\",\"activeId\"],[[23,0,[]],[24,[\"isActiveId\"]]]]],[24,[\"isActiveId\"]],[28,\"action\",[[23,0,[]],\"select\"],null],[24,[\"navItems\"]]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/fullwidth-tabs/template.hbs"
    }
  });

  _exports.default = _default;
});