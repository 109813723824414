define("propertycloud/pods/components/landlord-statements-approval/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kKuQJLXw",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[4,\"each\",[[24,[\"columns\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[28,\"concat\",[\"light-table/cells/\",[23,1,[\"cellType\"]]],null],[23,1,[]],[24,[\"row\"]]],[[\"table\",\"rawValue\",\"tableActions\"],[[24,[\"table\"]],[28,\"get\",[[24,[\"row\"]],[23,1,[\"valuePath\"]]],null],[24,[\"tableActions\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/landlord-statements-approval/row/template.hbs"
    }
  });

  _exports.default = _default;
});