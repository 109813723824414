define("propertycloud/pods/logged-in/tenant/my-details/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    agentContext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    model: function model() {
      var user = Ember.get(this, 'user');
      var tenantId = Ember.get(user, 'userId');
      return Ember.RSVP.hash({
        tenant: this.store.queryRecord('tenant', {
          tenantId: tenantId,
          agentId: this.agentContext.id
        })
      });
    },
    afterModel: function afterModel(model) {
      if (Ember.isBlank(Ember.get(model.tenant, 'photoId'))) {
        Ember.set(model.tenant, 'photoId', {
          data: null
        });
      }

      if (Ember.isBlank(Ember.get(model.tenant, 'studentId'))) {
        Ember.set(model.tenant, 'studentId', {
          data: null
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setModelMap(model.tenant);
    }
  });

  _exports.default = _default;
});