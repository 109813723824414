define("propertycloud/adapters/viewing", ["exports", "propertycloud/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    query: function query(store, type, _query) {
      var url = "".concat(this.host, "/").concat(this.namespace, "/viewings/viewings.list");
      var data = _query;
      return this.ajax(url, 'GET', {
        data: data
      });
    },
    findRecord: function findRecord(store, type, id) {
      var url = "".concat(this.host, "/").concat(this.namespace, "/viewings/viewing.detail");
      var data = {
        id: id,
        included: 'assignedRep'
      };
      return this.ajax(url, 'GET', {
        data: data
      });
    }
  });

  _exports.default = _default;
});