define("propertycloud/validators/in-future", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateInFuture;

  function validateInFuture() {
    return function (key, newValue) {
      var isInPast = (0, _moment.default)(newValue).isBefore((0, _moment.default)());

      if (isInPast) {
        return "Date cannot be in the past";
      }

      return true;
    };
  }
});