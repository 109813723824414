define("propertycloud/pods/components/uploader/upload-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qQb8+YEW",
    "block": "{\"symbols\":[\"@buttonText\",\"&default\"],\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"  \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"drag-drop-uploader__target\"],[8],[0,\"\\n\\n    \"],[7,\"label\",true],[10,\"class\",\"drag-drop-uploader__browse btn btn--small btn--positive\"],[8],[0,\"\\n\\n      \"],[7,\"input\",true],[10,\"class\",\"hidden-input\"],[11,\"name\",[23,0,[\"opts\",\"inputName\"]]],[11,\"multiple\",[28,\"not\",[[23,0,[\"restrictions\",\"maxNumberOfFiles\"]],1],null]],[11,\"accept\",[23,0,[\"restrictions\",\"allowedFileTypes\"]]],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"handleInputChange\"],null]],[10,\"value\",\"\"],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\\n      \"],[7,\"span\",true],[10,\"class\",\"drag-drop-uploader__label\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"error\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"drag-drop-uploader__error\"],[8],[0,\"\\n        \"],[1,[23,0,[\"error\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,0,[\"success\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"drag-drop-uploader__success\"],[8],[0,\"\\n        \"],[1,[23,0,[\"success\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"drag-drop-uploader__progress\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/uploader/upload-button/template.hbs"
    }
  });

  _exports.default = _default;
});