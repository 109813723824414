define("propertycloud/helpers/snake-to-camel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.snakeToCamel = snakeToCamel;
  _exports.default = void 0;

  // Doesnt really need to be an "ember" helper but we may as well.
  function snakeToCamel(str) {
    return str.replace(/([-_][a-z])/g, function (group) {
      return group.toUpperCase().replace('-', '').replace('_', '');
    });
  }

  var _default = Ember.Helper.helper(snakeToCamel);

  _exports.default = _default;
});