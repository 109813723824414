define("propertycloud/pods/logged-in/manage/landlord/controller", ["exports", "moment", "ember-cli-uuid", "propertycloud/validations/edit-landlord"], function (_exports, _moment, _emberCliUuid, _editLandlord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    errorHandler: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    overviewPeriod: null,
    isValidNumber: true,
    validationError: null,
    photoIdFile: null,
    ValidationFields: _editLandlord.default,
    init: function init() {
      this._super.apply(this, arguments); // Create a changeset object to map this model properties to. This is a workaround to solve an issue with ember-changeset@1.4.1 nested validation properties. SM


      this.modelMap = this.modelMap || {};
    },
    // Map model properties to changeset object
    setModelMap: function setModelMap(landlord) {
      var mapObj = Ember.get(this, 'modelMap');
      Ember.set(mapObj, 'name', Ember.get(landlord, 'primaryContact.name'));
      Ember.set(mapObj, 'mobileNumber', Ember.get(landlord, 'primaryContact.mobile-number'));
      Ember.set(mapObj, 'building', Ember.get(landlord, 'address.building'));
      Ember.set(mapObj, 'street', Ember.get(landlord, 'address.street'));
      Ember.set(mapObj, 'area', Ember.get(landlord, 'address.area'));
      Ember.set(mapObj, 'town', Ember.get(landlord, 'address.town'));
      Ember.set(mapObj, 'county', Ember.get(landlord, 'address.county'));
      Ember.set(mapObj, 'postcode', Ember.get(landlord, 'address.postcode'));
      Ember.set(mapObj, 'accountName', Ember.get(landlord, 'bankAccount.account-name'));
      Ember.set(mapObj, 'accountNumber', Ember.get(landlord, 'bankAccount.account-number'));
      Ember.set(mapObj, 'accountSortCode', Ember.get(landlord, 'bankAccount.sort-code'));
    },
    getFinancials: function getFinancials() {
      var landlordId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Ember.get(this, 'model.landlord.id');
      var date = (0, _moment.default)().subtract('1', Ember.get(this, 'overviewPeriod')).format('YYYY-MM-DD');
      var today = (0, _moment.default)().format('YYYY-MM-DD');
      Ember.set(this, 'financials', {
        income: this.store.query('transaction', {
          landlordId: landlordId,
          dateFrom: date,
          dateTo: today,
          income: 1,
          excludePaymentType: 'admin-fee,deposit'
        }),
        spent: this.store.query('transaction', {
          landlordId: landlordId,
          dateFrom: date,
          dateTo: today,
          expenditure: 1
        }),
        overdue: this.store.queryRecord('landlord-property-overdue-rent', {
          id: landlordId,
          date: today
        })
      });
    },
    updateFinancials: function updateFinancials() {
      var _this = this;

      var _EmberGet = Ember.get(this, 'financials'),
          income = _EmberGet.income,
          spent = _EmberGet.spent,
          overdue = _EmberGet.overdue;

      income.then(function (transactions) {
        var total = _this.calculateTotal(transactions);

        Ember.set(_this, 'incomePaid', total);
      });
      spent.then(function (transactions) {
        var total = _this.calculateTotal(transactions);

        Ember.set(_this, 'maintenanceSpent', total);
      });
      overdue.then(function (data) {
        var properties = Ember.get(data, 'properties');

        var total = _this.calculateTotalOverdue(properties);

        Ember.set(_this, 'overdueRent', total);
      });
    },
    calculateTotal: function calculateTotal(transactions) {
      var total = 0;
      transactions.forEach(function (transaction) {
        total += Number(Ember.get(transaction, 'amount.amount'));
      });
      return total;
    },
    calculateTotalOverdue: function calculateTotalOverdue(properties) {
      var total = 0;
      properties.forEach(function (property) {
        total += Number(property['overdue-rent']['overdue-amount'].amount);
      });
      return total;
    },
    actions: {
      saveData: function saveData(changeset) {
        var _this2 = this;

        Ember.set(this, 'isSaving', true); // Validate the changeset before saving

        changeset.validate().then(function () {
          var model = Ember.get(_this2, 'model');
          var errorHandler = Ember.get(_this2, 'errorHandler');
          var photoIdData = Ember.get(model.landlord, 'photoId.data');

          if (!changeset.get('isValid')) {
            Ember.set(_this2, 'isSaving', false);
            Ember.set(_this2, 'saveSuccessful', false);
            return;
          } // Update model with Changeset


          Ember.set(model.landlord, 'primaryContact.name', Ember.get(changeset, 'name'));
          Ember.set(model.landlord, 'primaryContact.mobile-number', Ember.get(changeset, 'mobileNumber'));
          Ember.set(model.landlord, 'address.building', Ember.get(changeset, 'building'));
          Ember.set(model.landlord, 'address.street', Ember.get(changeset, 'street'));
          Ember.set(model.landlord, 'address.area', Ember.get(changeset, 'area'));
          Ember.set(model.landlord, 'address.town', Ember.get(changeset, 'town'));
          Ember.set(model.landlord, 'address.county', Ember.get(changeset, 'county'));
          Ember.set(model.landlord, 'address.postcode', Ember.get(changeset, 'postcode'));
          Ember.set(model.landlord, 'bankAccount.account-name', Ember.get(changeset, 'accountName'));
          Ember.set(model.landlord, 'bankAccount.account-number', Ember.get(changeset, 'accountNumber'));
          Ember.set(model.landlord, 'bankAccount.sort-code', Ember.get(changeset, 'accountSortCode')); // set uuid for photo id

          if (Ember.isPresent(photoIdData)) {
            if (photoIdData.match(/(^data:)/ig)) {
              var base64 = Ember.get(model.landlord, 'photoId.data');
              Ember.set(model.landlord, 'photoId', {
                'document-id': (0, _emberCliUuid.uuid)(),
                data: base64
              });
            }
          }

          Ember.get(_this2, 'model.landlord').save({
            adapterOptions: {
              agentId: Ember.get(_this2, 'agentContext.id')
            }
          }).then(function () {
            errorHandler.handleSuccess();
            Ember.set(_this2, 'isSaving', false);
            Ember.set(_this2, 'saveSuccessful', true);
            Ember.run.later(function () {
              Ember.set(_this2, 'saveSuccessful', false);
            }, 3000);
          }).catch(function (error) {
            errorHandler.handleError(error);
            Ember.set(_this2, 'isSaving', false);
            Ember.set(_this2, 'saveSuccessful', false);
          });
        });
      },
      changePeriod: function changePeriod(period) {
        Ember.set(this, 'overviewPeriod', period);
        this.getFinancials();
        this.updateFinancials();
      }
    }
  });

  _exports.default = _default;
});