define("propertycloud/pods/logged-in/index/route", ["exports", "propertycloud/mixins/send-user"], function (_exports, _sendUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_sendUser.default, {});

  _exports.default = _default;
});