define("propertycloud/pods/components/repeater-form-field/component", ["exports", "lodash/find", "ember-changeset", "ember-changeset-validations", "propertycloud/validations/secondary-contacts"], function (_exports, _find2, _emberChangeset, _emberChangesetValidations, _secondaryContacts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['repeater-form-field'],
    column: false,
    fieldsBlank: false,
    ValidationFields: _secondaryContacts.default,
    init: function init() {
      this._super.apply(this, arguments);

      this.changeset = new _emberChangeset.default({
        itemName: null,
        itemEmail: null,
        itemMobile: null
      }, (0, _emberChangesetValidations.default)(_secondaryContacts.default), _secondaryContacts.default);
      this.items = this.items || [];
    },
    actions: {
      validateItem: function validateItem(property) {
        var changeset = this.changeset;
        changeset.validate(property);
      },
      addItem: function addItem() {
        var _this = this;

        var changeset = this.changeset;
        changeset.validate().then(function () {
          if (_this.changeset.get('isInvalid')) {
            return;
          }

          var items = _this.items;
          var alreadyExist = (0, _find2.default)(items, function (o) {
            return o.name === _this.itemName && o.emailAddress === _this.itemEmail && o.mobileNumber === _this.itemMobile;
          });

          if (alreadyExist) {
            return;
          }

          var obj = {
            name: Ember.get(_this, 'changeset.itemName'),
            'email-address': Ember.get(_this, 'changeset.itemEmail'),
            'mobile-number': Ember.get(_this, 'changeset.itemMobile')
          };
          items.pushObject(obj);

          _this.changeset.rollback();

          document.getElementById('SecondContactMobile').value = ''; // force clear input
        });
      },
      removeItem: function removeItem(item) {
        var items = this.items;
        items.removeObject(item);
      }
    }
  });

  _exports.default = _default;
});