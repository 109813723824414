define("propertycloud/pods/components/property-room-breakdown/entry/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UT1nmcbx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"property-cost-item__head\"],[8],[0,\"\\n\"],[0,\"  \"],[7,\"h3\",true],[8],[1,[22,\"roomNumber\"],false],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"property-cost-item__details\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"property-cost-item__weekly\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"form__label\"],[8],[0,\"Monthly cost\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"form__control-wrap form__control-wrap--money\"],[8],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"type\",\"class\",\"value\",\"key-up\"],[\"number\",\"form__control\",[24,[\"poundPrice\"]],[28,\"action\",[[23,0,[]],\"updateMonthlyPrice\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"property-cost-item__actions\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn--tiny btn--neutral\"],[12,\"disabled\",[22,\"isBenchmark\"]],[3,\"action\",[[23,0,[]],[24,[\"markAsBenchmark\"]],[24,[\"index\"]]]],[8],[0,\"Benchmark\"],[9],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn--tiny btn--negative\"],[3,\"action\",[[23,0,[]],[24,[\"removeEntry\"]],[24,[\"index\"]]]],[8],[0,\"Remove\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/property-room-breakdown/entry/template.hbs"
    }
  });

  _exports.default = _default;
});