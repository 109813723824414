define("propertycloud/pods/components/count-up/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this._insertOrUpdate();
    },
    _insertOrUpdate: function _insertOrUpdate() {
      if (Ember.isPresent(Ember.get(this, 'instance'))) {
        this._update();
      } else {
        this._insertAndStartCountUp();
      }
    },
    _update: function _update() {
      Ember.get(this, 'instance').update(Ember.get(this, 'endVal'));
    },
    _insertAndStartCountUp: function _insertAndStartCountUp() {
      var _this = this;

      Ember.run.next(function () {
        var countup = new CountUp(Ember.get(_this, 'elementId'), Ember.get(_this, 'startVal') || 0, Ember.get(_this, 'endVal') || 0, Ember.get(_this, 'decimals') || 0, Ember.get(_this, 'duration') || 1, {
          useEasing: Ember.get(_this, 'useEasing'),
          easingFn: Ember.get(_this, 'easingFn'),
          useGrouping: Ember.get(_this, 'useGrouping'),
          separator: Ember.get(_this, 'separator') || ',',
          decimal: Ember.get(_this, 'decimal') || '.',
          prefix: Ember.get(_this, 'prefix') || '',
          suffix: Ember.get(_this, 'suffix') || '',
          formattingFn: Ember.get(_this, 'formattingFn')
        });

        if (_this.onComplete) {
          countup.start(function () {
            if (_this.element) {
              _this.element.querySelector('span').classList.add('u-price--completed');

              _this.onComplete();
            }
          });
        } else {
          countup.start(function () {
            if (_this.element) {
              _this.element.querySelector('span').classList.add('u-price--completed');
            }
          });
        }

        Ember.set(_this, 'instance', countup);
      });
    }
  });

  _exports.default = _default;
});