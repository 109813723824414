define("propertycloud/pods/components/overdue-rent/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    classNames: ['overdue-rent'],
    chequeSelected: 0,
    init: function init() {
      this._super.apply(this, arguments);

      this.defaultDate = this.defaultDate || new Date();
      this.selected = this.selected || [];
    },
    submit: function submit(tenant) {
      var ajax = this.ajax;
      var payload = {
        payload: {
          type: 'rent',
          amount: {
            amount: Ember.get(tenant, 'paidAmount'),
            currency: 'GBP'
          },
          payment: {
            date: (0, _moment.default)(Ember.get(tenant, 'paidDate')[0]).format('YYYY-MM-DD h:mm:ss'),
            method: Ember.get(tenant, 'paidMethod')
          },
          metadata: {
            propertyId: Ember.get(tenant, 'propertyId'),
            tenantId: Ember.get(tenant, 'id'),
            note: null
          }
        }
      };

      if (Ember.get(tenant, 'paidMethod') === 'cheque') {
        payload.payload.payment.metadata = {
          chequeNumber: Ember.get(tenant, 'chequeNumber')
        };
      }

      var request = ajax.post('estate/property.record-income', {
        data: JSON.stringify(payload)
      });
      request.then(function () {
        var paid = Ember.get(tenant, 'paidAmount');
        var due = Ember.get(tenant, 'amountDue');

        if (due - paid <= 0) {
          tenant.unloadRecord();
        } else {
          Ember.set(tenant, 'amountDue', due - paid);
        }
      });
    },
    actions: {
      selectCheque: function selectCheque(add) {
        if (add) {
          this.incrementProperty('chequeSelected');
        } else {
          this.decrementProperty('chequeSelected');
        }
      },
      submitAllSelected: function submitAllSelected() {
        var _this = this;

        var selected = this.selected;
        selected.forEach(function (tenant) {
          _this.submit(tenant);
        });
      },
      select: function select(tenant) {
        this.selected.pushObject(tenant);
      },
      unselect: function unselect(tenant) {
        this.selected.removeObject(tenant);
      }
    }
  });

  _exports.default = _default;
});