define("propertycloud/utilities/domTraversal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FindAncestor = FindAncestor;
  _exports.IsDescendant = IsDescendant;
  _exports.FindSiblings = FindSiblings;

  /* Matches Polyfill */
  function matchesPolyfill() {
    // matches polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }
  }
  /* Checks whether a node is a descendant of another node */


  function IsDescendant(parent, child) {
    var node = child.parentNode;

    while (node != null) {
      if (node == parent) {
        return true;
      }

      node = node.parentNode;
    }

    return false;
  }
  /* Finds a parent element, similar to javascript .closest() */


  function FindAncestor(el, sel) {
    matchesPolyfill();

    while ((el = el.parentElement) && !(el.matches || el.matchesSelector).call(el, sel)) {
      ;
    }

    return el;
  }
  /**
   * Find the sibling elements of the element provided to the function, similar to jQuery .siblings() 
   * @param {node} el Element you are finding the siblings of
   * @param {string} sel selector of element you want to search for
   * @returns {array} Array of sibling nodes
   */


  function FindSiblings(el, sel) {
    matchesPolyfill();
    return Array.prototype.filter.call(el.parentNode.children, function (child) {
      if (sel) {
        return child !== el && child.matches(sel);
      }

      return child !== el;
    });
  }
});