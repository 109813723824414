define("propertycloud/pods/components/requires-attention-tenancies/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F16RZ0IX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[8],[4,\"link-to\",null,[[\"route\",\"model\"],[[24,[\"routeString\"]],[24,[\"propertyId\"]]]],{\"statements\":[[1,[22,\"propertyAddress\"],false]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"style\",\"width:120px;text-align:center;\"],[8],[1,[22,\"roomsInUse\"],false],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"style\",\"width:120px;text-align:center;\"],[8],[1,[22,\"totalRooms\"],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/requires-attention-tenancies/row/template.hbs"
    }
  });

  _exports.default = _default;
});