define("propertycloud/pods/logged-in/manage/property/tenants/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    showDropdown: true,
    showReplace: false,
    showRemoveTenant: false,
    showingInviteTenantDatepicker: false,
    currentTenancies: null,
    currentTenancyNumber: null,
    currentTenants: null,
    pastTenants: null,
    provsionalTenancies: null,
    pendingTenancies: null,
    currentTenantsLoading: true,
    pastTenantsLoading: true,
    provisionalTenanciesLoading: true,
    pendingTenanciesLoading: true,
    currentTenanciesWithUnapprovedTenants: Ember.computed('currentTenancies', {
      get: function get() {
        var tenancies = Ember.get(this, 'currentTenancies');
        return tenancies.filter(function (tenancy) {
          if (Ember.get(tenancy, 'tenancyType') === 'current' && Ember.get(tenancy, 'voided') === false && Ember.get(tenancy, 'isDeleted') === false && Ember.get(tenancy, 'totalUnapprovedTenants') > 0) {
            return tenancy;
          }
        });
      },
      set: function set(_, value) {
        return value;
      }
    }),
    currentTenanciesWithUpcomingTenants: Ember.computed('currentTenancies', {
      get: function get() {
        var tenancies = Ember.get(this, 'currentTenancies');
        return tenancies.filter(function (tenancy) {
          if (Ember.get(tenancy, 'tenancyType') === 'current' && Ember.get(tenancy, 'voided') === false && Ember.get(tenancy, 'isDeleted') === false && Ember.get(tenancy, 'totalNumberOfUpcomingTenants') > Ember.get(tenancy, 'totalUnapprovedTenants')) {
            return tenancy;
          }
        });
      },
      set: function set(_, value) {
        return value;
      }
    }),
    currentEmails: Ember.computed('currentTenants', {
      get: function get() {
        var emails = '';
        var tenants = Ember.get(this, 'currentTenants');
        tenants.forEach(function (tenant) {
          emails += "".concat(tenant.email, ";");
        });
        return emails;
      },
      set: function set(_, value) {
        return value;
      }
    }),
    setCurrentTenants: function setCurrentTenants(tenancies) {
      var _this = this;

      if (!tenancies) return;
      var currentTenants = [];
      tenancies.forEach(function (tenancy) {
        if (Ember.get(tenancy, 'tenancyType') === 'current' && Ember.get(tenancy, 'voided') === false) {
          var tenants = Ember.get(tenancy, 'agreements.content.meta.tenants');
          var agreements = Ember.get(tenancy, 'agreements');
          var tenancyNumber = Ember.get(tenancy, 'tenancyNumber'); // @TODO: this is not future proof, you can have mutliple current tenancies

          Ember.set(_this, "currentTenancyNumber", tenancyNumber);
          tenants.forEach(function (tenant) {
            tenant.tenancyNumber = tenancyNumber;
          });
          agreements.forEach(function (agreement, index) {
            if (Ember.get(agreement, 'state.status') === 'current') {
              var tenant = tenants[index];
              tenant.voided = false;
              tenant.start = Ember.get(agreement, 'start');
              tenant.end = Ember.get(agreement, 'end');
              currentTenants.pushObject(tenant);
            }
          });
        }
      });
      Ember.set(this, 'currentTenants', currentTenants);
      Ember.set(this, 'currentTenantsLoading', false);
    },
    setPastTenants: function setPastTenants(tenancies) {
      if (!tenancies) return;
      var pastTenants = [];
      tenancies.forEach(function (tenancy) {
        var tenants = Ember.get(tenancy, 'agreements.content.meta.tenants');
        var agreements = Ember.get(tenancy, 'agreements');
        agreements.forEach(function (agreement, index) {
          if (Ember.get(agreement, 'state.status') === 'past') {
            pastTenants.pushObject(tenants[index]);
          }
        });
      });
      Ember.set(this, 'pastTenants', pastTenants);
      Ember.set(this, 'pastTenantsLoading', false);
    },
    setProvisionalTenants: function setProvisionalTenants(tenancies) {
      var provisionalTenancies = tenancies.filter(function (tenancy) {
        if (Ember.get(tenancy, 'tenancyType') === 'upcoming' && Ember.get(tenancy, 'voided') === false && Ember.get(tenancy, 'isDeleted') === false && Ember.get(tenancy, 'totalUnapprovedTenants') > 0) {
          return tenancy;
        }
      });
      Ember.set(this, 'provisionalTenancies', provisionalTenancies);
      Ember.set(this, 'provisionalTenanciesLoading', false);
    },
    setPendingTenancies: function setPendingTenancies(tenancies) {
      var pendingTenancies = tenancies.filter(function (tenancy) {
        if (Ember.get(tenancy, 'tenancyType') === 'upcoming' && Ember.get(tenancy, 'voided') === false && Ember.get(tenancy, 'isDeleted') === false && Ember.get(tenancy, 'totalUnapprovedTenants') < 1) {
          return tenancy;
        }
      });
      Ember.set(this, 'pendingTenancies', pendingTenancies);
      Ember.set(this, 'pendingTenanciesLoading', false);
    },
    // Used when transitioning away from route.
    resetData: function resetData() {
      this.setProperties({
        currentTenantsLoading: true,
        pastTenantsLoading: true,
        provisionalTenanciesLoading: true,
        pendingTenanciesLoading: true,
        currentTenancies: null,
        currentTenants: null,
        upcomingTenancies: null,
        provisionalTenancies: null,
        pendingTenancies: null,
        currentTenanciesWithUnapprovedTenants: null,
        currentTenanciesWithUpcomingTenants: null,
        currentEmails: null
      });
    },
    actions: {
      verifyUpcomingTenancy: function verifyUpcomingTenancy(tenancyNumber) {
        var ajax = Ember.get(this, 'ajax');
        ajax.post('/estate/property.approve-tenancy', {
          data: JSON.stringify({
            payload: {
              tenancyNumber: tenancyNumber,
              propertyId: Ember.get(this, 'propertyId')
            }
          })
        });
      },
      hideTenancy: function hideTenancy(tenancy) {
        if (Ember.get(tenancy, 'tenancyType') === 'current') {
          Ember.get(this, 'currentTenanciesWithUnapprovedTenants').removeObject(tenancy);
        } else {
          Ember.get(this, 'provisionalTenancies').removeObject(tenancy);
        }
      },
      voidUpcomingTenancy: function voidUpcomingTenancy(tenancyNumber) {
        var ajax = Ember.get(this, 'ajax');
        ajax.post('/estate/property.void-tenancy', {
          data: JSON.stringify({
            payload: {
              tenancyNumber: tenancyNumber,
              propertyId: Ember.get(this, 'propertyId')
            }
          })
        });
      },
      endTenant: function endTenant(tenant) {
        var ajax = Ember.get(this, 'ajax');
        Ember.set(tenant, 'voided', true);
        ajax.post('/estate/property.end-individual-tenancy', {
          data: JSON.stringify({
            payload: {
              tenancyNumber: Ember.get(tenant, 'tenancyNumber'),
              tenantId: Ember.get(tenant, 'id'),
              propertyId: Ember.get(this, 'propertyId')
            }
          })
        });
      },
      voidTenant: function voidTenant(tenant) {
        var ajax = Ember.get(this, 'ajax');
        ajax.post('/estate/property.void-individual-tenancy', {
          data: JSON.stringify({
            payload: {
              tenancyNumber: Ember.get(tenant, 'tenancyNumber'),
              tenantId: Ember.get(tenant, 'id'),
              propertyId: Ember.get(this, 'propertyId')
            }
          })
        });
      },
      triggerRemoveTenant: function triggerRemoveTenant() {
        Ember.set(this, 'showRemoveTenant', true);
        Ember.set(this, 'showDropdown', false);
      },
      tenantReplace: function tenantReplace() {
        Ember.set(this, 'showReplace', true);
        Ember.set(this, 'showDropdown', false);
      },
      resetDropdown: function resetDropdown() {
        Ember.set(this, 'showReplace', false);
        Ember.set(this, 'showRemoveTenant', false);
        Ember.set(this, 'showDropdown', true);
      },
      resetState: function resetState() {//
      },
      preventCloseIfDatepickerShowing: function preventCloseIfDatepickerShowing() {
        if (Ember.get(this, 'showingInviteTenantDatepicker')) {
          return false;
        }
      },
      refreshProvisionalTenancies: function refreshProvisionalTenancies() {
        // This is ran after a successful tenant re-invite.
        var propertyId = this.get('property.id');
        this.flashMessages.clearMessages();
        this.get('getTenants').perform(propertyId, 'upcoming', this);
        this.flashMessages.success('Your tenant has been re-invited successfully.');
      }
    }
  });

  _exports.default = _default;
});