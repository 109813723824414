define("propertycloud/adapters/tenant", ["exports", "propertycloud/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // we want everything to use the host and namespace defined in the
  // application adapter so we extend from that instead
  var _default = _application.default.extend({
    queryRecord: function queryRecord(store, type, query) {
      var url = "".concat(this.host, "/").concat(this.namespace, "/estate/tenant.view-contact-details");
      return this.ajax(url, 'GET', {
        data: query
      });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var url = "".concat(this.host, "/").concat(this.namespace, "/estate/tenant.update-contact-details");
      var serializer = store.serializerFor(type.modelName);
      var data = {}; // this is a funky function in that it modifes the data directly
      // without needing to reassign a returned object

      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      }); // return this.errorHandledRequest( this.ajax(url, 'POST', { data: data }) );

      return this.ajax(url, 'POST', {
        data: data
      });
    }
  });

  _exports.default = _default;
});