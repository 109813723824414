define("propertycloud/pods/logged-in/manage/tenant/route", ["exports", "propertycloud/mixins/setup-tenant-communication-history"], function (_exports, _setupTenantCommunicationHistory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Route.extend(_setupTenantCommunicationHistory.default, {
    agentContext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    queryParams: {
      tenancy: {
        refreshModel: true
      }
    },
    model: function model(params) {
      Ember.set(this, 'tenantId', params.tenant_id);
      Ember.set(this, 'requestedTenancyId', params.tenancy); // Query doesn't need to include tenancy agreements. A separate query is already made for this

      var tenancyQuery = {
        tenantId: params.tenant_id,
        agentId: Ember.get(this, 'agentContext.id'),
        individualStatuses: 'current,past,upcoming'
      };
      return Ember.RSVP.hash({
        tenant: this.store.queryRecord('tenant', {
          tenantId: params.tenant_id,
          agentId: this.agentContext.id
        }),
        paymentSchedule: null,
        notes: this.store.queryRecord('tenant-note', {
          tenantId: params.tenant_id,
          agentId: Ember.get(this, 'agentContext.id')
        }),
        tenancies: this.store.query('tenancy', tenancyQuery)
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;

      // get tenancy details to make more data requests
      var requestedTenancyId = Ember.get(this, 'requestedTenancyId');
      var unvoidedTenancies = Ember.get(model, 'tenancies').filterBy('voided', false);
      Ember.set(model, 'tenancies', unvoidedTenancies);
      var tenancy, tenancyRequested, tenancyCurrent, tenancyUpcoming, tenancyPast;
      var tenanciesGroupedByProperty = {};
      unvoidedTenancies.forEach(function (tenancy) {
        if (!Ember.isArray(tenanciesGroupedByProperty[Ember.get(tenancy, 'property.id')])) {
          tenanciesGroupedByProperty[Ember.get(tenancy, 'property.id')] = [];
        }

        tenanciesGroupedByProperty[Ember.get(tenancy, 'property.id')].push(Ember.get(tenancy, 'tenancyNumber'));
      });

      if (Ember.isPresent(requestedTenancyId)) {
        var _unvoidedTenancies$fi = unvoidedTenancies.filterBy('id', requestedTenancyId);

        var _unvoidedTenancies$fi2 = _slicedToArray(_unvoidedTenancies$fi, 1);

        tenancyRequested = _unvoidedTenancies$fi2[0];
      } else {
        var _unvoidedTenancies$fi3 = unvoidedTenancies.filterBy('tenancyType', 'current');

        var _unvoidedTenancies$fi4 = _slicedToArray(_unvoidedTenancies$fi3, 1);

        tenancyCurrent = _unvoidedTenancies$fi4[0];

        var _unvoidedTenancies$fi5 = unvoidedTenancies.filterBy('tenancyType', 'upcoming');

        var _unvoidedTenancies$fi6 = _slicedToArray(_unvoidedTenancies$fi5, 1);

        tenancyUpcoming = _unvoidedTenancies$fi6[0];

        var _unvoidedTenancies$fi7 = unvoidedTenancies.filterBy('tenancyType', 'past');

        var _unvoidedTenancies$fi8 = _slicedToArray(_unvoidedTenancies$fi7, 1);

        tenancyPast = _unvoidedTenancies$fi8[0];
      }

      if (Ember.isPresent(tenancyRequested)) {
        // if we request a particular tenancy, use that
        tenancy = tenancyRequested;
      } else if (Ember.isEmpty(tenancyRequested) && Ember.isPresent(tenancyCurrent)) {
        // if there is a current tenancy and another hasn't been requested
        tenancy = tenancyCurrent;
      } else if (Ember.isEmpty(tenancyCurrent) && Ember.isEmpty(tenancyRequested) && Ember.isPresent(tenancyUpcoming)) {
        // if upcoming and no current set to upcoming
        tenancy = tenancyUpcoming;
      } else if (Ember.isEmpty(tenancyRequested) && Ember.isEmpty(tenancyCurrent) && Ember.isEmpty(tenancyUpcoming) && Ember.isPresent(tenancyPast)) {
        // if there are no other tenancies but past tenancies
        tenancy = tenancyPast;
      } // if tenant has a tenancy, we fetch more information to display


      if (Ember.isPresent(tenancy)) {
        Ember.set(model, 'tenancy', tenancy); // variables to use in requests

        var tenantId = Ember.get(model, 'tenant.id');
        var propertyId = Ember.get(model, 'tenancy.property.id'); // payment history

        this.store.query('transaction', {
          tenantId: tenantId,
          propertyId: propertyId,
          splitPayments: 1
        }).then(function (history) {
          Ember.set(model, 'paymentHistory', history);
        }); // tenancy agreement

        var agreementId = "".concat(propertyId, "-").concat(Ember.get(tenancy, 'tenancyNumber'), "-").concat(tenantId);
        this.store.findRecord('tenancy-agreement', agreementId, {
          reload: true
        }).then(function (agreement) {
          Ember.set(model, 'agreement', agreement);
        }); // This whole chunk needs refactoring at some point, as is triggering a duplicate request.
        // Would be far too much work at the moment though
        // payment schedule request

        var scheduleRequest = this.store.queryRecord('tenancy-payment-schedule', {
          tenantId: tenantId,
          tenancyNumber: Ember.get(tenancy, 'tenancyNumber'),
          propertyId: propertyId
        }); // get all schedules for current property

        var schedules = tenanciesGroupedByProperty[propertyId].map(function (tenancyNumber) {
          var ajax = Ember.get(_this, 'ajax');
          return ajax.request('/estate/property.view-tenant-payment-schedule', {
            data: {
              tenantId: tenantId,
              tenancyNumber: tenancyNumber,
              propertyId: propertyId
            }
          });
        });
        Ember.RSVP.all(schedules).then(function (response) {
          var fullSchedule = [];
          response.forEach(function (schedule) {
            var dates = Ember.get(schedule, 'data.attributes.schedule');
            dates.forEach(function (date) {
              fullSchedule.push(date);
            });
          }); // set full propertySchedule

          Ember.set(model, 'propertySchedule', fullSchedule);
        });
        scheduleRequest.then(function (response) {
          // we set these properties so they can be used when saving the model (required by the api)
          Ember.set(model, 'paymentSchedule', response);
        }).catch(function (error) {
          var tenancyNumber = Ember.get(model.tenancy, 'tenancyNumber');

          if (_typeof(error.payload) == 'object' && typeof error.payload.error == 'string' && error.payload.error == 'no-payment-schedule') {
            _this.flashMessages.warning("No payment schedule was found for tenancy #".concat(tenancyNumber, ". Please provide one."));

            var record = _this.store.push({
              data: {
                id: tenantId,
                type: 'tenancy-payment-schedule',
                attributes: {
                  schedule: []
                }
              }
            });

            Ember.set(model, 'paymentSchedule', record);
            return;
          } // this.flashMessages.danger("We could not find a payment schedule for this tenancy (#"+tenancyNumber+").");

        }).finally(function () {
          Ember.set(model, 'paymentSchedule.tenantId', tenantId);
          Ember.set(model, 'paymentSchedule.propertyId', propertyId);
          Ember.set(model, 'paymentSchedule.tenancyNumber', Ember.get(model.tenancy, 'tenancyNumber'));
        }); // property details request

        var propertyRequest = this.store.findRecord('property-detail', propertyId);
        propertyRequest.then(function (response) {
          Ember.set(model, 'propertyDetails', response);
        }); // guarantor details request

        var guarantorRequest = this.store.queryRecord('tenant-guarantor', {
          id: Ember.get(model.tenant, 'id'),
          tenancyNumber: Ember.get(model.tenancy, 'tenancyNumber'),
          propertyId: propertyId
        });
        guarantorRequest.then(function (response) {
          Ember.set(model, 'guarantor', response);
        }); // Failed request is handled by ajax service
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      Ember.set(controller, 'totalPaid', 0);
      Ember.set(controller, 'amountDue', 0);
      controller.setModelMap(model.tenant);
    }
  });

  _exports.default = _default;
});