define("propertycloud/pods/components/edit-certificates/certificate/component", ["exports", "ember-cli-uuid", "moment", "propertycloud/config/environment"], function (_exports, _emberCliUuid, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    classNameBindings: ['isExpired'],
    uploadUrl: "".concat(_environment.default.APP.apiConfig.host, "/").concat(_environment.default.APP.apiConfig.namespace, "/documents/document.upload"),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'uniqueUploadId', (0, _emberCliUuid.uuid)());
      Ember.set(this, 'uniqueUploadName', (0, _emberCliUuid.uuid)());
    },
    isExpired: Ember.computed('expiryDate', function () {
      var expiryDate = Ember.get(this, 'expiryDate');
      return (0, _moment.default)().isAfter((0, _moment.default)(expiryDate));
    }).readOnly(),
    actions: {
      changeDate: function changeDate(_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
            date = _ref2[0];

        var reportType = Ember.get(this, 'type');
        var updateReport = Ember.get(this, 'updateAction');
        updateReport(reportType, {
          'expiry-date': (0, _moment.default)(date).format('YYYY-MM-DD')
        });
      },
      upload: function upload(file) {
        var headers = {};

        var _this$get = this.get('session.data.authenticated'),
            access_token = _this$get.access_token;

        if (Ember.isPresent(access_token)) {
          headers.Authorization = "Bearer ".concat(access_token);
        }

        var documentId = (0, _emberCliUuid.uuid)();
        var reportType = Ember.get(this, 'type');
        var updateReport = Ember.get(this, 'updateAction');
        file.upload("".concat(Ember.get(this, 'uploadUrl'), "?documentId=").concat(documentId), {
          headers: headers
        }).then(function (response) {
          updateReport(reportType, {
            'document-id': documentId,
            'src': response.body.url
          });
        });
      },
      error: function error() {}
    }
  });

  _exports.default = _default;
});