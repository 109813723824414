define("propertycloud/pods/components/income-expenditure-table/undo/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      deletePayment: function deletePayment() {
        this.get('tableActions.deletePayment')(this.row);
      }
    }
  });

  _exports.default = _default;
});