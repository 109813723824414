define("propertycloud/pods/logged-in/manage/property/features/controller", ["exports", "lodash/find"], function (_exports, _find2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var furnishTypes = [{
    value: 'furnished',
    name: 'Furnished'
  }, {
    value: 'part-furnished',
    name: 'Part Furnished'
  }, {
    value: 'not-furnished',
    name: 'Not Furnished'
  }];
  var propertyTypes = [{
    value: 'house',
    name: 'House'
  }, {
    value: 'flat',
    name: 'Flat'
  }, {
    value: 'detached',
    name: 'Detached'
  }, {
    value: 'semi-detached',
    name: 'Semi-detached'
  }];

  var _default = Ember.Controller.extend({
    errorHandler: Ember.inject.service(),
    hasChanged: false,
    furnishTypes: furnishTypes,
    propertyTypes: propertyTypes,
    selectedFurnishType: Ember.computed('furnishTypes', 'model.furnishType', function () {
      var _this = this;

      if (Ember.get(this, 'model.furnishType')) {
        return (0, _find2.default)(Ember.get(this, 'furnishTypes'), function (obj) {
          return obj.value == Ember.get(_this, 'model.furnishType');
        });
      }

      return null;
    }),
    selectedPropertyType: Ember.computed('propertyTypes', 'model.propertyType', function () {
      var _this2 = this;

      if (Ember.get(this, 'model.propertyType')) {
        return (0, _find2.default)(Ember.get(this, 'propertyTypes'), function (obj) {
          return obj.value === Ember.get(_this2, 'model.propertyType');
        });
      }

      return null;
    }),
    actions: {
      saveData: function saveData() {
        var _this3 = this;

        Ember.set(this, 'isSaving', true);
        var errorHandler = Ember.get(this, 'errorHandler');
        Ember.RSVP.hash({
          features: Ember.get(this, 'model').save()
        }).then(function () {
          errorHandler.handleSuccess();
          Ember.set(_this3, 'isSaving', false);
          Ember.set(_this3, 'saveSuccessful', true);
          Ember.run.later(function () {
            Ember.set(_this3, 'saveSuccessful', false);
          }, 3000);
        }).catch(function (error) {
          errorHandler.handleError(error);
          Ember.set(_this3, 'isSaving', false);
          Ember.set(_this3, 'saveSuccessful', false);
        });
      },
      selectFurnishType: function selectFurnishType(furnishType) {
        Ember.set(this, 'model.furnishType', furnishType.value);
        Ember.set(this, 'selectedFurnishType', furnishType);
      },
      selectPropertyType: function selectPropertyType(propertyType) {
        Ember.set(this, 'model.propertyType', propertyType.value);
        Ember.set(this, 'selectedPropertyType', propertyType);
      }
    }
  });

  _exports.default = _default;
});