define("propertycloud/pods/components/overdue-rent-table/input-method/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var methods = [{
    name: 'Chip & Pin',
    option: 'chip-and-pin'
  }, {
    name: 'Cash',
    option: 'cash'
  }, {
    name: 'Bank Transfer',
    option: 'bank-transfer'
  }, {
    name: 'Cheque',
    option: 'cheque'
  }];

  var _default = Ember.Component.extend({
    methods: methods,
    method: methods[0],
    actions: {
      setCheque: function setCheque(selected) {
        var row = Ember.get(this, 'row');
        Ember.set(this, 'method', selected);

        if (selected === 'cheque') {
          Ember.get(this, 'tableActions.showCheque')(row);
        } else {
          Ember.get(this, 'tableActions.hideCheque')(row);
        }

        Ember.set(row, 'paidMethod', selected);
      }
    }
  });

  _exports.default = _default;
});