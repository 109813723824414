define("propertycloud/pods/components/uploader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CrR6fpVF",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"dragDrop\",\"uploadButton\",\"gallery\",\"linkUpload\",\"imagePreview\",\"progress\",\"preparing\",\"uppy\"],[[28,\"component\",[\"uploader/drag-drop\"],[[\"uppy\",\"buttonText\"],[[23,0,[\"uppyInstance\"]],\"Browse for files\"]]],[28,\"component\",[\"uploader/upload-button\"],[[\"uppy\",\"buttonText\"],[[23,0,[\"uppyInstance\"]],\"Browse for files\"]]],[28,\"component\",[\"uploader/gallery\"],[[\"uppy\"],[[23,0,[\"uppyInstance\"]]]]],[28,\"component\",[\"uploader/link-upload\"],[[\"uppy\"],[[23,0,[\"uppyInstance\"]]]]],[28,\"component\",[\"uploader/image-preview\"],[[\"uppy\"],[[23,0,[\"uppyInstance\"]]]]],[23,0,[\"progress\"]],[23,0,[\"preparingFile\"]],[23,0,[\"uppyInstance\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/uploader/template.hbs"
    }
  });

  _exports.default = _default;
});