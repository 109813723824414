define("propertycloud/validations/edit-landlord", ["exports", "ember-changeset-validations/validators", "propertycloud/validators/mobile-number", "propertycloud/validators/masked"], function (_exports, _validators, _mobileNumber, _masked) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: (0, _validators.validatePresence)({
      presence: true,
      message: 'Landlord name can\'t be blank'
    }),
    mobileNumber: [(0, _validators.validatePresence)(true), (0, _mobileNumber.default)()],
    building: (0, _validators.validatePresence)(true),
    street: (0, _validators.validatePresence)(true),
    postcode: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 5,
      message: 'Must be a valid postcode'
    })],
    accountName: (0, _validators.validatePresence)(true),
    accountNumber: [(0, _masked.default)({
      is: 8,
      char: '_'
    })],
    accountSortCode: [(0, _masked.default)({
      is: 6,
      char: ['_', '-']
    })]
  };
  _exports.default = _default;
});