define("propertycloud/pods/components/property-room-breakdown/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    rentMapping: null,
    benchmarkRoom: null,
    numRooms: Ember.computed('rentMapping.[]', function () {
      return this.rentMapping.length;
    }),
    actions: {
      addEntry: function addEntry() {
        this.rentMapping.pushObject({
          // name: '',
          amount: '0',
          currency: 'GBP'
        }); // set benchmarkRoom to 0 if null

        if (this.benchmarkRoom === null) {
          this.set('benchmarkRoom', 0);
        }
      },
      removeEntry: function removeEntry(index) {
        var result = this.rentMapping.filter(function (obj, i) {
          return i !== index;
        });
        this.set('rentMapping', result); // update benchmarkRoom...

        var benchmark = this.benchmarkRoom;
        var numRooms = this.rentMapping.length;

        if (numRooms === 0) {
          this.set('benchmarkRoom', null);
        } else if (benchmark >= numRooms) {
          this.set('benchmarkRoom', numRooms - 1);
        }
      },
      markAsBenchmark: function markAsBenchmark(index) {
        this.set('benchmarkRoom', index);
      }
    }
  });

  _exports.default = _default;
});