define("propertycloud/pods/logged-in/manage/property/certificates/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    errorHandler: Ember.inject.service(),
    hasChanged: false,
    actions: {
      saveData: function saveData() {
        var _this = this;

        Ember.set(this, 'isSaving', true);
        var errorHandler = Ember.get(this, 'errorHandler');
        Ember.get(this, 'model').save().then(function () {
          errorHandler.handleSuccess();
          Ember.set(_this, 'isSaving', false);
          Ember.set(_this, 'saveSuccessful', true);
          Ember.run.later(function () {
            Ember.set(_this, 'saveSuccessful', false);
          }, 3000);
          Ember.set(_this, 'hasChanged', false);
        }).catch(function (error) {
          errorHandler.handleError(error);
          Ember.set(_this, 'isSaving', false);
          Ember.set(_this, 'saveSuccessful', false);
        });
      },
      setChanged: function setChanged() {
        Ember.set(this, 'hasChanged', true);
      }
    }
  });

  _exports.default = _default;
});