define("propertycloud/pods/components/payment-schedule-percentage/component", ["exports", "big.js", "moment"], function (_exports, _big, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['schedule'],
    contractedAmount: null,
    profile: null,
    advanceMonthBy: 1,
    date: null,
    error: null,
    scheduledAmount: Ember.computed('profile.@each', function () {
      // as a percentage
      var totalPercent = new _big.default(0);
      var profile = Ember.get(this, 'profile');

      if (profile) {
        for (var i = 0; i < profile.length; i++) {
          totalPercent = totalPercent.plus(profile[i].percentage);
        }
      }

      return totalPercent.toFixed(11);
    }),
    isValid: Ember.computed('scheduledAmount', function () {
      var scheduled = new _big.default(Ember.get(this, 'scheduledAmount')).toFixed(2); // return (scheduled >= 99.99 && scheduled <= 100.01);

      return parseFloat(scheduled, 10) === 100;
    }),
    didInsertElement: function didInsertElement() {
      // // store current year (to determine whether entries are year 1 or 2)
      // let d = new Date();
      // set(this, 'currentYear', d.getFullYear());
      // set profile to empty array if null
      if (Ember.get(this, 'profile') === null) {
        Ember.set(this, 'profile', Ember.A());
      }
    },
    sortedProfile: Ember.computed.sort('profile', function (a, b) {
      var aDate = (0, _moment.default)(a.date);
      var bDate = (0, _moment.default)(b.date);

      if (aDate.isAfter(bDate)) {
        return 1;
      } else if (aDate.isSame(bDate)) {
        return 0;
      }

      return -1;
    }),
    pushToProfile: function pushToProfile(date, amount) {
      var profile = Ember.get(this, 'profile');
      var contractedAmount = new _big.default(Ember.get(this, 'contractedAmount'));
      var day = date.getDate();
      var month = date.getMonth();
      var currentYear = Ember.get(this, 'currentYear');
      var year = date.getFullYear() - currentYear + 1; // build identifier string

      var identifier = "".concat(day, "-").concat(month, "-").concat(year); // calculate percentage of contractedAmount from amount (input in pounds)

      var percentage = 0;

      if (contractedAmount > 0) {
        percentage = new _big.default(amount).times(100).div(contractedAmount).times(100);
      } // does an entry for this date exist? If not we create a new one, if so we update the assigned percentage value


      var result = profile.find(function (obj) {
        return obj.identifier === identifier;
      });

      if (typeof result === 'undefined') {
        profile.pushObject(Object.create({
          identifier: identifier,
          'date': {
            day: day,
            month: month,
            year: year
          },
          'percentage': percentage.toFixed(11)
        }));
      } else {
        Ember.set(result, 'percentage', percentage.toFixed(11));
      } // force to recalculate any observed properties
      // also, removing this seems to break the model.save()


      this.notifyPropertyChange('profile');
    },
    //eslint-disable-next-line
    updateValueInScheduleWhenContractedAmountChanges: Ember.observer('contractedAmount', function () {
      var newContractedAmount = Ember.get(this, 'contractedAmount');
      var profile = Ember.get(this, 'profile');

      if (profile !== null) {
        for (var i = 0; i < profile.length; i++) {
          var item = profile[i];
          Ember.set(item, 'amount', item.percentage / 100 * newContractedAmount);
        }
      }

      this.notifyPropertyChange('profile');
    }),
    actions: {
      addToPaymentSchedule: function addToPaymentSchedule() {
        Ember.get(this, 'setHasChanged')();
        var amount = Ember.get(this, 'amount');
        var date = Ember.get(this, 'date');
        var error = null;

        if (!amount || !date) {
          error = 'Please provide a date and amount due';
          Ember.set(this, 'error', error);
          return;
        }

        if (Number(amount) <= 0) {
          error = 'Amount due must be greater than 0';
          Ember.set(this, 'error', error);
          return;
        }

        Ember.set(this, 'error', error);
        var dateObj = new Date(date);
        this.pushToProfile(dateObj, amount);
        var advanceMonthBy = Ember.get(this, 'advanceMonthBy');
        Ember.set(this, 'date', [(0, _moment.default)(date[0]).add(advanceMonthBy, 'month').toDate()]);
      },
      removeFromPaymentSchedule: function removeFromPaymentSchedule(entry) {
        var id = entry.identifier;
        var result = Ember.get(this, 'profile').filter(function (obj) {
          return obj.identifier !== id;
        });
        Ember.set(this, 'profile', result);
      }
    }
  });

  _exports.default = _default;
});