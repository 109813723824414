define("propertycloud/adapters/feedback", ["exports", "propertycloud/adapters/application", "ember-data-url-templates"], function (_exports, _application, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberDataUrlTemplates.default, {
    queryUrlTemplate: '{+host}/{+namespace}/maintenance/feedback.list{?query*}&fields[feedback]=score,note,tenant,property,issue'
  });

  _exports.default = _default;
});