define("propertycloud/pods/components/overdue-rent-table/input-amount/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+2KVFscc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form__control-wrap form__control-wrap--money\"],[8],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"type\",\"min\",\"step\",\"class\",\"placeholder\",\"value\",\"max\",\"change\",\"disabled\"],[\"number\",\"0\",\"0.01\",\"form__control form__control--full\",\"0.00\",[24,[\"poundsPaid\"]],[28,\"div\",[[24,[\"row\",\"amountDue\"]],100],null],[28,\"action\",[[23,0,[]],\"setPaidAmount\"],null],[28,\"eq\",[[24,[\"row\",\"selected\"]],false],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/overdue-rent-table/input-amount/template.hbs"
    }
  });

  _exports.default = _default;
});