define("propertycloud/pods/logged-in/finance/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel() {
      this._super.apply(this, arguments);

      this.transitionTo('logged-in.finance.reports');
    }
  });

  _exports.default = _default;
});