define("propertycloud/pods/components/validation-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XgR0xDik",
    "block": "{\"symbols\":[],\"statements\":[[4,\"validation-input\",null,[[\"isCheckbox\",\"propertyName\",\"label\",\"fieldId\",\"changeset\"],[true,[24,[\"propertyName\"]],[24,[\"label\"]],[24,[\"fieldId\"]],[24,[\"changeset\"]]]],{\"statements\":[[0,\"  \"],[7,\"label\",true],[11,\"for\",[22,\"fieldId\"]],[10,\"class\",\"form__custom\"],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"type\",\"id\",\"checked\"],[\"checkbox\",[24,[\"fieldId\"]],[28,\"get\",[[24,[\"changeset\"]],[24,[\"propertyName\"]]],null]]]],false],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"form__custom__description\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/validation-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});