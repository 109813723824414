define("propertycloud/pods/tenancy/invite/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    model: function model(params) {
      Ember.set(this, 'params', params);
      return Ember.get(this, 'user.upcomingTenancy');
    },
    setupController: function setupController(controller) {
      Ember.set(controller, 'params', this.params);

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});