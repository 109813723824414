define("propertycloud/pods/components/get-contractor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['issue-notes__contractor'],
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var id = this.contractorId;
      var store = this.store;
      var contractor = store.findRecord('contractor', id);
      contractor.then(function (contractor) {
        Ember.set(_this, 'contractor', contractor);
      });
    }
  });

  _exports.default = _default;
});