define("propertycloud/pods/logged-in/dashboard/route", ["exports", "propertycloud/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    agentContext: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        viewings: this.store.query('viewing', {
          agentId: Ember.get(this, 'agentContext.id'),
          cancelled: 0,
          scheduled: 0
        }),
        applications: this.store.query('tenancy-application', {
          individualApplicationStatus: 'upcoming',
          agentId: Ember.get(this, 'agentContext.id')
        }),
        issues: this.store.query('issue', {
          agentId: Ember.get(this, 'agentContext.id'),
          actionRequired: 1
        }) // tenantRentDue: this.store.findAll('overdue-rent')

      });
    },
    logoutLink: Ember.computed(function () {
      var redirectURI = "".concat(window.location.origin, "/logout");
      return "".concat(_environment.default.authenticationUrl, "/authorize/logout?redirect=").concat(redirectURI);
    }).readOnly(),
    actions: {
      logout: function logout() {
        // get(this, 'session').invalidate();
        window.location.replace(this.logoutLink);
      }
    }
  });

  _exports.default = _default;
});