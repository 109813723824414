define("propertycloud/pods/components/re-invite-tenant-micro/component", ["exports", "ember-changeset", "ember-changeset-validations", "propertycloud/validations/re-invite-tenant"], function (_exports, _emberChangeset, _emberChangesetValidations, _reInviteTenant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    propertyId: null,
    ValidationFields: _reInviteTenant.default,
    init: function init() {
      this._super.apply(this, arguments);

      this.changeset = new _emberChangeset.default({
        emailAddress: null
      }, (0, _emberChangesetValidations.default)(_reInviteTenant.default), _reInviteTenant.default);
    },
    actions: {
      submit: function submit() {
        var _this = this;

        Ember.set(this, 'isLoading', true);
        Ember.set(this, 'hasErrors', false);
        Ember.set(this, 'errorMessage', 'There was an error. Please check your input and try again.');
        var propertyId = Ember.get(this, 'propertyId');
        var tenancyNumber = Ember.get(this, 'tenancyNumber');
        var tenantId = Ember.get(this, 'tenantId');
        var email = Ember.get(this, 'changeset.emailAddress'); // run validations

        this.changeset.validate().then(function () {
          if (!_this.changeset.get('isValid')) {
            Ember.set(_this, 'hasErrors', true);
            Ember.set(_this, 'isLoading', false);
            return;
          } // Make api request


          var request = Ember.get(_this, 'ajax').post('/estate/property.reinvite-tenant', {
            data: JSON.stringify({
              payload: {
                propertyId: propertyId,
                tenantId: tenantId,
                tenancyNumber: tenancyNumber,
                newEmailAddress: email
              }
            })
          });
          request.then(function () {
            Ember.set(_this, 'isLoading', false);
            Ember.set(_this, 'hasErrors', false);
            Ember.set(_this, 'isSuccessful', true); // Run the provsisional tenancies list request, refreshTenancies

            Ember.get(_this, 'refreshProvisionalTenancies')();
          });
          request.catch(function (error) {
            var sentryUuid = error.sentryUuid;
            var message = error.payload.error;

            if (message) {
              if (sentryUuid) {
                message += " Error id: ".concat(sentryUuid);
              }

              Ember.set(_this, 'errorMessage', message);
            }

            Ember.set(_this, 'isLoading', false);
            Ember.set(_this, 'hasErrors', true);
          });
        });
      }
    }
  });

  _exports.default = _default;
});