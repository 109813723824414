define("propertycloud/pods/components/inline-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Required Properties
   * options: array[object]
   * onSelect: action
   * selected: value of option ('string')
   *
   * Optional Properties
   * customClass: string
   */
  var _default = Ember.Component.extend({
    classNames: ['inline-select-container'],
    selectOptions: Ember.computed('options.[]', function () {
      var options = this.options;
      var selected = this.selected;
      var selectOptions = options.map(function (option) {
        var value = option.value,
            label = option.label;
        var currentSelected = false;

        if (selected === value) {
          currentSelected = true;
        }

        return Ember.Object.create({
          value: value,
          label: label,
          selected: currentSelected
        });
      });
      return selectOptions;
    }),
    actions: {
      optionSelected: function optionSelected(option) {
        var options = this.selectOptions;
        var currentSelected = options.filter(function (opt) {
          return opt.selected;
        });
        currentSelected[0].set('selected', false);
        option.set('selected', true);
        this.onSelect(option.value);
      }
    }
  });

  _exports.default = _default;
});