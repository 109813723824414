define("propertycloud/pods/components/landlord-statements-approval/preview/component", ["exports", "propertycloud/config/environment", "file-saver"], function (_exports, _environment, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    isGenerating: false,
    actions: {
      downloadPdf: function downloadPdf() {
        var that = this;
        Ember.set(this, 'isGenerating', true);
        var xhr = new XMLHttpRequest();

        xhr.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
            (0, _fileSaver.saveAs)(new Blob([this.response], {
              type: 'application/octet-stream'
            }), 'preview.pdf');
            Ember.set(that, 'isGenerating', false);
          }
        };

        xhr.open('GET', "".concat(_environment.default.APP.apiConfig.host, "/").concat(_environment.default.APP.apiConfig.namespace).concat(Ember.get(this, 'row.preview')));
        xhr.setRequestHeader('Accept', 'application/pdf');

        var _this$get = this.get('session.data.authenticated'),
            access_token = _this$get.access_token;

        if (Ember.isPresent(access_token)) {
          xhr.setRequestHeader('Authorization', "Bearer ".concat(access_token));
        }

        xhr.responseType = 'blob';
        xhr.send();
      }
    }
  });

  _exports.default = _default;
});