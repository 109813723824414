define("propertycloud/pods/components/file-manager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jKKEhIeh",
    "block": "{\"symbols\":[\"file\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"block__content block__content--flush\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"file-manager__panels\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"file-manager__panel\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"files\"]]],null,{\"statements\":[[0,\"        \"],[7,\"ul\",true],[10,\"class\",\"file-manager__file-list file-list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"files\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"file-manager/list-row\",null,[[\"file\",\"removeFile\",\"selectFile\"],[[23,1,[]],[24,[\"removeFile\"]],[24,[\"selectFile\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"block__empty-state\"],[8],[0,\"No Documents\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"file-manager__panel\"],[8],[0,\"\\n      \"],[1,[28,\"file-manager/upload\",null,[[\"fileList\",\"newFile\",\"fileError\",\"fileErrorMessage\"],[[24,[\"files\"]],[24,[\"newFile\"]],[24,[\"fileError\"]],[24,[\"fileErrorMessage\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/file-manager/template.hbs"
    }
  });

  _exports.default = _default;
});