define("propertycloud/pods/logged-in/manage/property/inspections/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hSAQJ7Hf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"loading-cube-spinner\"],[8],[0,\"\\n  \"],[1,[22,\"spinkit-cube-grid\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/manage/property/inspections/loading/template.hbs"
    }
  });

  _exports.default = _default;
});