define("propertycloud/pods/components/uploader/image-preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wmLX29QV",
    "block": "{\"symbols\":[\"@alt\",\"@newFile\"],\"statements\":[[4,\"if\",[[28,\"and\",[[23,0,[\"imageData\"]],[23,2,[]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"img\",true],[11,\"src\",[23,0,[\"imageData\"]]],[11,\"alt\",[23,1,[]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/uploader/image-preview/template.hbs"
    }
  });

  _exports.default = _default;
});