define("propertycloud/authenticators/custom", ["exports", "jquery", "ember-simple-auth-token/authenticators/jwt"], function (_exports, _jquery, _jwt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jwt.default.extend({
    /*
          We need to override the libraries request
          as the backend expects form data for the login.
      */
    makeRequest: function makeRequest(url, data, headers) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          url: url,
          data: data,
          type: 'POST',
          headers: _this.headers,
          beforeSend: function beforeSend(xhr, settings) {
            xhr.setRequestHeader('Accept', settings.accepts.json);

            if (headers) {
              Object.keys(headers).forEach(function (key) {
                xhr.setRequestHeader(key, headers[key]);
              });
            }
          }
        }).done(function (response) {
          resolve({
            json: response
          });
        }).fail(function (e) {
          reject(e.responseJSON);
        });
      });
    }
  });

  _exports.default = _default;
});