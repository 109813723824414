define("propertycloud/pods/components/validation-flatpickr/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onOpen: function onOpen() {},
    onClose: function onClose() {}
  });

  _exports.default = _default;
});