define("propertycloud/pods/components/app-sidebar/component", ["exports", "propertycloud/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    tagName: 'nav',
    classNames: ['app-sidebar'],
    logoutLink: Ember.computed(function () {
      var redirectURI = "".concat(window.location.origin, "/logout");
      return "".concat(_environment.default.authenticationUrl, "/authorize/logout?redirect=").concat(redirectURI);
    }).readOnly(),
    actions: {
      logout: function logout() {
        this.session.invalidate();
      }
    }
  });

  _exports.default = _default;
});