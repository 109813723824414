define("propertycloud/pods/logged-in/landlord/finances/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    agentContext: Ember.inject.service(),
    model: function model() {
      var landlordId = Ember.get(this, 'user.userId');
      return this.store.query('available-landlord-statement', {
        landlordId: landlordId
      });
    }
  });

  _exports.default = _default;
});