define("propertycloud/pods/register/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var session = this.session;

      if (Ember.get(session, 'isAuthenticated')) {
        session.invalidate();
      }
    }
  });

  _exports.default = _default;
});