define("propertycloud/mixins/smallchat", ["exports", "propertycloud/utilities/getScript"], function (_exports, _getScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    agentContext: Ember.inject.service(),
    activate: function activate() {
      if (Ember.get(this, 'agentContext.tradingName').toLowerCase() === 'rooms4u') {
        (0, _getScript.default)('https://embed.small.chat/T45LNL7H7G7YN27QDP.js', function () {
          Ember.run(function () {
            window.dispatchEvent(new Event('load')); // needed for small chat to work
          });
        });
      }
    },
    deactivate: function deactivate() {
      if (Ember.get(this, 'agentContext.tradingName').toLowerCase() === 'rooms4u') {
        var smallChat = document.getElementById('Smallchat');
        smallChat.parentNode.removeChild(smallChat);
      }
    }
  });

  _exports.default = _default;
});