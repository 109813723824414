define("propertycloud/pods/components/payment-schedule/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    classNames: ['schedule'],
    contractedAmount: null,
    profile: null,
    amount: null,
    currentYear: null,
    date: null,
    advancedMonthBy: 1,
    scheduledAmount: Ember.computed('profile.@each', function () {
      var total = 0;
      var profile = Ember.get(this, 'profile');

      if (profile) {
        for (var i = 0; i < profile.length; i++) {
          total = total + Number(profile[i].payment.amount);
        }
      }

      return total;
    }),
    isValid: Ember.computed('scheduledAmount', function () {
      var scheduled = Number(Ember.get(this, 'scheduledAmount')).toFixed(2);
      return parseFloat(scheduled, 10) === Ember.get(this, 'contractedAmount');
    }),
    sortedProfile: Ember.computed.sort('profile', function (a, b) {
      var aDate = (0, _moment.default)(a.date);
      var bDate = (0, _moment.default)(b.date);

      if (aDate.isAfter(bDate)) {
        return 1;
      } else if (aDate.isSame(bDate)) {
        return 0;
      }

      return -1;
    }),
    init: function init() {
      this._super.apply(this, arguments); // store current year (to determine whether entries are year 1 or 2)


      var d = new Date();
      Ember.set(this, 'currentYear', d.getFullYear()); // set profile to empty array if null

      if (Ember.get(this, 'profile') === null) {
        Ember.set(this, 'profile', Ember.A());
      }
    },
    pushToProfile: function pushToProfile() {
      var _EmberGet = Ember.get(this, 'date'),
          _EmberGet2 = _slicedToArray(_EmberGet, 1),
          date = _EmberGet2[0];

      var amount = Ember.get(this, 'amount') * 100; // amount to pence

      var profile = Ember.get(this, 'profile');
      var dateIdentifier = (0, _moment.default)(date).format('YYYY-MM-DD'); // does an entry for this date exist? If not we create a new one, if so we update the assigned percentage value

      var result = profile.find(function (obj) {
        return obj.date === dateIdentifier;
      });

      if (typeof result === 'undefined') {
        profile.pushObject({
          date: dateIdentifier,
          payment: {
            amount: amount,
            currency: 'GBP'
          }
        });
      } else {
        Ember.set(result, 'payment.amount', amount);
      } // force to recalculate any observed properties
      // also, removing this seems to break the model.save()
      // this.notifyPropertyChange('profile');

    },
    actions: {
      addToPaymentSchedule: function addToPaymentSchedule() {
        this.pushToProfile();
        var date = Ember.get(this, 'date');
        var advancedMonthBy = Ember.get(this, 'advancedMonthBy');
        Ember.set(this, 'date', [(0, _moment.default)(date[0]).add(advancedMonthBy, 'month').toDate()]);
      },
      removeFromPaymentSchedule: function removeFromPaymentSchedule(entry) {
        var id = entry.date;
        var result = Ember.get(this, 'profile').filter(function (obj) {
          return obj.date !== id;
        });
        Ember.set(this, 'profile', result);
      }
    }
  });

  _exports.default = _default;
});