define("propertycloud/pods/logged-in/dashboard/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    complianceEnd: (0, _moment.default)().add(1, 'months').format('YYYY-MM-DD')
  });

  _exports.default = _default;
});