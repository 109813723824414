define("propertycloud/pods/logged-in/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    uploader: Ember.inject.service(),
    showSpotlight: false,
    showAddWorkspace: false,
    addWorkspaceContext: 'add-workspace-tenancy',
    property: null,
    landlord: null,
    actions: {
      toggleWorkspace: function toggleWorkspace(context) {
        // this action is passed to the add-circle component
        // so when you click a thing to add it passes what you have clicked
        // (the context) and shows the panel
        Ember.set(this, 'addWorkspaceContext', "add-workspace-".concat(context));
        this.toggleProperty('showAddWorkspace');
      },
      showSpotlight: function showSpotlight() {
        this.toggleProperty('showSpotlight');
      },
      setProperty: function setProperty(property) {
        Ember.set(this, 'property', property);
      },
      setLandlord: function setLandlord(landlord) {
        Ember.set(this, 'landlord', landlord);
      },
      clearLandlordProperty: function clearLandlordProperty() {
        Ember.set(this, 'property', null);
        Ember.set(this, 'landlord', null);
      }
    }
  });

  _exports.default = _default;
});