define("propertycloud/pods/components/overdue-rent-graph/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3ZTmF2Hx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"block__content block__content--flush\"],[8],[0,\"\\n  \"],[1,[28,\"high-charts\",null,[[\"content\",\"chartOptions\",\"theme\"],[[24,[\"chartData\"]],[24,[\"chartOptions\"]],[24,[\"theme\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/overdue-rent-graph/template.hbs"
    }
  });

  _exports.default = _default;
});