define("propertycloud/pods/components/add-workspace-tenancy/edit-tenant/component", ["exports", "moment", "propertycloud/utilities/universities"], function (_exports, _moment, _universities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    step: 1,
    universities: _universities.default.universities,
    init: function init() {
      this._super.apply(this, arguments);

      this.atAddressSince = this.agreement.guarantorDetails.atAddressSince || null;
      this.schedule = this.agreement.paymentSchedule.payments || [];
      this.tenantChangeset = this.changesets.findBy('newAgreement', this.agreement).tenantChangeset;
      this.guarantorChangeset = this.changesets.findBy('newAgreement', this.agreement).guarantorChangeset;
      this.paymentChangeset = this.changesets.findBy('newAgreement', this.agreement).paymentChangeset;
      this.tenantDocsChangeset = this.changesets.findBy('newAgreement', this.agreement).tenantDocsChangeset;
      this.guarantorDocsChangeset = this.changesets.findBy('newAgreement', this.agreement).guarantorDocsChangeset;
      this.tenantChangeset.set('university', {
        ucasCode: "CHSTR"
      });
    },
    scheduledSorted: Ember.computed('schedule.[]', {
      get: function get() {
        return this.schedule.sortBy('dueDate');
      }
    }),
    actions: {
      attachTenantPhotoId: function attachTenantPhotoId(file, request, fileId) {
        this.tenantDocsChangeset.set('photoIdDocumentId', fileId);
        this.tenantDocsChangeset.execute();
      },
      attachTenantStudentId: function attachTenantStudentId(file, request, fileId) {
        this.tenantDocsChangeset.set('studentIdDocumentId', fileId);
        this.tenantDocsChangeset.execute();
      },
      attachGuarantorPhotoId: function attachGuarantorPhotoId(file, request, fileId) {
        this.guarantorDocsChangeset.set('photoIdDocumentId', fileId);
        this.guarantorDocsChangeset.execute();
      },
      attachGuarantorContract: function attachGuarantorContract(file, request, fileId) {
        this.guarantorDocsChangeset.set('contractDocumentId', fileId);
        this.guarantorDocsChangeset.execute();
      },
      attachGuarantorPoa: function attachGuarantorPoa(file, request, fileId) {
        this.guarantorDocsChangeset.set('proofOfAddressDocumentId', fileId);
        this.guarantorDocsChangeset.execute();
      },
      setUniversity: function setUniversity(val) {
        this.tenantChangeset.set('university', {
          ucasCode: val
        });
        this.tenantChangeset.save();
      },
      setHomeDate: function setHomeDate(_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
            val = _ref2[0];

        this.guarantorChangeset.set('atAddressSince', (0, _moment.default)(val).format("YYYY-MM-DD"));
        this.guarantorChangeset.save();
      },
      setGuarantorDOB: function setGuarantorDOB(_ref3) {
        var _ref4 = _slicedToArray(_ref3, 1),
            val = _ref4[0];

        this.guarantorChangeset.set('dateOfBirth', (0, _moment.default)(val).format("YYYY-MM-DD"));
        this.guarantorChangeset.save();
      },
      addToPaymentSchedule: function addToPaymentSchedule() {
        var date = (0, _moment.default)(this.dueDate[0]).format("YYYY-MM-DD");
        var result = this.schedule.find(function (obj) {
          return obj.dueDate === date;
        });

        if (typeof result === 'undefined') {
          this.schedule.pushObject({
            dueDate: date,
            amountDue: {
              amount: this.amountDue * 100,
              currency: "GBP"
            }
          });
        } else {
          Ember.set(result, 'amountDue.amount', this.amountDue * 100);
        }

        Ember.set(this, 'dueDate', [(0, _moment.default)(this.dueDate[0]).add(1, 'month').toDate()]);
        this.paymentChangeset.set('payments', this.schedule);
      },
      addTenant: function addTenant() {
        var _this = this;

        this.tenantChangeset.validate().then(function () {
          if (Ember.get(_this.tenantChangeset, 'isValid')) {
            _this.tenantChangeset.save();

            Ember.set(_this, 'step', 2);
          }
        });
      },
      addGuarantor: function addGuarantor() {
        var _this2 = this;

        this.guarantorChangeset.validate().then(function () {
          if (Ember.get(_this2.guarantorChangeset, 'isValid')) {
            _this2.guarantorChangeset.save();

            Ember.set(_this2, 'step', 3);
          }
        });
      },
      addDocs: function addDocs() {
        var _this3 = this;

        this.tenantDocsChangeset.validate().then(function () {
          _this3.guarantorDocsChangeset.validate().then(function () {
            if (Ember.get(_this3.tenantDocsChangeset, 'isValid') && Ember.get(_this3.guarantorDocsChangeset, 'isValid')) {
              _this3.tenantDocsChangeset.save();

              _this3.guarantorDocsChangeset.save();

              Ember.set(_this3, 'step', 4);
            }
          });
        });
      },
      removeFromPaymentSchedule: function removeFromPaymentSchedule(entry) {
        this.schedule.removeObject(entry);
        this.paymentChangeset.set('payments', this.schedule);
      },
      addAgreement: function addAgreement() {
        var _this4 = this;

        this.paymentChangeset.validate().then(function () {
          if (Ember.get(_this4.paymentChangeset, 'isValid')) {
            _this4.paymentChangeset.save();

            Ember.set(_this4, 'agreement.completed', true);
            Ember.set(_this4, 'editingTenant', false);
          }
        });
      }
    }
  });

  _exports.default = _default;
});