define("propertycloud/pods/logged-in/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IMGNg0mG",
    "block": "{\"symbols\":[\"workspace\"],\"statements\":[[4,\"if\",[[24,[\"user\",\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[1,[22,\"spinkit-cube-grid\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"app-columns\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"user\",\"isAdmin\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"app-columns__sidebar\"],[8],[0,\"\\n        \"],[1,[28,\"app-sidebar\",null,[[\"showSpotlight\",\"user\"],[[28,\"action\",[[23,0,[]],\"showSpotlight\"],null],[24,[\"user\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"app-columns__panel\"],[8],[0,\"\\n      \"],[1,[22,\"outlet\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"user\",\"isAdmin\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"id\",\"floatingButtons\"],[8],[0,\"\\n      \"],[1,[28,\"add-circle\",null,[[\"toggleWorkspace\"],[[28,\"action\",[[23,0,[]],\"toggleWorkspace\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[4,\"add-workspace\",null,[[\"isShown\",\"workspaceContext\",\"property\",\"landlord\"],[[24,[\"showAddWorkspace\"]],[24,[\"addWorkspaceContext\"]],[24,[\"property\"]],[24,[\"landlord\"]]]],{\"statements\":[[4,\"if\",[[24,[\"showAddWorkspace\"]]],null,{\"statements\":[[0,\"        \"],[1,[23,1,[\"body\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n    \"],[1,[28,\"global-search\",null,[[\"isShown\",\"keyboardActivated\"],[[24,[\"showSpotlight\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"galleryHolder\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"dashboardButtons\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/template.hbs"
    }
  });

  _exports.default = _default;
});