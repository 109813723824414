define("propertycloud/pods/login-callback/route", ["exports", "ember-simple-auth/mixins/oauth2-implicit-grant-callback-route-mixin"], function (_exports, _oauth2ImplicitGrantCallbackRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_oauth2ImplicitGrantCallbackRouteMixin.default, {
    authenticator: 'authenticator:oauth2-implicit-grant'
  });

  _exports.default = _default;
});