define("propertycloud/pods/components/income-expenditure-table/money/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s2vOsZPu",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"value\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"format-currency\",[[24,[\"value\"]],[28,\"hash\",null,[[\"noResize\"],[true]]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/income-expenditure-table/money/template.hbs"
    }
  });

  _exports.default = _default;
});