define("propertycloud/validators/masked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateMasked;

  // validators/masked.js
  function validateMasked() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        char = _ref.char,
        is = _ref.is,
        _ref$presence = _ref.presence,
        presence = _ref$presence === void 0 ? true : _ref$presence;

    return function (key, newValue) {
      var strippedValue = newValue;

      if (!strippedValue && presence === true) {
        return 'Should not be blank.';
      }

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = char[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var value = _step.value;
          var reg = new RegExp(value, "g");
          strippedValue = strippedValue.replace(reg, '');
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      if (strippedValue.length === is) {
        return true;
      } else {
        return "Should be ".concat(is, " characters.");
      }
    };
  }
});