define("propertycloud/helpers/format-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatNumber = formatNumber;
  _exports.default = void 0;

  /*
  This should be made more comprehensive
  */
  function formatNumber(params, hash) {
    var dp = hash.dp;
    var value = params[0];
    return parseFloat(value).toFixed(dp);
  }

  var _default = Ember.Helper.helper(formatNumber);

  _exports.default = _default;
});