define("propertycloud/pods/components/uploader/image-preview/component", ["exports", "@uppy/thumbnail-generator"], function (_exports, _thumbnailGenerator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.uppy.use(_thumbnailGenerator.default, {
        thumbnailWidth: 500
      });
      this.uppy.on('thumbnail:generated', function (fileID, preview) {
        Ember.set(_this, 'imageData', preview);
        Ember.set(_this, 'newFile', true);
      });
    }
  });

  _exports.default = _default;
});