define("propertycloud/pods/components/validation-flatpickr/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dtwPr4Ap",
    "block": "{\"symbols\":[],\"statements\":[[4,\"validation-input\",null,[[\"propertyName\",\"label\",\"fieldId\",\"changeset\"],[[24,[\"propertyName\"]],[24,[\"label\"]],[24,[\"fieldId\"]],[24,[\"changeset\"]]]],{\"statements\":[[4,\"if\",[[28,\"not\",[[24,[\"hideWrapper\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"form__control-wrap form__control-wrap--date\"],[8],[0,\"\\n      \"],[1,[28,\"ember-flatpickr\",null,[[\"onChange\",\"altInput\",\"date\",\"altFormat\",\"mode\",\"placeholder\",\"class\",\"onOpen\",\"onClose\",\"maxDate\",\"minDate\",\"id\"],[[24,[\"onChange\"]],true,[28,\"if\",[[28,\"get\",[[24,[\"changeset\"]],[24,[\"propertyName\"]]],null],[28,\"readonly\",[[28,\"get\",[[24,[\"changeset\"]],[24,[\"propertyName\"]]],null]],null],null],null],[24,[\"altFormat\"]],[24,[\"mode\"]],[24,[\"placeholder\"]],\"form__control\",[28,\"action\",[[23,0,[]],[24,[\"onOpen\"]]],null],[28,\"action\",[[23,0,[]],[24,[\"onClose\"]]],null],[24,[\"maxDate\"]],[24,[\"minDate\"]],[24,[\"fieldId\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"ember-flatpickr\",null,[[\"onChange\",\"altInput\",\"date\",\"altFormat\",\"mode\",\"placeholder\",\"class\",\"onOpen\",\"onClose\",\"maxDate\",\"minDate\",\"id\"],[[24,[\"onChange\"]],true,[28,\"if\",[[28,\"get\",[[24,[\"changeset\"]],[24,[\"propertyName\"]]],null],[28,\"readonly\",[[28,\"get\",[[24,[\"changeset\"]],[24,[\"propertyName\"]]],null]],null],null],null],[24,[\"altFormat\"]],[24,[\"mode\"]],[24,[\"placeholder\"]],\"form__control\",[28,\"action\",[[23,0,[]],[24,[\"onOpen\"]]],null],[28,\"action\",[[23,0,[]],[24,[\"onClose\"]]],null],[24,[\"maxDate\"]],[24,[\"minDate\"]],[24,[\"fieldId\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/validation-flatpickr/template.hbs"
    }
  });

  _exports.default = _default;
});