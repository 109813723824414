define("propertycloud/pods/components/landlord-payments/row/component", ["exports", "ember-light-table/components/lt-row", "moment"], function (_exports, _ltRow, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ltRow.default.extend({
    classNameBindings: ['align', 'isGroupColumn:lt-group-column', 'isHideable', 'isSortable', 'isSorted', 'isResizable', 'isResizing', 'isDraggable', 'column.classNames', 'isHighlighted', 'isSubmitting:is-recording', 'isDeleting'],
    attributeBindings: ['style'],
    isHighlighted: false,
    isSubmitting: Ember.computed.alias('row.isSubmitting'),
    isDeleting: Ember.computed.alias('row.isDeleting'),
    init: function init() {
      this._super.apply(this, arguments); // don't allow selection if balance is 0


      var balance = (0, _moment.default)(Ember.get(this, 'row.Balance'));

      if (balance <= 0) {
        Ember.set(this, 'isSelectable', false);
      }
    }
  });

  _exports.default = _default;
});