define("propertycloud/pods/logged-in/viewings/detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    agentContext: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        viewing: this.store.findRecord('viewing', params.viewing_id),
        staff: this.store.query('staff-member', {
          agentId: Ember.get(this, 'agentContext.id')
        })
      });
    },
    activate: function activate() {
      Ember.run.schedule('afterRender', function () {
        document.querySelector('.issues__main-column').classList.add('is-open');
      });
    },
    deactivate: function deactivate() {
      document.querySelector('.issues__main-column').classList.remove('is-open');
    }
  });

  _exports.default = _default;
});