define("propertycloud/pods/components/validation-mobile/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var phoneCountries = {
    preferred: ['gb', 'ie', 'im', 'us', 'es', 'fr', 'no', 'ch', 'om'],
    exclude: ['ba', 'cf', 'cd', 'cg', 'mf', 'pm', 'st']
  };

  var _default = Ember.Component.extend({
    phoneCountries: phoneCountries,
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.schedule('afterRender', function () {
        var input = _this.element.querySelector('input');

        input.addEventListener('blur', function () {
          var changeset = _this.changeset;

          if (Ember.typeOf(changeset.validate) === "function") {
            changeset.validate(_this.propertyName);
          }
        });
      });
    }
  });

  _exports.default = _default;
});