define("propertycloud/pods/logged-in/settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zcUbO5Os",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"grid grid--centred grid--padded\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid__row grid__row--small\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"grid__col\"],[8],[0,\"\\n\\n      \"],[5,\"settings/payment-gateway\",[],[[],[]]],[0,\"\\n\"],[4,\"if\",[[24,[\"agentContext\",\"stripeKey\"]]],null,{\"statements\":[[0,\"        \"],[5,\"settings/payment-configuration\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[5,\"settings/bank-details\",[],[[],[]]],[0,\"\\n\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/settings/template.hbs"
    }
  });

  _exports.default = _default;
});