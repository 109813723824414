define("propertycloud/serializers/tenant", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    agentContext: Ember.inject.service(),
    normalizeSingleResponse: function normalizeSingleResponse(store, type, payload) {
      // api returns longer id (including agentsId and type)
      // so we need to cut it down to just the tenantId portion (ie. the first uuid from the string)
      var tenantId = payload.data.id.substr(0, 36);
      return {
        data: {
          id: tenantId,
          type: 'tenant',
          attributes: {
            name: payload.data.attributes.name,
            emailAddress: payload.data.attributes['email-address'],
            dateOfBirth: payload.data.attributes['date-of-birth'],
            mobileNumber: payload.data.attributes['mobile-number'],
            university: payload.data.attributes.university,
            studentIdNumber: payload.data.attributes['student-id-number'],
            studentId: payload.data.attributes['student-id'] || {},
            photoId: payload.data.attributes['photo-id'] || {}
          }
        }
      };
    },
    serialize: function serialize(snapshot) {
      var payload = {
        'tenantId': snapshot.id,
        'agentId': Ember.get(this, 'agentContext.id'),
        'details': {
          'name': snapshot._attributes.name,
          'mobileNumber': snapshot._attributes.mobileNumber,
          'dateOfBirth': snapshot._attributes.dateOfBirth,
          'university': snapshot._attributes.university,
          'studentIdNumber': snapshot._attributes.studentIdNumber,
          'studentId': null,
          'photoId': null
        }
      };

      function hasNewPhotoId() {
        var photo = snapshot._attributes.photoId;

        if (Ember.isPresent(photo)) {
          return Ember.isPresent(photo['document-id']) && photo.data.match(/(^data:)/ig);
        }
      }

      function hasSamePhotoId() {
        var photo = snapshot._attributes.photoId;

        if (Ember.isPresent(photo)) {
          return Ember.isPresent(photo['document-id']) && Ember.isPresent(photo.data) && !photo.data.match(/(^data:)/ig);
        }
      }

      function hasNewStudentId() {
        var photo = snapshot._attributes.studentId;

        if (Ember.isPresent(photo)) {
          return Ember.isPresent(photo['document-id']) && photo.data.match(/(^data:)/ig);
        }
      }

      function hasSameStudentId() {
        var photo = snapshot._attributes.studentId;

        if (Ember.isPresent(photo)) {
          return Ember.isPresent(photo['document-id']) && Ember.isPresent(photo.data) && !photo.data.match(/(^data:)/ig);
        }
      }

      if (hasNewPhotoId()) {
        payload.details.photoId = {
          'documentId': snapshot._attributes.photoId['document-id'],
          'data': snapshot._attributes.photoId.data
        };
      }

      if (hasNewStudentId()) {
        payload.details.studentId = {
          'documentId': snapshot._attributes.studentId['document-id'],
          'data': snapshot._attributes.studentId.data
        };
      }

      if (hasSameStudentId()) {
        payload.details.studentId = {
          'documentId': snapshot._attributes.studentId['document-id'],
          'data': null
        };
      }

      if (hasSamePhotoId()) {
        payload.details.photoId = {
          'documentId': snapshot._attributes.photoId['document-id'],
          'data': null
        };
      }

      return {
        payload: payload
      };
    }
  });

  _exports.default = _default;
});