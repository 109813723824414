define("propertycloud/pods/components/landlord-statements-approval/amount-due/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UGb+k2Q/",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"format-currency\",[[24,[\"value\"]],[28,\"hash\",null,[[\"noResize\"],[true]]]],null],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/landlord-statements-approval/amount-due/template.hbs"
    }
  });

  _exports.default = _default;
});