define("propertycloud/pods/register/tenant/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    routeIfAlreadyAuthenticated: 'register',
    model: function model(params) {
      Ember.set(this, 'params', params);
    },
    setupController: function setupController(controller) {
      Ember.set(controller, 'params', this.params);

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});