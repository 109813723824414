define("propertycloud/pods/components/requires-attention-compliance/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1SbQPMiD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[10,\"style\",\"width:100px;\"],[8],[1,[24,[\"cert\",\"CertificateType\"]],false],[9],[0,\"\\n\"],[7,\"td\",true],[8],[7,\"strong\",true],[8],[4,\"link-to\",null,[[\"route\",\"model\"],[[24,[\"routeString\"]],[24,[\"cert\",\"PropertyId\"]]]],{\"statements\":[[1,[24,[\"cert\",\"PropertyAddress\"]],false]],\"parameters\":[]},null],[9],[9],[0,\"\\n\"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"withinTwoWeeks\"]],[28,\"gt\",[[24,[\"percentage\"]],10],null]],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"ember-progress-bar\",null,[[\"progress\",\"options\"],[[28,\"div\",[[24,[\"percentage\"]],100],null],[28,\"hash\",null,[[\"strokeWidth\",\"color\",\"trailColor\",\"trailWidth\"],[4,[24,[\"color\"]],\"#eee\",4]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"style\",\"width: 150px;text-align:right;\"],[11,\"title\",[28,\"moment-format\",[[24,[\"cert\",\"ExpiryDate\"]],\"Do MMMM, YYYY\"],null]],[8],[1,[28,\"relative-time\",[[24,[\"cert\",\"ExpiryDate\"]]],null],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/requires-attention-compliance/row/template.hbs"
    }
  });

  _exports.default = _default;
});