define("propertycloud/pods/components/upcoming-tenancies/row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isApproved: Ember.computed('tenancy', function () {
      return Ember.isPresent(Ember.get(this, 'tenancy.approvalDate'));
    })
  });

  _exports.default = _default;
});