define("propertycloud/pods/logged-in/landlord/my-details/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('logged-in/landlord');
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setModelMap(model);
    }
  });

  _exports.default = _default;
});