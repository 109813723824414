define("propertycloud/adapters/property-note", ["exports", "propertycloud/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // we want everything to use the host and namespace defined in the
  // application adapter so we extend from that instead
  var _default = _application.default.extend({
    queryRecord: function queryRecord(store, type, id) {
      var url = "".concat(this.host, "/").concat(this.namespace, "/estate/property.view-notes");
      var data = {
        propertyId: id
      };
      return this.ajax(url, 'GET', {
        data: data
      });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var url = "".concat(this.host, "/").concat(this.namespace, "/estate/property.update-notes");
      var serializer = store.serializerFor(type.modelName);
      var data = {}; // this is a funky function in that it modifes the data directly
      // without needing to reassign a returned object

      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      });
      data.payload.propertyId = snapshot.id.replace('-notes', '');

      if (Ember.isBlank(data.payload.notes)) {
        data.payload.notes = null;
      }

      return this.ajax(url, 'POST', {
        data: data
      });
    }
  });

  _exports.default = _default;
});