define("propertycloud/pods/components/maintenance/issue-list/component", ["exports", "ember-group-by"], function (_exports, _emberGroupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    order: 'desc',
    issueSorting: Ember.computed('order', function () {
      var order = Ember.get(this, 'order');
      return ["reportedDate:".concat(order)];
    }),
    issues: Ember.computed('model.[]', function () {
      var model = Ember.get(this, 'model');
      return model;
    }),
    isDetail: Ember.computed('router.currentRouteName', function () {
      return Ember.get(this, 'router.currentRouteName') === 'logged-in.maintenance.detail' || Ember.get(this, 'router.currentRouteName') === 'logged-in.tenant.maintenance.detail';
    }),
    issuesReversed: Ember.computed.sort('issues', 'issueSorting'),
    issuesByDate: (0, _emberGroupBy.default)('issuesReversed', 'date'),
    actions: {
      clearFilters: function clearFilters() {
        this.setProperties({
          property: null,
          supplier: null,
          category: null,
          resolvedQuery: null,
          scheduledQuery: null,
          assigned: null
        });
      },
      changeSortOrder: function changeSortOrder() {
        var order = Ember.get(this, 'order');

        if (order === 'desc') {
          Ember.set(this, 'order', 'asc');
        } else {
          Ember.set(this, 'order', 'desc');
        }
      }
    }
  });

  _exports.default = _default;
});