define("propertycloud/pods/components/hold-button/component", ["exports", "ember-hold-button/components/hold-button"], function (_exports, _holdButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _holdButton.default.extend({
    attributeBindings: ['buttonType:type'],
    buttonType: 'button'
  });

  _exports.default = _default;
});