define("propertycloud/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6zvgK88L",
    "block": "{\"symbols\":[\"flash\",\"component\",\"flash\"],\"statements\":[[1,[22,\"head-layout\"],false],[0,\"\\n\"],[1,[28,\"page-title\",[\"PropertyCloud\"],null],false],[0,\"\\n\\n\"],[5,\"ie11-banner\",[],[[],[]]],[0,\"\\n\"],[1,[22,\"planned-maintenance\"],false],[0,\"\\n\"],[1,[28,\"new-version-notifier\",null,[[\"updateMessage\",\"showReload\"],[\"A new version of PropertyCloud is available\",true]]],false],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"flash-messages\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"flashMessages\",\"arrangedQueue\"]]],null,{\"statements\":[[4,\"flash-message\",null,[[\"flash\"],[[23,1,[]]]],{\"statements\":[[0,\"      \"],[7,\"p\",true],[8],[1,[23,3,[\"message\"]],false],[9],[0,\"\\n      \"],[7,\"button\",true],[10,\"class\",\"alert__close\"],[8],[0,\"Dismiss\"],[9],[0,\"\\n\"]],\"parameters\":[2,3]},null]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/application/template.hbs"
    }
  });

  _exports.default = _default;
});