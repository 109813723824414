define("propertycloud/pods/logged-in/guarantor/route", ["exports", "propertycloud/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    logoutLink: Ember.computed(function () {
      var redirectURI = "".concat(window.location.origin, "/logout");
      return "".concat(_environment.default.authenticationUrl, "/authorize/logout?redirect=").concat(redirectURI);
    }).readOnly(),
    actions: {
      logout: function logout() {
        window.location.replace(this.logoutLink);
      }
    }
  });

  _exports.default = _default;
});