define("propertycloud/pods/contract/landlord/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    shouldLandlordSignContract: Ember.computed('user', function () {
      var user = Ember.get(this, 'user');
      return user.isLandlord && !user.signedContract;
    }),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var user = Ember.get(this, 'user');

      if (user.isLandlord) {
        if (Ember.get(this, 'shouldLandlordSignContract')) {
          this.transitionTo('contract.landlord');
        } else {
          this.transitionTo('logged-in.landlord');
        }
      }
    },
    model: function model() {
      var agentId = Ember.get(this, 'user.landlordInvitedAgentId');
      var landlordEmailAddress = Ember.get(this, 'user.email');
      return this.ajax.request("/lettings/landlord-invitations/".concat(agentId, "/").concat(landlordEmailAddress), {
        namespace: '/',
        headers: {
          accept: 'application/vnd.api+json'
        },
        contentType: 'application/json'
      }); // return {
      //   data: {
      //     id: 'some-id',
      //     type: 'landlord-invitation',
      //     attributes: {
      //       contactDetails: {
      //         name: 'Liam Potter',
      //         emailAddress: 'liam@testlandlord.com',
      //         mobileNumber: '+447814420619'
      //       },
      //       address: {
      //         flat: 2,
      //         building: 4,
      //         street: 'sandhurst street',
      //         area: 'aigburth',
      //         town: 'liverpool',
      //         county: 'merseyside',
      //         postcode: 'l17 7bt'
      //       },
      //       bankAccount: {
      //         accountName: 'barclays',
      //         accountNumber: '12345678',
      //         sortCode: '12-34-56'
      //       }
      //     },
      //     relationships: {
      //       contract: {
      //         links: {
      //           related: 'https://www.mowglistreetfood.com/wp-content/uploads/2019/04/Mowgli-Food-Menu.pdf'
      //         }
      //       },
      //       photoId: {
      //         links: {
      //           related: 'https://thumbs.dreamstime.com/z/tv-test-image-card-rainbow-multi-color-bars-geometric-signals-retro-hardware-s-minimal-pop-art-print-suitable-89603635.jpg'
      //         }
      //       }
      //     }
      //   }
      // }
    }
  });

  _exports.default = _default;
});