define("propertycloud/pods/tenancy/payment/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    model: function model(params) {
      var user = this.user;
      user.tenantInfo();
      var propertyId = params.property_id;
      Ember.set(this, 'propertyId', propertyId);
      var tenancyNumber = Ember.get(this, 'user.upcomingTenancyNumber');
      var userId = Ember.get(this, 'user.userId');
      return this.store.findRecord('tenancy-agreement', "".concat(propertyId, "-").concat(tenancyNumber, "-").concat(userId)); // tenancy: this.store.queryRecord('tenancy', { propertyId, tenancyNumber }),
      // agreement: this.store.findRecord('tenancy-agreement', `${propertyId}-${tenancyNumber}-${userId}`)
    },
    setupController: function setupController(controller) {
      Ember.set(controller, 'propertyId', this.propertyId);

      this._super.apply(this, arguments);

      Ember.get(controller, 'getBankDetails').perform();
    },
    afterModel: function afterModel(model) {
      // get tenancy to determine whether there are roommates
      var user = this.user;
      user.tenantInfo();
      var propertyId = this.propertyId;
      var tenancyNumber = Ember.get(user, 'upcomingTenancyNumber');
      var tenancyQuery = {
        propertyId: propertyId,
        tenancyNumber: tenancyNumber,
        tenantId: Ember.get(user, 'userId')
      };

      if (Ember.get(this, 'user.isAgent')) {
        tenancyQuery.include = 'agreements';
      }

      this.store.queryRecord('tenancy', tenancyQuery).then(function (tenancy) {
        var unapproved = Ember.get(tenancy, 'totalUnapprovedTenants');
        Ember.set(model, 'hasRoommates', unapproved > 1);
      });
    }
  });

  _exports.default = _default;
});