define("propertycloud/pods/components/saving-indicator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zmPFjBfD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"state\"]],\"submitting\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"saving-indicator__submitting\"],[8],[0,\"\\n    \"],[1,[28,\"svg-jar\",[\"spinner\"],null],false],[0,\"\\n    Submitting...\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"eq\",[[24,[\"state\"]],\"done\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"saving-indicator__done\"],[8],[0,\"\\n    \"],[1,[28,\"svg-jar\",[\"tick\"],null],false],[0,\"\\n    Done.\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"eq\",[[24,[\"state\"]],\"failed\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"saving-indicator__failed\"],[8],[0,\"\\n    Something went wrong, please try again.\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"state\"]],\"failed\"],null]],null,{\"statements\":[[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"saving-indicator__failed\"],[8],[0,\"\\n      \"],[1,[22,\"error\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"saving-indicator__failed\"],[8],[0,\"\\n      Something went wrong, please try again.\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/saving-indicator/template.hbs"
    }
  });

  _exports.default = _default;
});