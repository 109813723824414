define("propertycloud/serializers/property-image", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    serialize: function serialize(snapshot) {
      var _this = this;

      var json = {};
      snapshot.eachRelationship(function (key, relationship) {
        if (relationship.kind === 'belongsTo') {
          _this.serializeBelongsTo(snapshot, json, relationship);
        }
      });
      var payload = {
        'documentId': snapshot.id,
        'propertyId': json.relationships.gallery.data.id
      };
      return {
        payload: payload
      };
    }
  });

  _exports.default = _default;
});