define("propertycloud/templates/components/spinkit-cube-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K0qhQd4N",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n \\n\\n      \"],[7,\"div\",true],[10,\"class\",\"sk-cube-grid\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"sk-cube sk-cube1\"],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"sk-cube sk-cube2\"],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"sk-cube sk-cube3\"],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"sk-cube sk-cube4\"],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"sk-cube sk-cube5\"],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"sk-cube sk-cube6\"],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"sk-cube sk-cube7\"],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"sk-cube sk-cube8\"],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"sk-cube sk-cube9\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/templates/components/spinkit-cube-grid.hbs"
    }
  });

  _exports.default = _default;
});