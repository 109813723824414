define("propertycloud/pods/logged-in/manage/property/certificates/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var parentModel = this.modelFor('logged-in/manage/property');
      var propertyId = Ember.get(parentModel, 'id');
      return this.store.findRecord('property-certificates', propertyId);
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (Ember.get(this.controller, 'hasChanged') && !confirm('It looks like you have made some changes, if you navigate away before saving you will lose these changes. Are you sure you want to continue?')) {
          transition.abort();
        } else {
          Ember.set(this.controller, 'hasChanged', false);
          return true;
        }
      }
    }
  });

  _exports.default = _default;
});