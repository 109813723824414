define("propertycloud/pods/logged-in/guarantor/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a+BFwSmE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"dashboard-start guarantor-dashboard\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"dashboard-start__content\"],[8],[0,\"\\n    \"],[5,\"guarantor-contracts\",[],[[\"@isLoading\",\"@agreements\",\"@agreementsError\"],[[22,\"isLoading\"],[22,\"agreements\"],[22,\"agreementsError\"]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/guarantor/index/template.hbs"
    }
  });

  _exports.default = _default;
});