define("propertycloud/adapters/tenancy-agreement", ["exports", "propertycloud/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _application.default.extend({
    user: Ember.inject.service(),
    findRecord: function findRecord(store, type, id) {
      var url = "".concat(this.host, "/").concat(this.namespace, "/estate/property.view-individual-agreement");
      var reUUID = '([A-Z0-9]{8}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{12})'; // SQL GUID 1

      var reg = new RegExp("".concat(reUUID, ".*?(\\d+).*?").concat(reUUID), ['i']);
      var m = reg.exec(id);

      var _m = _slicedToArray(m, 4),
          propertyId = _m[1],
          tenancyNumber = _m[2],
          tenantId = _m[3];

      var data = {
        propertyId: propertyId,
        tenantId: tenantId,
        tenancyNumber: tenancyNumber
      };

      if (Ember.get(this, 'user.isTenant') || Ember.get(this, 'user.isAnonymous')) {
        // a tenant can only load their own agreement
        // if they try to load another they get a 403
        // which ember treats as fatal and stops rendering
        // so we load an empty record in it's place
        if (tenantId !== Ember.get(this, 'user.userId')) {
          return {
            data: {
              id: id,
              type: 'tenancy-agreement',
              attributes: {}
            }
          };
        }
      } // If the user is a guarantor, the other requests should never be made at this point
      // So this will just return an empty tenant


      if (Ember.get(this, 'user.isGuarantor') || Ember.get(this, 'user.isAnonymous')) {
        return {
          data: {
            id: id,
            type: 'tenancy-agreement',
            attributes: {}
          }
        };
      }

      return this.ajax(url, 'GET', {
        data: data
      });
    }
  });

  _exports.default = _default;
});