define("propertycloud/pods/components/section-navigation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['section-nav', 'property-nav'],
    name: null
  });

  _exports.default = _default;
});