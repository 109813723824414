define("propertycloud/pods/components/inline-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l9GcALel",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"inline-select \",[22,\"customClass\"]]]],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"selectOptions\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",[29,[\"inline-select__button \",[28,\"if\",[[23,1,[\"selected\"]],\"inline-select__button--selected\"],null]]]],[3,\"action\",[[23,0,[]],\"optionSelected\",[23,1,[]]]],[8],[1,[23,1,[\"label\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/inline-select/template.hbs"
    }
  });

  _exports.default = _default;
});