define("propertycloud/pods/logged-in/tenant/maintenance/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X3R3SA76",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"dashboard-columns dashboard-columns--padded issues\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"model\"]],\"empty\"],null]],null,{\"statements\":[[0,\"\\n    \"],[7,\"section\",true],[10,\"class\",\"dashboard-column issues__main-column\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"dashboard-columns dashboard-columns--no-bg issue-panel\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"dashboard-column issues__main-column issues__main-column--is-open\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"issue-panel__empty\"],[8],[0,\"\\n            Not part of a current tenancy\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[0,\"    \"],[7,\"section\",true],[10,\"class\",\"dashboard-column dashboard-column--sidebar issues__sidebar\"],[8],[0,\"\\n\\n\"],[0,\"      \"],[7,\"div\",true],[10,\"class\",\"issue-report\"],[8],[0,\"\\n        \"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn--positive btn--block btn--full-width\",\"logged-in.tenant.maintenance.new\"]],{\"statements\":[[0,\"Report New Issue\"]],\"parameters\":[]},null],[0,\"\\n      \"],[9],[0,\"\\n\\n\"],[0,\"      \"],[1,[28,\"maintenance/issue-list\",null,[[\"model\",\"tenantView\",\"showResolved\"],[[24,[\"issues\"]],true,true]]],false],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n\"],[0,\"    \"],[7,\"section\",true],[10,\"class\",\"dashboard-column issues__main-column\"],[8],[0,\"\\n\\n      \"],[1,[22,\"liquid-outlet\"],false],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/tenant/maintenance/template.hbs"
    }
  });

  _exports.default = _default;
});