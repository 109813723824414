define("propertycloud/adapters/property-marketing", ["exports", "propertycloud/adapters/application", "moment"], function (_exports, _application, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // we want everything to use the host and namespace defined in the
  // application adapter so we extend from that instead
  var _default = _application.default.extend({
    findRecord: function findRecord(store, type, id) {
      var url = "".concat(this.host, "/").concat(this.namespace, "/estate/property.view-marketing-info");
      var data = {
        propertyId: id
      };
      return this.ajax(url, 'GET', {
        data: data
      });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var url = "".concat(this.host, "/").concat(this.namespace, "/estate/property.update-marketing-info");
      var serializer = store.serializerFor(type.modelName);
      var data = {};
      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      });
      var postableData = {
        'payload': {
          'propertyId': data.data.id,
          'summary': data.data.attributes.summary,
          'description': data.data.attributes.description,
          'availability': {
            'from': (0, _moment.default)(data.data.attributes['start-date']).format('YYYY-MM-DD'),
            'to': (0, _moment.default)(data.data.attributes['end-date']).format('YYYY-MM-DD')
          },
          'advertisedRent': {
            'amount': data.data.attributes['price-per-month'],
            'currency': 'GBP',
            'frequency': 'pppcm'
          }
        }
      };
      return this.ajax(url, 'POST', {
        data: postableData
      });
    }
  });

  _exports.default = _default;
});