define("propertycloud/authenticators/oauth2-implicit-grant", ["exports", "ember-simple-auth/authenticators/oauth2-implicit-grant", "propertycloud/config/environment"], function (_exports, _oauth2ImplicitGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ifrm = document.createElement("iframe");
  ifrm.setAttribute('id', 'silent-refresher');
  ifrm.setAttribute("src", "".concat(window.location.origin, "/silent-refresh.html#authenticationUrl=").concat(_environment.default.authenticationUrl));
  ifrm.style.width = "0";
  ifrm.style.height = "0";
  ifrm.style.position = "absolute";
  ifrm.style.left = "-9999px";

  function inIframe() {
    if (window.self !== window.top) {
      return true;
    }

    return false;
  }

  var _default = _oauth2ImplicitGrant.default.extend({
    _createRefreshIframe: function _createRefreshIframe() {
      if (!document.getElementById('silent-refresher') && !inIframe()) {
        document.body.appendChild(ifrm);
      }
    },
    restore: function restore(data) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!_this._validateData(data)) {
          return reject('Could not restore session - "access_token" missing.');
        }

        _this._createRefreshIframe();

        return resolve(data);
      });
    },
    authenticate: function authenticate(hash) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (hash.error) {
          reject(hash.error);
        } else if (!_this2._validateData(hash)) {
          reject('Invalid auth params - "access_token" missing.');
        } else {
          _this2._createRefreshIframe();

          resolve(hash);
        }
      });
    }
  });

  _exports.default = _default;
});