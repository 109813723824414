define("propertycloud/adapters/property-image", ["exports", "propertycloud/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // we want everything to use the host and namespace defined in the
  // application adapter so we extend from that instead
  var _default = _application.default.extend({
    // findAll(store, type, id) {
    //   // let url = `${this.get('host')}/${this.get('namespace')}/estate/property.view`;
    //   // let data = {
    //   //   propertyId: id
    //   // };
    //   // return this.ajax(url, 'GET', { data });
    //   return { 'data': [{ 'type': 'property-image', 'id': '1', 'attributes': { 'filename': 'kitty-kat.jpg', 'width': 1200, 'height': 800, 'src': 'http://placekitten.com/g/1200/800' }, 'relationships': { 'property': { 'data': { 'type': 'property', 'id': 'bc90ea4d-e509-47d3-8dfe-ec2b045d5baf' } } } }, { 'type': 'property-image', 'id': '2', 'attributes': { 'filename': 'kitty-kat.jpg', 'width': 1100, 'height': 400, 'src': 'http://placekitten.com/g/1100/400' }, 'relationships': { 'property': { 'data': { 'type': 'property', 'id': 'bc90ea4d-e509-47d3-8dfe-ec2b045d5baf' } } } }] };
    // },
    createRecord: function createRecord(store, type, snapshot) {
      var endpoint = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'estate/property.add-image-to-gallery';
      var url = "".concat(this.host, "/").concat(this.namespace, "/").concat(endpoint);
      var serializer = store.serializerFor(type.modelName);
      var data = {}; // this is a funky function in that it modifes the data directly
      // without needing to reassign a returned object

      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      });
      return this.ajax(url, 'POST', {
        data: data
      });
    },
    deleteRecord: function deleteRecord(store, type, snapshot) {
      return this.createRecord(store, type, snapshot, 'estate/property.remove-image-from-gallery');
    }
  });

  _exports.default = _default;
});