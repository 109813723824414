define("propertycloud/pods/components/landlord-statements-approval/toggle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selected: Ember.computed('value', {
      set: function set() {
        var row = this.row;
        row.toggleProperty('selected');
        return this.value;
      },
      get: function get() {
        return this.value;
      }
    })
  });

  _exports.default = _default;
});