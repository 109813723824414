define("propertycloud/pods/logged-in/maintenance/detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NholU/lo",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[24,[\"model\",\"property\",\"addressShortFormat\"]]],null],false],[0,\"\\n\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"issue-details__header-button\",\"logged-in.maintenance\"]],{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"issue-details__header-button-close\"],[8],[1,[28,\"svg-jar\",[\"close\"],[[\"class\"],[\"issue-details__header-button-icon\"]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[1,[28,\"maintenance/issue-panel\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/maintenance/detail/template.hbs"
    }
  });

  _exports.default = _default;
});