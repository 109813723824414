define("propertycloud/utilities/date", ["exports", "moment", "moment-range"], function (_exports, _moment, _momentRange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.daysBetween = daysBetween;
  _exports.durationInWeeks = durationInWeeks;
  _exports.durationInMonths = durationInMonths;

  var moment = _momentRange.default.extendMoment(_moment.default);

  function daysBetween(startDate, endDate) {
    if (isNaN(startDate) || isNaN(endDate)) {
      return 0;
    } // convert to moment to compute diff
    // compute as: days => figure out months => round up - is this correct?


    var start = moment(startDate);
    var end = moment(endDate);
    var diffDays = end.diff(start, 'days') + 1; // We ignore any leap year differences

    var dateRange = moment.range(start, end);
    var leapDays = 0;

    for (var year = dateRange.start.year(); year <= dateRange.end.year(); year++) {
      var date = moment("".concat(year, "-02-29"));

      if (date.isLeapYear() && dateRange.contains(date)) {
        leapDays++;
      }
    }

    return diffDays - leapDays;
  }

  function durationInWeeks(startDate, endDate) {
    var start = moment(startDate);
    var end = moment(endDate);
    return Math.floor(end.diff(start, 'weeks'));
  }

  function durationInMonths(startDate, endDate) {
    return endDate.diff(startDate, 'months') + 1;
  }
});