define("propertycloud/pods/components/list-certificates/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['icon-list', 'property-certificate-list'],
    classNameBindings: ['small:icon-list--small'],
    tagName: 'ul',
    certificatesList: Ember.computed(function () {
      return [{
        type: 'fire',
        name: 'Fire Certificate',
        src: null,
        expiry: null,
        isExpired: false
      }, {
        type: 'epc',
        name: 'EPC',
        src: null,
        expiry: null,
        isExpired: false
      }, {
        type: 'electric',
        name: 'Electric Report',
        src: null,
        expiry: null,
        isExpired: false
      }, {
        type: 'gas',
        name: 'Gas Safety',
        src: null,
        expiry: null,
        isExpired: false
      }, {
        type: 'pat',
        name: 'PAT Certificate',
        src: null,
        expiry: null,
        isExpired: false
      }, {
        type: 'hmo-electric',
        name: 'HMO Electric',
        src: null,
        expiry: null,
        isExpired: false
      }];
    }),
    certificates: null,
    // passed in
    showMoreDetail: null,
    didInsertElement: function didInsertElement() {
      var certificatesList = Ember.get(this, 'certificatesList');
      var certificates = Ember.get(this, 'certificates');

      if (certificates === null || typeof certificates === 'undefined') {
        certificates = {};
      }

      var today = (0, _moment.default)();

      for (var i = 0; i < certificatesList.length; i++) {
        var certificate = certificates[certificatesList[i].type];

        if (typeof certificate !== 'undefined') {
          var expiryDate = certificate['expiry-date'];
          var isExpired = false;

          if (expiryDate !== null) {
            isExpired = (0, _moment.default)(expiryDate).isBefore(today, 'day');
          }

          Ember.setProperties(certificatesList[i], {
            src: certificate.src,
            expiry: certificate['expiry-date'],
            isExpired: isExpired
          });
        }
      }
    }
  });

  _exports.default = _default;
});