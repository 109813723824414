define("propertycloud/pods/components/tenancy-switcher/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "39a3HHdw",
    "block": "{\"symbols\":[\"sb\",\"tenancy\"],\"statements\":[[4,\"if\",[[24,[\"tenanciesArray\"]]],null,{\"statements\":[[4,\"select-box/native\",null,[[\"class\",\"value\",\"on-select\"],[\"form__control\",[24,[\"selectedTenancy\"]],[28,\"action\",[[23,0,[]],[24,[\"switchTenancy\"]]],null]]],{\"statements\":[[0,\"    \"],[7,\"option\",true],[10,\"disabled\",\"\"],[8],[0,\"Select Tenancy\"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"tenanciesArray\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"option\"]],\"expected `sb.option` to be a contextual component but found a string. Did you mean `(component sb.option)`? ('propertycloud/pods/components/tenancy-switcher/template.hbs' @ L5:C8) \"],null]],[[\"value\",\"label\"],[[23,2,[\"tenancy\"]],[23,2,[\"label\"]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  Loading Tenancies... \\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/tenancy-switcher/template.hbs"
    }
  });

  _exports.default = _default;
});