define("propertycloud/helpers/overdue-rent", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.overdueRent = overdueRent;
  _exports.default = void 0;

  function calculateTotalRentPaid(history) {
    var paymentHistory = history;
    var totalPaid = 0;

    if (typeof paymentHistory !== 'undefined') {
      paymentHistory.forEach(function (payment) {
        if (Ember.get(payment, 'paymentType') !== 'admin-fee' && Ember.get(payment, 'paymentType') !== 'deposit' && Ember.get(payment, 'paymentType') !== 'charge-payment') {
          totalPaid += Number(Ember.get(payment, 'amount.amount'));
        }
      });
    }

    return totalPaid;
  }

  function overdueRent(schedule, history) {
    var totalPaid = calculateTotalRentPaid(history);
    schedule = schedule.sortBy('date');
    var shouldHavePaid = 0;
    var overdueAmount = 0;

    for (var i = 0; i < schedule.length; i++) {
      // is date in past?
      if ((0, _moment.default)().isAfter((0, _moment.default)(schedule[i].date))) {
        shouldHavePaid += Number(schedule[i].payment.amount); // have they paid enough

        overdueAmount = shouldHavePaid - totalPaid;
      } else {
        // schedule date is in future, so we can stop
        break;
      }
    } // for


    return overdueAmount;
  }

  var _default = Ember.Helper.helper(overdueRent);

  _exports.default = _default;
});