define("propertycloud/pods/components/upcoming-tenancy/component", ["exports", "ember-changeset", "ember-changeset-validations", "propertycloud/validations/reinvite-guarantor"], function (_exports, _emberChangeset, _emberChangesetValidations, _reinviteGuarantor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    classNames: ['upcoming-tenancy-block'],
    // Just used in the template, updated when successfully changing email address.
    guarantorEmail: null,
    guarantorFormSuccessful: false,
    guarantorFormHasError: false,
    guarantorFormSending: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.reInviteGuarantorChangeset = new _emberChangeset.default({
        name: this.get('tenancy.guarantorInfo.name') || null,
        email: this.get('tenancy.guarantorInfo.email') || null
      }, (0, _emberChangesetValidations.default)(_reinviteGuarantor.default), _reinviteGuarantor.default);
      this.set('guarantorEmail', this.get('tenancy.guarantorInfo.email'));
    },
    actionRequired: Ember.computed('tenancy', function () {
      if (this.tenancy.status === 'self' || this.tenancy.status === 'guarantor') {
        return true;
      }

      return false;
    }),
    agreedOrApproved: Ember.computed('tenancy', function () {
      return this.tenancy.hasAgreed || this.tenancy.agreement && this.tenancy.agreement.approved;
    }),
    actions: {
      reInviteGuarantor: function reInviteGuarantor() {
        var _this = this;

        if (this.reInviteGuarantorChangeset.error && Object.keys(this.reInviteGuarantorChangeset.error).length > 0) {
          return;
        }

        this.set('guarantorFormSending', true);
        this.set('guarantorFormHasError', false);
        this.ajax.post('/estate/property.add-guarantor-info', {
          data: JSON.stringify({
            payload: {
              propertyId: this.get('tenancy.propertyId'),
              tenancyNumber: this.get('tenancy.tenancyNumber'),
              contact: {
                name: this.reInviteGuarantorChangeset.get('name'),
                emailAddress: this.reInviteGuarantorChangeset.get('email')
              }
            }
          })
        }).then(function () {
          _this.set('guarantorEmail', _this.reInviteGuarantorChangeset.get('email'));

          _this.set('guarantorFormSending', false);

          _this.set('guarantorFormSuccessful', true);
        }).catch(function () {
          _this.set('guarantorFormSending', false);

          _this.set('guarantorFormSuccessful', false);

          _this.set('guarantorFormHasError', true);
        });
      },
      resetGuarantorForm: function resetGuarantorForm() {
        this.set('guarantorFormSuccessful', false);
        this.set('guarantorFormHasError', false);
      }
    }
  });

  _exports.default = _default;
});