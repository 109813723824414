define("propertycloud/pods/logout/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    activate: function activate() {
      this.session.invalidate();
    }
  });

  _exports.default = _default;
});