define("propertycloud/utilities/universities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    universities: [{
      name: 'University of Chester',
      code: 'CHSTR'
    }, {
      name: 'University of Liverpool',
      code: 'LVRPL'
    }, {
      name: 'Edge Hill University',
      code: 'EHU'
    }, {
      name: 'Liverpool John Moores University',
      code: 'LJM'
    }, {
      name: 'Liverpool Hope University',
      code: 'LHOPE'
    }, {
      name: 'City of Liverpool College',
      code: 'COLC'
    }, {
      name: 'Liverpool Institute of Performing Arts',
      code: 'LIVIN'
    }, {
      name: 'University of Central Lancashire',
      code: 'CLANC'
    }, {
      name: 'University of Sheffield',
      code: 'SHEFD'
    }, {
      name: 'Sheffield Hallam University',
      code: 'SHU'
    }, {
      name: 'Sheffield College',
      code: 'SCOLL'
    }]
  };
  _exports.default = _default;
});