define("propertycloud/chart-themes/flatdark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    colors: ['#00FFA8', '#00FFF6', '#ba10ff', '#e74c3c', '#34495e', '#3498db', '#1abc9c', '#f39c12', '#d35400'],
    chart: {
      backgroundColor: '#3B3B3B',
      spacingTop: 20,
      spacingLeft: 20,
      spacingRight: 20,
      spacingbottom: 20
    },
    xAxis: {
      gridLineDashStyle: 'Dash',
      gridLineWidth: 1,
      gridLineColor: '#3B3B3B',
      lineColor: '#3B3B3B',
      minorGridLineColor: '#585858',
      tickColor: '#D8D8D8',
      tickWidth: 1,
      tickmarkPlacement: 'on',
      labels: {
        y: 40,
        style: {
          fontSize: '10px'
        }
      },
      title: {
        style: {
          color: '#737373'
        }
      }
    },
    yAxis: {
      gridLineDashStyle: 'Dash',
      gridLineColor: '#585858',
      lineColor: '#3B3B3B',
      minorGridLineColor: '#585858',
      tickColor: '#D8D8D8',
      tickWidth: 0,
      labels: {
        style: {
          color: '#fff',
          fontSize: '12px'
        }
      },
      title: {
        text: '',
        style: {
          color: '#FFFFFF',
          fontSize: 0
        }
      }
    },
    legendBackgroundColor: 'rgba(0, 0, 0, 0.5)',
    background2: '#505053',
    dataLabelsColor: '#B0B0B3',
    textColor: '#34495e',
    contrastTextColor: '#F0F0F3',
    maskColor: 'rgba(255,255,255,0.3)',
    title: {
      align: 'left',
      margin: 45,
      style: {
        color: '#fff',
        fontSize: 18
      }
    },
    subtitle: {
      style: {
        color: '#666666'
      }
    },
    legend: {
      itemStyle: {
        color: '#C0C0C0'
      },
      itemHoverStyle: {
        color: '#C0C0C0'
      },
      itemHiddenStyle: {
        color: '#444444'
      }
    }
  };
  _exports.default = _default;
});