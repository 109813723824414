define("propertycloud/validators/checked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateChecked;

  function validateChecked(_ref) {
    var _ref$checked = _ref.checked,
        checked = _ref$checked === void 0 ? true : _ref$checked;
    var errorMessage = '';

    switch (checked) {
      case true:
        errorMessage = 'Please check the box to confirm.';
        break;

      default:
        errorMessage = 'Please uncheck the box to confirm.';
    }

    return function (key, newValue) {
      return newValue === checked ? true : errorMessage;
    };
  }
});