define("propertycloud/utilities/scrollTo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Animated scroll to function
   * @param {node} element element to scroll
   * @param {integer} to position to scroll to 
   * @param {integer} duration duration of scroll animation
   */
  function scrollTo(element, to, duration) {
    if (element) {
      var start = element.scrollTop,
          change = to - start,
          currentTime = 0,
          increment = 20;

      var animateScroll = function animateScroll() {
        currentTime += increment;
        var val = Math.easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;

        if (currentTime < duration) {
          setTimeout(animateScroll, increment);
        }
      };

      animateScroll();
    }
  } //t = current time
  //b = start value
  //c = change in value
  //d = duration


  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  };

  var _default = scrollTo;
  _exports.default = _default;
});