define("propertycloud/serializers/property-room-breakdown", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    normalizeSingleResponse: function normalizeSingleResponse(store, type, payload) {
      // get monthyly rent map keys and add as names
      // let monthlyRentMapping = payload.data.attributes['monthly-rent-mapping'];
      // for (let i = 0; i < monthlyRentMapping.length; i++) {
      //   monthlyRentMapping[i].name = i;
      // }
      return {
        data: {
          id: payload.data.id,
          type: type.modelName,
          attributes: {
            benchmarkRoom: payload.data.attributes['benchmark-room'],
            monthlyRentMapping: payload.data.attributes['monthly-rent-mapping']
          }
        }
      };
    },
    serialize: function serialize(snapshot) {
      var payload = {
        'propertyId': snapshot.id,
        'monthly-rent-mapping': snapshot._attributes.monthlyRentMapping,
        'benchmark-room': "" + snapshot._attributes.benchmarkRoom
      };
      return {
        payload: payload
      };
    }
  });

  _exports.default = _default;
});