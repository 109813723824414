define("propertycloud/adapters/issue", ["exports", "propertycloud/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    query: function query(store, type, _query) {
      var url = "".concat(this.host, "/").concat(this.namespace, "/maintenance/list-issues");
      var data = _query;
      return this.ajax(url, 'GET', {
        data: data
      });
    },
    findRecord: function findRecord(store, type, id) {
      var url = "".concat(this.host, "/").concat(this.namespace, "/maintenance/issue-details");
      var data = {
        issueId: id,
        include: 'assigned-contractor'
      };
      return this.ajax(url, 'GET', {
        data: data
      });
    }
  });

  _exports.default = _default;
});