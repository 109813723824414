define("propertycloud/templates/freestyle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bKe9J9Ow",
    "block": "{\"symbols\":[\"section\"],\"statements\":[[5,\"freestyle-guide\",[],[[\"@title\",\"@subtitle\"],[\"Ember Freestyle\",\"Living Style Guide\"]],{\"statements\":[[0,\"\\n\\n  \"],[5,\"freestyle-section\",[],[[\"@name\"],[\"Visual Style\"]],{\"statements\":[[0,\"\\n\\n    \"],[6,[23,1,[\"subsection\"]],[],[[\"@name\"],[\"Typography\"]],{\"statements\":[[0,\"\\n\\n\"],[4,\"freestyle-usage\",[\"typography-times\"],[[\"title\"],[\"Times New Roman\"]],{\"statements\":[[0,\"        \"],[5,\"freestyle-typeface\",[],[[\"@fontFamily\"],[\"Times New Roman\"]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"freestyle-usage\",[\"typography-helvetica\"],[[\"title\"],[\"Helvetica\"]],{\"statements\":[[0,\"        \"],[5,\"freestyle-typeface\",[],[[\"@fontFamily\"],[\"Helvetica\"]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[6,[23,1,[\"subsection\"]],[],[[\"@name\"],[\"Color\"]],{\"statements\":[[0,\"\\n\\n\"],[4,\"freestyle-usage\",[\"fp\"],[[\"title\",\"usageTitle\"],[\"Freestyle Palette\",\"Usage\"]],{\"statements\":[[0,\"        \"],[5,\"freestyle-palette\",[],[[\"@colorPalette\",\"@title\",\"@description\"],[[22,\"colorPalette\"],\"Dummy App Color Palette\",\"This component displays the color palette specified in freestyle/palette.json\"]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n  \"]],\"parameters\":[1]}],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/templates/freestyle.hbs"
    }
  });

  _exports.default = _default;
});