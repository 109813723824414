define("propertycloud/adapters/inspection", ["exports", "propertycloud/adapters/application", "ember-data-url-templates"], function (_exports, _application, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberDataUrlTemplates.default, {
    urlTemplate: '{+host}/{+namespace}/inspections.view?inspectionId={id}',
    queryUrlTemplate: '{+host}/{+namespace}/inspections.search{?query*}',
    // createRecordUrlTemplate: '{+host}/{+namespace}/inspections.record',
    // updateRecordUrlTemplate: '{+host}/{+namespace}/inspections.update',
    updateRecord: function updateRecord(store, type, snapshot) {
      var url = "".concat(this.host, "/").concat(this.namespace, "/inspections.update");
      var serializer = store.serializerFor(type.modelName);
      var data = {}; // this is a funky function in that it modifes the data directly
      // without needing to reassign a returned object

      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      });
      return this.ajax(url, 'POST', {
        data: data
      });
    },
    createRecord: function createRecord(store, type, snapshot) {
      var url = "".concat(this.host, "/").concat(this.namespace, "/inspections.record");
      var serializer = store.serializerFor(type.modelName);
      var data = {}; // this is a funky function in that it modifes the data directly
      // without needing to reassign a returned object

      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      });
      return this.ajax(url, 'POST', {
        data: data
      });
    }
  });

  _exports.default = _default;
});