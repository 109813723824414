define("propertycloud/pods/logged-in/manage/property/certificates/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PUh5kscQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"grid grid--padded\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid__row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"grid__col\"],[8],[0,\"\\n      \"],[1,[28,\"edit-certificates\",null,[[\"certificates\",\"markChange\"],[[24,[\"model\",\"certificates\"]],[28,\"action\",[[23,0,[]],\"setChanged\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"ember-wormhole\",null,[[\"to\"],[\"floatingButtons\"]],{\"statements\":[[0,\"  \"],[1,[28,\"save-button\",null,[[\"isSaving\",\"saveSuccessful\",\"save\"],[[24,[\"isSaving\"]],[24,[\"saveSuccessful\"]],[28,\"action\",[[23,0,[]],\"saveData\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/manage/property/certificates/template.hbs"
    }
  });

  _exports.default = _default;
});