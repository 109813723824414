define("propertycloud/pods/logged-in/finance/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UaiE6iwp",
    "block": "{\"symbols\":[],\"statements\":[[5,\"spinkit-cube-grid\",[],[[],[]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/finance/loading/template.hbs"
    }
  });

  _exports.default = _default;
});