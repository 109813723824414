define("propertycloud/pods/logged-in/manage/property/contract-template/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      // we lookup the contract by property id so we need
      // the property model here
      var parentModel = this.modelFor('logged-in/manage/property');
      var propertyId = Ember.get(parentModel, 'id'); // get room breakdown in model too

      return Ember.RSVP.hash({
        details: this.modelFor('logged-in/manage/property'),
        contracts: this.store.findRecord('property-contract-template', propertyId),
        roomBreakdown: this.store.findRecord('property-room-breakdown', propertyId),
        features: this.store.findRecord('property-feature', propertyId)
      });
    },
    setupController: function setupController(controller, model) {
      // we want access to the property from the child route so give the controller
      // access to that model
      controller.set('property', this.modelFor('logged-in/manage/property'));
      controller.set('initialContractModel', model.contracts.serialize());

      this._super.apply(this, arguments);
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (Ember.get(this.controller, 'hasChanged') && !confirm('It looks like you have made some changes, if you navigate away before saving you will lose these changes. Are you sure you want to continue?')) {
          transition.abort();
        } else {
          Ember.set(this.controller, 'hasChanged', false);
          return true;
        }
      }
    }
  });

  _exports.default = _default;
});