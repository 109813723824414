define("propertycloud/pods/components/invite-guarantor-micro/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8U1PIh5N",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isSuccessful\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"replace-interface__response replace-interface__response--success\"],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"It worked, you've invited the guarantor.\"],[9],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn--tiny btn--positive\"],[3,\"action\",[[23,0,[]],[24,[\"resetDropdown\"]]]],[8],[0,\"OK, go back\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"form\",true],[10,\"class\",\"form\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"form__group\"],[8],[0,\"\\n      \"],[1,[28,\"validation-input\",null,[[\"propertyName\",\"fieldId\",\"type\",\"label\",\"changeset\"],[\"fullName\",\"full-name\",\"text\",\"Full Name\",[24,[\"changeset\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"form__group\"],[8],[0,\"\\n      \"],[1,[28,\"validation-input\",null,[[\"propertyName\",\"fieldId\",\"type\",\"label\",\"changeset\"],[\"emailAddress\",\"email-address\",\"email\",\"Email Address\",[24,[\"changeset\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"form__group form__group--centered\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"btn btn--positive btn--tiny btn--icon\"],[12,\"disabled\",[22,\"isLoading\"]],[12,\"type\",\"submit\"],[3,\"action\",[[23,0,[]],\"submit\"]],[8],[7,\"span\",true],[8],[0,\"Invite Guarantor\"],[9],[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[1,[28,\"svg-jar\",[\"spinner\"],null],false]],\"parameters\":[]},null],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/invite-guarantor-micro/template.hbs"
    }
  });

  _exports.default = _default;
});