define("propertycloud/pods/components/property-arbitrary-info/component", ["exports", "lodash/find"], function (_exports, _find2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['repeater-form-field'],
    items: null,
    itemKey: null,
    itemValue: null,
    fieldsBlank: false,
    canSubmit: false,
    actions: {
      updateFieldsBlank: function updateFieldsBlank() {
        var itemKey = Ember.get(this, 'itemKey');
        var itemValue = Ember.get(this, 'itemValue');

        if (itemKey !== null && itemValue !== null) {
          if (/^ *$/.test(itemKey) || /^ *$/.test(itemValue)) {
            Ember.set(this, 'canSubmit', false);
          } else {
            Ember.set(this, 'canSubmit', true);
          }
        } else {
          Ember.set(this, 'canSubmit', false);
        }
      },
      addItem: function addItem() {
        var _this = this;

        Ember.get(this, 'markChange')();
        var items = Ember.get(this, 'items');
        var alreadyExist = (0, _find2.default)(items, function (o) {
          return o.key === Ember.get(_this, 'itemKey') && o.value === Ember.get(_this, 'itemValue');
        });

        if (alreadyExist) {
          return;
        }

        var obj = {
          key: Ember.get(this, 'itemKey'),
          value: Ember.get(this, 'itemValue')
        };
        items.pushObject(obj);
        this.setProperties({
          key: null,
          value: null
        });
        this.send('updateFieldsBlank');
      },
      removeItem: function removeItem(item) {
        Ember.get(this, 'markChange')();
        var items = Ember.get(this, 'items');
        items.removeObject(item);
      }
    }
  });

  _exports.default = _default;
});