define("propertycloud/pods/logged-in/manage/landlord/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    agentContext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    landlordId: null,
    model: function model(params) {
      Ember.set(this, 'landlordId', params.landlord_id);
      return Ember.RSVP.hash({
        landlord: this.store.queryRecord('landlord', {
          landlordId: params.landlord_id,
          agentId: Ember.get(this, 'agentContext.id')
        }),
        properties: this.store.query('property', {
          landlordId: params.landlord_id
        }),
        statements: this.store.query('available-landlord-statement', {
          landlordId: params.landlord_id
        })
      });
    },
    setupController: function setupController(controller, model) {
      var ajax = Ember.get(this, 'ajax');
      controller.getFinancials(Ember.get(model.landlord, 'id'));
      controller.updateFinancials(Ember.get(model.landlord, 'id'));
      controller.setModelMap(model.landlord);
      ajax.request('/estate/landlord.communication-history', {
        data: {
          agentId: Ember.get(this, 'agentContext.id'),
          landlordId: Ember.get(this, 'landlordId')
        }
      }).then(function (response) {
        var messages = [];
        response.data.forEach(function (obj) {
          messages.push({
            message: obj.attributes.message,
            time: new Date(obj.attributes.timeSent),
            outgoing: true
          });
        });
        Ember.set(controller, 'messages', messages);
      });

      this._super.apply(this, arguments);
    },
    afterModel: function afterModel(model) {
      if (model.landlord.get('photoId') === null) {
        model.landlord.set('photoId', {
          data: null,
          'document-id': null
        });
      }

      if (model.landlord.get('address') === null) {
        model.landlord.set('address', {
          flat: null,
          building: null,
          street: null,
          area: null,
          town: null,
          county: null,
          postcode: null
        });
      }

      if (model.landlord.get('bankAccount') === null) {
        model.landlord.set('bankAccount', {
          'account-number': null,
          'sort-code': null
        });
      }

      this.controllerFor('logged-in').send('setLandlord', {
        'landlord-id': Ember.get(model.landlord, 'id'),
        'name': Ember.get(model.landlord, 'primaryContact.name'),
        'email-address': Ember.get(model.landlord, 'primaryContact.email-address')
      });
    },
    removeDefaults: Ember.on('deactivate', function () {
      this.controllerFor('logged-in').send('clearLandlordProperty');
    })
  });

  _exports.default = _default;
});