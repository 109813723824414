define("propertycloud/pods/logged-in/viewings/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    agentContext: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        // TODO: Viewings model may not need to be there. This currently isnt a paginated result.
        // This is used in viewing/viewing-grid
        viewings: this.store.query('viewing', {
          agentId: Ember.get(this, 'agentContext.id'),
          cancelled: 0
        }),
        staff: this.store.query('staff-member', {
          agentId: Ember.get(this, 'agentContext.id')
        })
      });
    }
  });

  _exports.default = _default;
});