define("propertycloud/pods/components/ical-link-generator/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    agentContext: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    classNames: ['ical-link-generator'],
    selectedStaffMember: null,
    generatedLink: null,
    actions: {
      generateLink: function generateLink() {
        var _this = this;

        var ajax = Ember.get(this, 'ajax');
        var userId = Ember.get(this, 'selectedStaffMember');
        var agentId = Ember.get(this, 'agentContext.id');
        var flashMessages = Ember.get(this, 'flashMessages');
        Ember.set(this, 'isGenerating', true);
        flashMessages.clearMessages();
        var request = ajax.raw('viewings/calendar.subscribe', {
          type: 'POST',
          dataType: 'text',
          data: JSON.stringify({
            payload: {
              userId: userId,
              agentId: agentId
            }
          })
        });
        request.then(function (_ref) {
          var jqXHR = _ref.jqXHR;
          Ember.set(_this, 'generatedLink', jqXHR.getResponseHeader('Location'));
        });
        request.catch(function () {
          flashMessages.danger('There was a server error, please try again later');
        });
        request.finally(function () {
          Ember.set(_this, 'isGenerating', false);
        });
      }
    }
  });

  _exports.default = _default;
});