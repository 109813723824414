define("propertycloud/templates/components/spinkit-folding-cube", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+QPInvJp",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n \\n\\n      \"],[7,\"div\",true],[10,\"class\",\"sk-folding-cube\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"sk-cube1 sk-cube\"],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"sk-cube2 sk-cube\"],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"sk-cube4 sk-cube\"],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"sk-cube3 sk-cube\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/templates/components/spinkit-folding-cube.hbs"
    }
  });

  _exports.default = _default;
});