define("propertycloud/serializers/tenancy-application", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    normalizeSingleResponse: function normalizeSingleResponse(store, type, payload) {
      var stages = payload.data.attributes.stages;
      var personalInfoCompleted = stages['accept-tenancy'].completed;
      var guarantorInfoCompleted = stages['guarantor-info'].completed;
      var invitedTenantsCompleted = stages['all-tenants-invited'].completed;
      var firstPaymentCompleted = stages['first-payment'].paid;
      return {
        data: {
          id: payload.data.id,
          type: type.modelName,
          attributes: {
            personalInfoCompleted: personalInfoCompleted,
            guarantorInfoCompleted: guarantorInfoCompleted,
            firstPaymentCompleted: firstPaymentCompleted,
            invitedTenantsCompleted: invitedTenantsCompleted,
            roomAvailability: payload.data.attributes['room-availability']
          }
        }
      };
    }
  });

  _exports.default = _default;
});