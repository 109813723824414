define("propertycloud/pods/components/income-expenditure-table/chart/component", ["exports", "propertycloud/chart-themes/flatdark", "propertycloud/helpers/format-currency", "papaparse", "moment"], function (_exports, _flatdark, _formatCurrency, _papaparse, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    agentContext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    // passed in options
    url: null,
    landlordId: null,
    // optional
    from: null,
    to: null,
    propertyId: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setupData();
    },
    chartData: null,
    income: null,
    expenses: null,
    projected: null,
    grouped: 'daily',
    chartOptions: Ember.computed(function () {
      return {
        chart: {
          type: 'area',
          zoomType: 'x'
        },
        title: {
          text: ''
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          labels: {
            formatter: function formatter() {
              this.value = this.value / 100;
              return "\xA3".concat(this.axis.defaultLabelFormatter.call(this));
            }
          }
        },
        tooltip: {
          formatter: function formatter() {
            return "<b>".concat((0, _moment.default)(this.x, 'x').format('Do MMMM, YYYY'), "</b>: \xA3").concat((0, _formatCurrency.formatCurrency)([this.y, {
              hideSign: true
            }]));
          }
        },
        plotOptions: {
          series: {
            lineWidth: 2,
            borderWidth: 0,
            marker: {
              fillColor: '#fff',
              radius: 3,
              symbol: 'circle'
            }
          }
        }
      };
    }),
    theme: _flatdark.default,
    setupData: function setupData() {
      var _this = this;

      var ajax = Ember.get(this, 'ajax');
      var url = Ember.get(this, 'url');
      var type = Ember.get(this, 'type');
      var landlordId = Ember.get(this, 'landlordId');
      var propertyId = Ember.get(this, 'propertyId');
      var agentId = Ember.get(this, 'agentContext.id');
      var data = {
        grouped: Ember.get(this, 'grouped'),
        from: Ember.get(this, 'from'),
        to: Ember.get(this, 'to')
      };
      var dataReal = Object.assign({
        agentId: agentId
      }, data);
      var dataProjected = Object.assign({}, data);

      if (type === 'agent') {
        dataProjected.agentId = agentId;
      }

      if (landlordId) {
        dataReal.landlordId = landlordId;
        dataProjected.landlordId = landlordId;
      }

      if (propertyId) {
        dataReal.propertyId = propertyId;
        dataProjected.propertyId = propertyId;
      }

      var promises = Ember.RSVP.hash({
        real: ajax.request(url, {
          dataType: 'text',
          data: dataReal
        }),
        projected: ajax.request('/estate/finance.projected-income', {
          dataType: 'text',
          data: dataProjected
        })
      });
      promises.then(function (data) {
        var incomeBuffer = [];
        var expensesBuffer = [];
        var totalIncome = 0;
        var totalExpenses = 0;
        var projectedBuffer = [];
        var totalProjected = 0;

        var projectedJson = _papaparse.default.parse(data.projected, {
          dynamicTyping: true,
          header: true,
          skipEmptyLines: true
        });

        var realJson = _papaparse.default.parse(data.real, {
          dynamicTyping: true,
          header: true,
          skipEmptyLines: true
        });

        projectedJson.data.forEach(function (element) {
          projectedBuffer.push([Number((0, _moment.default)(element.Date, 'YYYY-MM-DD').format('x')), element.Amount]);
          totalProjected += element.Amount;
        }, _this);
        realJson.data.forEach(function (element) {
          incomeBuffer.push([Number((0, _moment.default)(element.Date, 'YYYY-MM-DD').format('x')), element.Income]);
          expensesBuffer.push([Number((0, _moment.default)(element.Date, 'YYYY-MM-DD').format('x')), element.Expenditure]);
          totalIncome += element.Income;
          totalExpenses += element.Expenditure;
        }, _this);
        Ember.set(_this, 'totalIncome', totalIncome);
        Ember.set(_this, 'totalProjected', totalProjected);
        Ember.set(_this, 'totalExpenses', totalExpenses);
        Ember.set(_this, 'income', incomeBuffer);
        Ember.set(_this, 'projected', projectedBuffer);
        Ember.set(_this, 'expenses', expensesBuffer);
        Ember.set(_this, 'chartData', [{
          name: 'Scheduled',
          data: Ember.get(_this, 'projected'),
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [[0, 'rgba(25, 178, 149, 1)'], [1, 'rgba(25, 178, 149, 0)']]
          }
        }, {
          name: 'Income',
          data: Ember.get(_this, 'income'),
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [[0, 'rgba(25, 178, 149, 1)'], [1, 'rgba(25, 178, 149, 0)']]
          }
        }, {
          name: 'Expenses',
          data: Ember.get(_this, 'expenses'),
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [[0, 'rgba(0, 165, 255, 1)'], [1, 'rgba(0, 165, 255, 0)']]
          }
        }]);
      });
    },
    init: function init() {
      this.setupData();

      this._super.apply(this, arguments);
    },
    actions: {
      toggleGrouping: function toggleGrouping(group) {
        Ember.set(this, 'grouped', group);
        this.setupData();
      }
    }
  });

  _exports.default = _default;
});