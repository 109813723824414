define("propertycloud/pods/components/global-search-input/component", ["exports", "ember-keyboard"], function (_exports, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend(_emberKeyboard.EKMixin, _emberKeyboard.EKOnFocusMixin, {
    classNames: ['elastic-input-block__input'],
    // keyboard events
    keyUpSearch: Ember.on('input', function () {
      this.search(this.value);
    }),
    keypressCloseSpotlight: Ember.on((0, _emberKeyboard.keyDown)('Escape'), (0, _emberKeyboard.keyDown)('shift+Space'), function (event) {
      event.preventDefault();
      var closeSpotlight = Ember.get(this, 'closeSpotlight');
      closeSpotlight();
    }),
    keypressDownResults: Ember.on((0, _emberKeyboard.keyDown)('ArrowUp'), (0, _emberKeyboard.keyDown)('ArrowDown'), function (event) {
      event.preventDefault();
      Ember.set(this, 'direction', (0, _emberKeyboard.getCode)(event));
      var cycleResults = Ember.get(this, 'cycleResults');
      cycleResults((0, _emberKeyboard.getCode)(event));
    }),
    // 'Enter' only triggers on keyDown...
    keypressFollowLink: Ember.on((0, _emberKeyboard.keyDown)('Enter'), function (event) {
      event.preventDefault();
      var followLink = Ember.get(this, 'followLink');
      followLink();
    })
  });

  _exports.default = _default;
});