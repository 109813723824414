define("propertycloud/pods/logged-in/manage/tenant/controller", ["exports", "moment", "ember-cli-uuid", "propertycloud/validations/edit-tenant", "propertycloud/utilities/universities", "propertycloud/helpers/calculate-total-rent-paid"], function (_exports, _moment, _emberCliUuid, _editTenant, _universities, _calculateTotalRentPaid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Controller.extend({
    ValidationFields: _editTenant.default,
    init: function init() {
      this._super.apply(this, arguments); // Create a changeset object to map this model properties to. This is a workaround to solve an issue with ember-changeset@1.4.1 nested validation properties. SM


      this.modelMap = this.modelMap || {};
    },
    // Map model properties to changeset object
    setModelMap: function setModelMap(tenant) {
      var mapObj = Ember.get(this, 'modelMap');
      Ember.set(mapObj, 'name', Ember.get(tenant, 'name'));
      Ember.set(mapObj, 'mobileNumber', Ember.get(tenant, 'mobileNumber'));
      Ember.set(mapObj, 'dateOfBirth', Ember.get(tenant, 'dateOfBirth'));
    },
    sms: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    queryParams: ['tenancy'],
    tenancy: null,
    universities: _universities.default.universities,
    defaultUniversity: 'LVRPL',
    maxBirthdate: (0, _moment.default)().subtract(16, 'years').toDate(),
    amountDue: null,
    totalPaid: null,
    studentIdImage: null,
    studentIdFile: null,
    photoIdImage: null,
    photoIdFile: null,
    studentIdData: Ember.computed('model.tenant.studentId', {
      get: function get() {
        return Ember.get(this, 'model.tenant.studentId');
      }
    }),
    photoIdData: Ember.computed('model.tenant.photoId', {
      get: function get() {
        return Ember.get(this, 'model.tenant.photoId');
      }
    }),
    totalRentPaid: Ember.computed('model.paymentHistory', function () {
      return (0, _calculateTotalRentPaid.default)(this.model.paymentHistory);
    }),
    sortedSchedule: Ember.computed.sort('model.paymentSchedule.schedule', function (a, b) {
      var aDate = (0, _moment.default)(a.date);
      var bDate = (0, _moment.default)(b.date);

      if (aDate.isAfter(bDate)) {
        return 1;
      } else if (aDate.isSame(bDate)) {
        return 0;
      }

      return -1;
    }),
    sortedFullSchedule: Ember.computed.sort('model.propertySchedule', function (a, b) {
      var aDate = (0, _moment.default)(a.date);
      var bDate = (0, _moment.default)(b.date);

      if (aDate.isAfter(bDate)) {
        return 1;
      } else if (aDate.isSame(bDate)) {
        return 0;
      }

      return -1;
    }),
    calculateFinances: function calculateFinances() {
      var totalPaid = Ember.get(this, 'totalRentPaid');
      var schedule = Ember.get(this, 'sortedFullSchedule');
      var shouldHavePaid = 0;
      var overdueAmount = 0;
      var dueAmount = 0;

      for (var i = 0; i < schedule.length; i++) {
        // is date in past?
        if ((0, _moment.default)().isAfter((0, _moment.default)(schedule[i].date))) {
          shouldHavePaid += Number(schedule[i].payment.amount);
          overdueAmount = shouldHavePaid - totalPaid;
        } else {
          // schedule date is in future, so we can stop
          dueAmount = Number(schedule[i].payment.amount);
          break;
        }
      }

      return {
        dueAmount: dueAmount,
        overdueAmount: overdueAmount
      };
    },
    nextPayment: Ember.computed('model.propertySchedule.@each', 'totalRentPaid', function () {
      return this.calculateFinances().dueAmount;
    }),
    overdueAmount: Ember.computed('model.propertySchedule.@each', 'totalRentPaid', function () {
      return this.calculateFinances().overdueAmount;
    }),
    actions: {
      updateDoB: function updateDoB(changeset, value) {
        var _value = _slicedToArray(value, 1),
            date = _value[0];

        if (date) {
          Ember.set(changeset, 'dateOfBirth', (0, _moment.default)(date).format('YYYY-MM-DD'));
        }
      },
      updateUniversity: function updateUniversity(value) {
        var model = Ember.get(this, 'model');
        Ember.set(model.tenant, 'university', value);
      },
      saveData: function saveData(changeset) {
        var _this = this;

        Ember.set(this, 'isSaving', true);
        var model = Ember.get(this, 'model');
        var errorHandler = Ember.get(this, 'errorHandler'); // Validate the changeset before saving

        changeset.validate().then(function () {
          if (!changeset.get('isValid')) {
            Ember.set(_this, 'isSaving', false);
            Ember.set(_this, 'saveSuccessful', false);
            return;
          }

          Ember.set(model.tenant, 'name', Ember.get(changeset, 'name'));
          Ember.set(model.tenant, 'mobileNumber', Ember.get(changeset, 'mobileNumber'));
          Ember.set(model.tenant, 'dateOfBirth', Ember.get(changeset, 'dateOfBirth')); // set university to default if not been changed (so it matches the ui)

          var university = Ember.get(_this, 'model.tenant.university');

          if (university === null) {
            Ember.set(model.tenant, 'university', Ember.get(_this, 'defaultUniversity'));
          }

          var studentIdData = Ember.get(model.tenant, 'studentId.data');

          if (Ember.isPresent(studentIdData)) {
            if (studentIdData.match(/(^data:)/ig)) {
              var base64 = Ember.get(model.tenant, 'studentId.data');
              Ember.set(model.tenant, 'studentId', {
                'document-id': (0, _emberCliUuid.uuid)(),
                data: base64
              });
            }
          }

          var photoIdData = Ember.get(model.tenant, 'photoId.data');

          if (Ember.isPresent(photoIdData)) {
            if (photoIdData.match(/(^data:)/ig)) {
              var _base = Ember.get(model.tenant, 'photoId.data');

              Ember.set(model.tenant, 'photoId', {
                'document-id': (0, _emberCliUuid.uuid)(),
                data: _base
              });
            }
          }

          Ember.RSVP.hash({
            paymentSchedule: Ember.get(model, 'paymentSchedule').save(),
            tenantDetails: Ember.get(model, 'tenant').save(),
            notes: Ember.get(model, 'notes').save()
          }).then(function () {
            errorHandler.handleSuccess();
            Ember.set(_this, 'isSaving', false);
            Ember.set(_this, 'saveSuccessful', true);
            Ember.run.later(function () {
              Ember.set(_this, 'saveSuccessful', false);
            }, 3000);
          }).catch(function (error) {
            errorHandler.handleError(error);
            Ember.set(_this, 'isSaving', false);
            Ember.set(_this, 'saveSuccessful', false);
          });
        });
      },
      switchTenancy: function switchTenancy(val) {
        this.transitionToRoute({
          queryParams: {
            tenancy: Ember.get(val, 'id')
          }
        });
      }
    }
  });

  _exports.default = _default;
});