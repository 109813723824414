define("propertycloud/pods/components/validation-image-drop/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZH5NTYz6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"validation-input\",null,[[\"propertyName\",\"label\",\"fieldId\",\"changeset\"],[[24,[\"propertyName\"]],[24,[\"label\"]],[24,[\"fieldId\"]],[24,[\"changeset\"]]]],{\"statements\":[[0,\"  \"],[1,[28,\"image-drop\",null,[[\"placeholder\",\"image\",\"file\",\"helpText\"],[[24,[\"placeholder\"]],[28,\"get\",[[24,[\"changeset\"]],[24,[\"propertyName\"]]],null],[24,[\"file\"]],[24,[\"helpText\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/validation-image-drop/template.hbs"
    }
  });

  _exports.default = _default;
});