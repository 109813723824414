define("propertycloud/pods/tenancy/invite/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pSmYn5zP",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Invite Other Tenants\"],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"container container--full-height invite-tenants\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"side-panel\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"logo\"],[8],[0,\"\\n      \"],[1,[28,\"svg-jar\",[\"logo\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"side-panel__icon\"],[8],[0,\"\\n      \"],[1,[28,\"svg-jar\",[\"box-icon\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content-panel user-form\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"logo logo--dark\"],[8],[0,\"\\n      \"],[7,\"img\",true],[11,\"src\",[24,[\"agentContext\",\"logoLocation\"]]],[11,\"alt\",[29,[[24,[\"agentContext\",\"tradingName\"]],\" Logo\"]]],[8],[9],[0,\"\\n\\n    \"],[9],[0,\"\\n\"],[4,\"tenant-invite-others\",null,[[\"user\",\"expectedTenants\",\"propertyId\"],[[24,[\"user\"]],[24,[\"model\",\"expectedNumberOfUpcomingTenants\"]],[24,[\"params\",\"property_id\"]]]],{\"statements\":[[0,\"      \"],[7,\"header\",true],[10,\"class\",\"user-form__head\"],[8],[0,\"\\n        \"],[7,\"h1\",true],[10,\"class\",\"page-title\"],[8],[0,\"\\n          Invite fellow tenants to join you.\\n        \"],[9],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"page-introtext\"],[8],[0,\"\\n          The people you invite will each be notified to begin their registration process.\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/tenancy/invite/template.hbs"
    }
  });

  _exports.default = _default;
});