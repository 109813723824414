define("propertycloud/pods/components/validation-input-mask/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+WDF+J6Y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"validation-input\",null,[[\"propertyName\",\"label\",\"fieldId\",\"changeset\"],[[24,[\"propertyName\"]],[24,[\"label\"]],[24,[\"fieldId\"]],[24,[\"changeset\"]]]],{\"statements\":[[0,\"  \"],[1,[28,\"one-way-input-mask\",null,[[\"mask\",\"type\",\"id\",\"class\",\"value\",\"update\"],[[24,[\"mask\"]],[24,[\"type\"]],[24,[\"fieldId\"]],\"form__control\",[28,\"get\",[[24,[\"changeset\"]],[24,[\"propertyName\"]]],null],[28,\"action\",[[23,0,[]],\"change\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/validation-input-mask/template.hbs"
    }
  });

  _exports.default = _default;
});