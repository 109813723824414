define("propertycloud/pods/components/maintenance/issue-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O9QO7Pk7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"dashboard-columns dashboard-columns--no-bg issue-panel\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"dashboard-column dashboard-column--two-thirds issue-panel__info\"],[8],[0,\"\\n    \"],[1,[28,\"maintenance/issue-panel/summary\",null,[[\"issue\",\"tenantView\"],[[24,[\"model\"]],[24,[\"tenantView\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"not\",[[24,[\"tenantView\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"dashboard-column dashboard-column--third issue-panel__update\"],[8],[0,\"\\n      \"],[1,[28,\"maintenance/issue-panel/update\",null,[[\"issue\"],[[24,[\"model\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/maintenance/issue-panel/template.hbs"
    }
  });

  _exports.default = _default;
});