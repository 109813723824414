define("propertycloud/pods/components/sms-interface/component", ["exports", "ember-cli-uuid", "lodash/findLast", "propertycloud/utilities/scrollTo"], function (_exports, _emberCliUuid, _findLast2, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO: Deprecate the run loop.
  var _default = Ember.Component.extend({
    sms: Ember.inject.service(),
    agentContext: Ember.inject.service(),
    tagName: 'section',
    classNames: ['sms-interface'],
    classNameBindings: ['isTenant'],
    showMessenger: true,
    newMessage: null,
    type: null,
    entityId: null,
    isTenant: Ember.computed.equal('type', 'tenant'),
    canSend: Ember.computed.notEmpty('newMessage'),
    init: function init() {
      this._super.apply(this, arguments);

      this.messages = this.messages || [];
    },
    actions: {
      sendNewMessage: function sendNewMessage() {
        var _this = this;

        if (!Ember.get(this, 'canSend')) {
          return;
        }

        var messages = Ember.get(this, 'messages');
        var newMessage = Ember.get(this, 'newMessage');
        var type = Ember.get(this, 'type');
        var id = Ember.get(this, 'entityId');
        var sms = Ember.get(this, 'sms');
        var msgId = (0, _emberCliUuid.uuid)();
        var msg = {
          id: msgId,
          message: newMessage,
          time: new Date(),
          isSending: true,
          hasFailed: false,
          isSuccessful: false,
          outgoing: true
        };
        messages.pushObject(msg);
        var smsTask = sms.sendMessage(type, id, newMessage);

        function setFailed() {
          if (Ember.get(this, 'isError') === true) {
            (0, _findLast2.default)(messages, function (msg) {
              if (msg.id === msgId) {
                Ember.set(msg, 'isSending', false);
                Ember.set(msg, 'hasFailed', true);
              }
            });
            smsTask.removeObserver('isError', smsTask, setSuccessful);
          }
        }

        function setSuccessful() {
          if (Ember.get(this, 'isSuccessful') === true) {
            (0, _findLast2.default)(messages, function (msg) {
              if (msg.id === msgId) {
                Ember.set(msg, 'isSending', false);
                Ember.set(msg, 'isSuccessful', true);
              }
            });
            smsTask.removeObserver('isSuccessful', smsTask, setSuccessful);
          }
        }

        smsTask.addObserver('isError', smsTask, setFailed);
        smsTask.addObserver('isSuccessful', smsTask, setSuccessful);
        Ember.set(this, 'newMessage', null);
        Ember.run.later(function () {
          var container = _this.element.querySelector('.sms-history');

          (0, _scrollTo.default)(container, container.scrollHeight - container.clientHeight, 300);
        }, 100);
      }
    }
  });

  _exports.default = _default;
});