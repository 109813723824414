define("propertycloud/pods/components/edit-certificates/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['edit-certificates'],
    certificates: null,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'state', {
        'epc': {
          'expiry-date': null,
          'document-id': null,
          'src': null,
          'notes': null
        },
        'electric': {
          'expiry-date': null,
          'document-id': null,
          'src': null,
          'notes': null
        },
        'fire': {
          'expiry-date': null,
          'document-id': null,
          'src': null,
          'notes': null
        },
        'gas': {
          'expiry-date': null,
          'document-id': null,
          'src': null,
          'notes': null
        },
        'hmo-electric': {
          'expiry-date': null,
          'document-id': null,
          'src': null,
          'notes': null
        },
        'pat': {
          'expiry-date': null,
          'document-id': null,
          'src': null,
          'notes': null
        },
        'hmo-licence': {
          'expiry-date': null,
          'document-id': null,
          'src': null,
          'notes': null
        },
        'selective-licence': {
          'expiry-date': null,
          'document-id': null,
          'src': null,
          'notes': null
        },
        'tv-licence': {
          'expiry-date': null,
          'document-id': null,
          'src': null,
          'notes': null
        }
      });
    },
    actions: {
      updateReport: function updateReport(type, hash) {
        this.markChange();

        if (typeof type === 'undefined') {
          throw 'You must define a report type to update';
        }

        var model = this.certificates;
        var state = this.state;
        var certificate = Ember.get(state, type);

        if (Ember.isPresent(hash['expiry-date'])) {
          Ember.set(certificate, 'expiry-date', hash['expiry-date']);
        }

        if (Ember.isPresent(hash['document-id'])) {
          Ember.set(certificate, 'document-id', hash['document-id']);
        }

        if (Ember.isPresent(hash.src)) {
          Ember.set(certificate, 'src', hash.src);
        }

        Ember.set(model, type, state[type]);
      }
    }
  });

  _exports.default = _default;
});