define("propertycloud/pods/components/overdue-rent-graph/component", ["exports", "propertycloud/chart-themes/flatdark", "papaparse", "moment", "propertycloud/helpers/format-currency"], function (_exports, _flatdark, _papaparse, _moment, _formatCurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var chartData = [{
    name: 'Income',
    data: [],
    fillColor: {
      linearGradient: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 1
      },
      stops: [[0, 'rgba(25, 178, 149, 1)'], [1, 'rgba(25, 178, 149, 0)']]
    }
  }];
  var chartOptions = {
    chart: {
      type: 'area',
      zoomType: 'x'
    },
    title: {
      text: 'Overdue Rent YTD'
    },
    xAxis: {
      type: 'datetime'
    },
    yAxis: {
      labels: {
        formatter: function formatter() {
          this.value = this.value / 100;
          return "\xA3".concat(this.axis.defaultLabelFormatter.call(this));
        }
      }
    },
    tooltip: {
      formatter: function formatter() {
        return "<b>".concat((0, _moment.default)(this.x, 'x').format('Do MMMM, YYYY'), "</b>: ").concat((0, _formatCurrency.formatCurrency)([this.y, {
          noResize: true
        }]));
      }
    },
    plotOptions: {
      series: {
        lineWidth: 2,
        borderWidth: 0,
        marker: {
          fillColor: '#fff',
          radius: 3,
          symbol: 'circle'
        }
      }
    }
  };

  var _default = Ember.Component.extend({
    agentContext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'dateFrom', (0, _moment.default)().startOf('year').format('YYYY-MM-DD'));
      Ember.set(this, 'dateTo', (0, _moment.default)().format('YYYY-MM-DD'));
      Ember.set(this, 'grouped', 'daily');
      this.fetchRent();
    },
    totalOverdue: null,
    overdueAmount: null,
    theme: _flatdark.default,
    chartData: chartData,
    chartOptions: chartOptions,
    fetchRent: function fetchRent() {
      var _this = this;

      var ajax = Ember.get(this, 'ajax');
      var agentId = Ember.get(this, 'agentContext.id');
      var grouped = Ember.get(this, 'grouped');
      var dateFrom = Ember.get(this, 'dateFrom');
      var dateTo = Ember.get(this, 'dateTo');
      var request = ajax.request('/estate/finance.overdue-rent', {
        dataType: 'text',
        data: {
          agentId: agentId,
          grouped: grouped,
          from: dateFrom,
          to: dateTo
        }
      });
      request.then(function (csv) {
        var overdueAmountBuffer = [];
        var totalOverdue = 0;

        var json = _papaparse.default.parse(csv, {
          dynamicTyping: true,
          header: true,
          skipEmptyLines: true
        });

        json.data.forEach(function (element) {
          overdueAmountBuffer.push([Number((0, _moment.default)(element.Date, 'YYYY-MM-DD').format('x')), element.OverdueAmount]);
          totalOverdue += element.OverdueAmount;
        }, _this);
        Ember.set(_this, 'totalOverdue', totalOverdue);
        Ember.set(_this, 'overdueAmount', overdueAmountBuffer);
        Ember.set(_this, 'chartData', [{
          name: 'Overdue Rent',
          data: Ember.get(_this, 'overdueAmount'),
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [[0, 'rgba(25, 178, 149, 1)'], [1, 'rgba(25, 178, 149, 0)']]
          }
        }]);
      });
    }
  });

  _exports.default = _default;
});