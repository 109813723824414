define("propertycloud/pods/components/save-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['btn-save-data'],
    classNameBindings: ['isSaving', 'saveSuccessful'],
    click: function click() {
      this.save();
    }
  });

  _exports.default = _default;
});