define("propertycloud/pods/components/overdue-rent-table/name/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    user: Ember.inject.service()
  });

  _exports.default = _default;
});