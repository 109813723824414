define("propertycloud/pods/logged-in/manage/property/marketing/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    propertyType: Ember.computed.alias('model.features.propertyType'),
    numberOfBedrooms: Ember.computed.alias('model.features.numberOfBedrooms'),
    hasNecessaryFeatures: Ember.computed.and('propertyType', 'numberOfBedrooms')
  });

  _exports.default = _default;
});