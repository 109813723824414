define("propertycloud/pods/components/validation-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    type: 'text',
    wrap: false,
    showValidations: false,
    isTextarea: Ember.computed('type', function () {
      return this.type === 'textarea';
    }),
    formWrapper: Ember.computed('wrap', function () {
      if (this.wrap.length > 0) {
        return "form__control-wrap form__control-wrap--".concat(this.wrap);
      } else if (this.wrap) {
        return 'form__control-wrap';
      } else {
        return false;
      }
    }),
    actions: {
      onFocusAction: function onFocusAction() {
        var onFocusAction = Ember.get(this, 'onfocus');

        if (onFocusAction) {
          onFocusAction();
        }
      }
    }
  });

  _exports.default = _default;
});