define("propertycloud/adapters/tenancy", ["exports", "propertycloud/adapters/application", "ember-data-url-templates"], function (_exports, _application, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberDataUrlTemplates.default, {
    // urlTemplate: '{+host}/{+namespace}/estate/property.view-tenancy{?id,tenancyNumber}',
    queryUrlTemplate: '{+host}/{+namespace}/estate/view-tenancies{?query*}',
    queryRecordUrlTemplate: '{+host}/{+namespace}/estate/property.view-tenancy{?query*}'
  });

  _exports.default = _default;
});