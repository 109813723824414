define("propertycloud/pods/logged-in/manage/property/gallery/controller", ["exports", "propertycloud/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    uploader: Ember.inject.service(),
    images: null,
    uploadUrl: "".concat(_environment.default.APP.apiConfig.host, "/").concat(_environment.default.APP.apiConfig.namespace, "/documents/document.upload"),
    actions: {
      newImage: function newImage(file) {
        var _this = this;

        var pojo = null;
        var image = this.store.createRecord('property-image', {
          gallery: Ember.get(this, 'model'),
          width: null,
          height: null,
          src: null,
          filename: null
        });
        Ember.set(image, 'filename', Ember.get(file, 'name'));
        var headers = {};

        var _this$get = this.get('session.data.authenticated'),
            access_token = _this$get.access_token;

        if (Ember.isPresent(access_token)) {
          headers.Authorization = "Bearer ".concat(access_token);
        }

        file.read().then(function (dataUrl) {
          var placeholder = new Image();
          placeholder.src = dataUrl;

          placeholder.onload = function () {
            Ember.set(image, 'src', "http://placehold.it/".concat(placeholder.width, "x").concat(placeholder.height));
            Ember.set(image, 'width', placeholder.width);
            Ember.set(image, 'height', placeholder.height);
          };

          file.upload("".concat(Ember.get(_this, 'uploadUrl'), "?documentId=").concat(Ember.get(image, 'id')), {
            headers: headers
          }).then(function (response) {
            Ember.set(image, 'src', response.body.url); // photo-swipe requires a vanilla object rather than a DS record

            pojo = {
              w: Ember.get(image, 'width'),
              h: Ember.get(image, 'height'),
              src: Ember.get(image, 'src'),
              record: image
            };
            Ember.get(_this, 'images').pushObject(pojo);
            return image.save();
          }, function () {
            Ember.get(_this, 'images').removeObject(pojo);
            image.destroyRecord();
            pojo = null;
          });
        });
      },
      deleteImage: function deleteImage(image) {
        Ember.get(this, 'images').removeObject(image);
        image.record.destroyRecord();
      }
    }
  });

  _exports.default = _default;
});