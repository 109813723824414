define("propertycloud/pods/components/form-register/component", ["exports", "ember-ajax/errors"], function (_exports, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    isRegistering: false,
    hasError: false,
    password: null,
    email: null,
    token: null,
    passwordBlank: false,
    canSubmit: false,
    identification: Ember.computed.alias('email'),
    errorMessage: 'Someting has gone wrong, please try again.',
    actions: {
      passwordUpdate: function passwordUpdate() {
        if (/^ *$/.test(Ember.get(this, 'password'))) {
          Ember.set(this, 'passwordBlank', true);
          Ember.set(this, 'canSubmit', false);
        } else {
          Ember.set(this, 'passwordBlank', false);
          Ember.set(this, 'canSubmit', true);
        }
      },
      register: function register() {
        var _this = this;

        if (!Ember.get(this, 'passwordBlank')) {
          var ajax = Ember.get(this, 'ajax');
          var email = Ember.get(this, 'email');
          var token = Ember.get(this, 'token');
          var password = Ember.get(this, 'password');
          Ember.set(this, 'isRegistering', true);
          Ember.set(this, 'showLoginAndResetOptions', false);
          var request = ajax.post('/account/user.verify', {
            data: JSON.stringify({
              'payload': {
                password: password
              }
            }),
            headers: {
              'Authorization': "Basic ".concat(btoa("".concat(email, ":").concat(token)))
            }
          });
          request.then(function () {
            Ember.get(_this, 'session').authenticate('authenticator:custom', {
              '_username': _this.identification,
              '_password': _this.password
            });
          });
          request.catch(function (error) {
            Ember.set(_this, 'isRegistering', false);
            Ember.set(_this, 'hasError', true);

            if ((0, _errors.isUnauthorizedError)(error)) {
              if (error.payload.description === 'Invalid verification token') {
                Ember.set(_this, 'showLoginAndResetOptions', true);
                Ember.set(_this, 'errorMessage', "It looks like your account (".concat(email, ") already has a password set! Please login or reset your password"));
              } else {
                Ember.set(_this, 'errorMessage', "It doesn't look like you're supposed to be trying to register! Were you invited by your estate agent?");
              }
            }
          });
        }
      },
      reset: function reset() {
        this.setProperties({
          isRegistering: false,
          hasError: false,
          passwordBlank: false,
          password: null,
          canSubmit: false
        });
      }
    }
  });

  _exports.default = _default;
});