define("propertycloud/pods/logged-in/manage/property/inspections/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var parentModel = this.modelFor('logged-in/manage/property');
      var propertyId = Ember.get(parentModel, 'id');
      Ember.set(this, 'propertyId', propertyId);
      return this.store.query('inspection', {
        propertyId: propertyId,
        agentId: Ember.get(this, 'agentContext.id')
      });
    },
    setupController: function setupController(controller, model) {
      Ember.set(controller, 'propertyId', this.propertyId);
      Ember.set(controller, 'inspections', model);
    }
  });

  _exports.default = _default;
});