define("propertycloud/pods/feedback/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      Ember.set(this, 'params', params);
    },
    setupController: function setupController(controller) {
      var params = Ember.get(this, 'params');
      Ember.set(controller, 'params', params);
    }
  });

  _exports.default = _default;
});