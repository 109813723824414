define("propertycloud/pods/components/add-circle/component", ["exports", "propertycloud/mixins/outside-click"], function (_exports, _outsideClick) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_outsideClick.default, {
    classNames: ['add-area'],
    classNameBindings: ['isClosed'],
    isClosed: true,
    toggleWorkspace: function toggleWorkspace() {},
    click: function click() {
      this.toggleProperty('isClosed');
    },
    onOutsideClick: function onOutsideClick() {
      Ember.set(this, 'isClosed', true);
    },
    actions: {
      add: function add(context) {
        var toggleWorkspace = Ember.get(this, 'toggleWorkspace');
        toggleWorkspace(context);
      }
    }
  });

  _exports.default = _default;
});