define("propertycloud/pods/guarantee-tenant/controller", ["exports", "propertycloud/utilities/date", "propertycloud/utilities/contract"], function (_exports, _date, _contract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Controller.extend({
    queryParams: ['propertyId', 'tenantId', 'guarantorEmail', 'token', 'tenancyNumber'],
    propertyId: null,
    tenantId: null,
    guarantorEmail: null,
    token: null,
    tenantContractIsShowing: false,
    upcomingTenants: Ember.computed.filter('model.agreement.tenancy.agreements.content.meta.tenants', function (tenant) {
      if (Ember.get(tenant, 'agreement-type') === 'upcoming' && Ember.get(tenant, 'voided') === false && Ember.get(tenant, 'approved') === false) {
        return tenant;
      }
    }),
    otherTenants: Ember.computed.filter('upcomingTenants', function (tenant) {
      var tenantName = Ember.get(this, 'model.agreement.state.metadata.stages.accept-tenancy.name');

      if (Ember.get(tenant, 'name') !== tenantName) {
        return tenant;
      }
    }),
    contractTenantComponent: Ember.computed('agentContext.id', function () {
      var agentId = Ember.get(this, 'agentContext.id');
      var otherContractAgents = ['a1df372c-5c8d-43e1-b573-a5c7b302e930', 'c8480b7d-4940-4269-a029-29cea3da61bd', '2cf60175-9030-4e02-a955-cb7a3d9eef93'];

      if (otherContractAgents.includes(agentId)) {
        return "register-tenant-contract-".concat(agentId);
      } else {
        return 'register-tenant-contract';
      }
    }),
    individualMonthlyCost: Ember.computed('upcomingTenants', function () {
      var tenants = Ember.get(this, 'upcomingTenants');
      var total = 0;
      var id = Ember.get(this, 'model.agreement.id'); // REGEX splits the ID into property ID, tenancy number and tenant id and builds an array with them

      var reUUID = '([A-Z0-9]{8}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{12})'; // SQL GUID 1

      var reg = new RegExp("".concat(reUUID, ".*?(\\d+).*?").concat(reUUID), ['i']);
      var m = reg.exec(id);

      var _m = _slicedToArray(m, 4),
          tenantId = _m[3];

      tenants.forEach(function (tenant) {
        if (Ember.get(tenant, 'id') === tenantId) {
          total += Number(Ember.get(tenant, 'monthly-cost'));
        }
      });
      return total;
    }),
    aggregateMonthlyCost: Ember.computed('upcomingTenants', function () {
      var tenants = Ember.get(this, 'upcomingTenants');
      var total = 0;
      tenants.forEach(function (tenant) {
        total += Number(Ember.get(tenant, 'monthly-cost'));
      });
      return total;
    }),
    invitationToExistingTenancy: Ember.computed.notEmpty('model.agreement.state.metadata.stages.invitation.payment-schedule'),
    aggregateRoomCost: Ember.computed('rooms', function () {
      var total = 0;
      var rooms = Ember.get(this, 'rooms');

      for (var room in rooms) {
        total += Number(rooms[room].amount);
      }

      return total;
    }),
    rooms: Ember.computed('model.agreement.tenancy.{initialRoomBreakdown,agreements.content}', function () {
      var rooms = Ember.get(this, 'model.agreement.tenancy.initialRoomBreakdown');
      var tenants = Ember.get(this, 'model.agreement.tenancy.agreements.content.meta.tenants');
      if (!tenants) return;
      tenants.forEach(function (tenant) {
        var room = Ember.get(tenant, 'room-taken');

        if (Ember.isPresent(room)) {
          rooms[room].taken = true;
        }
      });
      return rooms;
    }),
    totalCost: Ember.computed('model.agreement', function () {
      var monthlyCost = Ember.get(this, 'model.agreement.monthlyCost');
      var startDate = Ember.get(this, 'model.agreement.start');
      var endDate = Ember.get(this, 'model.agreement.end');
      var days = (0, _date.daysBetween)(startDate, endDate);
      return (0, _contract.contractCost)(days, monthlyCost);
    }).readOnly(),
    actions: {
      showTenantContract: function showTenantContract() {
        this.toggleProperty('tenantContractIsShowing');
      }
    }
  });

  _exports.default = _default;
});