define("propertycloud/pods/components/ember-initials/component", ["exports", "ember-initials/components/initials"], function (_exports, _initials) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _initials.default.extend({});

  _exports.default = _default;
});