define("propertycloud/pods/components/no-tenancies/component", ["exports", "propertycloud/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    userIsGmail: Ember.computed('user.email', function () {
      return Ember.get(this, 'user.email').includes('gmail') || Ember.get(this, 'user.email').includes('googlemail');
    }),
    alternateEmail: Ember.computed('userIsGmail', function () {
      if (this.userIsGmail && Ember.get(this, 'user.email').includes('gmail')) {
        return Ember.get(this, 'user.email').replace('gmail', 'googlemail');
      } else {
        return Ember.get(this, 'user.email').replace('googlemail', 'gmail');
      }
    }),
    currentEmail: Ember.computed.alias('user.email'),
    logoutLink: Ember.computed(function () {
      var redirectURI = "".concat(window.location.origin, "/logout");
      return "".concat(_environment.default.authenticationUrl, "/authorize/logout?redirect=").concat(redirectURI);
    }).readOnly(),
    actions: {
      logout: function logout() {
        window.location.replace(this.logoutLink);
      }
    }
  });

  _exports.default = _default;
});