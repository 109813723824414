define("propertycloud/pods/components/ie11-banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hxftmZJ3",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showMessage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ie11-notification\"],[8],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"\\n      Your browser is not supported and some parts of this site may not function as intended. We recommend using Chrome for the best possible experience.\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/ie11-banner/template.hbs"
    }
  });

  _exports.default = _default;
});