define("propertycloud/pods/logged-in/landlord/my-details/controller", ["exports", "ember-cli-uuid", "propertycloud/validations/edit-landlord"], function (_exports, _emberCliUuid, _editLandlord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    errorHandler: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    photoId: Ember.computed('model.landlord.photoId', function () {
      return Ember.get(this, 'model.landlord.photoId');
    }),
    photoIdFile: null,
    ValidationFields: _editLandlord.default,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'messages', []);
      Ember.set(this, 'overviewPeriod', 'months');
      Ember.set(this, 'incomePaid', 0);
      Ember.set(this, 'maintenanceSpent', 0);
      Ember.set(this, 'overdueRent', 0); // Create a changeset object to map this model properties to. This is a workaround to solve an issue with ember-changeset@1.4.1 nested validation properties. SM

      this.modelMap = this.modelMap || {};
    },
    // Map model properties to changeset object
    setModelMap: function setModelMap(model) {
      var landlord = model.landlord;
      var mapObj = Ember.get(this, 'modelMap');
      Ember.set(mapObj, 'name', Ember.get(landlord, 'primaryContact.name'));
      Ember.set(mapObj, 'mobileNumber', Ember.get(landlord, 'primaryContact.mobile-number'));
      Ember.set(mapObj, 'emailAddress', Ember.get(landlord, 'primaryContact.email-address'));
      Ember.set(mapObj, 'photoId', Ember.get(landlord, 'photoId.data'));
      Ember.set(mapObj, 'secondaryContacts', Ember.get(landlord, 'secondaryContacts'));
      Ember.set(mapObj, 'flat', Ember.get(landlord, 'address.flat'));
      Ember.set(mapObj, 'building', Ember.get(landlord, 'address.building'));
      Ember.set(mapObj, 'street', Ember.get(landlord, 'address.street'));
      Ember.set(mapObj, 'area', Ember.get(landlord, 'address.area'));
      Ember.set(mapObj, 'town', Ember.get(landlord, 'address.town'));
      Ember.set(mapObj, 'county', Ember.get(landlord, 'address.county'));
      Ember.set(mapObj, 'postcode', Ember.get(landlord, 'address.postcode'));
      Ember.set(mapObj, 'accountName', Ember.get(landlord, 'bankAccount.account-name'));
      Ember.set(mapObj, 'accountNumber', Ember.get(landlord, 'bankAccount.account-number'));
      Ember.set(mapObj, 'accountSortCode', Ember.get(landlord, 'bankAccount.sort-code'));
    },
    actions: {
      saveData: function saveData(changeset) {
        var _this = this;

        Ember.set(this, 'isSaving', true);
        changeset.validate().then(function () {
          var model = Ember.get(_this, 'model');
          var errorHandler = Ember.get(_this, 'errorHandler');

          if (Ember.get(changeset, 'isInvalid')) {
            Ember.set(_this, 'isSaving', false);
            errorHandler.handleError('Oops, looks like there\'s a problem with the form. Please check and try again.');
            return;
          } // Update model from changeset


          Ember.set(model.landlord, 'primaryContact.name', Ember.get(changeset, 'name'));
          Ember.set(model.landlord, 'primaryContact.mobile-number', Ember.get(changeset, 'mobileNumber'));
          Ember.set(model.landlord, 'primaryContact.email-address', Ember.get(changeset, 'emailAddress'));
          Ember.set(model.landlord, 'photoId.data', Ember.get(changeset, 'photoId'));
          Ember.set(model.landlord, 'secondaryContacts', Ember.get(changeset, 'secondaryContacts'));
          Ember.set(model.landlord, 'address.flat', Ember.get(changeset, 'flat'));
          Ember.set(model.landlord, 'address.building', Ember.get(changeset, 'building'));
          Ember.set(model.landlord, 'address.street', Ember.get(changeset, 'street'));
          Ember.set(model.landlord, 'address.area', Ember.get(changeset, 'area'));
          Ember.set(model.landlord, 'address.town', Ember.get(changeset, 'town'));
          Ember.set(model.landlord, 'address.county', Ember.get(changeset, 'county'));
          Ember.set(model.landlord, 'address.postcode', Ember.get(changeset, 'postcode'));
          Ember.set(model.landlord, 'bankAccount.account-name', Ember.get(changeset, 'accountName'));
          Ember.set(model.landlord, 'bankAccount.account-number', Ember.get(changeset, 'accountNumber'));
          Ember.set(model.landlord, 'bankAccount.sort-code', Ember.get(changeset, 'accountSortCode')); // set uuid for photo id

          if (Ember.get(model.landlord, 'photoId.data') !== null && Ember.get(model.landlord, 'photoId.data').match(/(^data:)/ig)) {
            var base64 = Ember.get(model.landlord, 'photoId.data');
            Ember.set(model.landlord, 'photoId', {
              'document-id': (0, _emberCliUuid.uuid)(),
              data: base64
            });
          }

          Ember.get(_this, 'model.landlord').save({
            adapterOptions: {
              agentId: null
            }
          }).then(function () {
            Ember.set(_this, 'isSaving', false);
            Ember.set(_this, 'saveSuccessful', true);
            Ember.run.later(function () {
              Ember.set(_this, 'saveSuccessful', false);
            }, 3000);
            errorHandler.handleSuccess();
          }).catch(function (error) {
            Ember.set(_this, 'isSaving', false);
            Ember.set(_this, 'saveSuccessful', false);
            errorHandler.handleError(error);
          });
        });
      }
    }
  });

  _exports.default = _default;
});