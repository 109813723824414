define("propertycloud/adapters/agent-contractor-agreement", ["exports", "propertycloud/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    query: function query(store, type, _query) {
      var url = "".concat(this.host, "/").concat(this.namespace, "/aas/agent.list-contractors");
      var data = _query;
      return this.ajax(url, 'GET', {
        data: data
      });
    } //   findRecord(store, type, id) {
    //     let url = `${this.get('host')}/${this.get('namespace')}/contractor-agreement`;
    //     let data = {
    //       issueId: id
    //     };
    //     return this.ajax(url, 'GET', { data });
    //   }

  });

  _exports.default = _default;
});