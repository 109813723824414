define("propertycloud/pods/components/viewing/viewing-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z970/GSD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"viewing-card__time\"],[8],[1,[22,\"viewingTime\"],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"viewing-card__name\"],[8],[1,[24,[\"viewing\",\"contactName\"]],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"not\",[[24,[\"hideStatus\"]]],null]],null,{\"statements\":[[4,\"if\",[[24,[\"viewing\",\"cancelledAt\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"viewing-card__status viewing-card__status--cancelled\"],[8],[0,\"Cancelled\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"viewing\",\"assigned\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"viewing-card__status viewing-card__status--confirmed\"],[8],[0,\"Confirmed\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"viewing-card__status\"],[8],[0,\"Unassigned\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/viewing/viewing-card/template.hbs"
    }
  });

  _exports.default = _default;
});