define("propertycloud/pods/components/property-room-breakdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7y2NEjOY",
    "block": "{\"symbols\":[\"item\",\"index\"],\"statements\":[[7,\"form\",true],[10,\"class\",\"form\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"rentMapping\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"property-room-breakdown/entry\",null,[[\"item\",\"index\",\"benchmarkRoom\",\"markAsBenchmark\",\"removeEntry\",\"markChange\"],[[23,1,[]],[23,2,[]],[24,[\"benchmarkRoom\"]],[28,\"action\",[[23,0,[]],\"markAsBenchmark\"],null],[28,\"action\",[[23,0,[]],\"removeEntry\"],null],[24,[\"setChangedRooms\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[7,\"button\",false],[12,\"class\",\"btn\"],[3,\"action\",[[23,0,[]],\"addEntry\"]],[8],[0,\"Add Room\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[10,\"class\",\"form__error\"],[8],[1,[22,\"error\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/property-room-breakdown/template.hbs"
    }
  });

  _exports.default = _default;
});