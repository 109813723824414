define("propertycloud/pods/components/error-messages/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PwRqhEge",
    "block": "{\"symbols\":[\"error\",\"&default\"],\"statements\":[[14,2],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"errorHandler\",\"error\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"errors\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"errorHandler\",\"error\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[10,\"class\",\"errors__message\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[7,\"button\",false],[12,\"class\",\"errors__close\"],[3,\"action\",[[23,0,[]],\"closeError\"]],[8],[0,\"Hide\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/error-messages/template.hbs"
    }
  });

  _exports.default = _default;
});