define("propertycloud/pods/components/overdue-rent/row/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var methods = [{
    name: 'Bank Transfer',
    option: 'bank-transfer'
  }, {
    name: 'Cheque',
    option: 'cheque'
  }];

  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNameBindings: ['isHighlighted', 'isSelected'],
    methods: methods,
    method: methods[0],
    chequeSelected: false,
    selectCheque: null,
    poundsPaid: null,
    isHighlighted: false,
    isSelected: false,
    isDisabled: true,
    //eslint-disable-next-line
    selectOrUnselectRow: Ember.observer('isSelected', function () {
      if (Ember.get(this, 'isSelected')) {
        // run function passed into component as "select"
        Ember.get(this, 'select')(Ember.get(this, 'tenant'));
        Ember.set(this, 'isDisabled', false);
      } else {
        // run function passed into component as "unselect"
        Ember.get(this, 'unselect')(Ember.get(this, 'tenant'));
        Ember.set(this, 'isDisabled', true);
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var tenant = Ember.get(this, 'tenant');
      var dueDate = (0, _moment.default)(Ember.get(this, 'tenant.dateDue'));
      var today = (0, _moment.default)();
      var diff = dueDate.diff(today, 'days');

      if (diff <= -10) {
        Ember.set(this, 'isHighlighted', true);
      }

      Ember.set(tenant, 'paidAmount', Ember.get(tenant, 'amountDue'));
      Ember.set(tenant, 'paidDate', Ember.get(this, 'defaultDate'));
      Ember.set(tenant, 'paidMethod', methods[0].option);
      Ember.set(tenant, 'chequeNumber', null);
      Ember.set(this, 'poundsPaid', Ember.get(tenant, 'amountDue') / 100);
    },
    actions: {
      setPaidAmount: function setPaidAmount() {
        Ember.set(Ember.get(this, 'tenant'), 'paidAmount', Ember.get(this, 'poundsPaid') * 100);
      },
      setCheque: function setCheque(selected) {
        Ember.set(this, 'method', selected);
        Ember.set(Ember.get(this, 'tenant'), 'paidMethod', selected.option);

        if (selected.option === 'cheque') {
          Ember.get(this, 'selectCheque')(true);
        } else {
          Ember.get(this, 'selectCheque')(false);
        }
      }
    }
  });

  _exports.default = _default;
});