define("propertycloud/pods/components/viewing/viewings-widget/component", ["exports", "ember-group-by"], function (_exports, _emberGroupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   *  TODO: This widget needs to be paginated, this is on the dashboard.
   *  This should also be changed to start date instead of dateNative and date since it is 'upcoming viewings'.
   */
  var _default = Ember.Component.extend({
    classNames: ['viewings-widget'],
    order: 'asc',
    sortBy: 'dateNative',
    viewingSorting: Ember.computed('order', function () {
      var order = this.order;
      var sortBy = this.sortBy;
      return ["".concat(sortBy, ":").concat(order)];
    }),
    viewingsSorted: Ember.computed.sort('viewings', 'viewingSorting'),
    viewingsGrouped: (0, _emberGroupBy.default)('viewingsSorted', 'date')
  });

  _exports.default = _default;
});