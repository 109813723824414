define("propertycloud/helpers/simple-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.simpleFormat = simpleFormat;
  _exports.default = void 0;
  var Handlebars = Ember.Handlebars;
  var AUTO_EMAIL_RE = /([\w.!#$%+-]+@[\w-]+(?:\.[\w-]+)+)/;
  var AUTO_LINK_CRE = [/<[^>]+$/, /^[^>]*>/];
  var AUTO_LINK_RE = /(?:((?:ed2k|ftp|http|https|irc|mailto|news|gopher|nntp|telnet|webcal|xmpp|callto|feed|svn|urn|aim|rsync|tag|ssh|sftp|rtsp|afs|file):)\/\/|(www\.))([^\s<]+)/;

  var autoLink = function autoLink(text, context) {
    var buffer = [];

    if (context.autoLink) {
      if (context.autoLink || context.autoLink === 'all') {
        var partialHTML = autoLinkUrls(text, context);

        for (var i = 0, len = partialHTML.length; i < len; i++) {
          if (Ember.String.isHTMLSafe(partialHTML[i])) {
            buffer.push(partialHTML[i]);
          } else {
            buffer = buffer.concat(autoLinkEmailAddresses(partialHTML[i]));
          }
        }
      } else if (context.autoLink == 'urls') {
        buffer = autoLinkUrls(text, context);
      } else if (context.autoLink == 'emailAddresses') {
        buffer = autoLinkEmailAddresses(text);
      } else {
        buffer.push(text);
      }
    } else {
      buffer.push(text);
    }

    return buffer;
  };

  var autoLinkUrls = function autoLinkUrls(text, options) {
    if (Ember.String.isHTMLSafe(text)) {
      text = text.string;
    }

    var parts = text.split(AUTO_LINK_RE);
    var part, index;
    var html = [];

    while ((index = text.search(AUTO_LINK_RE)) !== -1) {
      part = parts.shift();
      text = text.slice(index);
      html.push(part);
      var schema = parts.shift();
      var www = parts.shift();
      var href = parts.shift();
      href = (www || '') + href; // Remove the parts we're changing by hand

      text = text.slice((schema && schema.length + 2 || 0) + (href && href.length || 0));

      if (!isAutoLinked(part, parts[0])) {
        var url = void 0;

        if (schema) {
          url = href = "".concat(schema, "//").concat(href);
        } else {
          url = href;
          href = "http://".concat(href);
        }

        var template = "<a href=\"".concat(href, "\"");

        if (options.target) {
          template += " target=\"".concat(options.target, "\"");
        }

        template += ">".concat(url, "</a>");
        html.push(Ember.String.htmlSafe(template));
      }
    }

    html = html.concat(parts);
    return html;
  };

  var autoLinkEmailAddresses = function autoLinkEmailAddresses(text) {
    if (Ember.String.isHTMLSafe(text)) {
      text = text.string;
    }

    var part, index;
    var parts = text.split(AUTO_EMAIL_RE);
    var html = [];

    while ((index = text.search(AUTO_EMAIL_RE)) !== -1) {
      part = parts.shift();
      text = text.slice(index);
      html.push(part);
      var address = parts.shift(); // Remove the parts we're changing by hand

      text = text.slice(address.length);

      if (!isAutoLinked(part, parts[0])) {
        html.push(Ember.String.htmlSafe("<a href=\"mailto:".concat(address, "\">").concat(address, "</a>")));
      }
    }

    html = html.concat(parts);
    return html;
  };

  var isAutoLinked = function isAutoLinked(left, right) {
    return AUTO_LINK_CRE[0].test(left) && AUTO_LINK_CRE[2].test(right);
  };

  var splitParagraphs = function splitParagraphs(text) {
    return text.split(/\n\n+/).map(function (text) {
      return text.replace(/([^\n]\n)(?=[^\n])/g, '$1<br>') || text;
    });
  };
  /**
    The `{{simple-format}}` helper renders text into
    simplified HTML.
    ```handlebars
    {{simple-format "Hello, Monsieur!\nToday is your birthday!"}}
    ```
    ```html
    Hello, Monsieur!<br>Today is your birthday!
    ```
    This helper takes a variety of parameters to customize
    the block further.
    The `autoLink` attribute will automatically markup links found
    in the text. The options for this are `"all"`, `"urls"`,
    `"emailAddresses"`. If `autoLink` is set to `true`, it behaves
    as if it was set to `"all"`.
    ```handlebars
    {{simple-format "http://www.emberjs.com is an awesome framework!" autoLink="urls"}}
    ```
    ```html
    <a href="http://www.emberjs.com">http://www.emberjs.com</a> is an awesome framework!
    ```
    If using `autoLink`, you may provide a `target` attribute
    to indicate where the link should open.
    ```handlebars
    {{simple-format "You should really check out http://www.emberjs.com" autoLink="urls" target="__blank"}}
    ```
    ```html
    You should really check out <a href="http://www.emberjs.com" target="__blank">http://www.emberjs.com</a>
    ```
    If the HTML provided shouldn't be stripped of HTML, simply use {{{simple-format}}} to
    identify the string as safe.
    @public
    @method simple-format
    @for Handlebars.helpers
    @param text  {String} The text to format
    @return {String} HTML string
   */


  function simpleFormat(params, hash) {
    var text = params[0] || '';
    var buffer = autoLink(text, hash);

    for (var i = 0, len = buffer.length; i < len; i++) {
      buffer[i] = Handlebars.Utils.escapeExpression(buffer[i]);
    }

    text = buffer.join('');
    var paragraphs = splitParagraphs(text);
    var wrapperTag = hash['wrapper-tag'];

    if (wrapperTag === undefined) {
      wrapperTag = 'p';
    }

    if (wrapperTag) {
      paragraphs = paragraphs.map(function (paragraph) {
        return "<".concat(wrapperTag, ">").concat(paragraph, "</").concat(wrapperTag, ">");
      });
    }

    text = paragraphs.join('\n\n');
    return Ember.String.htmlSafe(text);
  }

  var _default = Ember.Helper.helper(simpleFormat);

  _exports.default = _default;
});