define("propertycloud/pods/logged-in/manage/property/inspections/controller", ["exports", "ember-cli-uuid"], function (_exports, _emberCliUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    inspections: null,
    actions: {
      newInspection: function newInspection() {
        var propertyId = this.propertyId;
        this.transitionToRoute('logged-in.manage.property.inspections.edit', propertyId, (0, _emberCliUuid.uuid)());
      }
    }
  });

  _exports.default = _default;
});