define("propertycloud/errorMessages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    'general': 'There was an error completing this task. Please try again.',
    'cannot-plan-tenancy-without-dates': 'There is no contract template for this property. Please add one and try again.',
    'contractor-already-invited-to-agent-account': 'This contractor has already been invited to this agency',
    payments: {
      'insufficient-funds': 'You do not have the funds available to cover this payment.',
      'fraudulent': 'Your bank has declined this transaction. Please speak to your bank to resolve this problem.',
      'declined': 'Your bank has declined this transaction. Please speak to your bank to resolve this problem.',
      'unknown': 'We were unable to take payment. Please refresh and try again.'
    },
    'already-cancelled': 'This viewing has already been cancelled.',
    'invalid-date': 'Slots are only available from 24 hour in advance.',
    'invalid-phone-number': 'The phone number provided is invalid.',
    'mobile-number-required': 'Please provide a mobile number.',
    'invalid-date-range': '`to` cannot be before `from`.',
    'date-range-too-large': 'Date range must be less than 1 year.',
    'file-upload-error': 'There was an error uploading the file.',
    'unsupported-media-type': 'Expected `Content-type` header of either `application/json` or `multipart/form-data`.',
    'can-only-preview-statement-pdfs': 'Ask for ?preview=1 or view the already generated document',
    'incorrect-parameters': 'Your request contains incorrect parameters.',
    'missing-parameter': 'You are missing required parameters in your request.',
    'missing-query-parameter': 'You are missing required parameters in your request.',
    'missing-filter': 'There are missing filters from your request.',
    'invitation-already-accepted': 'An invitation has already been accepted.',
    'address-fields-insufficient': 'You have not provided all required address fields.',
    'address-part-invalid': 'Part of the address is invalid.',
    'statement-submitted-out-of-order': 'The statement has been submitted out of order.',
    'no-such-transaction-in-property': 'There was no such transaction for this property.',
    'application-not-yet-completed': 'The tenancy application has not yet been completed.',
    'payment-schedule-does-not-match-expected-total-value': 'The payment schedule does not match the total value expected.',
    'no-current-tenancy-exists': 'No current tenancy exists.',
    'no-such-tenant-in-tenancy': 'The tenant is not in the tenancy.',
    'property-not-found': 'The property could not be found.',
    'document-already-exists': 'The document you provided already exists.',
    'room-already-taken': 'The room you have chosen has already been taken.',
    'no-such-room': 'There is no such room at this property.',
    'tenant-not-found': 'We could not find the tenant.',
    'schedule-already-fixed': 'The schedule is already fixed.',
    'schedule-not-yet-known': 'The schedule has not been found.',
    'agreement-already-accepted': 'The agreement has already been accepted.',
    'guarantor-already-agreed': 'The guarantor has already agreed.',
    'no-such-tenancy-exists-for-property': 'The tenancy does not exist.',
    'tenant-already-in-tenancy': 'Tenant already in the tenancy.',
    'invalid-action-due-to-tenancy-status': 'Cannot end the tenant from the tenancy',
    'wrong-payment-amount': 'You provided a wrong payment amount.',
    'tenant-never-lived-in-property': 'The tenant never lived at this property.',
    'tenant-has-no-tenancy-at-property': 'The tenant has no tenancy at this property.',
    'invalid-payment-schedule-template': 'An invalid payment schedule template has been provided.',
    'invalid-payment-schedule-payment': 'An invalid payment schedule payment has been provided.',
    'tenant-cannot-report-issue-without-tenancy-number': 'Supply a tenancy number to associate the issue with a specific tenancy.'
  };
  _exports.default = _default;
});