define("propertycloud/pods/logged-in/tenant/route", ["exports", "propertycloud/mixins/smallchat", "propertycloud/mixins/setup-tenant-communication-history"], function (_exports, _smallchat, _setupTenantCommunicationHistory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_smallchat.default, _setupTenantCommunicationHistory.default, {
    agentContext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    model: function model() {
      return this.store.query('tenancy', {
        tenantId: Ember.get(this, 'user.userId'),
        individualStatuses: 'current,past,upcoming'
      });
    },
    actions: {
      toggleMessages: function toggleMessages() {
        this.controller.toggleProperty('isMessagesShown');
      }
    },
    setupController: function setupController(controller, model) {
      Ember.set(controller, 'tenancies', model);
      Ember.set(this, 'tenantId', Ember.get(this, 'user.userId'));

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});