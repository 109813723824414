define("propertycloud/pods/user-change-email/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uvNLTKBQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container container--full-height\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"side-panel\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"logo\"],[8],[1,[28,\"svg-jar\",[\"logo\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"side-panel__icon\"],[8],[1,[28,\"svg-jar\",[\"secure-icon\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content-panel content-panel--centered\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"login-form\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"content-panel__inner\"],[8],[0,\"\\n        \"],[1,[22,\"outlet\"],false],[0,\"\\n        \"],[7,\"h1\",true],[10,\"class\",\"page-title\"],[8],[0,\"Your email has been changed.\"],[9],[7,\"br\",true],[8],[9],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"page-introtext\"],[8],[0,\"Please click the \\\"Login\\\" button below.\"],[9],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn--large btn--positive\",\"login\"]],{\"statements\":[[0,\"          \"],[7,\"span\",true],[8],[0,\"Login\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/user-change-email/template.hbs"
    }
  });

  _exports.default = _default;
});