define("propertycloud/pods/components/register-form/component", ["exports", "propertycloud/mixins/form-with-errors", "ember-changeset", "ember-changeset-validations", "propertycloud/validations/register-tenant", "moment", "ember-cli-uuid", "propertycloud/utilities/date", "propertycloud/utilities/contract", "propertycloud/utilities/universities", "propertycloud/utilities/scrollTo"], function (_exports, _formWithErrors, _emberChangeset, _emberChangesetValidations, _registerTenant, _moment, _emberCliUuid, _date, _contract, _universities, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formWithErrors.default, {
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    agentContext: Ember.inject.service(),
    user: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['container', 'container--full-height', 'register-tenant'],
    ValidationFields: _registerTenant.default,
    universities: _universities.default.universities,
    paymentMethods: Ember.computed(function () {
      return ['Monthly', 'Student Loan'];
    }),
    understandConfirmation: false,
    university: 'LVRPL',
    // select default
    paymentMethod: Ember.computed('profiles', function () {
      var profiles = Ember.get(this, 'profiles');
      var defaultMethod = 'Monthly';

      if (Ember.isEmpty(profiles.monthly) && Ember.isPresent(profiles['student-loan'])) {
        defaultMethod = 'Student Loan';
      }

      return defaultMethod;
    }),
    // select default
    fullName: null,
    propertyId: null,
    contractDate: (0, _moment.default)().format('YYYY-MM-DD'),
    selectedRoom: null,
    selectedRoomPrice: null,
    studentIdImage: null,
    studentIdFile: null,
    photoIdImage: null,
    photoIdFile: null,
    studentIdNumber: null,
    dateOfBirth: null,
    maxDate: (0, _moment.default)().subtract(18, 'years').toDate(),
    propertyAddress: null,
    userEmail: null,
    roomTaken: false,
    noRoomSelected: false,
    isSuccessful: false,
    isLoading: false,
    firstName: Ember.computed('fullName', function () {
      var fullName = Ember.get(this, 'fullName');

      if (fullName) {
        return fullName.split(' ')[0];
      } else {
        return fullName;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var model = Ember.get(this, 'model');
      this.changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_registerTenant.default), _registerTenant.default);
      Ember.set(this.changeset, 'fullName', Ember.get(this, 'tenant.name'));
      this.otherTenants = this.otherTenants || [];
    },
    otherTenantscomp: Ember.computed('tenants', 'rooms', function () {
      var _this = this;

      var tenants = Ember.get(this, 'tenants');
      var otherTenants = [];
      var rooms = Ember.get(this, 'rooms');
      tenants.forEach(function (tenant) {
        var monthlyCost = 0;
        var totalCost = 0;
        var room = Ember.get(tenant, 'room-taken');

        if (Ember.isPresent(room)) {
          monthlyCost = rooms[room].amount;
          var contractLengthDays = (0, _date.daysBetween)(Ember.get(_this, 'startDate'), Ember.get(_this, 'endDate'));
          totalCost = (0, _contract.contractCost)(contractLengthDays, monthlyCost);
        } else {
          monthlyCost = tenant['monthly-cost'];
          totalCost = tenant['total-cost'];
        }

        if (tenant.id !== Ember.get(_this, 'user.userId')) {
          otherTenants.push({
            name: tenant.name,
            signedDate: tenant['signed-date'],
            signedContract: tenant['signed-date'] !== null,
            deposit: tenant.deposit,
            monthlyCost: monthlyCost,
            totalCost: totalCost
          });
        }
      });
      return otherTenants;
    }),
    invitationToExistingTenancy: Ember.computed.notEmpty('agreement.state.metadata.stages.invitation.payment-schedule'),
    aggregateRoomCost: Ember.computed('rooms', function () {
      var total = 0;
      var rooms = Ember.get(this, 'rooms');

      for (var room in rooms) {
        total += Number(rooms[room].amount);
      }

      return total;
    }),
    contractComponent: Ember.computed('agentId', function () {
      var agentId = Ember.get(this, 'agentId');
      var otherContractAgents = ['a1df372c-5c8d-43e1-b573-a5c7b302e930', 'c8480b7d-4940-4269-a029-29cea3da61bd', '2cf60175-9030-4e02-a955-cb7a3d9eef93'];

      if (otherContractAgents.includes(agentId)) {
        return "register-tenant-contract-".concat(agentId);
      } else {
        return 'register-tenant-contract';
      }
    }),
    rooms: Ember.computed('tenancy.initialRoomBreakdown', function () {
      var rooms = Ember.get(this, 'tenancy.initialRoomBreakdown');
      var tenants = Ember.get(this, 'tenancy.agreements.content.meta.tenants');
      tenants.forEach(function (tenant) {
        var room = Ember.get(tenant, 'room-taken');

        if (Ember.isPresent(room)) {
          rooms[room].taken = true;
        }
      });
      return rooms;
    }),
    totalCost: Ember.computed('selectedRoomPrice', 'startDate', 'endDate', function () {
      var monthlyCost = Ember.get(this, 'selectedRoomPrice');
      var startDate = Ember.get(this, 'startDate');
      var endDate = Ember.get(this, 'endDate');
      var days = (0, _date.daysBetween)(startDate, endDate);
      return (0, _contract.contractCost)(days, monthlyCost);
    }).readOnly(),
    actions: {
      selectUniversity: function selectUniversity(value) {
        Ember.set(this, 'university', value);
      },
      selectPaymentMethod: function selectPaymentMethod(value) {
        Ember.set(this, 'paymentMethod', value);
      },
      selectRoom: function selectRoom(name, price) {
        Ember.set(this, 'selectedRoom', name);
        Ember.set(this, 'selectedRoomPrice', parseInt(price));
      },
      clearRoomError: function clearRoomError() {
        Ember.set(this, 'noRoomSelected', false);
      },
      submit: function submit() {
        var _this2 = this;

        Ember.set(this, 'isLoading', true);
        Ember.set(this, 'noRoomSelected', false);
        this.resetErrors();
        var propertyId = Ember.get(this, 'propertyId');
        var tenancyNumber = Ember.get(this, 'tenancy.tenancyNumber');
        var studentId = Ember.get(this, 'changeset.studentIdImage');
        var photoId = Ember.get(this, 'changeset.photoIdImage');
        var selectedRoom = Ember.get(this, 'selectedRoom'); // format date with moment

        var dateOfBirth = Ember.get(this, 'changeset.dateOfBirth');

        if (dateOfBirth) {
          dateOfBirth = (0, _moment.default)(dateOfBirth[0]).format('YYYY-MM-DD');
        }

        if (Ember.isEmpty(Ember.get(this, 'agreement.state.metadata.stages.invitation.payment-schedule'))) {
          if (selectedRoom === null) {
            Ember.set(this, 'noRoomSelected', true);
            this.reportErrorMessage('Please make sure you have selected a room.');
          }
        } // run validations


        this.changeset.validate().then(function () {
          if (!_this2.changeset.get('isValid')) {
            _this2.reportErrorMessage('Please correct the validation errors.');

            Ember.set(_this2, 'isLoading', false);
            return;
          } // serialize data from form and make request


          var dashedPaymentMethod = _this2.paymentMethod.replace(' ', '-').toLowerCase();

          var payload = {
            propertyId: propertyId,
            tenancyNumber: tenancyNumber,
            name: _this2.changeset.get('fullName'),
            payment: {
              schedule: null
            },
            mobileNumber: _this2.changeset.get('phoneNumber'),
            dateOfBirth: dateOfBirth,
            room: null,
            university: Ember.get(_this2, 'university'),
            studentIdNumber: Ember.get(_this2.changeset, 'studentIdNumber'),
            photoId: {
              documentId: (0, _emberCliUuid.uuid)(),
              data: photoId
            },
            studentId: {
              documentId: (0, _emberCliUuid.uuid)(),
              data: studentId
            }
          };

          if (Ember.isEmpty(Ember.get(_this2, 'agreement.state.metadata.stages.invitation.payment-schedule'))) {
            payload.room = Ember.get(_this2, 'selectedRoom');
            payload.payment = {
              schedule: dashedPaymentMethod
            };
          }

          var request = _this2.ajax.post('/estate/property.accept-tenancy', {
            data: JSON.stringify({
              payload: payload
            })
          });

          request.catch(function (response) {
            var sentryUuid = response.sentryUuid;
            var message = 'Oh no, something has gone wrong. Please try again, if this continues to happen please let us know at support@rooms4uapp.co.uk';

            if (sentryUuid) {
              message += " Error id: ".concat(sentryUuid);
            }

            _this2.reportErrorMessage(message);

            if (response.error === 'room-already-taken') {
              Ember.set(_this2, 'roomTaken', true);
            }
          });
          request.then(function () {
            _this2.resetErrors();

            Ember.set(_this2, 'isSuccessful', true);
            Ember.set(_this2, 'user.signedContract', true);
            (0, _scrollTo.default)(document.body, 150, 300);
          });
        });
      }
    }
  });

  _exports.default = _default;
});