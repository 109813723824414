define("propertycloud/pods/components/replace-tenant-interface/component", ["exports", "moment", "ember-changeset", "ember-changeset-validations", "propertycloud/validations/replace-tenant"], function (_exports, _moment, _emberChangeset, _emberChangesetValidations, _replaceTenant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    classNames: ['replace-interface'],
    propertyId: null,
    tenantToReplaceId: null,
    includeDate: true,
    nameReplace: null,
    inviteStep: 1,
    contractedAmount: 0,
    // Initialise a change set for validations fields
    ValidationFields: _replaceTenant.default,
    init: function init() {
      this._super.apply(this, arguments);

      this.changeset = new _emberChangeset.default({
        nameReplace: null,
        emailReplace: null,
        mobileNumberReplace: null,
        dateFrom: null,
        schedule: [],
        adminFee: 0,
        deposit: 0,
        advancedRent: 0,
        contractualMonthlyRent: '0.00'
      }, (0, _emberChangesetValidations.default)(_replaceTenant.default), _replaceTenant.default);
      this.stepValidate = this.stepValidate || [];
    },
    contractualMonthlyRentPence: Ember.computed('changeset.contractualMonthlyRent', function () {
      return Number(Ember.get(this, 'changeset.contractualMonthlyRent')) * 100;
    }),
    scheduledAmount: Ember.computed('changeset.schedule.@each', function () {
      var total = 0;
      var profile = Ember.get(this, 'changeset.schedule');

      if (profile) {
        for (var i = 0; i < profile.length; i++) {
          total = total + Number(profile[i].payment.amount);
        }
      }

      return total;
    }),
    tenantReplaceLoading: false,
    tenantReplaceSuccess: false,
    tenantReplaceError: false,
    replaceTenantCall: function replaceTenantCall() {
      var _this = this;

      Ember.set(this, 'tenantReplaceLoading', true);
      Ember.set(this, 'tenantReplaceError', false);
      var ajax = Ember.get(this, 'ajax');
      var nameReplace = Ember.get(this, 'changeset.nameReplace');
      var mobileNumberReplace = Ember.get(this, 'changeset.mobileNumberReplace');
      var emailReplace = Ember.get(this, 'changeset.emailReplace');
      var schedule = Ember.get(this, 'changeset.schedule');
      var tenancyNumber = Ember.get(this, 'tenancyNumber');
      var contractualMonthlyRent = Ember.get(this, 'changeset.contractualMonthlyRent');

      if (Ember.isEmpty(nameReplace) || Ember.isEmpty(emailReplace) || Ember.isEmpty(mobileNumberReplace) || Ember.isEmpty(contractualMonthlyRent)) {
        Ember.set(this, 'tenantReplaceLoading', false);
        Ember.set(this, 'tenantReplaceError', true);
        return false;
      }

      var date = (0, _moment.default)().format('YYYY-MM-DD');

      if (Ember.get(this, 'includeDate')) {
        var _EmberGet = Ember.get(this, 'changeset.dateFrom'),
            _EmberGet2 = _slicedToArray(_EmberGet, 1),
            inputDate = _EmberGet2[0];

        date = (0, _moment.default)(inputDate).format('YYYY-MM-DD');
      }

      var request = ajax.post('/estate/property.invite-tenant-to-join-tenancy', {
        data: JSON.stringify({
          payload: {
            'property-id': Ember.get(this, 'propertyId'),
            'scheduled': date,
            'new-tenant': {
              'name': nameReplace,
              'mobile-number': mobileNumberReplace,
              'email-address': emailReplace
            },
            schedule: schedule,
            'admin-fee': {
              amount: Ember.get(this, 'changeset.adminFee'),
              currency: 'GBP'
            },
            'deposit': {
              amount: Ember.get(this, 'changeset.deposit'),
              currency: 'GBP'
            },
            'advanced-rent': {
              amount: Ember.get(this, 'changeset.advancedRent'),
              currency: 'GBP'
            },
            'tenancy-number': tenancyNumber,
            'contractual-monthly-rent': {
              amount: Ember.get(this, 'changeset.contractualMonthlyRent') * 100,
              currency: 'GBP'
            }
          }
        })
      });
      request.then(function () {
        Ember.set(_this, 'tenantReplaceLoading', false);
        Ember.set(_this, 'tenantReplaceSuccess', true);
        Ember.set(_this, 'tenantReplaceError', false);
      });
      request.catch(function () {
        Ember.set(_this, 'tenantReplaceLoading', false);
        Ember.set(_this, 'tenantReplaceError', true);
        Ember.set(_this, 'tenantReplaceSuccess', false);
      });
    },
    actions: {
      changeStep: function changeStep(stepNumber, stepValidate) {
        var _this2 = this;

        var changeset = this.changeset;

        if (stepValidate) {
          var validateFields = stepValidate.replace(/ /g, '').split(',');
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = validateFields[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var field = _step.value;
              changeset.validate(field).then(function () {
                if (changeset.get('isValid')) {
                  Ember.set(_this2, 'inviteStep', stepNumber);
                }
              });
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        } else {
          Ember.set(this, 'inviteStep', stepNumber);
        }
      },
      submitReplace: function submitReplace() {
        this.replaceTenantCall();
      },
      resetState: function resetState() {
        this.changeset.rollback();
        Ember.set(this, 'tenantReplaceLoading', false);
        Ember.set(this, 'tenantReplaceError', false);
        Ember.set(this, 'tenantReplaceSuccess', false);
        Ember.set(this, 'nameReplace', null);
        Ember.set(this, 'emailReplace', null);
        Ember.set(this, 'mobileNumberReplace', null);
        Ember.set(this, 'dateFrom', null);
        Ember.set(this, 'inviteStep', 1);
        Ember.set(this, 'schedule', []);
        Ember.set(this, 'contractedAmount', 0);
        Ember.set(this, 'fees', {
          adminFee: 0,
          deposit: 0,
          advancedRent: 0
        });
        Ember.set(this, 'contractualMonthlyRent', '0.00');
      }
    }
  });

  _exports.default = _default;
});