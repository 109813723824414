define("propertycloud/validations/create-landlord", ["exports", "ember-changeset-validations/validators", "propertycloud/validators/mobile-number", "propertycloud/validators/masked"], function (_exports, _validators, _mobileNumber, _masked) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bankDetailsValidations = _exports.addressValidations = _exports.photoIdValidations = _exports.contractValidations = _exports.contactValidations = void 0;
  var uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  var contactValidations = {
    name: (0, _validators.validatePresence)(true),
    mobileNumber: [(0, _validators.validatePresence)(true), (0, _mobileNumber.default)()],
    emailAddress: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      type: 'email'
    })]
  };
  _exports.contactValidations = contactValidations;
  var contractValidations = {
    'contract.documentId': (0, _validators.validateFormat)({
      regex: uuidRegex,
      message: 'You must upload a copy of the contract'
    })
  };
  _exports.contractValidations = contractValidations;
  var photoIdValidations = {
    'photoId.documentId': (0, _validators.validateFormat)({
      regex: uuidRegex,
      message: 'You must upload an image of their photographic id'
    })
  };
  _exports.photoIdValidations = photoIdValidations;
  var addressValidations = {
    'address.building': (0, _validators.validatePresence)({
      presence: true,
      message: "Building is required"
    }),
    'address.street': (0, _validators.validatePresence)({
      presence: true,
      message: "Street is required"
    }),
    'address.postcode': [(0, _validators.validatePresence)({
      presence: true,
      message: "Postcode is required"
    }), (0, _validators.validateLength)({
      min: 5,
      message: 'Must be a valid postcode'
    })]
  };
  _exports.addressValidations = addressValidations;
  var bankDetailsValidations = {
    'bankAccount.accountName': (0, _validators.validatePresence)(true),
    'bankAccount.accountNumber': [(0, _masked.default)({
      is: 8,
      char: '_'
    })],
    'bankAccount.sortCode': [(0, _masked.default)({
      is: 6,
      char: ['_', '-']
    })]
  };
  _exports.bankDetailsValidations = bankDetailsValidations;
});