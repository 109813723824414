define("propertycloud/pods/logged-in/manage/property/inspections/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9+wT5g2l",
    "block": "{\"symbols\":[\"inspection\"],\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"grid grid--centred grid--padded property-inspections\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid__row grid__row--medium\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"grid__col\"],[8],[0,\"\\n\\n      \"],[1,[22,\"outlet\"],false],[0,\"\\n\\n      \"],[7,\"ul\",true],[10,\"class\",\"inspection-list\"],[8],[0,\"\\n        \"],[7,\"li\",true],[10,\"class\",\"inspection-list__item\"],[8],[0,\"\\n          \"],[7,\"button\",false],[12,\"class\",\"new-inspection-report\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"newInspection\"]],[8],[0,\"\\n            \"],[1,[28,\"svg-jar\",[\"inspection-report\"],null],false],[0,\"\\n            Create New Inspection Report\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"inspections\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\",true],[10,\"class\",\"inspection-list__item\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\",\"models\"],[\"logged-in.manage.property.inspections.edit\",[28,\"array\",[[24,[\"propertyId\"]],[23,1,[\"id\"]]],null]]],{\"statements\":[[0,\"              \"],[1,[28,\"inspection-card\",null,[[\"inspection\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/manage/property/inspections/template.hbs"
    }
  });

  _exports.default = _default;
});