define("propertycloud/pods/components/mail-check/component", ["exports", "mailcheck"], function (_exports, _mailcheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['mailcheck'],
    classNameBindings: ['hasHint:has-warning'],
    type: 'email',
    name: 'email',
    value: '',
    placeholder: '',
    suggestion: '',
    hint: null,
    hasHint: Ember.computed.notEmpty('hint'),
    hintMessage: 'You seem to be missing an email domain, like @gmail.com or @hotmail.com',
    suggestionMessage: 'Did you mean',
    inputClass: 'mailcheck-input',
    disabled: false,
    required: false,
    title: null,
    actions: {
      mailcheck: function mailcheck() {
        var that = this;

        _mailcheck.default.defaultDomains.push('2015.ljmu.ac.uk', '2016.ljmu.ac.uk', '2016ljmu.ac.uk', 'hope.ac.uk', 'lipa.ac.uk', 'liv.ac.uk', 'live.co.uk', 'live.com', 'liverpool.ac.uk', 'liverpool.student.ac.uk', 'ljmu.ac.uk', 'student.liverpool.ac.uk');

        _mailcheck.default.run({
          email: that.value,
          suggested: function suggested(suggestion) {
            Ember.set(that, 'hint', "".concat(Ember.get(that, 'suggestionMessage'), " "));
            Ember.set(that, 'suggestion', suggestion.full);
          },
          empty: function empty() {
            var email = that.value;

            if (isEmail(email)) {
              Ember.set(that, 'suggestion', null);
              Ember.set(that, 'hint', null);
              return;
            }

            Ember.set(that, 'hint', Ember.get(that, 'hintMessage'));
            Ember.set(that, 'suggestion', null);
          }
        });
      },
      useSuggestion: function useSuggestion() {
        this.set('value', this.suggestion);
        this.set('suggestion', null);
        this.set('hint', null);
      }
    }
  });

  _exports.default = _default;

  function isEmail(email) {
    return Ember.isPresent(email) && /^([w_.\-+])+@([w-]+\.)+([\w]{2,10})+$/.test(email);
  }
});