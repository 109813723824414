define("propertycloud/pods/components/section-navigation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N9+Q9iJD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"name\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\",true],[10,\"class\",\"section-nav__heading\"],[8],[1,[22,\"name\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"nav\",true],[10,\"class\",\"section-nav__nav\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"section-nav__tab\"],[8],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/section-navigation/template.hbs"
    }
  });

  _exports.default = _default;
});