define("propertycloud/pods/components/tenant-invite-others/component", ["exports", "ember-changeset", "ember-changeset-validations", "propertycloud/validations/invite-other-tenants"], function (_exports, _emberChangeset, _emberChangesetValidations, _inviteOtherTenants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // InviteValidations,
    ajax: Ember.inject.service(),
    classNames: ['register-form'],
    isLoading: false,
    hasErrors: false,
    isSuccessful: false,
    // Initialise a changeset for validations fields
    ValidationFields: _inviteOtherTenants.default,
    init: function init() {
      this._super.apply(this, arguments);

      this.changeset = new _emberChangeset.default({
        addTenantEmail: null,
        addTenantName: null,
        addTenantMobileNumber: null
      }, (0, _emberChangesetValidations.default)(_inviteOtherTenants.default), _inviteOtherTenants.default);
      this.tenantsAdded = this.tenantsAdded || [];
    },
    roomRentMapping: null,
    propertyId: null,
    email: null,
    token: null,
    remaining: Ember.computed('expectedTenants', 'tenantsAdded.@each', function () {
      var added = Ember.get(this, 'tenantsAdded');
      var maxInvites = Ember.get(this, 'expectedTenants') - 1;
      var remaining = maxInvites;

      if (added.length > 0) {
        remaining = maxInvites - added.length;
      }

      return remaining;
    }),
    actions: {
      addTenant: function addTenant() {
        var _this = this;

        this.changeset.validate().then(function () {
          if (_this.changeset.get('isInvalid')) {
            return;
          }

          var name = Ember.get(_this, 'changeset.addTenantName');
          var email = Ember.get(_this, 'changeset.addTenantEmail');
          var mobileNumber = Ember.get(_this, 'changeset.addTenantMobileNumber') === '' ? null : Ember.get(_this, 'changeset.addTenantMobileNumber');

          if (Ember.get(_this, 'remaining') > 0) {
            Ember.get(_this, 'tenantsAdded').pushObject({
              name: name.trim(),
              emailAddress: email.trim(),
              mobileNumber: mobileNumber.trim()
            });

            _this.changeset.rollback();

            document.querySelector('.form__control--phone-number input').value = ''; // force clear input
          }
        });
      },
      removeTenant: function removeTenant(index) {
        var tenants = Ember.get(this, 'tenantsAdded');
        var newList = [];

        if (tenants.length > 1) {
          newList = tenants.filter(function (item, i) {
            return i !== index;
          });
        }

        Ember.set(this, 'tenantsAdded', newList);
      },
      submit: function submit() {
        var _this2 = this;

        Ember.set(this, 'isLoading', true);
        Ember.set(this, 'hasErrors', false);
        var propertyId = Ember.get(this, 'propertyId');
        var tenants = Ember.get(this, 'tenantsAdded');
        var tenancyNumber = Ember.get(this, 'user.upcomingTenancyNumber'); // Make api request

        var request = Ember.get(this, 'ajax').post('/estate/property.specify-other-tenants-in-application', {
          data: JSON.stringify({
            payload: {
              propertyId: propertyId,
              tenancyNumber: tenancyNumber,
              tenants: tenants
            }
          })
        });
        request.then(function () {
          var user = Ember.get(_this2, 'user');
          Ember.set(_this2, 'isLoading', false);
          Ember.set(_this2, 'hasErrors', false);
          Ember.set(_this2, 'isSuccessful', true);
          Ember.set(user, 'invitedTenants', true);
        });
        request.catch(function () {
          Ember.set(_this2, 'isLoading', false);
          Ember.set(_this2, 'hasErrors', true);
        });
      }
    }
  });

  _exports.default = _default;
});