define("propertycloud/pods/components/property-room-breakdown/entry/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['property-cost-item'],
    classNameBindings: ['isBenchmark:property-cost-item--benchmark'],
    item: null,
    index: null,
    benchmarkRoom: null,
    poundPrice: 0,
    isBenchmark: Ember.computed('benchmarkRoom', function () {
      return this.index == this.benchmarkRoom; // jshint ignore:line
    }),
    roomNumber: Ember.computed('index', function () {
      return this.index + 1;
    }),
    didInsertElement: function didInsertElement() {
      var monthlyPricePence = Ember.get(this, 'item.amount');
      var pounds = 0;

      if (typeof monthlyPricePence !== 'undefined') {
        pounds = monthlyPricePence / 100;
      }

      this.set('poundPrice', pounds);
    },
    actions: {
      updateMonthlyPrice: function updateMonthlyPrice(val) {
        this.markChange();
        var computedMonthlyPence = Number(val).toFixed(2) * 100; // convert to pence as input value is formatted as pounds

        this.set('item.amount', computedMonthlyPence);
      }
    }
  });

  _exports.default = _default;
});