define("propertycloud/helpers/calculate-total-rent-paid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function calculateTotalRentPaid(paymentHistory) {
    var totalPaid = 0;

    if (typeof paymentHistory !== 'undefined') {
      paymentHistory.forEach(function (payment) {
        if (payment.paymentType !== 'admin-fee' && payment.paymentType !== 'deposit' && payment.paymentType !== 'charge-payment') {
          totalPaid += Number(payment.amount.amount);
        }
      });
    }

    return totalPaid;
  }

  var _default = calculateTotalRentPaid;
  _exports.default = _default;
});