define("propertycloud/serializers/property-feature", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    serialize: function serialize(snapshot) {
      var snap = snapshot._attributes;
      snap.propertyId = snapshot.id;
      return {
        payload: snap
      };
    }
  });

  _exports.default = _default;
});