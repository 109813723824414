define("propertycloud/pods/logged-in/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "@sentry/browser"], function (_exports, _authenticatedRouteMixin, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return Ember.RSVP.hash({
        user: this.user.whoami({
          showLoading: false
        }),
        terms: this.user.termsAndConditions()
      });
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      var _this$user = this.user,
          isAgent = _this$user.isAgent,
          isTenant = _this$user.isTenant;
      Sentry.configureScope(function (scope) {
        scope.setUser({
          email: model.user.authentication['email-address'],
          isAgent: isAgent,
          isTenant: isTenant
        });
      });
    }
  });

  _exports.default = _default;
});