define("propertycloud/serializers/property-marketing", ["exports", "@ember-data/serializer/json-api", "moment"], function (_exports, _jsonApi, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    normalizeSingleResponse: function normalizeSingleResponse(store, type, payload) {
      var response = {
        data: {
          id: payload.data.id,
          type: 'property-marketing',
          attributes: {
            startDate: null,
            marketed: payload.data.attributes.marketed,
            description: payload.data.attributes.marketing_data.description,
            summary: payload.data.attributes.marketing_data.summary,
            endDate: null,
            pricePerMonth: null
          }
        }
      };

      if (payload.data.attributes.marketing_data.advertised_rent) {
        response.data.attributes.pricePerMonth = payload.data.attributes.marketing_data.advertised_rent.amount;
      }

      if (payload.data.attributes.marketing_data.availability != null) {
        response.data.attributes.endDate = (0, _moment.default)(payload.data.attributes.marketing_data.availability.to).toDate();
        response.data.attributes.startDate = (0, _moment.default)(payload.data.attributes.marketing_data.availability.from).toDate();
      }

      return response;
    }
  });

  _exports.default = _default;
});