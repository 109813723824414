define("propertycloud/pods/components/viewing/viewing-grid/component", ["exports", "moment", "ember-group-by"], function (_exports, _moment, _emberGroupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['viewings-grid'],
    order: 'asc',
    sortBy: 'dateNative',
    viewingSorting: Ember.computed('order', function () {
      var order = this.order;
      var sortBy = this.sortBy;
      return ["".concat(sortBy, ":").concat(order)];
    }),
    viewings: Ember.computed('model.[]', function () {
      var model = this.model;
      model = model.filter(function (record) {
        var today = (0, _moment.default)();
        var recordDate = (0, _moment.default)(Ember.get(record, 'dateNative'));

        if (today.isSameOrBefore(recordDate, 'days')) {
          return record;
        }
      });
      return model;
    }),
    viewingsSorted: Ember.computed.sort('viewings', 'viewingSorting'),
    viewingsFiltered: Ember.computed('viewingsSorted', function () {
      var filteredResults = this.viewingsSorted.filter(function (viewing) {
        return viewing.scheduledAt && !viewing.cancelled;
      });
      return filteredResults;
    }),
    viewingsGrouped: (0, _emberGroupBy.default)('viewingsFiltered', 'dateScheduled')
  });

  _exports.default = _default;
});