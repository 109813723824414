define("propertycloud/helpers/address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.address = address;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function address(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        _ref2$ = _ref2[0],
        address = _ref2$ === void 0 ? {} : _ref2$,
        _ref2$2 = _ref2[1],
        options = _ref2$2 === void 0 ? {
      multiline: false,
      short: false
    } : _ref2$2;

    var breaker = '';
    var addressStr = '';
    var flat = address.flat || null;
    var street = address.street || null;
    var area = address.area || null;
    var town = address.town || null;
    var county = address.county || null;
    var building = address.building || null;
    var postcode = address.postcode || null;

    if (options.multiline) {
      breaker = '<br>';
    }

    if (flat !== null) {
      addressStr = "Flat ".concat(flat, ", ");
      addressStr += "".concat(building, " ").concat(street);
    } else {
      addressStr += "".concat(building, " ").concat(street);
    }

    if (!options.short) {
      addressStr += area !== null ? ",".concat(breaker, " ").concat(area) : '';
      addressStr += town !== null ? ",".concat(breaker, " ").concat(town) : '';
      addressStr += county !== null ? ",".concat(breaker, " ").concat(county) : '';
      addressStr += postcode !== null ? ",".concat(breaker, " ").concat(postcode) : '';
    }

    return Ember.String.htmlSafe(addressStr);
  }

  var _default = Ember.Helper.helper(address);

  _exports.default = _default;
});