define("propertycloud/pods/logged-in/finance/record/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TRWB2y85",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"grid grid--padded record-expenses-area\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid__row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"grid__col grid__col--six\"],[8],[0,\"\\n      \"],[1,[28,\"record-income\",null,[[\"user\",\"companies\"],[[24,[\"user\"]],[23,1,[\"suppliers\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"grid__col grid__col--six\"],[8],[0,\"\\n      \"],[1,[28,\"record-expenses\",null,[[\"suppliers\"],[[23,1,[\"suppliers\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"grid__row\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"grid__col scrollable-table-mob\"],[8],[0,\"\\n      \"],[1,[28,\"overdue-rent-table\",null,[[\"agentId\",\"isEditable\"],[[24,[\"agentContext\",\"id\"]],true]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/finance/record/template.hbs"
    }
  });

  _exports.default = _default;
});