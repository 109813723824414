define("propertycloud/validations/register-tenant", ["exports", "ember-changeset-validations/validators", "propertycloud/validators/mobile-number", "propertycloud/validators/checked", "propertycloud/validators/filesize"], function (_exports, _validators, _mobileNumber, _checked, _filesize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    fullName: (0, _validators.validatePresence)(true),
    phoneNumber: [(0, _validators.validatePresence)(true), (0, _mobileNumber.default)()],
    dateOfBirth: (0, _validators.validatePresence)(true),
    studentIdNumber: (0, _validators.validatePresence)(true),
    understandConfirmation: (0, _checked.default)({
      checked: true
    }),
    studentIdImage: [(0, _validators.validatePresence)(true), (0, _filesize.default)()],
    photoIdImage: [(0, _validators.validatePresence)(true), (0, _filesize.default)()]
  };
  _exports.default = _default;
});