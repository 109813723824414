define("propertycloud/pods/logged-in/manage/property/marketing/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var parentModel = this.modelFor('logged-in/manage/property');
      var propertyId = Ember.get(parentModel, 'id');
      return Ember.RSVP.hash({
        marketing: this.store.findRecord('property-marketing', propertyId),
        features: this.store.findRecord('property-feature', propertyId)
      });
    },
    setupController: function setupController(controller) {
      // we want access to the property from the child route so give the controller
      // access to that model
      this._super.apply(this, arguments);

      controller.set('property', this.modelFor('logged-in/manage/property'));
    },
    actions: {
      refreshModel: function refreshModel() {
        this.model();
      }
    }
  });

  _exports.default = _default;
});