define("propertycloud/adapters/view-tenancy", ["exports", "propertycloud/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // we want everything to use the host and namespace defined in the
  // application adapter so we extend from that instead
  var _default = _application.default.extend({
    queryRecord: function queryRecord(store, type, id) {
      var url = "".concat(this.host, "/").concat(this.namespace, "/estate/tenant.view-tenancies");
      return this.ajax(url, 'GET', {
        data: {
          tenantId: id
        }
      });
    }
  });

  _exports.default = _default;
});