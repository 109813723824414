define("propertycloud/components/export-selector-onselect", ["exports", "ember-cli-data-export/components/export-selector-onselect"], function (_exports, _exportSelectorOnselect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _exportSelectorOnselect.default;
    }
  });
});