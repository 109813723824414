define("propertycloud/pods/components/register-form-room/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['btn', 'btn--small'],
    classNameBindings: ['isSelected', 'isUnavailable'],
    selected: null,
    room: null,
    isUnavailable: null,
    isSelected: Ember.computed('selected', function () {
      return this.name === this.selected;
    }),
    click: function click(e) {
      e.preventDefault();

      if (!this.isUnavailable) {
        // pass in first room name associated with this price and the price
        this.select(this.name, Ember.get(this, 'room.amount'));
      }
    }
  });

  _exports.default = _default;
});