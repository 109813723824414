define("propertycloud/pods/components/add-workspace-landlord-old/component", ["exports", "ember-changeset", "ember-changeset-validations", "propertycloud/validations/invite-landlord", "propertycloud/utilities/scrollTo"], function (_exports, _emberChangeset, _emberChangesetValidations, _inviteLandlord, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['add-workspace__form'],
    agentContext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    isLoading: false,
    hasError: false,
    wasSuccessful: false,
    // Initialise a changeset for validations fields
    ValidationFields: _inviteLandlord.default,
    init: function init() {
      this._super.apply(this, arguments);

      this.changeset = new _emberChangeset.default({
        fullName: null,
        emailAddress: null,
        phoneNumber: null
      }, (0, _emberChangesetValidations.default)(_inviteLandlord.default), _inviteLandlord.default);
    },
    actions: {
      hideWorkspace: function hideWorkspace() {
        // run the hideWorkspace action in the parent component (add-workspace)
        this.hideWorkspace();
        this.send('resetForm');
      },
      resetForm: function resetForm() {
        this.changeset.rollback();
        this.setProperties({
          wasSuccessful: false
        });
      },
      submit: function submit() {
        var _this = this;

        Ember.set(this, 'hasError', false);
        Ember.set(this, 'isLoading', true); // Validation

        this.changeset.validate().then(function () {
          if (_this.changeset.get('isValid')) {
            var request = _this.ajax.post('/estate/landlord.invite-to-agent-account', {
              data: JSON.stringify({
                payload: {
                  primaryContact: {
                    'email-address': Ember.get(_this, 'changeset.emailAddress'),
                    'name': Ember.get(_this, 'changeset.fullName'),
                    'mobile-number': Ember.get(_this, 'changeset.phoneNumber')
                  },
                  agentId: Ember.get(_this, 'agentContext.id')
                }
              })
            });

            request.catch(function () {
              Ember.set(_this, 'hasError', true);
              Ember.set(_this, 'isLoading', false);
            });
            request.then(function () {
              Ember.set(_this, 'hasError', false);
              Ember.set(_this, 'isLoading', false);
              Ember.set(_this, 'wasSuccessful', true);
            });
          } else {
            Ember.set(_this, 'hasError', true);
            Ember.set(_this, 'isLoading', false);
          }

          (0, _scrollTo.default)(document.body, 0, 300);
        });
      }
    }
  });

  _exports.default = _default;
});