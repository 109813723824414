define("propertycloud/pods/logged-in/manage/property/contract-template/controller", ["exports", "moment", "propertycloud/utilities/date", "propertycloud/utilities/contract"], function (_exports, _moment, _date, _contract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Controller.extend({
    errorHandler: Ember.inject.service(),
    property: null,
    // set in route
    agentContext: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    failedToSaveMessage: 'Contract template failed to save.',
    tenantContractIsShowing: false,
    hasChanged: Ember.computed.or('hasChangedRooms', 'hasChangedFees', 'hasChangedMonthly', 'hasChangedTermly', 'hasChangedNotes'),
    hasChangedRooms: false,
    hasChangedFees: false,
    hasChangedMonthly: false,
    hasChangedTermly: false,
    hasChangedNotes: false,
    roomBreakdownError: Ember.computed('model.roomBreakdown.benchmarkRoom', function () {
      return this.model.roomBreakdown.benchmarkRoom === null ? 'Please add at least 1 room' : null;
    }),
    adminFeeError: Ember.computed('model.contracts.adminFee', function () {
      var adminFee = this.model.contracts.adminFee;
      return adminFee === null || adminFee < 0 || isNaN(Number.parseInt(adminFee)) ? 'Please enter a valid admin fee' : null;
    }),
    depositError: Ember.computed('model.contracts.deposit', function () {
      var deposit = this.model.contracts.deposit;
      return deposit === null || deposit < 0 || isNaN(Number.parseInt(deposit)) ? 'Please enter a valid deposit' : null;
    }),
    advancedRentError: Ember.computed('model.contracts.advancedRent', function () {
      var advancedRent = this.model.contracts.advancedRent;
      return advancedRent === null || advancedRent < 0 || isNaN(Number.parseInt(advancedRent)) ? 'Please enter a valid initial payment' : null;
    }),
    datesError: Ember.computed('startDate', 'endDate', 'model.contracts.yearDifference', function () {
      var start = Ember.get(this, 'startDate');
      var end = Ember.get(this, 'endDate');

      if (typeof start !== 'undefined' && typeof end !== 'undefined' && this.model.contracts.yearDifference !== null) {
        return null;
      }

      return 'Please enter valid start and end dates';
    }),
    paymentSchedulesError: Ember.computed('model.contracts.monthlyProfile.length', 'model.contracts.termlyProfile.length', function () {
      var _this$model$contracts = this.model.contracts,
          monthlyProfile = _this$model$contracts.monthlyProfile,
          termlyProfile = _this$model$contracts.termlyProfile;

      if (monthlyProfile && monthlyProfile.length > 0 || termlyProfile && termlyProfile.length > 0) {
        return null;
      }

      return 'Please fill in one or more payment schedules';
    }),
    otherDummyTenants: Ember.computed('model.{roomBreakdown.monthlyRentMapping.@each,contracts.deposit}', function () {
      var rooms = Ember.get(this, 'model.roomBreakdown.monthlyRentMapping');
      var otherTenants = [];

      for (var i = 2; i <= rooms.length; i++) {
        otherTenants.push({
          name: "Tenant ".concat(i),
          monthlyCost: rooms[i - 1].amount,
          deposit: Ember.get(this, 'model.contracts.deposit')
        });
      }

      return otherTenants;
    }),
    contractTenantComponent: Ember.computed('agentContext.id', function () {
      var agentId = Ember.get(this, 'agentContext.id');
      var otherContractAgents = ['a1df372c-5c8d-43e1-b573-a5c7b302e930', 'c8480b7d-4940-4269-a029-29cea3da61bd', '2cf60175-9030-4e02-a955-cb7a3d9eef93'];

      if (otherContractAgents.includes(agentId)) {
        return "register-tenant-contract-".concat(agentId);
      } else {
        return 'register-tenant-contract';
      }
    }),
    contractLengthDays: Ember.computed('startDate', 'endDate', function () {
      var start = Ember.get(this, 'startDate');
      var end = Ember.get(this, 'endDate');
      return (0, _date.daysBetween)(start, end);
    }),
    contractLength1dp: Ember.computed('contractLength', function () {
      return Math.round(Ember.get(this, 'contractLength') * 10) / 10;
    }),
    // Contract Lenght in Months
    contractLength: Ember.computed('contractLengthDays', function () {
      return Ember.get(this, 'contractLengthDays') / 30.436875;
    }),
    proportionOfYear: Ember.computed('contractLengthDays', function () {
      var proportionOfYear = Ember.get(this, 'contractLengthDays') / 365;
      return proportionOfYear;
    }),
    startDate: Ember.computed('model.contracts.tenancyStart', function () {
      var tenancyStartDate = Ember.get(this, 'model.contracts.tenancyStart');
      var date = new Date();

      if (tenancyStartDate !== null) {
        date.setMonth(tenancyStartDate.month - 1); // 0 index

        date.setDate(tenancyStartDate.day);
      }

      return date;
    }),
    tenancyStartYear: Ember.computed('startDate', function () {
      var date = Ember.get(this, 'startDate');
      return (0, _moment.default)(date).format('YYYY');
    }),
    endDate: Ember.computed('model.contracts.tenancyEnd', function () {
      var tenancyEndDate = Ember.get(this, 'model.contracts.tenancyEnd');
      var yearDifference = Ember.get(this, 'model.contracts.yearDifference');
      var date = new Date();
      date = (0, _moment.default)(date).add(yearDifference, 'years').toDate();

      if (tenancyEndDate !== null) {
        date.setMonth(tenancyEndDate.month - 1); // 0 index

        date.setDate(tenancyEndDate.day);
      }

      return date;
    }),
    yearDifference: Ember.computed('model.contracts.yearDifference', function () {
      return Ember.get(this, 'model.contracts.yearDifference');
    }),
    // used for range in picker on load
    dateRange: Ember.computed('startDate', 'endDate', {
      get: function get() {
        var start = (0, _moment.default)(Ember.get(this, 'startDate')).format('YYYY-MM-DD');
        var end = (0, _moment.default)(Ember.get(this, 'endDate')).format('YYYY-MM-DD');
        return "".concat(start, " to ").concat(end);
      },
      set: function set(key, value) {
        if (value !== null) {
          var _value = _slicedToArray(value, 2),
              startDate = _value[0],
              endDate = _value[1];

          if (typeof endDate !== 'undefined') {
            Ember.set(this, 'startDate', startDate);
            Ember.set(this, 'endDate', endDate);
            startDate = (0, _moment.default)(startDate);
            endDate = (0, _moment.default)(endDate);
            var yearDifference = endDate.format('YYYY') - startDate.format('YYYY');
            Ember.set(this, 'model.contracts.yearDifference', yearDifference);
          }
        }

        return value;
      }
    }),
    contractedAmount: Ember.computed('contractLengthDays', 'model.roomBreakdown.{monthlyRentMapping.@each.amount,benchmarkRoom}', function () {
      // get benchmark room price
      var contractLengthDays = Ember.get(this, 'contractLengthDays');
      var roomPrices = Ember.get(this, 'model.roomBreakdown.monthlyRentMapping');
      var benchmarkRoom = Ember.get(this, 'model.roomBreakdown.benchmarkRoom');
      var benchmarkRoomPrice = roomPrices[benchmarkRoom];

      if (typeof benchmarkRoomPrice === 'undefined') {
        return 0;
      }

      var monthlyCost = benchmarkRoomPrice.amount;
      return (0, _contract.contractCost)(contractLengthDays, monthlyCost);
    }),
    aggregateRoomCost: Ember.computed('contractLength', 'model.roomBreakdown.monthlyRentMapping.@each.amount', function () {
      var map = Ember.get(this, 'model.roomBreakdown.monthlyRentMapping');
      var total = 0;
      map.forEach(function (obj) {
        total += Number(obj.amount);
      });
      return total;
    }),
    checkValidity: function checkValidity() {
      if (this.roomBreakdownError || this.adminFeeError || this.depositError || this.advancedRentError || this.datesError || this.paymentSchedulesError) {
        return false;
      }

      return true;
    },
    actions: {
      saveData: function saveData() {
        var _this = this;

        var model = Ember.get(this, 'model');

        if (!this.checkValidity()) {
          this.flashMessages.danger(this.failedToSaveMessage);
          return;
        }

        Ember.set(this, 'isSaving', true);
        var errorHandler = Ember.get(this, 'errorHandler');
        var start = Ember.get(this, 'startDate');
        var end = Ember.get(this, 'endDate');

        if (typeof start !== 'undefined') {
          Ember.set(model.contracts, 'tenancyStart', {
            'month': start.getMonth() + 1,
            // account for 0 index
            'day': start.getDate()
          });
        }

        if (typeof end !== 'undefined') {
          Ember.set(model.contracts, 'tenancyEnd', {
            'month': end.getMonth() + 1,
            // account for 0 index
            'day': end.getDate()
          });
        }

        Ember.RSVP.hash({
          roomBreakdown: Ember.get(model, 'roomBreakdown').save(),
          contracts: Ember.get(model, 'contracts').save()
        }).then(function () {
          errorHandler.handleSuccess();
          Ember.set(_this, 'isSaving', false);
          Ember.set(_this, 'saveSuccessful', true);
          Ember.run.later(function () {
            Ember.set(_this, 'saveSuccessful', false);
          }, 3000);
        }).catch(function (error) {
          errorHandler.handleError(error);
          Ember.set(_this, 'isSaving', false);
          Ember.set(_this, 'saveSuccessful', false);

          _this.flashMessages.danger(_this.failedToSaveMessage);
        }).finally(function () {
          Ember.set(_this, 'hasChangedRooms', false);
          Ember.set(_this, 'hasChangedFees', false);
          Ember.set(_this, 'hasChangedMonthly', false);
          Ember.set(_this, 'hasChangedTermly', false);
          Ember.set(_this, 'hasChangedNotes', false);
        });
      },
      toggleContractPreview: function toggleContractPreview() {
        this.toggleProperty('tenantContractIsShowing');
      },
      changeDates: function changeDates(val) {
        Ember.set(this, 'dateRange', val);
        this.send('markChangeFees');
      },
      markChangeRooms: function markChangeRooms() {
        Ember.set(this, 'hasChangedRooms', true);
      },
      markChangeFees: function markChangeFees() {
        Ember.set(this, 'hasChangedFees', true);
      },
      markChangedMonthly: function markChangedMonthly() {
        Ember.set(this, 'hasChangedMonthly', true);
      },
      markChangedTermly: function markChangedTermly() {
        Ember.set(this, 'hasChangedTermly', true);
      },
      markChangedNote: function markChangedNote() {
        Ember.set(this, 'hasChangedNotes', true);
      }
    }
  });

  _exports.default = _default;
});