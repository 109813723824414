define("propertycloud/pods/components/add-circle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g/ofQ//y",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"add-circle\"],[8],[0,\"\\n  \"],[7,\"span\",true],[8],[1,[28,\"svg-jar\",[\"plus\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"ul\",true],[10,\"class\",\"add-circle__items\"],[8],[0,\"\\n  \"],[7,\"li\",false],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"add\",\"property\"]],[8],[0,\"Property\"],[9],[0,\"\\n  \"],[7,\"li\",false],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"add\",\"landlord\"]],[8],[0,\"Landlord\"],[9],[0,\"\\n  \"],[7,\"li\",false],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"add\",\"tenancy\"]],[8],[0,\"Tenancy\"],[9],[0,\"\\n  \"],[7,\"li\",false],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"add\",\"issue\"]],[8],[0,\"Issue\"],[9],[0,\"\\n  \"],[7,\"li\",false],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"add\",\"contractor\"]],[8],[0,\"Contractor\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/add-circle/template.hbs"
    }
  });

  _exports.default = _default;
});