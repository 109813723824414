define("propertycloud/pods/components/register-tenant-contract-a1df372c-5c8d-43e1-b573-a5c7b302e930/component", ["exports", "moment", "propertycloud/utilities/date"], function (_exports, _moment, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    agentContext: Ember.inject.service(),
    classNames: ['contract-viewer'],
    // passed in
    isInteractive: null,
    fullName: null,
    otherTenants: null,
    contractDate: null,
    landlord: null,
    property: null,
    features: null,
    advancedRent: null,
    adminFee: null,
    deposit: null,
    startDate: null,
    endDate: null,
    depositSchemeName: 'n/a',
    tenantNames: Ember.computed('otherTenants', function () {
      var otherTenants = this.otherTenants;
      otherTenants = otherTenants.map(function (a) {
        return a.name;
      });
      otherTenants.push(this.fullName);
      return otherTenants.reverse().join(', ');
    }),
    termLength: Ember.computed('startDate,endDate', function () {
      return (0, _date.durationInMonths)(this.startDate, this.endDate);
    }),
    currentYear: Ember.computed('contractDate', function () {
      return (0, _moment.default)(this.contractDate).get('year');
    }),
    dueToday: Ember.computed('advancedRent', 'adminFee', function () {
      return parseFloat(this.advancedRent) + parseFloat(this.adminFee);
    }),
    aggregateMonthlyCost: Ember.computed('tenants', function () {
      var tenants = this.tenants;
      var total = 0;

      if (!tenants) {
        return false;
      } else {
        tenants.forEach(function (tenant) {
          total += Number(Ember.get(tenant, 'monthly-cost'));
        });
        return total;
      }
    })
  });

  _exports.default = _default;
});