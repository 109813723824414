define("propertycloud/pods/logged-in/landlord/route", ["exports", "propertycloud/mixins/smallchat"], function (_exports, _smallchat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_smallchat.default, {
    agentContext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    model: function model() {
      var user = Ember.get(this, 'user');
      var landlordId = Ember.get(user, 'userId');
      return Ember.RSVP.hash({
        landlord: this.store.queryRecord('landlord', {
          landlordId: landlordId,
          agentId: this.agentContext.id
        }),
        properties: this.store.query('property', {
          landlordId: landlordId
        })
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var ajax = Ember.get(this, 'ajax');
      var messages = [{
        message: "Welcome to your new PropertyCloud dashboard.\n\n      You can get an overview of your property portfolio, generate reports, view details about your properties, keep your details up to date and we have more features in development.\n\n      You can always contact ".concat(Ember.get(this, 'agentContext.tradingName'), " on ").concat(Ember.get(this, 'agentContext.contactNumber'), " or email at ").concat(Ember.get(this, 'agentContext.contactEmail')),
        time: new Date('2017-05-08T17:12:46+01:00'),
        outgoing: false
      }];
      Ember.set(controller, 'messages', []);
      ajax.request('/estate/landlord.communication-history', {
        data: {
          agentId: Ember.get(this, 'agentContext.id'),
          landlordId: Ember.get(this, 'user.userId')
        }
      }).then(function (response) {
        response.data.forEach(function (obj) {
          messages.push({
            message: obj.attributes.message,
            time: new Date(obj.attributes.timeSent),
            outgoing: false
          });
        });
        Ember.set(controller, 'messages', messages);
      });
    },
    afterModel: function afterModel(model) {
      if (model.landlord.get('photoId') === null) {
        model.landlord.set('photoId', {
          data: null,
          'document-id': null
        });
      }

      if (model.landlord.get('address') === null) {
        model.landlord.set('address', {
          flat: null,
          building: null,
          street: null,
          area: null,
          town: null,
          county: null,
          postcode: null
        });
      }

      if (model.landlord.get('bankAccount') === null) {
        model.landlord.set('bankAccount', {
          'account-number': null,
          'sort-code': null
        });
      }
    },
    actions: {
      toggleMessages: function toggleMessages() {
        this.controller.toggleProperty('isMessagesShown');
      }
    }
  });

  _exports.default = _default;
});