define("propertycloud/mixins/outside-click", ["exports", "propertycloud/utilities/domTraversal"], function (_exports, _domTraversal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    onOutsideClick: function onOutsideClick() {},
    handleOutsideClick: function handleOutsideClick(event) {
      var $element = this.element;
      var $target = event.target;

      if (!(0, _domTraversal.IsDescendant)($element, $target)) {
        this.onOutsideClick();
      }
    },
    setupOutsideClickListener: Ember.on('didInsertElement', function () {
      var clickHandler = this.handleOutsideClick.bind(this);
      return document.addEventListener('click', clickHandler);
    }),
    removeOutsideClickListener: Ember.on('willDestroyElement', function () {
      var clickHandler = this.handleOutsideClick.bind(this);
      return document.removeEventListener('click', Ember.run.cancel(this, clickHandler));
    })
  });

  _exports.default = _default;
});