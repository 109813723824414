define("propertycloud/pods/login/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3f8cygLe",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Login\"],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"login-form\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content-panel__inner\"],[8],[0,\"\\n\\n    \"],[7,\"h1\",true],[10,\"class\",\"page-title\"],[8],[0,\"Sign in to Property\"],[7,\"strong\",true],[8],[0,\"Cloud\"],[9],[0,\".\"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"page-introtext\"],[8],[0,\"By clicking the button below you will be taken to PropertyCloud's authentication system.\"],[9],[0,\"\\n\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn--positive\"],[12,\"type\",\"submit\"],[3,\"action\",[[23,0,[]],\"loginWithImplicitGrant\"]],[8],[0,\"Login using PropertyCloud\"],[9],[0,\"\\n\\n\"],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/login/index/template.hbs"
    }
  });

  _exports.default = _default;
});