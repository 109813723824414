define("propertycloud/pods/components/maintenance/issue-progress/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iCz/kNxl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"issue-progress__info\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"issue-progress__status\"],[8],[0,\"\\n    Status: \"],[7,\"span\",true],[10,\"class\",\"issue-progress__status-text\"],[8],[1,[24,[\"progress\",\"text\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"issue-progress__duration\"],[8],[1,[22,\"duration\"],false],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"ember-progress-bar\",null,[[\"progress\",\"options\",\"class\"],[[24,[\"progress\",\"float\"]],[28,\"hash\",null,[[\"easing\",\"duration\",\"strokeWidth\",\"trailWidth\",\"trailColor\",\"from\",\"to\",\"svgStyle\",\"step\"],[\"linear\",1400,3,3,\"rgba(0, 0, 0, 0.1)\",[28,\"hash\",null,[[\"color\"],[\"#F32D1D\"]]],[28,\"hash\",null,[[\"color\"],[\"#49D186\"]]],[28,\"hash\",null,[[\"width\"],[\"100%\"]]],[24,[\"changeColor\"]]]]],\"issue-progress__bar\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/maintenance/issue-progress/template.hbs"
    }
  });

  _exports.default = _default;
});