define("propertycloud/pods/contract/tenant/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "83rF7/6e",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\"],[1,[28,\"register-form\",null,[[\"model\",\"tenancy\",\"tenant\",\"agreement\",\"tenants\",\"userEmail\",\"property\",\"propertyId\",\"landlord\",\"landlordAddress\",\"startDate\",\"endDate\",\"advancedRent\",\"deposit\",\"adminFee\",\"monthlyCost\",\"profiles\",\"billsIncluded\",\"notes\",\"agentId\",\"agentDetails\"],[[24,[\"model\"]],[24,[\"model\",\"tenancy\"]],[24,[\"thisTenant\",\"firstObject\"]],[24,[\"model\",\"agreement\"]],[24,[\"upcomingTenants\"]],[24,[\"user\",\"email\"]],[24,[\"model\",\"tenancy\",\"property\"]],[24,[\"model\",\"tenancy\",\"property\",\"id\"]],[24,[\"model\",\"tenancy\",\"property\",\"landlordPrimaryContactName\"]],[24,[\"model\",\"tenancy\",\"property\",\"landlordAddress\"]],[24,[\"model\",\"agreement\",\"start\"]],[24,[\"model\",\"agreement\",\"end\"]],[24,[\"model\",\"agreement\",\"state\",\"metadata\",\"stages\",\"invitation\",\"advanced-rent\"]],[24,[\"model\",\"agreement\",\"state\",\"metadata\",\"stages\",\"invitation\",\"deposit\"]],[24,[\"model\",\"agreement\",\"state\",\"metadata\",\"stages\",\"invitation\",\"admin-fee\"]],[24,[\"thisTenant\",\"firstObject\",\"monthly-cost\"]],[24,[\"model\",\"parameters\",\"profiles\"]],[24,[\"model\",\"agreement\",\"billsIncluded\"]],[24,[\"model\",\"agreement\",\"contractNotes\"]],[24,[\"model\",\"parameters\",\"agent\",\"id\"]],[24,[\"model\",\"agentDetails\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/contract/tenant/template.hbs"
    }
  });

  _exports.default = _default;
});