define("propertycloud/serializers/property-arbitrary-information", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    normalizeSingleResponse: function normalizeSingleResponse(store, type, payload) {
      return {
        data: {
          id: payload.data.id,
          type: type.modelName,
          attributes: {
            arbitraryInformation: payload.data.attributes['arbitrary-information'] || []
          }
        }
      };
    },
    serialize: function serialize(snapshot) {
      var payload = {
        'propertyId': snapshot.id,
        'arbitraryInformation': snapshot._attributes.arbitraryInformation
      };
      return {
        payload: payload
      };
    }
  });

  _exports.default = _default;
});