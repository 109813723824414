define("propertycloud/pods/register/tenant/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8pY6+YK5",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Register Tenant\"],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"container container--full-height register-tenant\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"side-panel\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"logo\"],[8],[1,[28,\"svg-jar\",[\"logo\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"side-panel__icon\"],[8],[1,[28,\"svg-jar\",[\"secure-icon\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"content-panel content-panel--centered login-form login-form--choose-password\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content-panel__inner\"],[8],[0,\"\\n\\n\"],[4,\"form-register\",null,[[\"email\",\"token\"],[[24,[\"params\",\"email\"]],[24,[\"params\",\"token\"]]]],{\"statements\":[[0,\"        \"],[7,\"h1\",true],[10,\"class\",\"page-title\"],[8],[0,\"Register to PropertyCloud.\"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"page-introtext\"],[8],[0,\"\\n          \"],[7,\"p\",true],[8],[0,\"Hello, to continue setting up your tenant account you need to choose a password.\"],[9],[0,\"\\n          \"],[7,\"p\",true],[8],[0,\"Your login will be \"],[7,\"strong\",true],[8],[1,[24,[\"params\",\"email\"]],false],[9],[0,\". If you already have an account, please \"],[4,\"link-to\",null,[[\"route\"],[\"login\"]],{\"statements\":[[0,\"login\"]],\"parameters\":[]},null],[0,\".\"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/register/tenant/template.hbs"
    }
  });

  _exports.default = _default;
});