define("propertycloud/validations/invite-tenant", ["exports", "ember-changeset-validations/validators", "propertycloud/validators/mobile-number"], function (_exports, _validators, _mobileNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    tenantName: (0, _validators.validatePresence)(true),
    mobileNumber: [(0, _validators.validatePresence)(true), (0, _mobileNumber.default)()],
    selectedProperty: (0, _validators.validatePresence)(true),
    emailAddress: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      type: 'email'
    })]
  };
  _exports.default = _default;
});