define("propertycloud/pods/components/requires-attention-tenancies/row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    property: false,
    routeString: Ember.computed('property', function () {
      if (this.property) {
        return 'logged-in.manage.property';
      } else {
        return 'logged-in.manage.property.certificates';
      }
    })
  });

  _exports.default = _default;
});