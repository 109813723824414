define("propertycloud/pods/components/property-contract-fees/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form__group'],
    adminFee: 0.00,
    deposit: 0.00,
    advancedRent: 0.00,
    didInsertElement: function didInsertElement() {
      // convert fees to pounds from model amounts (pence)
      Ember.set(this, 'adminFee', this.convertToPounds(Ember.get(this, 'model.adminFee')));
      Ember.set(this, 'deposit', this.convertToPounds(Ember.get(this, 'model.deposit')));
      Ember.set(this, 'advancedRent', this.convertToPounds(Ember.get(this, 'model.advancedRent')));
    },
    convertToPounds: function convertToPounds(amount) {
      // expects amount = { amount: 1 }
      if (typeof amount === 'undefined' || amount === null) {
        return null;
      } else {
        return (amount / 100).toFixed(2);
      }
    },
    actions: {
      updateInputValue: function updateInputValue(fieldName, value) {
        if (value) {
          Ember.set(this, "model.".concat(fieldName), parseFloat(value, 10) * 100);
        } else {
          Ember.set(this, "model.".concat(fieldName), 0);
          Ember.set(this, fieldName, 0.00);
        }
      }
    }
  });

  _exports.default = _default;
});