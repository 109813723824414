define("propertycloud/pods/logged-in/finance/record/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    agentContext: Ember.inject.service(),
    model: function model() {
      return this.ajax.request('/estate/agent.list-suppliers', {
        data: {
          agentId: Ember.get(this, 'agentContext.id')
        }
      }).then(function (response) {
        var supplierArray = [];
        response.data.forEach(function (obj) {
          supplierArray.push({
            name: obj.attributes.name,
            id: obj.id
          });
        });
        return {
          suppliers: supplierArray
        };
      }).catch(function () {
        return {
          suppliers: null
        };
      });
    }
  });

  _exports.default = _default;
});