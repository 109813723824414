define("propertycloud/pods/components/blob-generator/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    tagName: 'img',
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var options = {
        headers: {}
      };

      var _this$get = this.get('session.data.authenticated'),
          access_token = _this$get.access_token;

      if (Ember.isPresent(access_token)) {
        options.headers.Authorization = "Bearer ".concat(access_token);
      }

      fetch(this.documentUrl, options).then(function (res) {
        return res.blob();
      }).then(function (blob) {
        _this.element.src = URL.createObjectURL(blob);
      });
    }
  });

  _exports.default = _default;
});