define("propertycloud/pods/components/maintenance/issue-report/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ieLL+NUy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"issue-report\"],[8],[0,\"\\n  \"],[7,\"button\",true],[10,\"class\",\"btn btn--positive btn--block btn--full-width\"],[10,\"type\",\"button\"],[8],[0,\"Report New Issue\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/maintenance/issue-report/template.hbs"
    }
  });

  _exports.default = _default;
});