define("propertycloud/adapters/user", ["exports", "propertycloud/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // we want everything to use the host and namespace defined in the
  // application adapter so we extend from that instead
  var _default = _application.default.extend({
    agentContext: Ember.inject.service(),
    findRecord: function findRecord() {
      var url = "".concat(this.host, "/").concat(this.namespace, "/account/auth.whoami");
      return this.ajax(url, 'GET');
    }
  });

  _exports.default = _default;
});