define("propertycloud/pods/components/landlord-payments/input-amount/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    poundsPaid: 0,
    init: function init() {
      var row = this.row;
      Ember.set(this, 'poundsPaid', Number(Ember.get(row, 'Balance') / 100).toFixed(2));
      var poundsPaid = this.poundsPaid;
      Ember.set(row, 'paidAmount', poundsPaid * 100);

      this._super.apply(this, arguments);
    },
    actions: {
      setPaidAmount: function setPaidAmount() {
        var row = this.row;
        var poundsPaid = this.poundsPaid;
        var balance = Ember.get(row, 'Balance');

        if (poundsPaid * 100 > balance) {
          Ember.set(this, 'poundsPaid', balance);
          poundsPaid = Number(balance / 100).toFixed(2);
        }

        Ember.set(this, 'poundsPaid', poundsPaid);
        Ember.set(row, 'paidAmount', poundsPaid * 100);
      }
    }
  });

  _exports.default = _default;
});