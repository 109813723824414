define("propertycloud/pods/components/money-up/component", ["exports", "propertycloud/helpers/format-currency"], function (_exports, _formatCurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    format: function format(num) {
      return (0, _formatCurrency.formatCurrency)([num]);
    }
  });

  _exports.default = _default;
});