define("propertycloud/pods/logged-in/tenant/finances/controller", ["exports", "moment", "propertycloud/helpers/format-currency", "propertycloud/errorMessages", "propertycloud/helpers/calculate-total-rent-paid"], function (_exports, _moment, _formatCurrency, _errorMessages, _calculateTotalRentPaid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // @todo this whole thing needs refactoring, too many side effects in these functions and computed properties
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    queryParams: ['tenancy'],
    tenancy: null,
    isScheduleShowing: true,
    isMakePayment: false,
    isSuccessfulPayment: false,
    hasErrorPayment: false,
    isProcessingPayment: false,
    showBankDetails: true,
    paymentErrorMessage: _errorMessages.default.payments.unknown,
    stripeKey: Ember.computed('model.propertyDetails.agentId', function () {
      return Ember.get(this, 'agentContext.stripeKey');
    }),
    nextPaymentAmountPence: Ember.computed('nextPayment', 'nextPaymentAmount', function () {
      return Ember.get(this, 'nextPaymentAmount') * 100;
    }),
    paymentButtonLabel: Ember.computed('nextPaymentAmountPence', function () {
      var amount = (0, _formatCurrency.formatCurrency)([Ember.get(this, 'nextPaymentAmountPence'), {
        hideSign: true
      }]);
      return "Pay \xA3".concat(amount);
    }),
    sortedSchedule: Ember.computed.sort('model.propertySchedule', function (a, b) {
      var aDate = (0, _moment.default)(a.date);
      var bDate = (0, _moment.default)(b.date);

      if (aDate.isAfter(bDate)) {
        return 1;
      } else if (aDate.isSame(bDate)) {
        return 0;
      }

      return -1;
    }),
    sortedFullSchedule: Ember.computed.sort('model.propertySchedule', function (a, b) {
      var aDate = (0, _moment.default)(a.date);
      var bDate = (0, _moment.default)(b.date);

      if (aDate.isAfter(bDate)) {
        return 1;
      } else if (aDate.isSame(bDate)) {
        return 0;
      }

      return -1;
    }),
    totalRentPaid: Ember.computed('model.paymentHistory', function () {
      return (0, _calculateTotalRentPaid.default)(this.model.paymentHistory);
    }),
    calculateFinances: function calculateFinances() {
      var totalPaid = Ember.get(this, 'totalRentPaid');
      var schedule = Ember.get(this, 'sortedFullSchedule');
      var shouldHavePaid = 0;
      var overdueAmount = 0;
      var dueAmount = 0;

      for (var i = 0; i < schedule.length; i++) {
        // is date in past?
        if ((0, _moment.default)().isAfter((0, _moment.default)(schedule[i].date))) {
          shouldHavePaid += Number(schedule[i].payment.amount); // have they paid enough

          overdueAmount = shouldHavePaid - totalPaid;
        } else {
          // schedule date is in future, so we can stop
          dueAmount = Number(schedule[i].payment.amount);
          break;
        }
      }

      return {
        dueAmount: dueAmount,
        overdueAmount: overdueAmount
      };
    },
    nextPayment: Ember.computed('model.propertySchedule.@each', 'totalRentPaid', function () {
      return this.calculateFinances().dueAmount;
    }),
    overdueAmount: Ember.computed('model.propertySchedule.@each', 'totalRentPaid', function () {
      return this.calculateFinances().overdueAmount;
    }),

    /*
    build payment list with schedule, and compute total amount due
    */
    paymentList: Ember.computed('model.{paymentHistory,propertySchedule,agreement,propertySchedule.@each}', function () {
      var schedule = Ember.get(this, 'sortedSchedule');
      var paymentList = [];

      for (var i = 0; i < schedule.length; i++) {
        paymentList.push({
          date: schedule[i].date,
          amount: schedule[i].payment.amount,
          dueAmount: null,
          // amount to be paid, if different to scheduled amount
          paid: false,
          overdue: false
        });
      }

      return paymentList;
    }),

    /*
    compute total amount due
      */
    totalAmountDue: Ember.computed('model.{paymentHistory,propertySchedule,agreement,propertySchedule.@each}', function () {
      var schedule = Ember.get(this, 'sortedSchedule');
      var totalAmountDue = 0;

      for (var i = 0; i < schedule.length; i++) {
        totalAmountDue += Number(schedule[i].payment.amount);
      }

      return totalAmountDue;
    }),
    totalPaidToDate: Ember.computed('model.{paymentHistory,propertySchedule,agreement}', function () {
      var paymentHistory = Ember.get(this, 'model.paymentHistory');
      var totalPaidToDate = 0; // compute total paid

      if (typeof paymentHistory !== 'undefined') {
        paymentHistory.forEach(function (payment) {
          if (Ember.get(payment, 'paymentType') !== 'admin-fee' && Ember.get(payment, 'paymentType') !== 'deposit') {
            totalPaidToDate += Number(Ember.get(payment, 'amount.amount'));
          }
        });
      }

      return totalPaidToDate;
    }),

    /*
    update payment list with payment history and compute totalPaidToDate
    */
    applyHistoryToPaymentList: function applyHistoryToPaymentList() {
      var totalPaidToDate = Ember.get(this, 'totalPaidToDate'); // update paymentList with paymentHistory
      // if (totalPaidToDate > 0) {

      var paymentList = Ember.get(this, 'paymentList');
      var runningTotal = 0; // if totalPaid is >= than runningTotal --> mark as being paid and continue
      // else --> we know it hasn't been paid, and update the due amount, if there is one

      for (var i = 0; i < paymentList.length; i++) {
        runningTotal += Number(paymentList[i].amount);

        if (totalPaidToDate >= runningTotal) {
          Ember.set(paymentList[i], 'paid', true);
          Ember.set(paymentList[i], 'dueAmount', null);
        } else {
          var remainingAmount = runningTotal - totalPaidToDate;
          var dueAmount = Number(paymentList[i].amount); // set remaining amount as due amount if not equal (ie. some of the previous payment has eaten into the next)

          if (dueAmount !== remainingAmount) {
            dueAmount = remainingAmount;
            Ember.set(paymentList[i], 'dueAmount', dueAmount); // set overdue amount if date is in past

            if ((0, _moment.default)().isAfter((0, _moment.default)(paymentList[i].date))) {
              Ember.set(paymentList[i], 'overdue', true);
            }
          }

          Ember.set(this, 'nextPaymentAmount', dueAmount / 100); // exit loop

          break;
        } // }

      }

      Ember.set(this, 'totalPaidToDate', totalPaidToDate);
      var totalAmountDue = Ember.get(this, 'totalAmountDue');
      Ember.set(this, 'totalAmountDue', totalAmountDue - totalPaidToDate);
    },
    actions: {
      switchTabs: function switchTabs() {
        this.toggleProperty('isScheduleShowing');
      },
      togglePaymentState: function togglePaymentState() {
        this.toggleProperty('isMakePayment');
        Ember.set(this, 'isSuccessfulPayment', false);
      },
      processStripeToken: function processStripeToken(token) {
        var _this = this;

        Ember.set(this, 'isProcessingPayment', true);
        var tokenValue = token.id;
        var propertyId = Ember.get(this, 'model.propertyDetails.id');
        var request = Ember.get(this, 'ajax').post('/estate/property.make-payment', {
          data: JSON.stringify({
            payload: {
              propertyId: propertyId,
              payment: {
                amount: Ember.get(this, 'nextPaymentAmountPence'),
                currency: 'GBP'
              },
              paymentToken: tokenValue,
              paymentType: 'rent'
            }
          })
        });
        request.then(function () {
          Ember.set(_this, 'hasErrorPayment', false);
          Ember.set(_this, 'isProcessingPayment', false);
          Ember.set(_this, 'isSuccessfulPayment', true); // push payment to payment history model, and update the payment list

          var paymentHistory = Ember.get(_this, 'model.paymentHistory');

          var newPayment = _this.store.createRecord('tenant-payment', {
            amount: {
              amount: Ember.get(_this, 'nextPaymentAmountPence')
            },
            datePaymentMade: (0, _moment.default)().format('ddd MMM DD YYYY HH:mm:ss')
          });

          paymentHistory.pushObject(newPayment);
        });
        request.catch(function (_ref) {
          var sentryUuid = _ref.sentryUuid,
              payload = _ref.payload;
          Ember.set(_this, 'hasErrorPayment', true); // If any of the left conditions resolve to false or null, display a general error message. This way we still output the Sentry issue id

          var paymentError = payload && payload.payment_failure_code && _errorMessages.default.payments[payload.payment_failure_code] || _errorMessages.default.general;
          var message = paymentError;

          if (sentryUuid) {
            message += " Error id: ".concat(sentryUuid);
          }

          Ember.set(_this, 'paymentErrorMessage', message);
        });
      },
      switchTenancy: function switchTenancy(val) {
        this.transitionToRoute({
          queryParams: {
            tenancy: Ember.get(val, 'id')
          }
        });
      }
    }
  });

  _exports.default = _default;
});