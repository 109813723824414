define("propertycloud/pods/components/uploader/upload-button/component", ["exports", "@uppy/status-bar"], function (_exports, _statusBar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function toArray(list) {
    return Array.prototype.slice.call(list || [], 0);
  }

  var _default = Ember.Component.extend({
    classNameBindings: ['isDragOver', 'isDragDropSupported'],
    classNames: ['drag-drop-uploader'],
    init: function init() {
      this._super.apply(this, arguments);

      this.restrictions = this.uppy.opts.restrictions;
      var opts = {};
      var defaultLocale = {
        strings: {
          dropHereOr: 'Drop files here or %{browse}',
          browse: 'browse'
        }
      }; // Default options

      var defaultOpts = {
        target: null,
        inputName: 'files[]',
        width: '100%',
        height: '100%',
        note: null,
        locale: defaultLocale
      }; // Merge default options with the ones set by user

      this.opts = Object.assign({}, defaultOpts, opts);
      this.id = this.opts.id || 'UploadButton';
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.uppy.use(_statusBar.default, {
        target: '.drag-drop-uploader__progress',
        hideUploadButton: true,
        hideAfterFinish: true
      });
      this.uppy.on('complete', function (result) {
        var successful = result.successful;

        if (_this.restrictions.maxNumberOfFiles === 1) {
          Ember.set(_this, 'success', "".concat(successful[0].name, " was uploaded sucessfully!"));
        } else {
          Ember.set(_this, 'success', "".concat(successful.length, " file").concat(successful.length > 1 ? 's were' : ' was', " uploaded sucessfully!"));
        }
      });
    },
    handleDrop: function handleDrop(files) {
      var _this2 = this;

      Ember.set(this, 'error', null);
      Ember.set(this, 'success', null);
      files.forEach(function (file) {
        try {
          _this2.uppy.addFile({
            source: _this2.id,
            name: file.name,
            type: file.type,
            data: file
          });
        } catch (err) {
          Ember.set(_this2, 'error', err.toString().replace('Error: ', ''));
        }
      });
    },
    actions: {
      handleInputChange: function handleInputChange(ev) {
        var files = toArray(ev.target.files);
        this.handleDrop(files);
      }
    }
  });

  _exports.default = _default;
});