define("propertycloud/pods/components/overdue-rent-table/input-payment-date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1nxmimFg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"row\",\"selected\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ember-flatpickr\",null,[[\"onChange\",\"altInput\",\"date\",\"altFormat\",\"mode\",\"weekNumbers\",\"defaultDate\",\"altInputClass\"],[[28,\"action\",[[23,0,[]],\"setDate\",[24,[\"row\"]]],null],true,[28,\"readonly\",[[24,[\"row\",\"content\",\"paidDate\"]]],null],\"j F Y\",\"single\",true,[24,[\"paidDate\"]],\"form__control\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"input\",null,[[\"type\",\"class\",\"value\",\"disabled\"],[\"text\",\"form__control form__control--full\",[28,\"moment-format\",[[24,[\"paidDate\"]],\"D MMMM YYYY\"],null],[24,[\"row\",\"selected\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/overdue-rent-table/input-payment-date/template.hbs"
    }
  });

  _exports.default = _default;
});