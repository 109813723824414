define("propertycloud/pods/logged-in/viewings/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6CXmm3yP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"dashboard-columns dashboard-columns--no-bg issue-panel\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"dashboard-column issues__main-column issues__main-column--is-open\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"grid grid--padded\"],[8],[0,\"\\n      \"],[1,[28,\"viewing/viewing-grid\",null,[[\"model\"],[[24,[\"model\",\"viewings\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"grid grid--padded\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"block\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"block__header\"],[8],[0,\"Calendars\"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"block__content\"],[8],[0,\"\\n          \"],[7,\"p\",true],[8],[0,\"Below you can generate links to subscribe to calendars. These can be used in all popular calendar and email applications.\"],[9],[0,\"\\n\\n          \"],[1,[28,\"ical-link-generator\",null,[[\"staff\"],[[24,[\"model\",\"staff\"]]]]],false],[0,\"\\n\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/viewings/index/template.hbs"
    }
  });

  _exports.default = _default;
});