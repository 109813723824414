define("propertycloud/pods/components/error-messages/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    errorHandler: Ember.inject.service(),
    actions: {
      closeError: function closeError() {
        this.errorHandler.clearErrors();
      }
    }
  });

  _exports.default = _default;
});