define("propertycloud/pods/logged-in/finance/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yf5V3L+6",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Finance\"],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"app-internal-nav\"],[8],[0,\"\\n\"],[4,\"section-navigation\",null,[[\"name\"],[\"Finance\"]],{\"statements\":[[0,\"    \"],[7,\"ul\",true],[8],[0,\"\\n      \"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"route\"],[\"logged-in.finance.reports\"]],{\"statements\":[[0,\"Financial Reports\"]],\"parameters\":[]},null],[9],[0,\"\\n      \"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"route\"],[\"logged-in.finance.record\"]],{\"statements\":[[0,\"Record Income & Expenses\"]],\"parameters\":[]},null],[9],[0,\"\\n      \"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"route\"],[\"logged-in.finance.landlord-payments\"]],{\"statements\":[[0,\"Landlord Payments\"]],\"parameters\":[]},null],[9],[0,\"\\n      \"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"route\"],[\"logged-in.finance.landlord-statements\"]],{\"statements\":[[0,\"Landlord Statements\"]],\"parameters\":[]},null],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"app-internal-nav__outlet\"],[8],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/finance/template.hbs"
    }
  });

  _exports.default = _default;
});