define("propertycloud/pods/components/viewing/viewing-grid/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FPJ+JqIQ",
    "block": "{\"symbols\":[\"viewingsGroup\",\"viewing\"],\"statements\":[[4,\"each\",[[24,[\"viewingsGrouped\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"viewings-grid__col\"],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"block \",[28,\"if\",[[28,\"eq\",[[28,\"relative-time\",[[23,1,[\"value\"]]],null],\"Today\"],null],\"block--reverse\"],null]]]],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"block__header\"],[8],[7,\"span\",true],[8],[1,[23,1,[\"value\"]],false],[9],[0,\" \"],[7,\"small\",true],[8],[1,[28,\"relative-time\",[[23,1,[\"value\"]]],null],false],[9],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"block__content block__content--flush\"],[8],[0,\"\\n        \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[\"items\"]]],null,{\"statements\":[[0,\"            \"],[7,\"li\",true],[10,\"class\",\"issue-list__item\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"logged-in.viewings.detail\",[23,2,[\"id\"]]]],{\"statements\":[[0,\"                \"],[1,[28,\"viewing/viewing-card\",null,[[\"viewing\",\"hideStatus\"],[[23,2,[]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/viewing/viewing-grid/template.hbs"
    }
  });

  _exports.default = _default;
});