define("propertycloud/pods/logged-in/manage/property/viewings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8i916jtW",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"grid grid--padded\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"public-booking-details\"],[8],[0,\"\\n    \"],[7,\"a\",true],[11,\"href\",[22,\"publicBookingUrl\"]],[10,\"class\",\"btn\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[8],[0,\"Open booking page\"],[9],[0,\"\\n\"],[4,\"copy-button\",null,[[\"clipboardText\",\"success\",\"error\",\"class\"],[[24,[\"publicBookingUrl\"]],null,null,\"btn btn--positive\"]],{\"statements\":[[0,\"      Copy booking url\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[5,\"viewing/viewing-grid\",[],[[\"@viewings\"],[[23,1,[\"viewings\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/manage/property/viewings/template.hbs"
    }
  });

  _exports.default = _default;
});