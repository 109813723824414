define("propertycloud/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  var duration = 250;

  function _default() {
    this.transition(this.hasClass('left-to-right'), this.toValue(true), this.use('toLeft', {
      duration: duration
    }), this.reverse('toRight', {
      duration: duration
    }));
    this.transition(this.hasClass('right-to-left'), this.toValue(true), this.use('toRight', {
      duration: duration
    }), this.reverse('toLeft', {
      duration: duration
    }));
    this.transition(this.hasClass('add-to-tenancy'), this.toValue(function (to, from) {
      return to > from;
    }), this.use('toLeft', {
      duration: duration
    }), this.reverse('toRight', {
      duration: duration
    }));
    this.transition(this.hasClass('existing-tenancy'), this.toValue(function (to, from) {
      return to > from;
    }), this.use('explode', {
      pick: '.workspace-animate',
      use: ['toLeft', {
        duration: duration,
        easing: 'easeInOut'
      }]
    }, {
      pick: '.wizard-breadcrumb',
      use: ['crossFade', {
        duration: duration
      }]
    }), this.reverse('explode', {
      pick: '.workspace-animate',
      use: ['toRight', {
        duration: duration,
        easing: 'easeInOut'
      }]
    }, {
      pick: '.wizard-breadcrumb',
      use: ['crossFade', {
        duration: duration
      }]
    }));
    this.transition(this.fromRoute('logged-in.tenant'), this.use('toLeft', {
      duration: duration * 3
    }));
    var transitionInViewingsMaintenance = [{
      pick: '.issue-details',
      use: ['toRight', {
        duration: 1000,
        easing: 'easeInOut'
      }]
    }, {
      pick: '.issue-panel__update',
      use: ['toLeft', {
        duration: 1000,
        easing: 'easeInOut'
      }]
    }, {
      pick: '.issue-panel__empty',
      use: ['fade', {
        duration: 500
      }]
    }];
    var transitionOutViewingsMaintenance = [{
      pick: '.issue-details',
      use: ['toLeft', {
        duration: 1000,
        easing: 'easeInOut'
      }]
    }, {
      pick: '.issue-panel__update',
      use: ['toRight', {
        duration: 1000,
        easing: 'easeInOut'
      }]
    }, {
      pick: '.issue-panel__empty',
      use: ['fade', {
        duration: 1000
      }]
    }];
    this.transition(this.media('(min-width: 720px)'), this.toRoute('logged-in.maintenance.detail'), this.use.apply(this, ['explode'].concat(transitionInViewingsMaintenance)), this.reverse.apply(this, ['explode'].concat(transitionOutViewingsMaintenance)));
    this.transition(this.media('(min-width: 720px)'), this.toRoute('logged-in.viewings.detail'), this.use.apply(this, ['explode'].concat(transitionInViewingsMaintenance)), this.reverse.apply(this, ['explode'].concat(transitionOutViewingsMaintenance)));
    this.transition(this.media('(min-width: 720px)'), this.toRoute('logged-in.tenant.maintenance'), this.use('explode', {
      pick: '.issues__sidebar',
      use: ['toRight', {
        duration: 1000,
        easing: 'easeInOut'
      }]
    }, {
      pick: '.issue-panel__empty',
      use: ['fade', {
        duration: 1000
      }]
    }), this.reverse('explode', {
      pick: '.issues__sidebar',
      use: ['toLeft', {
        duration: 1000,
        easing: 'easeInOut'
      }]
    }, {
      pick: '.issue-panel__empty',
      use: ['fade', {
        duration: 100
      }]
    })); // this.transition(
    //   this.withinRoute(function(routeName) {
    //     return routeName.startsWith('logged-in.tenant');
    //   }),
    //   this.use('fade', { duration }),
    //   this.reverse('fade', { duration })
    // );
    // this.transition(
    //   this.fromRoute('logged-in.property.contracts'),
    //   this.toRoute('logged-in.property.tenants'),
    //   this.use('toUp', { duration }),
    //   this.reverse('toDown', { duration })
    // );
    // this.transition(
    //   this.fromRoute('logged-in.property.tenants'),
    //   this.toRoute('logged-in.property.details'),
    //   this.use('toDown', { duration }),
    //   this.reverse('toUp', { duration })
    // );
  }
});