define("propertycloud/adapters/contractor", ["exports", "propertycloud/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    findRecord: function findRecord(store, type, id, snapshot) {
      var url = "".concat(this.host, "/").concat(this.namespace, "/aas/contractor.details");
      var data = this.buildQuery(snapshot);
      data.contractorId = id;
      return this.ajax(url, 'GET', {
        data: data
      });
    }
  });

  _exports.default = _default;
});