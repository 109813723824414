define("propertycloud/pods/logged-in/manage/property/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel(model) {
      this.transitionTo('logged-in.manage.property.details', Ember.get(model, 'id'));
    }
  });

  _exports.default = _default;
});