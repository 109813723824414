define("propertycloud/pods/components/overdue-rent-table/date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lJ17g3r7",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"moment-from-now\",[[24,[\"value\"]]],[[\"hideAffix\"],[true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/overdue-rent-table/date/template.hbs"
    }
  });

  _exports.default = _default;
});