define("propertycloud/pods/components/viewing/viewing-card/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['issue-card', 'viewing-card'],
    viewingTime: Ember.computed('sort', function () {
      var date = Ember.get(this, 'viewing.preferredTime');

      if (this.sort) {
        var sort = this.sort;
        var sortDatePropertyMapping = {
          '-start': 'start',
          'start': 'start',
          '-requested-at': 'requestedAt',
          'requested-at': 'requestedAt',
          '-preferred-time': 'preferredTime',
          'preferred-time': 'preferredTime'
        };
        date = Ember.get(this, "viewing.".concat(sortDatePropertyMapping[sort]));
      } else if (Ember.get(this, 'viewing.start')) {
        date = Ember.get(this, 'viewing.start');
      }

      return (0, _moment.default)(date).format('h:mm A');
    })
  });

  _exports.default = _default;
});