define("propertycloud/pods/logged-in/tenant/my-details/controller", ["exports", "ember-cli-uuid", "propertycloud/validations/edit-tenant", "propertycloud/utilities/universities"], function (_exports, _emberCliUuid, _editTenant, _universities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    ValidationFields: _editTenant.default,
    init: function init() {
      this._super.apply(this, arguments); // Create a changeset object to map this model properties to.
      // This is a workaround to solve an issue with ember-changeset@1.4.1 nested validation properties. SM


      this.modelMap = this.modelMap || {};
    },
    // Map model properties to changeset object
    setModelMap: function setModelMap(tenant) {
      var mapObj = Ember.get(this, 'modelMap');
      Ember.set(mapObj, 'name', Ember.get(tenant, 'name'));
      Ember.set(mapObj, 'mobileNumber', Ember.get(tenant, 'mobileNumber'));
      Ember.set(mapObj, 'dateOfBirth', Ember.get(tenant, 'dateOfBirth'));
    },
    studentIdFile: null,
    photoIdImage: null,
    photoIdFile: null,
    isSaving: false,
    universities: _universities.default.universities,
    checkValidity: function checkValidity() {
      var fieldsWithError = document.querySelectorAll('.has-error');
      fieldsWithError.forEach(function (node) {
        node.classList.remove('has-error');
      });
      var flashMessages = Ember.get(this, 'flashMessages');
      flashMessages.clearMessages();
      var errors = 0;
      var model = Ember.get(this, 'model.tenant'); // have they entered a mobile number

      var phoneNumber = Ember.get(model, 'mobileNumber');

      if (Ember.isBlank(phoneNumber)) {
        errors++;
        document.querySelector('#phoneNumberField').classList.add('has-error');
        flashMessages.danger('Please add your phone number');
      } // have they selected a university


      var university = Ember.get(model, 'university');

      if (Ember.isBlank(university)) {
        errors++;
        document.querySelector('#universityField').classList.add('has-error');
        flashMessages.danger('Please select your university');
      } // have they entered photoIds


      var studentIdData = Ember.get(model, 'studentId.data');

      if (Ember.isBlank(studentIdData)) {
        errors++;
        document.querySelector('#studentIdField').classList.add('has-error');
        flashMessages.danger('Please add your Student ID');
      }

      var photoIdData = Ember.get(model, 'photoId.data');

      if (Ember.isBlank(photoIdData)) {
        errors++;
        document.querySelector('#photoIdField').classList.add('has-error');
        flashMessages.danger('Please add your Photo ID');
      }

      return errors;
    },
    actions: {
      saveData: function saveData() {
        var _this = this;

        Ember.set(this, 'isSaving', true);

        if (this.checkValidity() > 0) {
          Ember.set(this, 'isSaving', false);
          return;
        }

        var model = Ember.get(this, 'model.tenant');
        var errorHandler = Ember.get(this, 'errorHandler');

        if (Ember.get(model, 'studentId.data').match(/(^data:)/ig)) {
          var base64 = Ember.get(model, 'studentId.data');
          Ember.set(model, 'studentId', {
            'document-id': (0, _emberCliUuid.uuid)(),
            data: base64
          });
        }

        if (Ember.get(model, 'photoId.data').match(/(^data:)/ig)) {
          var _base = Ember.get(model, 'photoId.data');

          Ember.set(model, 'photoId', {
            'document-id': (0, _emberCliUuid.uuid)(),
            data: _base
          });
        }

        model.save().then(function () {
          errorHandler.handleSuccess();
          Ember.set(_this, 'isSaving', false);
        }).catch(function (error) {
          errorHandler.handleError(error);
          Ember.set(_this, 'isSaving', false);
        });
      },
      updateUniversity: function updateUniversity(value) {
        var model = Ember.get(this, 'model');
        Ember.set(model.tenant, 'university', value);
      }
    }
  });

  _exports.default = _default;
});