define("propertycloud/pods/components/upcoming-tenancies/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FzL8FCL3",
    "block": "{\"symbols\":[\"tenancy\",\"&default\"],\"statements\":[[14,2],[0,\"\\n\"],[4,\"if\",[[24,[\"applications\"]]],null,{\"statements\":[[0,\"  \"],[7,\"table\",true],[10,\"class\",\"datagrid\"],[8],[0,\"\\n    \"],[7,\"thead\",true],[10,\"class\",\"block__header\"],[8],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"th\",true],[8],[0,\"\\n          Property\\n        \"],[9],[0,\"\\n        \"],[7,\"th\",true],[10,\"style\",\"width: 320px;\"],[8],[0,\"\\n          Progress\\n        \"],[9],[0,\"\\n        \"],[7,\"th\",true],[10,\"style\",\"width: 170px; text-align: right;\"],[8],[0,\"\\n          Expires\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"datagrid-scroller\"],[8],[0,\"\\n    \"],[7,\"table\",true],[10,\"class\",\"datagrid\"],[8],[0,\"\\n      \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"applications\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"upcoming-tenancies/row\",null,[[\"tagName\",\"tenancy\"],[\"tr\",[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/upcoming-tenancies/template.hbs"
    }
  });

  _exports.default = _default;
});