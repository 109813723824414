define("propertycloud/pods/components/maintenance/issue-panel/update/component", ["exports", "ember-changeset", "ember-changeset-validations", "propertycloud/validations/assign-contractor"], function (_exports, _emberChangeset, _emberChangesetValidations, _assignContractor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    agentContext: Ember.inject.service(),
    isAssigning: false,
    supplierNote: null,
    assignErrorMessage: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.assignContractorChangeset = new _emberChangeset.default({
        contractor: null,
        contractorNote: null
      }, (0, _emberChangesetValidations.default)(_assignContractor.default), _assignContractor.default);
      var ajax = Ember.get(this, 'ajax');
      ajax.request('/aas/agent.list-contractors', {
        data: {
          agentId: Ember.get(this, 'agentContext.id'),
          include: 'contractors'
        }
      }).then(function (response) {
        var supplierArray = {
          data: []
        },
            ValidSuppliersArray = [];
        response.data.forEach(function (agreement) {
          if (agreement.attributes['agreement-accepted']) {
            response.included.forEach(function (contractor) {
              if ('contractor' === contractor.type && contractor.id === agreement.relationships.contractor.data.id) {
                supplierArray.data.push({
                  id: contractor.id,
                  type: 'contractor',
                  attributes: {
                    name: contractor.attributes.name
                  }
                });
                ValidSuppliersArray.push(_this.store.peekRecord('contractor', contractor.id));
              }
            });
          }
        });

        _this.store.push(supplierArray);

        Ember.set(_this, 'contractors', ValidSuppliersArray);
      });
    },
    selectedContractor: Ember.computed('issue.assignedContractor', {
      get: function get() {
        return Ember.get(this, 'issue.assignedContractor').then(function (contractor) {
          return contractor;
        });
      },
      set: function set(key, value) {
        Ember.set(this, 'issue.assignedContractor', value);
        return value;
      }
    }),
    selectedType: Ember.computed('issue.category', function () {
      var _this2 = this;

      return Ember.get(this, 'issueTypes').find(function (item) {
        if (item.value === Ember.get(_this2, 'issue.category')) {
          return item;
        }
      });
    }),
    issueTypes: Object.freeze([{
      value: 'plumbing',
      name: 'Plumbing'
    }, {
      value: 'gas',
      name: 'Gas'
    }, {
      value: 'electric',
      name: 'Electric'
    }, {
      value: 'roofing',
      name: 'Roofing'
    }, {
      value: 'decoration',
      name: 'Decoration'
    }, {
      value: 'furniture',
      name: 'Furniture'
    }, {
      value: 'whiteGoods',
      name: 'White Goods'
    }, {
      value: 'pestControl',
      name: 'Pest Control'
    }, {
      value: 'joinery',
      name: 'Joinery'
    }, {
      value: 'glazing',
      name: 'Glazing'
    }, {
      value: 'cleaning',
      name: 'Cleaning'
    }, {
      value: 'general',
      name: 'General'
    }, {
      value: 'internet',
      name: 'Internet'
    }, {
      value: 'security',
      name: 'Security'
    }]),
    selectedPriority: Ember.computed('issue.priority', function () {
      var _this3 = this;

      return Ember.get(this, 'issuePriorities').find(function (item) {
        if (item.value === Ember.get(_this3, 'issue.priority')) {
          return item;
        }
      });
    }),
    issuePriorities: Object.freeze([{
      value: 'low',
      name: 'Low'
    }, {
      value: 'medium',
      name: 'Medium'
    }, {
      value: 'high',
      name: 'High'
    }]),
    actions: {
      categoriseIssueType: function categoriseIssueType(option) {
        Ember.set(this, 'issue.category', Ember.get(option, 'value'));
        var ajax = Ember.get(this, 'ajax');
        ajax.post('/maintenance/issue.categorise', {
          data: JSON.stringify({
            payload: {
              propertyId: Ember.get(this, 'issue.property.id'),
              issueId: Ember.get(this, 'issue.id'),
              category: Ember.get(option, 'value')
            }
          })
        });
      },
      categoriseIssuePriority: function categoriseIssuePriority(option) {
        Ember.set(this, 'issue.priority', Ember.get(option, 'value'));
        var ajax = Ember.get(this, 'ajax');
        ajax.post('/maintenance/issue.rank', {
          data: JSON.stringify({
            payload: {
              propertyId: Ember.get(this, 'issue.property.id'),
              issueId: Ember.get(this, 'issue.id'),
              rank: Ember.get(option, 'value')
            }
          })
        });
      },
      assignIssue: function assignIssue() {
        var _this4 = this;

        Ember.set(this, 'isAssigning', true);
        Ember.set(this, 'issue.assigned', true);
        this.assignContractorChangeset.validate().then(function () {
          if (_this4.assignContractorChangeset.get('isValid')) {
            var ajax = Ember.get(_this4, 'ajax');
            var changeset = _this4.assignContractorChangeset;
            var request = ajax.post('/maintenance/issue.assign-to-supplier', {
              data: JSON.stringify({
                payload: {
                  propertyId: Ember.get(_this4, 'issue.property.id'),
                  issueId: Ember.get(_this4, 'issue.id'),
                  supplierId: Ember.get(changeset, 'contractor.id'),
                  note: Ember.get(changeset, 'contractorNote') || null
                }
              })
            });
            request.then(function () {
              Ember.set(_this4, 'isAssigning', false);
              Ember.set(_this4, 'supplierNote', null);
              Ember.set(_this4, 'assignErrorMessage', null);
            });
            request.catch(function (error) {
              var sentryUuid = error.sentryUuid;
              var message = 'There has been an error.';

              if (sentryUuid) {
                message += " Error id: ".concat(sentryUuid);
              }

              Ember.set(_this4, 'assignErrorMessage', message);
              Ember.set(_this4, 'isAssigning', false);
              Ember.set(_this4, 'issue.assigned', false);
            });
          } else {
            Ember.set(_this4, 'isAssigning', false);
            Ember.set(_this4, 'issue.assigned', false);
          }
        });
      },
      resolveIssue: function resolveIssue() {
        var _this5 = this;

        var ajax = Ember.get(this, 'ajax');
        Ember.set(this, 'issue.resolved', true);
        var request = ajax.post('/maintenance/issue.resolve', {
          data: JSON.stringify({
            payload: {
              propertyId: Ember.get(this, 'issue.property.id'),
              issueId: Ember.get(this, 'issue.id')
            }
          })
        });
        request.catch(function () {
          Ember.set(_this5, 'issue.resolved', false);
        });
      }
    }
  });

  _exports.default = _default;
});