define("propertycloud/pods/freestyle/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    termsContent: "\n\n## Mark down transformer\n\nWe use a markdown transformer here so the API can send the terms as an MD document, will also allow simple git version control of the terms documents\n\n  "
  });

  _exports.default = _default;
});