define("propertycloud/pods/logged-in/tenant/maintenance/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      var issueController = this.controllerFor('logged-in.tenant.maintenance');
      var currentTenancy = Ember.get(issueController, 'tenancy');
      var propertyId = Ember.get(currentTenancy, 'property.id');
      var tenancyNumber = Ember.get(currentTenancy, 'tenancyNumber');
      Ember.set(controller, 'propertyId', propertyId);
      Ember.set(controller, 'issueController', issueController);
      Ember.set(controller, 'tenancyNumber', tenancyNumber);

      this._super.apply(this, arguments);
    },
    activate: function activate() {
      Ember.run.schedule('afterRender', function () {
        document.querySelector('.issues__main-column').classList.add('is-open');
      });
    },
    deactivate: function deactivate() {
      document.querySelector('.issues__main-column').classList.remove('is-open');
    }
  });

  _exports.default = _default;
});