define("propertycloud/pods/components/file-manager/upload/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['file-manager__upload'],
    actions: {
      newFile: function newFile(file) {
        this.newFile(file);
      },
      fileError: function fileError(error) {
        this.fileError(error);
      }
    }
  });

  _exports.default = _default;
});