define("propertycloud/services/error-handler", ["exports", "ember-ajax/errors"], function (_exports, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    flashMessages: Ember.inject.service(),
    error: null,
    clearErrors: function clearErrors() {
      this.set('error', null);
    },
    handleSuccess: function handleSuccess() {
      this.clearErrors();
      var flashMessages = this.flashMessages;
      flashMessages.clearMessages();
      flashMessages.success('Save Successful', {
        sticky: false
      });
    },
    handleError: function handleError(error) {
      // determine whether it's a 'fake' error (like a 204), if it is, call the handleSuccess function
      if (typeof error.errors === 'undefined' && error.name === 'TypeError') {
        this.handleSuccess();
        return;
      }

      var messages = [];
      var errors = error.errors;

      if (typeof errors !== 'undefined' && errors.length) {
        for (var i = 0; i < errors.length; i++) {
          var detail = errors[i].detail;

          if (typeof detail !== 'undefined') {
            var description = detail.description;

            if (typeof description !== 'undefined') {
              messages.push(description);
            }
          }
        }

        if (messages.length < 1) {
          if ((0, _errors.isBadRequestError)(error)) {
            messages[0] = 'There was an error. Please check your data and try again.';
          } else if ((0, _errors.isUnauthorizedError)(error)) {
            messages[0] = 'There was an authentication error.';
          } else if ((0, _errors.isServerError)(error)) {
            messages[0] = 'There was a server error. Please try again.';
          } else {
            messages[0] = 'There was an error. Please check your data and try again.';
          }
        }

        this.set('error', messages);
        var flashMessages = this.flashMessages;

        for (var _i = 0; _i < messages.length; _i++) {
          flashMessages.danger(messages[_i]);
        }
      }
    }
  });

  _exports.default = _default;
});