define("propertycloud/serializers/landlord", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    serialize: function serialize(snapshot) {
      var payload = {
        id: snapshot.id,
        address: {
          flat: snapshot._attributes.address.flat === '' ? null : snapshot._attributes.address.flat,
          building: snapshot._attributes.address.building,
          street: snapshot._attributes.address.street === '' ? null : snapshot._attributes.address.street,
          area: snapshot._attributes.address.area === '' ? null : snapshot._attributes.address.area,
          town: snapshot._attributes.address.town === '' ? null : snapshot._attributes.address.town,
          county: snapshot._attributes.address.county === '' ? null : snapshot._attributes.address.county,
          postcode: snapshot._attributes.address.postcode
        },
        'photoId': null,
        'primary-contact': {
          name: snapshot._attributes.primaryContact.name,
          'email-address': snapshot._attributes.primaryContact['email-address'],
          'mobile-number': snapshot._attributes.primaryContact['mobile-number']
        },
        'secondary-contacts': snapshot._attributes.secondaryContacts
      };

      if (snapshot._attributes.bankAccount) {
        var bankAccountName = snapshot._attributes.bankAccount['account-name'];
        var bankAccountNumber = snapshot._attributes.bankAccount['account-number'];
        var bankAccountSortCode = snapshot._attributes.bankAccount['sort-code'];
        var bankAccount = null;

        if (bankAccountSortCode !== null && bankAccountNumber !== null) {
          bankAccount = {
            'account-name': bankAccountName,
            'account-number': bankAccountNumber,
            'sort-code': bankAccountSortCode
          };
        }

        payload['bank-account'] = bankAccount;
      }

      function hasNewPhotoId() {
        var photo = snapshot._attributes.photoId;

        if (Ember.isPresent(photo)) {
          return Ember.isPresent(photo['document-id']) && photo.data.match(/(^data:)/ig);
        }
      }

      function hasSamePhotoId() {
        var photo = snapshot._attributes.photoId;

        if (Ember.isPresent(photo)) {
          return Ember.isPresent(photo['document-id']) && Ember.isPresent(photo.data) && !photo.data.match(/(^data:)/ig);
        }
      }

      if (hasNewPhotoId()) {
        payload["photoId"] = {
          'documentId': snapshot._attributes.photoId['document-id'],
          'data': snapshot._attributes.photoId.data
        };
      }

      if (hasSamePhotoId()) {
        payload["photoId"] = {
          'documentId': snapshot._attributes.photoId['document-id'],
          'data': null
        };
      }

      return {
        payload: payload
      };
    }
  });

  _exports.default = _default;
});