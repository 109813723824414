define("propertycloud/components/fullwidth-tabs/tab-pane", ["exports", "ember-responsive-tabs/components/fullwidth-tabs/tab-pane"], function (_exports, _tabPane) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _tabPane.default;
    }
  });
});