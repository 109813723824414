define("propertycloud/pods/logged-in/manage/property/notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ulIdWeL7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"grid grid--padded\"],[8],[0,\"\\n  \"],[5,\"textarea\",[[12,\"class\",\"form__control\"]],[[\"@autoresize\",\"@value\"],[true,[24,[\"model\",\"notes\"]]]]],[0,\"\"],[9],[0,\"\\n\\n\"],[5,\"ember-wormhole\",[],[[\"@to\"],[\"floatingButtons\"]],{\"statements\":[[0,\"\\n  \"],[5,\"save-button\",[],[[\"@isSaving\",\"@saveSuccessful\",\"@save\"],[[22,\"isSaving\"],[22,\"saveSuccessful\"],[28,\"action\",[[23,0,[]],\"save\",[24,[\"model\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/manage/property/notes/template.hbs"
    }
  });

  _exports.default = _default;
});