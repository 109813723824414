define("propertycloud/pods/feedback/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['token', 'property'],
    token: null,
    property: null,
    ajax: Ember.inject.service(),
    issueFeedbackScore: 1,
    actions: {
      submitFeedback: function submitFeedback() {
        var _this = this;

        Ember.set(this, 'isSubmittingFeedback', true);
        var request = this.ajax.post('/maintenance/issue.leave-feedback', {
          contentType: 'application/json',
          data: JSON.stringify({
            payload: {
              issueId: Ember.get(this, 'params.issue'),
              propertyId: this.property,
              token: this.token,
              feedback: {
                score: parseInt(this.issueFeedbackScore),
                note: this.issueFeedbackNote
              }
            }
          })
        });
        request.catch(function () {
          Ember.set(_this, 'isSubmittingFeedback', false);
        });
        request.then(function () {
          Ember.set(_this, 'isSubmittingFeedback', false);
          Ember.set(_this, 'feedbackSubmitted', true);
        });
      }
    }
  });

  _exports.default = _default;
});