define("propertycloud/adapters/property-gallery", ["exports", "propertycloud/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // we want everything to use the host and namespace defined in the
  // application adapter so we extend from that instead
  var _default = _application.default.extend({
    findRecord: function findRecord(store, type, id) {
      var url = "".concat(this.host, "/").concat(this.namespace, "/estate/property.view-gallery");
      var data = {
        propertyId: id
      };
      return this.ajax(url, 'GET', {
        data: data
      });
    },
    updateRecord: function updateRecord() {// const url = `${this.get('host')}/${this.get('namespace')}/estate/property.update-address`;
      // const serializer = store.serializerFor(type.modelName);
      // let data = {};
      // // this is a funky function in that it modifes the data directly
      // // without needing to reassign a returned object
      // serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
      // return this.ajax(url, 'POST', { data });
    }
  });

  _exports.default = _default;
});