define("propertycloud/serializers/property-contract-template", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    formatProfileFromApi: function formatProfileFromApi(profile) {
      // update date to 0 index month
      // add unique identifier to each entry
      if (profile !== null) {
        for (var i = 0; i < profile.length; i++) {
          profile[i].date.month = profile[i].date.month - 1; // 0 index

          profile[i].identifier = "".concat(profile[i].date.day, "-").concat(profile[i].date.month, "-").concat(profile[i].date.year);
        }
      }

      return profile;
    },
    formatProfileToApi: function formatProfileToApi(profile) {
      // remove extra fields
      // reverse 0 indexing on month
      var profileFormatted = [];

      if (profile === null || profile === undefined || profile.length === 0) {
        return null;
      }

      if (profile !== null) {
        for (var i = 0; i < profile.length; i++) {
          profileFormatted.push({
            'percentage': profile[i].percentage,
            'date': {
              'month': profile[i].date.month + 1,
              'day': profile[i].date.day,
              'year': profile[i].date.year
            }
          });
        }
      }

      return profileFormatted;
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, type, payload) {
      // add years to dates
      // if end date month is before start date month, increment end date year
      var startDate = payload.data.attributes.tenancy.start;
      var endDate = payload.data.attributes.tenancy.end; // other way to do this?
      // "can't read property 'amount' of null" if we don't do something like this...

      var adminFee = payload.data.attributes.fees['admin-fee'];

      if (adminFee !== null) {
        adminFee = adminFee.amount;
      }

      var deposit = payload.data.attributes.fees.deposit;

      if (deposit !== null) {
        deposit = deposit.amount;
      }

      var advancedRent = payload.data.attributes.fees['advanced-rent'];

      if (advancedRent !== null) {
        advancedRent = advancedRent.amount;
      }

      return {
        data: {
          id: payload.data.id,
          type: type.modelName,
          attributes: {
            adminFee: adminFee,
            deposit: deposit,
            advancedRent: advancedRent,
            tenancyStart: startDate,
            tenancyEnd: endDate,
            yearDifference: payload.data.attributes.tenancy.yearDifference,
            monthlyProfile: this.formatProfileFromApi(payload.data.attributes.profiles.monthly),
            termlyProfile: this.formatProfileFromApi(payload.data.attributes.profiles['student-loan']),
            notes: payload.data.attributes.notes,
            address: payload.data.attributes.tenancy.property.address
          }
        }
      };
    },
    serialize: function serialize(snapshot) {
      // default dates to something sensible if null so api doesn't break...
      var _snapshot$_attributes = snapshot._attributes,
          tenancyStart = _snapshot$_attributes.tenancyStart,
          tenancyEnd = _snapshot$_attributes.tenancyEnd,
          yearDifference = _snapshot$_attributes.yearDifference;

      if (tenancyStart == null) {
        tenancyStart = {
          day: 1,
          month: 1
        };
      }

      if (tenancyEnd == null) {
        tenancyEnd = {
          day: 1,
          month: 2
        };
      }

      var payload = {
        'propertyId': snapshot.id,
        'fees': {
          'adminFee': {
            'amount': snapshot._attributes.adminFee,
            'currency': 'GBP'
          },
          'deposit': {
            'amount': snapshot._attributes.deposit,
            'currency': 'GBP'
          },
          'advancedRent': {
            'amount': snapshot._attributes.advancedRent,
            'currency': 'GBP'
          }
        },
        'tenancy': {
          'start': {
            'month': tenancyStart.month,
            'day': tenancyStart.day
          },
          'end': {
            'month': tenancyEnd.month,
            'day': tenancyEnd.day
          },
          yearDifference: yearDifference
        },
        'profiles': {
          'monthly': this.formatProfileToApi(snapshot._attributes.monthlyProfile),
          'student-loan': this.formatProfileToApi(snapshot._attributes.termlyProfile)
        },
        'notes': snapshot._attributes.notes
      };
      return {
        payload: payload
      };
    }
  });

  _exports.default = _default;
});