define("propertycloud/pods/components/add-workspace/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['isShown'],
    isShown: null,
    isAlertShown: null,
    workspaceContext: null,
    property: null,
    landlord: null,
    actions: {
      hideWorkspace: function hideWorkspace() {
        Ember.set(this, 'isShown', false);
        Ember.set(this, 'isAlertShown', false);
      },
      openAlert: function openAlert() {
        Ember.set(this, 'isAlertShown', true);
      },
      closeAlert: function closeAlert() {
        Ember.set(this, 'isAlertShown', false);
      }
    }
  });

  _exports.default = _default;
});