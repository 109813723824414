define("propertycloud/pods/components/guarantor-contract-form/component", ["exports", "propertycloud/mixins/form-with-errors", "ember-changeset", "ember-changeset-validations", "propertycloud/validations/register-guarantor", "moment", "ember-cli-uuid", "propertycloud/utilities/scrollTo", "propertycloud/errorMessages"], function (_exports, _formWithErrors, _emberChangeset, _emberChangesetValidations, _registerGuarantor, _moment, _emberCliUuid, _scrollTo, _errorMessages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formWithErrors.default, {
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    classNames: ['container', 'container--full-height', 'register-tenant'],
    ValidationFields: _registerGuarantor.default,
    flat: null,
    area: null,
    dateOfBirth: null,
    dateCurrentAddress: null,
    minAge: (0, _moment.default)().subtract(18, 'years').toDate(),
    proofIdImage: null,
    proofIdFile: null,
    proofAddressImage: null,
    proofAddressFile: null,
    homeowner: false,
    errorMessage: 'There was an error. Please check your input and try again.',
    model: null,
    propertyId: null,
    propertyAddress: null,
    isSuccessful: false,
    currentUser: null,
    init: function init() {
      this._super.apply(this, arguments);

      var model = Ember.get(this, 'model');
      this.changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_registerGuarantor.default), _registerGuarantor.default);
    },
    contractComponent: Ember.computed('agentContext.id', function () {
      var agentId = Ember.get(this, 'agentContext.id');
      var otherContractAgents = ['a1df372c-5c8d-43e1-b573-a5c7b302e930'];

      if (otherContractAgents.includes(agentId)) {
        return "guarantor-contract-".concat(agentId);
      } else {
        return 'guarantor-contract';
      }
    }),
    actions: {
      submit: function submit() {
        var _this = this;

        Ember.set(this, 'isLoading', true);
        Ember.set(this, 'errorMessage', 'There was an error. Please check your input and try again.');
        this.resetErrors();
        var propertyId = Ember.get(this, 'propertyId');
        var tenantId = Ember.get(this, 'model.tenantId');
        var tenancyNumber = Ember.get(this, 'model.tenancyNumber');
        var guarantorEmail = Ember.get(this, 'model.guarantorEmail');
        var token = Ember.get(this, 'model.token'); // format dates with moment

        var dateOfBirth = Ember.get(this, 'changeset.dateOfBirth');

        if (dateOfBirth) {
          dateOfBirth = (0, _moment.default)(dateOfBirth[0]).format('YYYY-MM-DD');
        }

        var dateCurrentAddress = Ember.get(this, 'changeset.dateCurrentAddress');

        if (dateCurrentAddress) {
          dateCurrentAddress = (0, _moment.default)(dateCurrentAddress[0]).format('YYYY-MM-DD');
        } // get image uploads


        var proofId = this.get('changeset.proofIdImage');
        var proofAddress = this.get('changeset.proofAddressImage');
        var isAuthenticated = Ember.get(this, 'session.isAuthenticated'); // run validations

        this.changeset.validate().then(function () {
          if (!_this.changeset.get('isValid')) {
            _this.reportErrorMessage('Please correct the validation errors.');

            Ember.set(_this, 'isLoading', false);
            return;
          }

          var payload = {
            data: JSON.stringify({
              payload: {
                propertyId: propertyId,
                tenantId: tenantId,
                tenancyNumber: tenancyNumber,
                guarantor: {
                  name: Ember.get(_this, 'changeset.fullName'),
                  relationToTenant: Ember.get(_this, 'changeset.relation'),
                  address: {
                    flat: Ember.get(_this, 'flat') === '' ? null : Ember.get(_this, 'flat'),
                    building: Ember.get(_this, 'changeset.building'),
                    street: Ember.get(_this, 'changeset.street'),
                    area: Ember.get(_this, 'area') === '' ? null : Ember.get(_this, 'area'),
                    town: Ember.get(_this, 'changeset.town') === '' ? null : Ember.get(_this, 'changeset.town'),
                    county: Ember.get(_this, 'changeset.county') === '' ? null : Ember.get(_this, 'changeset.county'),
                    postcode: Ember.get(_this, 'changeset.postcode')
                  },
                  mobile: Ember.get(_this, 'changeset.phoneNumber'),
                  dateOfBirth: dateOfBirth,
                  atAddressSince: dateCurrentAddress,
                  photoId: {
                    documentId: (0, _emberCliUuid.uuid)(),
                    data: proofId
                  },
                  proofOfAddress: {
                    documentId: (0, _emberCliUuid.uuid)(),
                    data: proofAddress
                  },
                  homeowner: Ember.get(_this, 'changeset.homeowner')
                }
              }
            }),
            headers: null
          };

          if (!isAuthenticated || _this.currentUser && (_this.currentUser.isTenant || _this.currentUser.isAgent)) {
            if (guarantorEmail && token) {
              var authHeader = "Basic ".concat(btoa("".concat(guarantorEmail, ":").concat(token)));
              payload.headers = {
                'Authorization': authHeader
              };
            }
          }

          var request = _this.ajax.post('/estate/property.agree-to-guarantee-tenant', payload);

          request.catch(function (response) {
            var sentryUuid = response.sentryUuid;
            var errCode = response.payload.error;
            var message = null;

            if (_errorMessages.default[errCode]) {
              message = _errorMessages.default[errCode];
            } else {
              message = _errorMessages.default.general;
            }

            if (sentryUuid) {
              message += " Error id: ".concat(sentryUuid);
            }

            _this.reportErrorMessage(message);

            Ember.set(_this, 'isSuccessful', false);
            Ember.set(_this, 'isLoading', false);
            Ember.set(_this, 'hasErrors', true);
          });
          request.then(function () {
            _this.resetErrors();

            Ember.set(_this, 'isSuccessful', true);
            Ember.set(_this, 'isLoading', false);
            Ember.set(_this, 'hasErrors', false);
            (0, _scrollTo.default)(document.body, 150, 300);
          });
        });
      }
    }
  });

  _exports.default = _default;
});