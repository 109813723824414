define("propertycloud/pods/components/ytd-finance-chart/component", ["exports", "propertycloud/chart-themes/flatdark", "propertycloud/helpers/format-currency", "papaparse", "moment"], function (_exports, _flatdark, _formatCurrency, _papaparse, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var chartOptions = {
    chart: {
      type: 'area',
      zoomType: 'x'
    },
    title: {
      text: 'Property Financials'
    },
    xAxis: {
      type: 'datetime'
    },
    yAxis: {
      labels: {
        formatter: function formatter() {
          this.value = this.value / 100;
          return "\xA3".concat(this.axis.defaultLabelFormatter.call(this));
        }
      }
    },
    tooltip: {
      formatter: function formatter() {
        if (Ember.get(this, 'grouped') === 'daily') {
          return "<b>".concat((0, _moment.default)(this.x, 'x').format('Do MMMM, YYYY'), "</b>: ").concat((0, _formatCurrency.formatCurrency)([this.y, {
            noResize: true
          }]));
        } else {
          return "<b>".concat((0, _moment.default)(this.x, 'x').format('MMMM, YYYY'), "</b>: ").concat((0, _formatCurrency.formatCurrency)([this.y, {
            noResize: true
          }]));
        }
      }
    },
    plotOptions: {
      series: {
        lineWidth: 2,
        borderWidth: 0,
        marker: {
          fillColor: '#fff',
          radius: 3,
          symbol: 'circle'
        }
      }
    }
  };

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    agentContext: Ember.inject.service(),
    // passed in options
    url: null,
    landlordId: null,
    // optional
    type: null,
    chartData: null,
    income: null,
    expenses: null,
    projected: null,
    grouped: 'daily',
    dateFormat: Ember.computed('grouped', function () {
      var grouped = Ember.get(this, 'grouped');

      if (grouped === 'daily') {
        return 'YYYY-MM-DD';
      } else if (grouped === 'monthly') {
        return 'YYYY-MM';
      } // This last return should never be called


      return 'YYYY-MM-DD';
    }),
    startDate: (0, _moment.default)().startOf('year').toDate(),
    endDate: (0, _moment.default)().endOf('year').toDate(),
    from: Ember.computed('startDate', function () {
      return (0, _moment.default)(Ember.get(this, 'startDate')).format(Ember.get(this, 'dateFormat'));
    }),
    to: Ember.computed('endDate', function () {
      return (0, _moment.default)(Ember.get(this, 'endDate')).format(Ember.get(this, 'dateFormat'));
    }),
    dateRange: Ember.computed('startDate', 'endDate', {
      get: function get() {
        var start = (0, _moment.default)(Ember.get(this, 'startDate')).format('YYYY-MM-DD');
        var end = (0, _moment.default)(Ember.get(this, 'endDate')).format('YYYY-MM-DD');
        return "".concat(start, " to ").concat(end);
      },
      set: function set(key, value) {
        if (value !== null) {
          var _value = _slicedToArray(value, 2),
              startDate = _value[0],
              endDate = _value[1];

          if (typeof endDate !== 'undefined') {
            Ember.set(this, 'startDate', startDate);
            Ember.set(this, 'endDate', endDate);
            this.setupData();
          }
        }

        return value;
      }
    }),
    chartOptions: chartOptions,
    theme: _flatdark.default,
    setupData: function setupData() {
      var _this = this;

      var ajax = Ember.get(this, 'ajax');
      var url = Ember.get(this, 'url');
      var type = Ember.get(this, 'type');
      var landlordId = Ember.get(this, 'landlordId');
      var propertyId = Ember.get(this, 'propertyId');
      var agentId = Ember.get(this, 'agentContext.id'); // data to send differs slightly between requests

      var data = {
        grouped: Ember.get(this, 'grouped'),
        from: Ember.get(this, 'from'),
        to: Ember.get(this, 'to')
      };
      var dataReal = Object.assign({
        agentId: agentId
      }, data);
      var dataProjected = Object.assign({}, data);

      if (type === 'agent') {
        dataProjected.agentId = agentId;
      }

      if (landlordId) {
        dataReal.landlordId = landlordId;
        dataProjected.landlordId = landlordId;
      }

      if (propertyId) {
        dataReal.propertyId = propertyId;
        dataProjected.propertyId = propertyId;
      }

      var promises = Ember.RSVP.hash({
        real: ajax.request(url, {
          dataType: 'text',
          data: dataReal
        }),
        projected: ajax.request('/estate/finance.projected-income', {
          dataType: 'text',
          data: dataProjected
        })
      });
      promises.then(function (data) {
        var incomeBuffer = [];
        var expensesBuffer = [];
        var totalIncome = 0;
        var totalExpenses = 0;
        var projectedBuffer = [];
        var totalProjected = 0;

        var projectedJson = _papaparse.default.parse(data.projected, {
          dynamicTyping: true,
          header: true,
          skipEmptyLines: true
        });

        var realJson = _papaparse.default.parse(data.real, {
          dynamicTyping: true,
          header: true,
          skipEmptyLines: true
        });

        projectedJson.data.forEach(function (element) {
          projectedBuffer.push([Number((0, _moment.default)(element.Date, 'YYYY-MM-DD').format('x')), element.Amount]);
          totalProjected += element.Amount;
        }, _this);
        realJson.data.forEach(function (element) {
          incomeBuffer.push([Number((0, _moment.default)(element.Date, 'YYYY-MM-DD').format('x')), element.Income]);
          expensesBuffer.push([Number((0, _moment.default)(element.Date, 'YYYY-MM-DD').format('x')), element.Expenditure]);
          totalIncome += element.Income;
          totalExpenses += element.Expenditure;
        }, _this);
        Ember.set(_this, 'totalIncome', totalIncome);
        Ember.set(_this, 'totalProjected', totalProjected);
        Ember.set(_this, 'totalExpenses', totalExpenses);
        Ember.set(_this, 'income', incomeBuffer);
        Ember.set(_this, 'projected', projectedBuffer);
        Ember.set(_this, 'expenses', expensesBuffer);
        Ember.set(_this, 'chartData', [{
          name: 'Income',
          data: Ember.get(_this, 'income'),
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [[0, 'rgba(25, 178, 149, 1)'], [1, 'rgba(25, 178, 149, 0)']]
          }
        }, {
          name: 'Expenses',
          data: Ember.get(_this, 'expenses'),
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [[0, 'rgba(0, 165, 255, 1)'], [1, 'rgba(0, 165, 255, 0)']]
          }
        }, {
          name: 'Scheduled',
          data: Ember.get(_this, 'projected'),
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [[0, 'rgba(115, 35, 147, 1)'], [1, 'rgba(115, 35, 147, 0)']]
          }
        }]);
      });
    },
    init: function init() {
      this.setupData();

      this._super.apply(this, arguments);
    },
    actions: {
      toggleGrouping: function toggleGrouping(group) {
        Ember.set(this, 'grouped', group);
        this.setupData();
      }
    }
  });

  _exports.default = _default;
});