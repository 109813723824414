define("propertycloud/pods/logged-in/manage/property/amenities/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iPpqiaIK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"grid grid--centred grid--padded\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid__row grid__row--tiny\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"grid__col\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"block\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"block__header\"],[8],[0,\"Extra Information\"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"block__content\"],[8],[0,\"\\n          \"],[1,[28,\"property-arbitrary-info\",null,[[\"items\",\"markChange\"],[[24,[\"model\",\"arbitraryInformation\"]],[28,\"action\",[[23,0,[]],\"setChanged\"],null]]]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"ember-wormhole\",null,[[\"to\"],[\"floatingButtons\"]],{\"statements\":[[0,\"  \"],[1,[28,\"save-button\",null,[[\"isSaving\",\"saveSuccessful\",\"save\"],[[24,[\"isSaving\"]],[24,[\"saveSuccessful\"]],[28,\"action\",[[23,0,[]],\"saveData\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/manage/property/amenities/template.hbs"
    }
  });

  _exports.default = _default;
});