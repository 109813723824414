define("propertycloud/pods/components/property-verify-tenancy/component", ["exports", "moment", "file-saver"], function (_exports, _moment, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    showDropdown: true,
    showSMS: false,
    showReplace: false,
    showRemoveTenant: false,
    progress: Ember.computed('tenantsList.[]', function () {
      var stages = [];
      Ember.get(this, 'tenantsList').forEach(function (stage) {
        stages.push(stage.signedContract);
        stages.push(stage.addedGuarantorInfo);
        stages.push(stage.guarantorAgreed);
        stages.push(stage.paidFirstPayment);
      });
      var stageLength = stages.length;
      var numberOfStagesThatAreComplete = 0;
      stages.forEach(function (stage) {
        if (stage) {
          numberOfStagesThatAreComplete = numberOfStagesThatAreComplete + 1;
        }
      });
      return numberOfStagesThatAreComplete / stageLength;
    }),
    allSigned: Ember.computed('tenantsList.[]', function () {
      var contracts = [];
      var tenants = Ember.get(this, 'tenantsList');
      var numberOfTenants = tenants.length;
      tenants.forEach(function (stage) {
        contracts.push(stage.signedContract);
      });
      var numberOfContractsThatAreComplete = 0;
      contracts.forEach(function (contract) {
        if (contract) {
          numberOfContractsThatAreComplete = numberOfContractsThatAreComplete + 1;
        }
      });
      return numberOfContractsThatAreComplete / numberOfTenants >= 1;
    }),
    firstThree: Ember.computed('tenantsList.[]', function () {
      var tenants = [];

      for (var i = 0; i < 3; i++) {
        if (typeof Ember.get(this, 'tenantsList')[i] !== 'undefined') {
          tenants.pushObject(Ember.get(this, 'tenantsList')[i]);
        }
      }

      return tenants;
    }),
    remaining: Ember.computed('tenantsList.[]', function () {
      var tenantsLength = Ember.get(this, 'tenantsList').length - 3;

      if (tenantsLength < 0) {
        tenantsLength = 0;
      }

      return tenantsLength;
    }),
    emails: Ember.computed('tenantsList.[]', function () {
      var emails = '';
      var tenants = Ember.get(this, 'tenantsList');
      tenants.forEach(function (tenant) {
        emails += "".concat(tenant.email, ";");
      });
      return emails;
    }),
    init: function init() {
      var _this = this;

      var model = Ember.get(this, 'model');
      var tenants = Ember.get(model, 'agreements.meta.tenants');
      var agreements = Ember.get(model, 'agreements');
      var contract = Ember.get(model, 'upcomingContract.href');
      Ember.set(this, 'tenantsList', []);
      tenants.forEach(function (tenant, index) {
        var id = tenant.id,
            name = tenant.name;
        var tenantAgreement = agreements.objectAt(index);
        var status = Ember.get(tenantAgreement, 'state.status');
        var start = Ember.get(tenantAgreement, 'start');
        var end = Ember.get(tenantAgreement, 'end');
        var isVoided = Ember.get(tenantAgreement, 'state.voided');
        var stages = Ember.get(tenantAgreement, 'state.metadata.stages');
        var email = Ember.get(tenantAgreement, 'state.metadata.stages.invitation.email');
        var mobile = Ember.get(tenantAgreement, 'state.metadata.stages.invitation.mobile-number');
        var approved = Ember.get(tenantAgreement, 'state.metadata.stages.agent-approval');
        var hideType = Ember.get(_this, 'hideType');
        var shouldHideTenant = false;

        if (hideType === 'unapproved' && !approved) {
          shouldHideTenant = true;
        }

        if (hideType === 'approved' && !!approved && (0, _moment.default)().isBefore((0, _moment.default)(start))) {
          shouldHideTenant = true;
        }

        if (!isVoided && status === 'upcoming' && !shouldHideTenant) {
          Ember.get(_this, 'tenantsList').pushObject({
            id: id,
            name: name,
            email: email,
            mobile: mobile,
            start: start,
            end: end,
            contract: contract,
            voided: false,
            signedContract: stages['accept-tenancy'],
            addedGuarantorInfo: stages['guarantor-info'],
            guarantorAgreed: stages['guarantor-agreement'],
            paidFirstPayment: stages['first-payment']
          });
        }
      });

      this._super.apply(this, arguments);
    },
    actions: {
      tenantSms: function tenantSms() {
        Ember.set(this, 'showSMS', true);
        Ember.set(this, 'showDropdown', false);
      },
      inviteGuarantor: function inviteGuarantor() {
        Ember.set(this, 'showInviteGuarantor', true);
        Ember.set(this, 'showDropdown', false);
      },
      tenantReplace: function tenantReplace() {
        Ember.set(this, 'showReplace', true);
        Ember.set(this, 'showDropdown', false);
      },
      tenantDetails: function tenantDetails() {
        Ember.set(this, 'showDetails', true);
        Ember.set(this, 'showDropdown', false);
      },
      tenantVoid: function tenantVoid() {
        Ember.set(this, 'showRemoveTenant', true);
        Ember.set(this, 'showDropdown', false);
      },
      reInviteTenant: function reInviteTenant() {
        Ember.set(this, 'showReInviteTenant', true);
        Ember.set(this, 'showDropdown', false);
      },
      resetDropdown: function resetDropdown() {
        Ember.set(this, 'showSMS', false);
        Ember.set(this, 'showReplace', false);
        Ember.set(this, 'showRemoveTenant', false);
        Ember.set(this, 'showDropdown', true);
        Ember.set(this, 'showDetails', false);
      },
      verifyTenancy: function verifyTenancy(model) {
        Ember.set(model, 'totalUnapprovedTenants', 0);
        var tenants = Ember.get(model, 'agreements.content.meta.tenants');
        var agreements = Ember.get(model, 'agreements');
        tenants.forEach(function (tenant, index) {
          var tenantAgreement = agreements.objectAt(index);
          var status = Ember.get(tenantAgreement, 'state.status');
          var isVoided = Ember.get(tenantAgreement, 'state.voided');

          if (!isVoided && status === 'upcoming') {
            Ember.set(tenantAgreement, 'state.metadata.stages.agent-approval', true);
          }
        });
        Ember.get(this, 'verify')(); // Pushes the tenancy to the pending tenants box and removes the current one.

        Ember.get(this, 'hideTenancy')();
        Ember.get(this, 'pendingTenancies').pushObject(model);
      },
      voidTenant: function voidTenant(tenant) {
        Ember.set(tenant, 'voided', true);
        Ember.get(this, 'voidTenant')({
          id: Ember.get(tenant, 'id'),
          tenancyNumber: Ember.get(this, 'tenancyNumber')
        });
      },
      voidTenancy: function voidTenancy(model) {
        model.deleteRecord();
        Ember.get(this, 'void')();
        model.set('isVoided', true);
      },
      tenantContract: function tenantContract(contractLink) {
        Ember.set(this, 'isGeneratingPdf', true);
        Ember.set(this, 'showDropdown', false);
        var that = this;
        var xhr = new XMLHttpRequest();

        xhr.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
            (0, _fileSaver.saveAs)(new Blob([this.response], {
              type: 'application/octet-stream'
            }), 'contract-draft.pdf');
            Ember.set(that, 'isGeneratingPdf', false);
            Ember.set(that, 'showDropdown', true);
          }
        };

        xhr.open('GET', contractLink);
        xhr.setRequestHeader('Accept', 'application/pdf');

        var _this$get = this.get('session.data.authenticated'),
            access_token = _this$get.access_token;

        if (Ember.isPresent(access_token)) {
          xhr.setRequestHeader('Authorization', "Bearer ".concat(access_token));
        }

        xhr.responseType = 'blob';
        xhr.send();
      }
    }
  });

  _exports.default = _default;
});