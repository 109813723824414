define("propertycloud/services/ajax", ["exports", "ember-ajax/services/ajax", "ember-ajax/errors", "propertycloud/config/environment", "jwt-decode", "@sentry/browser", "ember-cli-uuid"], function (_exports, _ajax, _errors, _environment, _jwtDecode, Sentry, _emberCliUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    host: _environment.default.APP.apiConfig.host,
    namespace: _environment.default.APP.apiConfig.namespace,
    headers: Ember.computed('session.data.authenticated.token', {
      get: function get() {
        var headers = {};

        var _this$get = this.get('session.data.authenticated'),
            access_token = _this$get.access_token;

        if (Ember.isPresent(access_token)) {
          headers.Authorization = "Bearer ".concat(access_token);
        }

        return headers;
      }
    }),
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var result = this._super.apply(this, arguments);

      if ((0, _errors.isAjaxError)(result)) {
        result.sentryUuid = (0, _emberCliUuid.uuid)();

        var _this$get2 = this.get('session.data.authenticated'),
            access_token = _this$get2.access_token;

        Sentry.configureScope(function (scope) {
          var decodedAccessToken = null;

          try {
            decodedAccessToken = (0, _jwtDecode.default)(access_token);
            scope.setExtra('userId', decodedAccessToken.sub);
          } catch (e) {
            scope.setExtra('accessToken', access_token);
          }

          scope.setTag('uuid', result.sentryUuid);
          scope.setExtra('requestData', requestData);

          if (typeof payload === 'string') {
            scope.setExtra('responseData', payload.substr(0, 100));
          } else if (payload) {
            var title = payload.title,
                error = payload.error,
                description = payload.description;

            if (error || description) {
              // A handled API response
              scope.setExtra('responseData', {
                error: error,
                description: description
              });
            } else if (title) {
              // An unhandled API response
              scope.setExtra('responseData', {
                title: title
              });
            } else {
              scope.setExtra('responseData', JSON.stringify(payload).substr(0, 100));
            }
          }
        });
      }

      if ((0, _errors.isBadRequestError)(result) || (0, _errors.isInvalidError)(result) || (0, _errors.isServerError)(result)) {
        Sentry.configureScope(function (scope) {
          scope.setLevel('error');
        });
        Sentry.captureMessage("API call failed with status code: ".concat(status));
      }

      if ((0, _errors.isUnauthorizedError)(result)) {
        // if the server tells us our JWT is expired invalidate the session
        var session = this.session;

        if (Ember.get(session, 'isAuthenticated')) {
          var _this$get3 = this.get('session.data.authenticated'),
              _access_token = _this$get3.access_token;

          Sentry.configureScope(function (scope) {
            scope.setExtra('token', (0, _jwtDecode.default)(_access_token));
          });
          Sentry.captureMessage('User was logged out by an unauthorized error from the server');
          session.invalidate();
        }
      }

      return result;
    }
  });

  _exports.default = _default;
});