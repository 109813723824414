define("propertycloud/pods/logged-in/maintenance/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    agentContext: Ember.inject.service(),
    model: function model(params) {
      var promises = {
        supplierAgreements: this.store.query('agent-contractor-agreement', {
          agentId: this.agentContext.id,
          include: 'contractor'
        }),
        categories: [{
          value: 'plumbing',
          name: 'Plumbing'
        }, {
          value: 'gas',
          name: 'Gas'
        }, {
          value: 'electric',
          name: 'Electric'
        }, {
          value: 'roofing',
          name: 'Roofing'
        }, {
          value: 'decoration',
          name: 'Decoration'
        }, {
          value: 'furniture',
          name: 'Furniture'
        }, {
          value: 'whiteGoods',
          name: 'White Goods'
        }, {
          value: 'pestControl',
          name: 'Pest Control'
        }, {
          value: 'joinery',
          name: 'Joinery'
        }, {
          value: 'glazing',
          name: 'Glazing'
        }, {
          value: 'cleaning',
          name: 'Cleaning'
        }, {
          value: 'general',
          name: 'General'
        }, {
          value: 'internet',
          name: 'Internet'
        }, {
          value: 'security',
          name: 'Security'
        }, {
          value: 'null',
          name: 'No Category'
        }]
      };

      if (params.property) {
        promises.selectedProperty = this.store.findRecord('property-detail', params.property);
      }

      return Ember.RSVP.hash(promises);
    }
  });

  _exports.default = _default;
});