define("propertycloud/pods/components/income-expenditure-table/chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fUV8TjQo",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"chart-wrap\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"chartData\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"high-charts\",null,[[\"content\",\"chartOptions\",\"theme\"],[[24,[\"chartData\"]],[24,[\"chartOptions\"]],[24,[\"theme\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[22,\"spinkit-cube-grid\"],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/income-expenditure-table/chart/template.hbs"
    }
  });

  _exports.default = _default;
});