define("propertycloud/pods/components/overdue-rent-table/name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FQAHFJKa",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"user\",\"isAgent\"]]],null,{\"statements\":[[0,\"  \"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"logged-in.manage.tenant\",[24,[\"row\",\"content\",\"id\"]]]],{\"statements\":[[1,[22,\"value\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[22,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/overdue-rent-table/name/template.hbs"
    }
  });

  _exports.default = _default;
});