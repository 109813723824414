define("propertycloud/pods/logged-in/manage/property/gallery/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var parentModel = this.modelFor('logged-in/manage/property');
      var propertyId = Ember.get(parentModel, 'id');
      return this.store.findRecord('property-gallery', propertyId, {
        include: 'images'
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var images = [];
      Ember.get(model, 'images').forEach(function (item) {
        images.push({
          w: Ember.get(item, 'width'),
          h: Ember.get(item, 'height'),
          src: Ember.get(item, 'src'),
          record: item
        });
      });
      Ember.set(controller, 'images', images);
    }
  });

  _exports.default = _default;
});