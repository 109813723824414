define("propertycloud/serializers/overdue-rent", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload) {
      payload.data = payload.data.map(function (item) {
        var obj = {
          id: item.id,
          type: 'overdue-rent',
          attributes: {
            'paid-amount': item.attributes['overdue-amount'].amount,
            'paid-date': new Date().toString(),
            'paid-method': 'chip-and-pin',
            'tenant-name': item.attributes.tenant.name,
            'amount-due': item.attributes['overdue-amount'].amount,
            'date-due': item.attributes['overdue-since'],
            'property-id': item.attributes.property['property-id'],
            'property-address': item.attributes.property.address
          }
        };
        return obj;
      });
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});