define("propertycloud/adapters/landlord-property-expenditure", ["exports", "propertycloud/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // we want everything to use the host and namespace defined in the
  // application adapter so we extend from that instead
  var _default = _application.default.extend({
    queryRecord: function queryRecord(store, type, query) {
      var url = "".concat(this.host, "/").concat(this.namespace, "/estate/landlord.view-property-expenditure");
      var data = query;
      return this.ajax(url, 'GET', {
        data: data
      });
    }
  });

  _exports.default = _default;
});