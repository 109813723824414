define("propertycloud/hotjar/main", ["exports", "propertycloud/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var hj = window.hj = window.hj || function () {
    (window.hj.q = window.hj.q || []).push(arguments);
  };

  function load(id, forceSSL, snippetVer) {
    setTimeout(function () {
      window._hjSettings = {
        hjid: id,
        hjsv: snippetVer ? snippetVer : 5
      };
      var prefix = '//';

      if (forceSSL) {
        prefix = 'https://';
      }

      var a = document.getElementsByTagName('head')[0];
      var r = document.createElement('script');
      r.async = 1;
      r.src = "".concat(prefix, "static.hotjar.com/c/hotjar-").concat(window._hjSettings.hjid, ".js?sv=").concat(window._hjSettings.hjsv);
      a.appendChild(r);
    }, 1);
  }

  if (_environment.default.hotjar && _environment.default.hotjar.id) {
    if ('enabled' in _environment.default.hotjar && !_environment.default.hotjar.enabled) {
      Ember.Logger.debug('Not running hotjar script, config.hotjar.enabled set to false');
    } else {
      load(_environment.default.hotjar.id, !!_environment.default.hotjar.forceSSL, _environment.default.hotjar.snippetVersion);
    }
  } else {
    throw new TypeError('Missing config/environment entry `config.hotjar.id`');
  }

  var _default = {
    create: function create() {
      return hj;
    }
  };
  _exports.default = _default;
});