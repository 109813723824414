define("propertycloud/pods/logged-in/maintenance/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NCZHwD71",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"dashboard-columns dashboard-columns--no-bg issue-panel\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"dashboard-column issues__main-column issues__main-column--is-open\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"issue-panel__empty\"],[8],[0,\"\\n      \"],[7,\"span\",true],[8],[0,\"Select an issue from the left\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/maintenance/index/template.hbs"
    }
  });

  _exports.default = _default;
});