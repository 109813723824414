define("propertycloud/components/pl-uploader", ["exports", "ember-plupload/components/pl-uploader", "propertycloud/config/environment"], function (_exports, _plUploader, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _plUploader.default.extend({
    BASE_URL: _environment.default.PLUPLOAD_BASE_URL || '/assets/'
  });

  _exports.default = _default;
});