define("propertycloud/utilities/normalizer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = normalize;

  function normalize(obj) {
    var stringHelper = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Ember.String.camelize;
    var cleanObject = obj;

    if (Ember.isArray(obj)) {
      cleanObject = [];
      var i = 0;

      while (i < obj.length) {
        cleanObject.push(normalize(obj[i], stringHelper));
        ++i;
      }
    } else if (Ember.typeOf(obj) === 'object') {
      cleanObject = {};

      for (var k in obj) {
        cleanObject[stringHelper(Ember.String.dasherize(k))] = normalize(obj[k], stringHelper);
      }
    }

    return cleanObject;
  }
});