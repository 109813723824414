define("propertycloud/pods/logged-in/manage/property/maintenance/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var dragulaConfig = {
    options: {
      copy: false,
      revertOnSpill: false,
      removeOnSpill: false,
      direction: 'horizontal',
      accepts: function accepts(el, target, source) {
        if (target.classList.contains('issue-board__list--active') || target === source) {
          return false;
        }

        return true;
      }
    },
    enabledEvents: ['drop']
  };

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    dragulaConfig: dragulaConfig,
    issues: Ember.computed.readOnly('model'),
    activeIssues: Ember.computed('model.@each', 'issues', function () {
      return Ember.get(this, 'issues').filterBy('assigned').filterBy('resolved', false);
    }),
    unassignedIssues: Ember.computed('model.@each', 'issues', function () {
      return Ember.get(this, 'issues').filterBy('assigned', false).filterBy('resolved', false);
    }),
    resolvedIssues: Ember.computed('model.@each', 'issues', function () {
      return Ember.get(this, 'issues').filterBy('resolved');
    }),
    actions: {
      drop: function drop(el) {
        var _this = this;

        // this.send('refreshModel');
        this.store.findRecord('issue', el.id).then(function (issue) {
          var ajax = Ember.get(_this, 'ajax');
          Ember.set(issue, 'resolved', true);
          var request = ajax.post('/maintenance/issue.resolve', {
            data: JSON.stringify({
              payload: {
                propertyId: Ember.get(issue, 'property.id'),
                issueId: Ember.get(issue, 'id')
              }
            })
          });
          request.catch(function () {
            Ember.set(issue, 'resolved', false);
          });
        });
      }
    }
  });

  _exports.default = _default;
});