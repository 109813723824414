define("propertycloud/pods/logged-in/guarantor/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isLoading: true,
    agreements: null,
    agreementsError: null
  });

  _exports.default = _default;
});