define("propertycloud/pods/application/controller", ["exports", "propertycloud/overrides/link-to", "propertycloud/overrides/date-fix"], function (_exports, _linkTo, _dateFix) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({});

  _exports.default = _default;
});