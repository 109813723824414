define("propertycloud/validators/mobile-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateMobileNumber;

  /* global intlTelInputUtils */
  function validateMobileNumber()
  /* options = {} */
  {
    return function (key, newValue) {
      // intlTelInputUtils is a global available because we're using ember-intl-tel-input
      var valid = intlTelInputUtils.isValidNumber(newValue);
      var numberType = intlTelInputUtils.getNumberType(newValue);
      var isLikelyMobile = false;
      var allowedTypes = [intlTelInputUtils.numberType.MOBILE, intlTelInputUtils.numberType.FIXED_LINE_OR_MOBILE, intlTelInputUtils.numberType.PAGER];

      if (allowedTypes.includes(numberType)) {
        isLikelyMobile = true;
      }

      if (valid && isLikelyMobile) {
        return true;
      }

      return "This doesn't look like a valid mobile number";
    };
  }
});