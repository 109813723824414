define("propertycloud/pods/components/payment-history/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['payment-history'],
    history: null,
    order: 'asc',
    updatedHistory: Ember.computed('history', function () {
      var history = Ember.get(this, 'history');

      if (typeof history !== 'undefined') {
        return history;
      }

      return {};
    }),
    sortDefinition: Ember.computed('order', function () {
      return ["date:".concat(Ember.get(this, 'order'))];
    }),
    sortedHistory: Ember.computed.sort('updatedHistory', 'sortDefinition'),
    actions: {
      switchOrder: function switchOrder() {
        var order = Ember.get(this, 'order');

        if (order === 'desc') {
          Ember.set(this, 'order', 'asc');
        } else {
          Ember.set(this, 'order', 'desc');
        }
      }
    }
  });

  _exports.default = _default;
});