define("propertycloud/helpers/address-with-breaks", ["exports", "propertycloud/helpers/address"], function (_exports, _address) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addressWithBreaks = addressWithBreaks;
  _exports.default = void 0;

  function addressWithBreaks(addressObj) {
    return (0, _address.address)(addressObj).replace(/,/g, ',<br>');
  }

  var _default = Ember.Helper.helper(addressWithBreaks);

  _exports.default = _default;
});