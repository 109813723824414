define("propertycloud/pods/components/guarantor-contract-a1df372c-5c8d-43e1-b573-a5c7b302e930/component", ["exports", "propertycloud/utilities/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    agentContext: Ember.inject.service(),
    classNames: ['contract-viewer'],
    durationInWeeks: Ember.computed('startDate', 'endDate', function () {
      var start = this.startDate;
      var end = this.endDate;
      return (0, _date.durationInWeeks)(start, end);
    }).readOnly()
  });

  _exports.default = _default;
});