define("propertycloud/pods/components/requires-attention-compliance/row/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    property: false,
    routeString: Ember.computed('property', function () {
      if (this.property) {
        return 'logged-in.manage.property';
      } else {
        return 'logged-in.manage.property.certificates';
      }
    }),
    withinTwoWeeks: Ember.computed('cert.ExpiryDate', function () {
      var expiry = Ember.get(this, 'cert.ExpiryDate');
      var date = (0, _moment.default)(expiry, 'YYYY-MM-DD');

      if ((0, _moment.default)(date).diff((0, _moment.default)(), 'days') <= 14) {
        return true;
      }

      return false;
    }),
    percentage: Ember.computed('cert.ExpiryDate', function () {
      var expiry = Ember.get(this, 'cert.ExpiryDate');
      var date = (0, _moment.default)(expiry, 'YYYY-MM-DD');
      var daysBeforeExpiry = 14 - (0, _moment.default)(date).diff((0, _moment.default)(), 'days');
      var percentage = daysBeforeExpiry / 14 * 100;

      if (percentage > 100) {
        percentage = 100;
      }

      return percentage;
    }),
    color: Ember.computed('percentage', function () {
      var percentage = this.percentage;

      if (percentage >= 80) {
        return '#de0c36';
      }

      return '#ff8212';
    }),
    isExpired: Ember.computed('cert.ExpiryDate', function () {
      var expiry = Ember.get(this, 'cert.ExpiryDate');
      var date = (0, _moment.default)(expiry, 'YYYY-MM-DD');

      if (date.isSameOrBefore((0, _moment.default)())) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});