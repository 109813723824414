define("propertycloud/serializers/property-metadatum", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.data.attributes['landlord-license'] === null) {
        payload.data.attributes['landlord-license'] = {
          number: null,
          startDate: null,
          endDate: null
        };
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    serialize: function serialize(snapshot) {
      var snap = snapshot._attributes;
      snap.propertyId = snapshot.id;

      if (snap.landlordLicense.number === null || snap.landlordLicense.number === '') {
        snap.landlordLicense = null;
      }

      return {
        payload: snap
      };
    }
  });

  _exports.default = _default;
});