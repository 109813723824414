define("propertycloud/serializers/property-certificates", ["exports", "@ember-data/serializer/json-api", "lodash/keyBy", "lodash/map"], function (_exports, _jsonApi, _keyBy2, _map2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    normalizeSingleResponse: function normalizeSingleResponse(store, type, payload) {
      return {
        data: {
          id: payload.data.id,
          type: type.modelName,
          attributes: {
            certificates: (0, _keyBy2.default)(payload.data.attributes.certificates, 'type')
          }
        }
      };
    },
    serialize: function serialize(snapshot) {
      var state = [];
      (0, _map2.default)(snapshot._attributes.certificates, function (obj, key) {
        if (obj['expiry-date'] !== null && obj['document-id'] !== null) {
          delete obj.src;
          obj.type = key;
          state.push(obj);
        }
      });
      var payload = {
        id: snapshot.id,
        certificates: state
      };
      return {
        payload: payload
      };
    }
  });

  _exports.default = _default;
});