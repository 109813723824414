define("propertycloud/pods/logged-in/manage/property/marketing/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KEGkECYE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"grid grid--padded grid--centred\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid__row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"grid__col\"],[8],[0,\"\\n\"],[4,\"marketing-form\",null,[[\"marketingInfo\",\"hasNecessaryFeatures\"],[[24,[\"model\",\"marketing\"]],[24,[\"hasNecessaryFeatures\"]]]],{\"statements\":[[4,\"if\",[[28,\"not\",[[24,[\"hasNecessaryFeatures\"]]],null]],null,{\"statements\":[[4,\"if\",[[28,\"not\",[[24,[\"propertyType\"]]],null]],null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[10,\"class\",\"warning-alert\"],[8],[0,\"\\n              You must set a property type in the features section before publishing.\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"not\",[[24,[\"numberOfBedrooms\"]]],null]],null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[10,\"class\",\"warning-alert\"],[8],[0,\"\\n              You must set the number of bedrooms in the features section before publishing.\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/manage/property/marketing/template.hbs"
    }
  });

  _exports.default = _default;
});