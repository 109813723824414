define("propertycloud/pods/components/contracts-table/tenants/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CA2rhCud",
    "block": "{\"symbols\":[\"item\",\"key\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"comma-list\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"-each-in\",[[24,[\"value\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[10,\"class\",\"comma-list__item\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/contracts-table/tenants/template.hbs"
    }
  });

  _exports.default = _default;
});