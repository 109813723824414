define("propertycloud/pods/components/overdue-rent-table/input-amount/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    poundsPaid: 0,
    init: function init() {
      var row = this.row;
      Ember.set(this, 'poundsPaid', Number(Ember.get(row, 'amountDue') / 100).toFixed(2));

      this._super.apply(this, arguments);
    },
    actions: {
      setPaidAmount: function setPaidAmount() {
        var row = this.row;
        var poundsPaid = this.poundsPaid;
        var amountDue = Ember.get(row, 'amountDue');

        if (poundsPaid * 100 > amountDue) {
          Ember.set(this, 'poundsPaid', amountDue);
          poundsPaid = Number(amountDue / 100).toFixed(2);
        }

        Ember.set(this, 'poundsPaid', poundsPaid);
        Ember.set(row, 'paidAmount', poundsPaid * 100);
      }
    }
  });

  _exports.default = _default;
});