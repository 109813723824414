define("propertycloud/helpers/relative-time", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.relativeTime = relativeTime;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function relativeTime(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        date = _ref2[0];

    date = (0, _moment.default)(date, ['MMMM Do, YYYY', 'YYYY-MM-DD']);

    if ((0, _moment.default)().diff(date, 'days') >= 1) {
      return date.from((0, _moment.default)().startOf('day')); // '2 days ago' etc.
    }

    return date.calendar().split(' ')[0];
  }

  var _default = Ember.Helper.helper(relativeTime);

  _exports.default = _default;
});