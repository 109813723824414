define("propertycloud/validations/create-tenancy", ["exports", "ember-changeset-validations/validators", "propertycloud/validators/mobile-number", "propertycloud/validators/checked", "propertycloud/validators/in-future"], function (_exports, _validators, _mobileNumber, _checked, _inFuture) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.paymenyScheduleValidations = _exports.tenantDocumentValidations = _exports.guarantorDocumentValidations = _exports.guarantorDetailsValidations = _exports.tenantDetailsValidations = _exports.contractValidations = _exports.propertyValidations = void 0;
  var uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  var propertyValidations = {
    selectedProperty: (0, _validators.validatePresence)(true)
  };
  _exports.propertyValidations = propertyValidations;
  var contractValidations = {
    contractDocumentId: (0, _validators.validateFormat)({
      regex: uuidRegex,
      message: 'You must upload a copy of the contract'
    }),
    from: (0, _validators.validatePresence)({
      presence: true,
      message: 'Start date must be set'
    }),
    to: [(0, _validators.validatePresence)({
      presence: true,
      message: 'End date must be set'
    }), (0, _inFuture.default)()],
    numberOfTenants: (0, _validators.validateNumber)({
      gt: 0,
      integer: true
    })
  };
  _exports.contractValidations = contractValidations;
  var tenantDetailsValidations = {
    name: (0, _validators.validatePresence)(true),
    mobileNumber: [(0, _validators.validatePresence)(true), (0, _mobileNumber.default)()],
    emailAddress: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      type: 'email'
    })],
    university: (0, _validators.validatePresence)(true),
    studentNumber: (0, _validators.validatePresence)(true)
  };
  _exports.tenantDetailsValidations = tenantDetailsValidations;
  var guarantorDetailsValidations = {
    name: (0, _validators.validatePresence)(true),
    mobileNumber: [(0, _validators.validatePresence)(true), (0, _mobileNumber.default)()],
    emailAddress: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      type: 'email'
    })],
    relationToTenant: (0, _validators.validatePresence)(true),
    dateOfBirth: (0, _validators.validatePresence)(true),
    isHomeOwner: (0, _checked.default)(true),
    atAddressSince: (0, _validators.validatePresence)(true),
    'address.building': (0, _validators.validatePresence)({
      presence: true,
      message: "Building is required"
    }),
    'address.street': (0, _validators.validatePresence)({
      presence: true,
      message: "Street is required"
    }),
    'address.postcode': [(0, _validators.validatePresence)({
      presence: true,
      message: "Postcode is required"
    }), (0, _validators.validateLength)({
      min: 5,
      message: 'Must be a valid postcode'
    })]
  };
  _exports.guarantorDetailsValidations = guarantorDetailsValidations;
  var guarantorDocumentValidations = {
    contractDocumentId: (0, _validators.validateFormat)({
      regex: uuidRegex,
      message: 'Please upload the guarantor contract'
    }),
    photoIdDocumentId: (0, _validators.validateFormat)({
      regex: uuidRegex,
      message: 'Please upload the guarantor photo ID'
    }),
    proofOfAddressDocumentId: (0, _validators.validateFormat)({
      regex: uuidRegex,
      message: 'Please upload the guarantor proof of address'
    })
  };
  _exports.guarantorDocumentValidations = guarantorDocumentValidations;
  var tenantDocumentValidations = {
    studentIdDocumentId: (0, _validators.validateFormat)({
      regex: uuidRegex,
      message: 'Please upload the tenant student ID'
    }),
    photoIdDocumentId: (0, _validators.validateFormat)({
      regex: uuidRegex,
      message: 'Please upload the tenant photo ID'
    })
  };
  _exports.tenantDocumentValidations = tenantDocumentValidations;
  var paymenyScheduleValidations = {
    payments: (0, _validators.validatePresence)(true)
  };
  _exports.paymenyScheduleValidations = paymenyScheduleValidations;
});