define("propertycloud/initializers/ember-hotjar", ["exports", "propertycloud/hotjar/main"], function (_exports, _main) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    var application = arguments[0];

    if (arguments.length === 2) {
      //for ember 1.x
      var container = arguments[0];
      application = arguments[1];
      container.options('hotjar:main');
    }

    var h = _main.default.create();

    application.register('hotjar:main', h, {
      instantiate: false
    });
    application.inject('controller', '_hj', 'hotjar:main');
    application.inject('route', '_hj', 'hotjar:main');
  }

  var _default = {
    name: 'ember-hotjar',
    initialize: initialize
  };
  _exports.default = _default;
});