define("propertycloud/pods/components/landlord-payments/property/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w3M1HnOd",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",null,[[\"route\",\"model\"],[\"logged-in.manage.property\",[24,[\"row\",\"content\",\"propertyId\"]]]],{\"statements\":[[1,[22,\"value\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/landlord-payments/property/template.hbs"
    }
  });

  _exports.default = _default;
});