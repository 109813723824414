define("propertycloud/pods/logged-in/tenant-view-schedule/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    model: function model() {
      return this.ajax.request('/estate/property.my-payment-schedule', {
        data: {
          propertyId: '5df03a1e-c281-4e2f-82f5-b633b54abd04'
        }
      });
    }
  });

  _exports.default = _default;
});