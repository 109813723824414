define("propertycloud/pods/components/sms-micro-interface/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sms: Ember.inject.service(),
    classNames: ['micro-sms-interface'],
    message: null,
    isSuccessful: false,
    isLoading: false,
    hasError: false,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'isSuccessful', false);
    },
    canSend: Ember.computed('message', function () {
      var msg = Ember.get(this, 'message');

      if (Ember.isEmpty(msg)) {
        return false;
      }

      return true;
    }),
    actions: {
      sendMessage: function sendMessage() {
        var _this = this;

        Ember.set(this, 'isLoading', true);
        var sms = Ember.get(this, 'sms');
        var type = Ember.get(this, 'type');
        var tenancyNumber = Ember.get(this, 'tenancyNumber');
        var id = Ember.get(this, 'sendToId');
        var message = Ember.get(this, 'message');
        var sendMessage = sms.sendMessage(type, id, message, tenancyNumber);
        sendMessage.then(function () {
          Ember.setProperties(_this, {
            message: null,
            isSuccessful: true,
            isLoading: false
          });
        });
        sendMessage.catch(function () {
          Ember.setProperties(_this, {
            isLoading: false,
            hasError: true
          });
        });
      }
    }
  });

  _exports.default = _default;
});