define("propertycloud/pods/components/maintenance/issue-panel/summary/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sms: Ember.inject.service(),
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    user: Ember.inject.service(),
    historySorting: Ember.computed(function () {
      return ['date:desc'];
    }),
    history: Ember.computed.sort('issue.history', 'historySorting'),
    issueFeedbackScore: 1,
    issueFeedbackNote: null,
    groupSms: false,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'note', null);
      Ember.set(this, 'isLoading', false);
      Ember.set(this, 'isSubmittingFeedback', false);
    },
    currentTenants: Ember.computed('issue', 'issue.currentTenancies', function () {
      var tenancies = Ember.get(this, 'issue.currentTenancies');
      var currentTenants = [];

      if (Ember.isPresent(Ember.get(this, 'issue.currentTenancies'))) {
        tenancies.forEach(function (tenancy) {
          var tenants = Ember.get(tenancy, 'agreements.content.meta.tenants');
          tenants.forEach(function (tenantTemp) {
            if (Ember.get(tenantTemp, 'agreement-type') === 'current' && Ember.get(tenantTemp, 'voided') === false) {
              currentTenants.push(tenantTemp);
            }
          });
        });
      }

      return currentTenants;
    }),
    actions: {
      addNote: function addNote() {
        var _this = this;

        var flashMessages = Ember.get(this, 'flashMessages');
        var ajax = Ember.get(this, 'ajax');
        Ember.set(this, 'isLoading', true);
        flashMessages.clearMessages();

        if (Ember.isBlank(Ember.get(this, 'note'))) {
          flashMessages.danger('Note cannot be empty');
          Ember.set(this, 'isLoading', false);
        } else {
          var propertyId = Ember.get(this, 'issue.property.id');
          var text = Ember.get(this, 'note');
          var request = ajax.post('/maintenance/issue.add-note', {
            data: JSON.stringify({
              payload: {
                propertyId: propertyId,
                issueId: Ember.get(this, 'issue.id'),
                text: text
              }
            })
          });
          var history = Ember.get(this, 'issue.history');
          var noteObj = {
            event: 'note-added',
            date: (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss'),
            text: text
          };
          history.pushObject(noteObj);
          request.then(function () {
            Ember.set(_this, 'isLoading', false);
            Ember.set(_this, 'note', null);
          });
          request.catch(function () {
            Ember.set(_this, 'isLoading', false);
            history.removeObject(noteObj);
          });

          if (Ember.get(this, 'groupSms')) {
            var sms = Ember.get(this, 'sms');
            sms.sendMessage('currentTenants', propertyId, text);
          }
        }
      },
      submitFeedback: function submitFeedback() {
        var _this2 = this;

        Ember.set(this, 'isSubmittingFeedback', true);
        var request = Ember.get(this, 'ajax').post('/maintenance/issue.leave-feedback', {
          data: JSON.stringify({
            payload: {
              issueId: Ember.get(this, 'issue.id'),
              propertyId: Ember.get(this, 'issue.property.id'),
              tenantId: Ember.get(this, 'user.userId'),
              feedback: {
                score: parseInt(Ember.get(this, 'issueFeedbackScore')),
                note: Ember.get(this, 'issueFeedbackNote')
              }
            }
          })
        });
        request.catch(function () {
          Ember.set(_this2, 'isSubmittingFeedback', false);
        });
        request.then(function () {
          Ember.set(_this2, 'isSubmittingFeedback', false);
          Ember.set(_this2, 'feedbackSubmitted', true);
        });
      }
    }
  });

  _exports.default = _default;
});