define("propertycloud/pods/components/global-search-result/component", ["exports", "propertycloud/utilities/domTraversal"], function (_exports, _domTraversal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'dd',
    classNames: ['global-search__result'],
    classNameBindings: ['isSelected'],
    selected: null,
    item: null,
    isSelected: Ember.computed('selected', 'item', function () {
      return Ember.get(this, 'selected') === Ember.get(this, 'item');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'keyboardPriority', 1);
    },
    getPositions: function getPositions() {
      var el = this.element;
      var gp = (0, _domTraversal.FindAncestor)(el, '.global-search__results-area');
      var titleHeight = (0, _domTraversal.FindSiblings)(el, '.global-search__title')[0].offsetHeight;
      var elRect = el.getBoundingClientRect(),
          gpRect = gp.getBoundingClientRect();
      var elDimensions = {
        top: elRect.top + document.body.scrollTop,
        left: elRect.left + document.body.scrollLeft
      };
      var gpDimensions = {
        top: gpRect.top + document.body.scrollTop,
        left: gpRect.left + document.body.scrollLeft
      };
      var pos = {
        elTop: elDimensions.top - gpDimensions.top - titleHeight,
        elHeight: el.offsetHeight,
        gpHeight: gp.offsetHeight - titleHeight
      };

      if (pos.elTop >= 0 && pos.elTop + pos.elHeight < pos.gpHeight) {
        pos.visible = true;
      } else {
        pos.visible = false;
      }

      return pos;
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      var el = this.element;
      var gp = (0, _domTraversal.FindAncestor)(el, '.global-search__results-area');

      if (Ember.get(this, 'isSelected')) {
        var pos = this.getPositions();
        var currentScroll = gp.scrollTop;
        var goingDown = currentScroll + pos.elTop - pos.gpHeight + pos.elHeight;
        var goingUp = currentScroll + pos.elTop;

        if (pos.visible === false) {
          var newScroll = Ember.get(this, 'direction') === 'ArrowDown' ? goingDown : goingUp;
          gp.scrollTop = newScroll;
        }
      }
    }
  });

  _exports.default = _default;
});