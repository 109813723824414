define("propertycloud/pods/components/sms-interface/textarea/component", ["exports", "ember-keyboard"], function (_exports, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextArea.extend(_emberKeyboard.EKMixin, _emberKeyboard.EKOnFocusMixin, {
    sendMessageHotkey: Ember.on((0, _emberKeyboard.keyDown)('Enter+cmd'), function () {
      var send = this.send;
      send();
    })
  });

  _exports.default = _default;
});