define("propertycloud/pods/components/validation-errors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n/Vw1R2W",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[4,\"if\",[[28,\"get\",[[24,[\"changeset\",\"error\"]],[24,[\"propertyName\"]]],null]],null,{\"statements\":[[4,\"each\",[[28,\"get\",[[28,\"get\",[[24,[\"changeset\",\"error\"]],[24,[\"propertyName\"]]],null],\"validation\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"form__error\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/validation-errors/template.hbs"
    }
  });

  _exports.default = _default;
});