define("propertycloud/pods/components/toggle-rent-reminders/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "smCQBlYa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"toggle-rent-reminders\"],[8],[0,\"\\n  \"],[7,\"label\",true],[10,\"class\",\"switch-light switch-material\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"toggleReminders\"],[[\"preventDefault\"],[false]]]],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[24,[\"willSend\"]]]]],false],[0,\"\\n\\n    \"],[7,\"strong\",true],[8],[0,\"\\n      Send Rent Reminders\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[7,\"span\",true],[8],[0,\"Off\"],[9],[0,\"\\n      \"],[7,\"span\",true],[8],[0,\"On\"],[9],[0,\"\\n      \"],[7,\"a\",true],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/toggle-rent-reminders/template.hbs"
    }
  });

  _exports.default = _default;
});