define("propertycloud/pods/components/maintenance/issue-progress/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    duration: Ember.computed('issue.{reportedDate,resolved,history.[]}', function () {
      var days = 0;
      var hours = 0;
      var minutes = 0;
      var today = (0, _moment.default)();
      var reportedDate = (0, _moment.default)(Ember.get(this, 'issue.reportedDate'), 'YYYY-MM-DD HH:mm:ss');
      var resolvedDate = null;
      var date = today;

      if (Ember.get(this, 'issue.resolved')) {
        if (Ember.isBlank(Ember.get(this, 'issue.history'))) {
          return 'Loading...';
        }

        resolvedDate = Ember.get(this, 'issue.history').find(function (item) {
          if (item.event === 'resolved') {
            return true;
          }
        });

        if (resolvedDate) {
          date = (0, _moment.default)(resolvedDate.date, 'YYYY-MM-DD HH:mm:ss');
        }
      }

      days = date.diff(reportedDate, 'days');
      hours = date.diff(reportedDate, 'hours');
      minutes = date.diff(reportedDate, 'minutes');

      if (days > 0) {
        return "".concat(days, " Day").concat(days !== 1 ? 's' : '');
      } else if (hours > 0) {
        return "".concat(hours, " Hour").concat(hours !== 1 ? 's' : '');
      } else if (minutes > 0) {
        return "".concat(minutes, " Minute").concat(minutes !== 1 ? 's' : '');
      }

      return;
    }),
    progress: Ember.computed('issue.{resolved,assigned}', function () {
      var resolved = Ember.get(this, 'issue.resolved');
      var assigned = Ember.get(this, 'issue.assigned');

      if (resolved) {
        return {
          text: 'Resolved',
          float: 1,
          color: '#49D186'
        };
      }

      if (assigned) {
        return {
          text: 'Assigned & In Progress',
          float: 0.5,
          color: '#F39F1D'
        };
      }

      return {
        text: 'Reported',
        float: 0.1,
        color: '#F3591D'
      };
    }),
    changeColor: function changeColor(state, bar) {
      bar.path.setAttribute('stroke', state.color);
    }
  });

  _exports.default = _default;
});