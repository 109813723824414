define("propertycloud/validations/replace-tenant", ["exports", "ember-changeset-validations/validators", "propertycloud/validators/mobile-number"], function (_exports, _validators, _mobileNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    nameReplace: (0, _validators.validatePresence)(true),
    mobileNumberReplace: [(0, _validators.validatePresence)(true), (0, _mobileNumber.default)()],
    emailReplace: [(0, _validators.validateFormat)({
      type: 'email'
    })],
    dateFrom: [(0, _validators.validatePresence)(true)],
    schedule: (0, _validators.validatePresence)(true),
    adminFee: [(0, _validators.validatePresence)({
      presence: true,
      message: 'Admin Fee is required'
    }), (0, _validators.validateNumber)({
      gte: 0
    })],
    deposit: [(0, _validators.validatePresence)({
      presence: true,
      message: 'Deposit is required'
    }), (0, _validators.validateNumber)({
      gte: 0
    })],
    advancedRent: [(0, _validators.validatePresence)({
      presence: true,
      message: 'Initial payment is required'
    }), (0, _validators.validateNumber)({
      gte: 0
    })],
    contractualMonthlyRent: [(0, _validators.validatePresence)({
      presence: true,
      message: 'Monthly rent is required'
    }), (0, _validators.validateNumber)({
      gt: 0
    })]
  };
  _exports.default = _default;
});