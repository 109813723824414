define("propertycloud/pods/logged-in/viewings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wzOAcEdY",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Viewings\"],null],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"dashboard-columns issues\"],[8],[0,\"\\n\\n  \"],[7,\"section\",true],[10,\"class\",\"dashboard-column dashboard-column--sidebar issues__sidebar\"],[8],[0,\"\\n    \"],[1,[28,\"viewing/viewing-list\",null,[[\"viewings\",\"totalResults\",\"totalPages\",\"page\",\"selectedProperty\",\"staffList\",\"loadMore\",\"sort\",\"loadingViewings\",\"scheduled\",\"start\",\"property\",\"userId\"],[[24,[\"viewings\"]],[24,[\"totalResults\"]],[24,[\"totalPages\"]],[24,[\"page\"]],[24,[\"model\",\"selectedProperty\"]],[24,[\"model\",\"staff\"]],[28,\"action\",[[23,0,[]],\"loadMore\"],null],[24,[\"sort\"]],[24,[\"loadingViewings\"]],[24,[\"scheduled\"]],[24,[\"start\"]],[24,[\"property\"]],[24,[\"userId\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"section\",true],[10,\"class\",\"dashboard-column issues__main-column\"],[8],[0,\"\\n    \"],[1,[22,\"liquid-outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/viewings/template.hbs"
    }
  });

  _exports.default = _default;
});