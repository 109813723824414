define("propertycloud/pods/components/contracts-table/toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S4We5+WR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",false],[12,\"class\",\"row-toggle\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],[28,\"toggle\",[\"expanded\",[24,[\"row\"]]],null]]],[8],[0,\"\\n  \"],[7,\"i\",true],[11,\"class\",[29,[\"fa \",[28,\"if\",[[24,[\"row\",\"expanded\"]],\"fa-chevron-down\",\"fa-chevron-right\"],null]]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/contracts-table/toggle/template.hbs"
    }
  });

  _exports.default = _default;
});