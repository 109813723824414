define("propertycloud/pods/components/add-workspace-tenancy/tenant-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "97A269yX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"agreement\",\"completed\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"tenant-card__details\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"tenant-card__name\"],[8],[0,\"\\n      \"],[1,[24,[\"agreement\",\"tenantDetails\",\"name\"]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"editable\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\",false],[12,\"class\",\"false-link\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"editAgreement\"]],[8],[0,\"Edit Tenant\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"tenant-card__email\"],[8],[0,\"\\n      \"],[1,[24,[\"agreement\",\"tenantDetails\",\"emailAddress\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"tenant-card__finance\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"tenant-card__total\"],[8],[0,\"\\n      \"],[1,[28,\"format-currency\",[[24,[\"total\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"tenant-card__payments\"],[8],[0,\"\\n      over \"],[1,[24,[\"agreement\",\"paymentSchedule\",\"payments\",\"length\"]],false],[0,\" payment\"],[1,[28,\"if\",[[28,\"not-eq\",[[24,[\"agreement\",\"paymentSchedule\",\"payments\",\"length\"]],1],null],\"s\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"tenant-card__details tenant-card__details--empty\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn--positive btn--small\"],[3,\"action\",[[23,0,[]],\"editAgreement\"]],[8],[0,\"Add Tenant\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/add-workspace-tenancy/tenant-row/template.hbs"
    }
  });

  _exports.default = _default;
});