define("propertycloud/pods/components/uploader/link-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zx0LOzlU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n\"],[7,\"label\",true],[10,\"class\",\"link-uploader__browse\"],[8],[0,\"\\n\\n  \"],[7,\"input\",true],[10,\"class\",\"hidden-input\"],[11,\"name\",[23,0,[\"opts\",\"inputName\"]]],[11,\"multiple\",[28,\"not\",[[23,0,[\"restrictions\",\"maxNumberOfFiles\"]],1],null]],[11,\"accept\",[23,0,[\"restrictions\",\"allowedFileTypes\"]]],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"handleInputChange\"],null]],[10,\"value\",\"\"],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\\n  \"],[7,\"span\",true],[10,\"class\",\"drag-drop-uploader__label\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"success\"]]],null,{\"statements\":[[0,\"      \"],[1,[22,\"success\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/uploader/link-upload/template.hbs"
    }
  });

  _exports.default = _default;
});