define("propertycloud/serializers/tenant-contract-parameter", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    normalizeSingleResponse: function normalizeSingleResponse(store, type, payload) {
      var adminFee = payload.data.attributes['admin-fee'];

      if (adminFee !== null) {
        adminFee = adminFee.amount;
      }

      var deposit = payload.data.attributes.deposit;

      if (deposit !== null) {
        deposit = deposit.amount;
      }

      var advancedRent = payload.data.attributes['advanced-rent'];

      if (advancedRent !== null) {
        advancedRent = advancedRent.amount;
      }

      return {
        data: {
          id: payload.data.id,
          type: 'tenant-contract-parameter',
          attributes: {
            adminFee: adminFee,
            advancedRent: advancedRent,
            deposit: deposit,
            tenancyStart: payload.data.attributes['start-date'],
            tenancyEnd: payload.data.attributes['end-date'],
            notes: payload.data.attributes.notes,
            tenants: payload.data.attributes.tenants,
            billsIncluded: payload.data.attributes['bills-included'],
            monthlyCost: payload.data.attributes['monthly-cost'].amount,
            landlord: payload.data.attributes.landlord,
            property: payload.data.attributes.property,
            profiles: payload.data.attributes.profiles,
            agent: payload.data.attributes.agent // relationships: {
            //   landlord: {
            //     data: {
            //       type: 'landlord',
            //       id: payload.data.relationships.landlord.data.id
            //     }
            //   },
            //   property: {
            //     data: {
            //       type: 'property-detail', // use this model, not 'property'
            //       id: payload.data.relationships.property.data.id
            //     }
            //   }
            // }

          }
        }
      };
    }
  });

  _exports.default = _default;
});