define("propertycloud/pods/components/upcoming-tenancies/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "86eHjYdg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"lte\",[[24,[\"tenancy\",\"percentageComplete\"]],100],null]],null,{\"statements\":[[0,\"  \"],[7,\"td\",true],[10,\"class\",\"upcoming-tenancies__address\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"logged-in.manage.property.tenants\",[24,[\"tenancy\",\"property\",\"id\"]]]],{\"statements\":[[0,\"      \"],[1,[24,[\"tenancy\",\"property\",\"address\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"td\",true],[10,\"style\",\"width: 320px;\"],[10,\"class\",\"upcoming-tenancies__progress\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"gte\",[[24,[\"tenancy\",\"percentageComplete\"]],100],null]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"btn btn--ghost btn--tiny btn--block\",\"logged-in.manage.property.tenants\",[24,[\"tenancy\",\"property\",\"id\"]]]],{\"statements\":[[0,\"        Review & Verify Tenancy\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"ember-progress-bar\",null,[[\"progress\",\"options\"],[[28,\"div\",[[24,[\"tenancy\",\"percentageComplete\"]],100],null],[28,\"hash\",null,[[\"strokeWidth\",\"color\",\"trailColor\",\"trailWidth\"],[4,\"#ff8212\",\"#eee\",4]]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"td\",true],[10,\"style\",\"width: 170px; text-align: right;\"],[8],[4,\"if\",[[24,[\"tenancy\",\"deadlineForCompletion\"]]],null,{\"statements\":[[1,[28,\"moment-from-now\",[[24,[\"tenancy\",\"deadlineForCompletion\"]]],[[\"interval\"],[1000]]],false]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/upcoming-tenancies/row/template.hbs"
    }
  });

  _exports.default = _default;
});