define("propertycloud/pods/logged-in/tenant/maintenance/detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uofe278D",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"dashboard-panel\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"dashboard-panel__inner dashboard-panel__inner--flush dashboard-panel__inner--no-bg\"],[8],[0,\"\\n    \"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn--neutral dashboard-panel__mobile-closer\",\"logged-in.tenant.maintenance\"]],{\"statements\":[[0,\"Close\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[28,\"maintenance/issue-panel\",null,[[\"model\",\"tenantView\"],[[24,[\"model\"]],true]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/tenant/maintenance/detail/template.hbs"
    }
  });

  _exports.default = _default;
});