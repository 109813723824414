define("propertycloud/templates/components/mail-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Iqc05rWk",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input\",null,[[\"type\",\"name\",\"value\",\"placeholder\",\"class\",\"focus-out\",\"disabled\",\"required\",\"title\"],[[24,[\"type\"]],[24,[\"name\"]],[24,[\"value\"]],[24,[\"placeholder\"]],[24,[\"inputClass\"]],\"mailcheck\",[24,[\"disabled\"]],[24,[\"required\"]],[24,[\"title\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"hint\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"mailcheck-hint\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"suggestion\"]]],null,{\"statements\":[[0,\"      \"],[1,[22,\"hint\"],false],[0,\" \"],[7,\"a\",false],[12,\"href\",\"#!\"],[3,\"action\",[[23,0,[]],\"useSuggestion\"]],[8],[1,[22,\"suggestion\"],false],[9],[0,\"?\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[22,\"hint\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/templates/components/mail-check.hbs"
    }
  });

  _exports.default = _default;
});