define("propertycloud/mixins/form-with-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isLoading: false,
    errorMessages: Ember.computed.alias('errorBuffer'),
    errorBuffer: [],
    reportErrorMessage: function reportErrorMessage(message) {
      // this did not work properly without ibeing aliased to another computed property.
      // no idea why.
      // sorry future me.
      var errorBuffer = this.errorBuffer;
      errorBuffer.pushObject(message);
      Ember.set(this, 'isLoading', false);
    },
    resetErrors: function resetErrors() {
      Ember.set(this, 'errorBuffer', []);
    }
  });

  _exports.default = _default;
});