define("propertycloud/pods/logged-in/manage/property/maintenance/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var parentModel = this.modelFor('logged-in/manage/property');
      var propertyId = Ember.get(parentModel, 'id');
      return this.store.query('issue', {
        propertyId: propertyId
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.model();
      }
    }
  });

  _exports.default = _default;
});