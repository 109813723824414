define("propertycloud/pods/logged-in/landlord/properties/details/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var propertyId = params.property_id;
      var tenancyTypes = 'current';
      return Ember.RSVP.hash({
        details: this.store.findRecord('property-detail', propertyId),
        currentTenancies: this.store.query('tenancy', {
          propertyId: propertyId,
          tenancyTypes: tenancyTypes
        }),
        gallery: this.store.findRecord('property-gallery', propertyId, {
          include: 'images'
        }),
        certificates: this.store.findRecord('property-certificates', propertyId)
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var images = [];
      var modelImages = Ember.get(model, 'gallery.images');
      modelImages.forEach(function (item) {
        images.push({
          w: Ember.get(item, 'width'),
          h: Ember.get(item, 'height'),
          src: Ember.get(item, 'src')
        });
      });
      Ember.set(controller, 'images', images); // controller.updateFinanceTable();
    }
  });

  _exports.default = _default;
});