define("propertycloud/utilities/flatpickrMonthSelect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = monthSelectPlugin;

  // TODO: This file should be replaced with the flatpickr dependency when there is a fix for: https://github.com/flatpickr/flatpickr/issues/2074
  var _assign = function __assign() {
    _assign = Object.assign || function __assign(t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];

        for (var p in s) {
          if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
        }
      }

      return t;
    };

    return _assign.apply(this, arguments);
  };

  var monthToStr = function monthToStr(monthNumber, shorthand, locale) {
    return locale.months[shorthand ? "shorthand" : "longhand"][monthNumber];
  };

  var defaultConfig = {
    shorthand: false,
    dateFormat: "F Y",
    altFormat: "F Y",
    theme: "light"
  };

  function monthSelectPlugin(pluginConfig) {
    var config = _assign({}, defaultConfig, pluginConfig);

    return function (fp) {
      fp.config.dateFormat = config.dateFormat;
      fp.config.altFormat = config.altFormat;
      var self = {
        monthsContainer: null
      };

      function clearUnnecessaryDOMElements() {
        if (!fp.rContainer || !fp.daysContainer || !fp.weekdayContainer) return;
        fp.rContainer.removeChild(fp.daysContainer);
        fp.rContainer.removeChild(fp.weekdayContainer);

        for (var index = 0; index < fp.monthElements.length; index++) {
          var element = fp.monthElements[index];
          if (!element.parentNode) continue;
          element.parentNode.removeChild(element);
        }
      }

      function addListeners() {
        fp._bind(fp.prevMonthNav, "click", function () {
          fp.currentYear -= 1;
          selectYear();
        });

        fp._bind(fp.nextMonthNav, "mousedown", function () {
          fp.currentYear += 1;
          selectYear();
        });
      }

      function addMonths() {
        if (!fp.rContainer) return;
        self.monthsContainer = fp._createElement("div", "flatpickr-monthSelect-months");
        self.monthsContainer.tabIndex = -1;
        fp.calendarContainer.classList.add("flatpickr-monthSelect-theme-" + config.theme);

        for (var i = 0; i < 12; i++) {
          var month = fp._createElement("span", "flatpickr-monthSelect-month");

          month.dateObj = new Date(fp.currentYear, i);
          month.$i = i;
          month.textContent = monthToStr(i, config.shorthand, fp.l10n);
          month.tabIndex = -1;
          month.addEventListener("click", selectMonth);
          self.monthsContainer.appendChild(month);

          if (fp.config.minDate && month.dateObj < fp.config.minDate || fp.config.maxDate && month.dateObj > fp.config.maxDate) {
            month.classList.add("disabled");
          }
        }

        fp.rContainer.appendChild(self.monthsContainer);
      }

      function setCurrentlySelected(setMonth) {
        if (!fp.rContainer) return;
        var currentlySelected = fp.rContainer.querySelectorAll(".flatpickr-monthSelect-month.selected");

        for (var index = 0; index < currentlySelected.length; index++) {
          currentlySelected[index].classList.remove("selected");
        }

        if (setMonth) {
          var month = fp.rContainer.querySelector(".flatpickr-monthSelect-month:nth-child(" + (fp.currentMonth + 1) + ")");

          if (month) {
            month.classList.add("selected");
          }
        }
      }

      function selectYear() {
        var selectedDate = fp.selectedDates[0];

        if (selectedDate) {
          selectedDate = new Date(selectedDate);
          selectedDate.setFullYear(fp.currentYear);

          if (fp.config.minDate && selectedDate < fp.config.minDate) {
            selectedDate = fp.config.minDate;
          }

          if (fp.config.maxDate && selectedDate > fp.config.maxDate) {
            selectedDate = fp.config.maxDate;
          }

          fp.currentYear = selectedDate.getFullYear();
          fp.currentYearElement.value = String(fp.currentYear);
          setCurrentlySelected();
        }

        if (fp.rContainer) {
          var months = fp.rContainer.querySelectorAll(".flatpickr-monthSelect-month");
          months.forEach(function (month) {
            month.dateObj.setFullYear(fp.currentYear);

            if (fp.config.minDate && month.dateObj < fp.config.minDate || fp.config.maxDate && month.dateObj > fp.config.maxDate) {
              month.classList.add("disabled");
            } else {
              month.classList.remove("disabled");
            }
          });
        }
      }

      function selectMonth(e) {
        e.preventDefault();
        e.stopPropagation();

        if (e.target instanceof Element && !e.target.classList.contains("disabled")) {
          setMonth(e.target.dateObj);
          fp.close();
        }
      }

      function setMonth(date) {
        var selectedDate = new Date(date);
        selectedDate.setFullYear(fp.currentYear);
        fp.currentMonth = selectedDate.getMonth();
        fp.setDate(selectedDate, true);
        setCurrentlySelected(true);
      }

      var shifts = {
        37: -1,
        39: 1,
        40: 3,
        38: -3
      };

      function onKeyDown(_, __, ___, e) {
        var shouldMove = shifts[e.keyCode] !== undefined;

        if (!shouldMove && e.keyCode !== 13) {
          return;
        }

        if (!fp.rContainer || !self.monthsContainer) return;
        var currentlySelected = fp.rContainer.querySelector(".flatpickr-monthSelect-month.selected");
        var index = Array.prototype.indexOf.call(self.monthsContainer.children, document.activeElement);

        if (index === -1) {
          var target = currentlySelected || self.monthsContainer.firstElementChild;
          target.focus();
          index = target.$i;
        }

        if (shouldMove) {
          self.monthsContainer.children[(12 + index + shifts[e.keyCode]) % 12].focus();
        } else if (e.keyCode === 13 && self.monthsContainer.contains(document.activeElement)) {
          setMonth(document.activeElement.dateObj);
        }
      }

      function destroyPluginInstance() {
        if (self.monthsContainer !== null) {
          var months = self.monthsContainer.querySelectorAll(".flatpickr-monthSelect-month");

          for (var index = 0; index < months.length; index++) {
            months[index].removeEventListener("click", selectMonth);
          }
        }
      }

      return {
        onParseConfig: function onParseConfig() {
          fp.config.mode = "single";
          fp.config.enableTime = false;
        },
        onValueUpdate: setCurrentlySelected,
        onKeyDown: onKeyDown,
        onReady: [clearUnnecessaryDOMElements, addListeners, addMonths, setCurrentlySelected],
        onDestroy: destroyPluginInstance
      };
    };
  }
});