define("propertycloud/pods/components/no-tenancies/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "asWf7ctV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"no-tenancies\"],[8],[0,\"\\n  \"],[1,[28,\"svg-jar\",[\"no-tenancies\"],null],false],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"no-tenancies__explanation\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[0,\"We looked but we couldn't find any tenancies for this account\"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"We have searched our records related to your account but have not been able to find any current, upcoming or past tenancies.\"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"If you believe this is a mistake please speak to your current estate agent to confirm the account details they used to invite you to PropertyCloud.\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"userIsGmail\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"no-tenancies__gmail\"],[8],[0,\"\\n        \"],[7,\"p\",true],[8],[0,\"It looks like you use Google's GMail as your email provider. Google provides two addresses for your inbox and it's possible your agent has used the wrong one.\"],[9],[0,\"\\n        \"],[7,\"p\",true],[8],[0,\"You may try logging in with \"],[7,\"strong\",true],[8],[1,[22,\"alternateEmail\"],false],[9],[0,\" instead of \"],[7,\"strong\",true],[8],[1,[22,\"currentEmail\"],false],[9],[0,\" before speaking to your agent.\"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn--large btn--negative\"],[3,\"action\",[[23,0,[]],\"logout\"]],[8],[0,\"Logout\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/no-tenancies/template.hbs"
    }
  });

  _exports.default = _default;
});