define("propertycloud/adapters/tenant-upcoming-tenancy", ["exports", "propertycloud/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    queryRecord: function queryRecord(store, type, query) {
      var url = "".concat(this.host, "/").concat(this.namespace, "/estate/tenant.view-tenancies");
      query.type = 'upcoming';
      return this.ajax(url, 'GET', {
        data: query
      });
    }
  });

  _exports.default = _default;
});