define("propertycloud/initializers/flash-messages", ["exports", "propertycloud/config/environment", "ember-cli-flash/utils/flash-message-options"], function (_exports, _environment, _flashMessageOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /* eslint-disable ember/new-module-imports */
  var deprecate = Ember.deprecate;
  var INJECTION_FACTORIES_DEPRECATION_MESSAGE = '[ember-cli-flash] Future versions of ember-cli-flash will no longer inject the service automatically. Instead, you should explicitly inject it into your Route, Controller or Component with `Ember.inject.service`.';

  function initialize() {
    var application = arguments[1] || arguments[0];

    var _ref = _environment.default || {},
        flashMessageDefaults = _ref.flashMessageDefaults;

    var _ref2 = flashMessageDefaults || [],
        injectionFactories = _ref2.injectionFactories;

    var options = (0, _flashMessageOptions.default)(flashMessageDefaults);
    var shouldShowDeprecation = !(injectionFactories && injectionFactories.length);
    deprecate(INJECTION_FACTORIES_DEPRECATION_MESSAGE, shouldShowDeprecation, {
      id: 'ember-cli-flash.deprecate-injection-factories',
      until: '2.0.0'
    });
    options.injectionFactories.forEach(function (factory) {
      application.inject(factory, 'flashMessages', 'service:flash-messages');
    });
  }

  var _default = {
    name: 'flash-messages',
    initialize: initialize
  };
  _exports.default = _default;
});