define("propertycloud/pods/application/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "moment"], function (_exports, _applicationRouteMixin, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      _moment.default.locale('en-gb');
    },
    activate: function activate() {
      var _this = this;

      window.addEventListener('message', function (e) {
        if (e.data.access_token) {
          _this.session.authenticate('authenticator:oauth2-implicit-grant', e.data);
        }
      });
    }
  });

  _exports.default = _default;
});