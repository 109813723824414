define("propertycloud/pods/user-change-email/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    model: function model(params) {
      Ember.set(this, 'params', params);
    },
    activate: function activate() {
      var ajax = Ember.get(this, 'ajax');
      ajax.post('iam/user.confirm-email-change', {
        data: JSON.stringify({
          payload: {
            emailAddress: Ember.get(this, 'params.new_email'),
            userId: Ember.get(this, 'params.user_id'),
            token: Ember.get(this, 'params.token')
          }
        })
      }); //supports .then
    }
  });

  _exports.default = _default;
});