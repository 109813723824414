define("propertycloud/pods/logged-in/finance/reports/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MDzwqSp+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"grid grid--padded\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid__row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"grid__col\"],[8],[0,\"\\n      \"],[1,[28,\"income-expenditure-table\",null,[[\"title\",\"canDelete\",\"perPage\",\"tableHeight\",\"showFilters\",\"showChart\",\"type\"],[\"Income and Expenditure\",true,25,\"520px\",true,false,\"agent\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/finance/reports/template.hbs"
    }
  });

  _exports.default = _default;
});