define("propertycloud/pods/components/validation-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      validate: function validate(prop, changeset) {
        changeset.validate(prop);
      },
      makeChange: function makeChange(value) {
        var propertyName = this.propertyName;
        var changeset = this.changeset;
        Ember.set(changeset, propertyName, value);
        var changeAction = this.onchange;

        if (changeAction) {
          changeAction(value);
        }
      },
      onFocusAction: function onFocusAction() {
        var onFocus = this.onfocus;

        if (onFocus) {
          onFocus();
        }
      },
      showCreateWhenAction: function showCreateWhenAction(value) {
        var showCreateWhen = this.showCreateWhen;

        if (showCreateWhen) {
          // ShowCreateWhen function needs to returns true|false
          return showCreateWhen(value);
        }
      },
      onCreateAction: function onCreateAction(value) {
        var oncreate = this.oncreate;

        if (oncreate) {
          oncreate(value);
        }
      }
    }
  });

  _exports.default = _default;
});