define("propertycloud/pods/components/file-manager/list-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g9gbxSxV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\",true],[10,\"class\",\"file-list__file\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isImage\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"file-list__icon\"],[8],[0,\"\\n      \"],[1,[28,\"svg-jar\",[\"photo-icon\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"file-list__preview\"],[8],[0,\"\\n      \"],[7,\"img\",true],[11,\"src\",[28,\"html-safe\",[[24,[\"file\",\"url\"]]],null]],[10,\"alt\",\"Upload Preview\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",false],[12,\"class\",\"file-list__name\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"selectFile\",[24,[\"file\"]]]],[8],[0,\"\\n      Photo\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"file-list__icon\"],[8],[0,\"\\n      \"],[1,[28,\"svg-jar\",[\"video-icon\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",false],[12,\"class\",\"file-list__name\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"selectFile\",[24,[\"file\"]]]],[8],[0,\"\\n      Video\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[4,\"hold-button\",[[24,[\"file\"]]],[[\"type\",\"action\",\"delay\"],[\"btn btn--nothing file-list__remove\",\"removeFile\",2000]],{\"statements\":[[0,\"    Hold to Remove\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/file-manager/list-row/template.hbs"
    }
  });

  _exports.default = _default;
});