define("propertycloud/pods/contract/tenant/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    upcomingTenants: Ember.computed.filter('model.tenancy.agreements.content.meta.tenants', function (tenant) {
      if (Ember.get(tenant, 'agreement-type') === 'upcoming' && Ember.get(tenant, 'voided') === false && Ember.get(tenant, 'approved') === false) {
        return tenant;
      }
    }),
    thisTenant: Ember.computed.filter('upcomingTenants', function (tenant) {
      if (Ember.get(tenant, 'id') === Ember.get(this, 'user.userId')) {
        return tenant;
      }
    })
  });

  _exports.default = _default;
});