define("propertycloud/pods/components/landlord-payments/input-payment-date/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.paidDate = this.paidDate || new Date();
    },
    actions: {
      setDate: function setDate(row, val) {
        if (val.length) {
          Ember.set(this, 'paidDate', val[0]);
          Ember.set(row, 'paidDate', val[0]);
        }
      }
    }
  });

  _exports.default = _default;
});