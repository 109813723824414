define("propertycloud/pods/components/viewing/viewing-list/component", ["exports", "ember-group-by", "moment", "propertycloud/helpers/snake-to-camel"], function (_exports, _emberGroupBy, _moment, _snakeToCamel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    showPast: false,
    order: 'desc',
    sortBy: 'preferredTime',
    viewingSorting: Ember.computed('order', 'sort', function () {
      var order = Ember.get(this, 'order');
      var sort = Ember.get(this, 'sort');
      var sortDatePropertyMapping = {
        '-start': 'start',
        'start': 'start',
        '-requested-at': 'requestedAt',
        'requested-at': 'requestedAt',
        '-preferred-time': 'preferredTime',
        'preferred-time': 'preferredTime'
      };
      return ["".concat(sortDatePropertyMapping[sort], ":").concat(order)];
    }),
    selectedPropertyFilter: null,
    selectedStaffFilter: null,
    selectedDate: Ember.computed('start', function () {
      // If there is a query parameter of start, set that, if not, set todays date.
      if (Ember.get(this, 'start')) {
        return Ember.get(this, 'start');
      }

      return (0, _moment.default)().format('YYYY-MM-DD');
    }),
    filtersExist: Ember.computed('property', 'start', 'userId', function () {
      var propertyFilter = Ember.get(this, 'property'),
          dateFilter = Ember.get(this, 'start'),
          staffFilter = Ember.get(this, 'userId');

      if (propertyFilter || dateFilter || staffFilter) {
        return true;
      }

      return false;
    }),
    orderByOptions: Ember.computed('scheduled', function () {
      if (Ember.get(this, 'scheduled')) {
        return [{
          value: 'start',
          label: 'Scheduled For'
        }, {
          value: 'requestedAt',
          label: 'Requested At'
        }];
      } else {
        return [{
          value: 'preferredTime',
          label: 'Preferred Time'
        }, {
          value: 'requestedAt',
          label: 'Requested At'
        }];
      }
    }),
    orderBy: Ember.computed('scheduled', 'sort', function () {
      var sortStripped = (0, _snakeToCamel.snakeToCamel)(Ember.get(this, 'sort').replace(/^-/, ''));

      if (sortStripped === 'preferredTime') {
        return {
          value: 'preferredTime',
          label: 'Preferred Time'
        };
      } else if (sortStripped === 'start') {
        return {
          value: 'start',
          label: 'Scheduled For'
        };
      }

      return {
        value: 'requestedAt',
        label: 'Requested At'
      };
    }),
    isDetail: Ember.computed('router.currentRouteName', function () {
      return Ember.get(this, 'router.currentRouteName') === 'logged-in.viewings.detail';
    }),
    viewingsSorted: Ember.computed.sort('viewings', 'viewingSorting'),
    viewingsByScheduled: (0, _emberGroupBy.default)('viewingsSorted', 'dateScheduled'),
    // human readable format of scheduledAt
    viewingsByRequested: (0, _emberGroupBy.default)('viewingsSorted', 'dateRequested'),
    // human readable formats of requestedAt
    viewingsByPreferred: (0, _emberGroupBy.default)('viewingsSorted', 'datePreferred'),
    // human readable format of preferredTime
    viewingsGrouped: Ember.computed('orderBy', 'order', 'viewings', 'viewingsByScheduled', 'viewingsByRequested', 'viewingsByPreferred', function () {
      if (Ember.get(this, 'orderBy.value') === 'start') {
        return Ember.get(this, 'viewingsByScheduled');
      } else if (Ember.get(this, 'orderBy.value') === 'preferredTime') {
        return Ember.get(this, 'viewingsByPreferred');
      }

      return Ember.get(this, 'viewingsByRequested');
    }),
    actions: {
      reverseSort: function reverseSort() {
        if (this.order === 'desc') {
          Ember.set(this, 'order', 'asc');
        } else {
          Ember.set(this, 'order', 'desc');
        }

        var newSort = null;
        var orderByTransformed = this.orderBy.value.split(/(?=[A-Z])/).join('-').toLowerCase();

        if (this.order === 'desc') {
          newSort = '-' + orderByTransformed;
        } else {
          newSort = orderByTransformed;
        }

        Ember.set(this, 'sort', newSort);
      },
      // Options: start, -start, requested-at, -requested-at, preferred-time, -preferred-time
      orderByChange: function orderByChange(order) {
        Ember.set(this, 'orderBy', order);
        var newSort = null;
        var orderByTransformed = order.value.split(/(?=[A-Z])/).join('-').toLowerCase();

        if (this.order === 'desc') {
          newSort = '-' + orderByTransformed;
        } else {
          newSort = orderByTransformed;
        }

        Ember.set(this, 'sort', newSort);
      },
      clearFilter: function clearFilter() {
        this.setProperties({
          property: null,
          userId: null,
          start: null,
          selectedPropertyFilter: null,
          selectedStaffFilter: null,
          selectedDate: null
        });
      }
    }
  });

  _exports.default = _default;
});