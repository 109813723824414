define("propertycloud/pods/components/landlord-statements-approval/name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yrozW0G0",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",null,[[\"route\",\"model\"],[\"logged-in.manage.tenant\",[24,[\"row\",\"content\",\"id\"]]]],{\"statements\":[[1,[22,\"value\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/components/landlord-statements-approval/name/template.hbs"
    }
  });

  _exports.default = _default;
});