define("propertycloud/pods/logged-in/finance/landlord-payments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0h01iWSM",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Landlord Payments\"],null],false],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"grid grid--padded\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid__row\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"grid__col scrollable-table-mob\"],[8],[0,\"\\n      \"],[1,[28,\"landlord-payments\",null,[[\"isEditable\"],[true]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/finance/landlord-payments/template.hbs"
    }
  });

  _exports.default = _default;
});