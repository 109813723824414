define("propertycloud/pods/logged-in/guarantor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pX01XAYq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-internal-nav\"],[8],[0,\"\\n\"],[4,\"section-navigation\",null,[[\"class\"],[\"dashboard-nav\"]],{\"statements\":[[0,\"    \"],[7,\"ul\",true],[10,\"class\",\"icon-menu\"],[8],[0,\"\\n      \"],[7,\"li\",false],[12,\"class\",\"icon-menu__item icon-menu__item--logout\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"logout\"]],[8],[1,[28,\"svg-jar\",[\"logout\"],[[\"class\"],[\"icon-menu__icon\"]]],false],[0,\" Logout\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"app-internal-nav__outlet\"],[8],[0,\"\\n    \"],[1,[22,\"liquid-outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "propertycloud/pods/logged-in/guarantor/template.hbs"
    }
  });

  _exports.default = _default;
});