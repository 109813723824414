define("propertycloud/pods/components/photo-swipe/component", ["exports", "ember-cli-photoswipe/components/photo-swipe"], function (_exports, _photoSwipe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _photoSwipe.default.extend({
    _buildOptions: function _buildOptions() {
      var reqOpts = {
        history: false,
        getThumbBoundsFn: function getThumbBoundsFn(index) {
          var thumbnail = document.querySelectorAll('.justify-gallery img')[index];
          var pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
          var rect = thumbnail.getBoundingClientRect();
          return {
            x: rect.left,
            y: rect.top + pageYScroll,
            w: rect.width
          };
        }
      };
      var options = Ember.assign(reqOpts, this.options || {});
      this.set('options', options);
    },
    onInsert: function onInsert() {},
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, this.initGallery);
    },
    initGallery: function initGallery() {
      this.set('pswpEl', document.querySelector('.pswp'));
      this.set('pswpTheme', PhotoSwipeUI_Default);

      this._buildOptions();

      if (this.items) {
        return this._initItemGallery();
      }
    }
  });

  _exports.default = _default;
});