define("propertycloud/pods/components/landlord-statements/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    statements: Ember.computed('model', function () {
      var statements = {};
      this.model.forEach(function (record) {
        var date = Ember.get(record, 'month');

        if (typeof statements[(0, _moment.default)(date).format('YYYY')] === 'undefined') {
          statements[(0, _moment.default)(date).format('YYYY')] = [];
        }

        statements[(0, _moment.default)(date).format('YYYY')].push(record);
      });
      return statements;
    })
  });

  _exports.default = _default;
});